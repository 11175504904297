import { activitiesMonthPath } from 'src/helpers/routes';
import activity from 'src/routes/activity';
import { API_CALL } from 'src/middleware/API';
import { fetchEvents } from 'src/actions/Activities';
import { startStrMonth, endStrMonth } from 'src/helpers/activityDefaultDates';
import history from 'src/helpers/history';

const prepareData = ({ store }) => {
  const state = store.get();
  if (state.Auth.connected === false) return;
  const accessToken = state.Auth.token;
  const query = { startStr: startStrMonth, endStr: endStrMonth };
  if (history.location.state && history.location.state.renderRange) {
    const { start, end } = history.location.state.renderRange;
    query.startStr = start.toISOString();
    query.endStr = end.toISOString();
  }
  store.dispatch(API_CALL, fetchEvents({ accessToken, query }));
};

export default Object.assign(
  {},
  activity,
  { path: activitiesMonthPath(), prepareData }
);
