import * as types from 'src/constants/actionTypes/ListingsActionTypes';
import initialState from 'src/helpers/initialStateSubmit';
import { SUBMIT_RECENTLY_VIEWED } from 'src/constants/actionTypes/RecentlyViewedActionTypes';

export default store => {
  store.on('@init', () => ({ SubmitListing: initialState }));

  store.on(types.SUBMIT_LISTING_RESET, () => ({ SubmitListing: initialState }));

  store.on(types.SUBMIT_LISTING_REQUEST, ({ SubmitListing }) =>
    ({ SubmitListing: { ...initialState, sendRequest: true }}));
  store.on(types.SUBMIT_LISTING_SUCCESS, ({ SubmitListing }, { response }) => {
    store.dispatch(SUBMIT_RECENTLY_VIEWED, {
      data: { id: response.data.id, name: response.data.name, time: Date.now()},
      endpoint: 'listings'
    });
    return ({ SubmitListing: { ...SubmitListing, success: true, sendRequest: false }});
  });
  store.on(types.SUBMIT_LISTING_ERROR, ({ SubmitListing }, { response }) => {
    let errors = {};
    if (response && response.body && response.body.errors) errors = response.body.errors;
    return ({ SubmitListing: {...SubmitListing, error: true, errors: errors, sendRequest: false }});
  });
}
