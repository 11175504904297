import React from 'react';
import PropTypes from 'prop-types';
import Link from 'src/helpers/Link';
import { contactPath } from 'src/helpers/routes';

const LinkToContactWithDate = ({ contactName, contactId, date, isDate = false }) => {
  return (
    <span className="link-block">
      <Link to={contactPath(contactId)} className="mr-2" target="_blank">{contactName}</Link>
      {isDate && <span className="badge badge-pill badge-info">{date}</span>}
    </span>
  )
}

LinkToContactWithDate.propTypes = {
  contactName: PropTypes.string.isRequired,
  contactId: PropTypes.number.isRequired
};

export default LinkToContactWithDate;
