import { adminCompanyDetailsEdit } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchBaseCompany } from 'src/actions/BaseCompany';
import { fetchCompanyCollections } from 'src/actions/Companies';
import { API_CALL } from 'src/middleware/API';

const CompanyDetailsEdit = lazy(() => import('src/components/views/admin/company/Edit'));

export default {
  path: adminCompanyDetailsEdit(),
  exact: true,
  strict: true,
  component: CompanyDetailsEdit,
  protected: true,
  perform: 'admin/companyDetails:edit',
  title: 'Company Details',
  prepareData: ({ store, params }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    if (Object.keys(state.CompanyCollections.items).length ===  0) {
      store.dispatch(API_CALL, fetchCompanyCollections({ accessToken }));
    }
    const query = { noTypeCast: true }
    store.dispatch(API_CALL, fetchBaseCompany({ accessToken, query }));
  }
};
