import { propertyNewPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchPropertyCollections } from 'src/actions/Properties';
import { API_CALL } from 'src/middleware/API';

const PropertyNew = lazy(() => import('src/components/views/properties/New'));

export default {
  path: propertyNewPath(),
  exact: true,
  strict: true,
  component: PropertyNew,
  perform: 'properties:new',
  protected: true,
  prepareData: ({ store }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    if (Object.keys(state.PropertyCollections.items).length > 0) return;
    const accessToken = state.Auth.token;
    store.dispatch(API_CALL, fetchPropertyCollections({ accessToken }));
  }
};
