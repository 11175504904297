import * as types from 'src/constants/actionTypes/OffersActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  loaded: false,
  items: {}
}

export default store => {
  store.on('@init', () => ({ OfferCollections: initialState }));

  store.on(types.FETCH_OFFER_COLLECTION_REQUEST, ({ OfferCollections }) =>
    ({ OfferCollections: { ...OfferCollections, isFetching: true, loaded: false }}));
  store.on(types.FETCH_OFFER_COLLECTION_SUCCESS, ({ OfferCollections }, { response }) =>
    ({ OfferCollections: { ...OfferCollections, items: response.data, loaded: true }}));
  store.on(types.FETCH_OFFER_COLLECTION_ERROR, ({ OfferCollections }) =>
    ({ OfferCollections: {...OfferCollections, error: true }}));
}
