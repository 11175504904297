export const FETCH_OFFERS_REQUEST = 'FETCH_OFFERS_REQUEST';
export const FETCH_OFFERS_SUCCESS = 'FETCH_OFFERS_SUCCESS';
export const FETCH_OFFERS_ERROR = 'FETCH_OFFERS_ERROR';

export const FETCH_OFFER_REQUEST = 'FETCH_OFFER_REQUEST';
export const FETCH_OFFER_SUCCESS = 'FETCH_OFFER_SUCCESS';
export const FETCH_OFFER_ERROR = 'FETCH_OFFER_ERROR';
export const FETCH_OFFER_RESET = 'FETCH_OFFER_RESET';

export const FETCH_OFFER_COLLECTION_REQUEST = 'FETCH_OFFER_COLLECTION_REQUEST';
export const FETCH_OFFER_COLLECTION_SUCCESS = 'FETCH_OFFER_COLLECTION_SUCCESS';
export const FETCH_OFFER_COLLECTION_ERROR = 'FETCH_OFFER_COLLECTION_ERROR';

export const DELETE_OFFER_REQUEST = 'DELETE_OFFER_REQUEST';
export const DELETE_OFFER_SUCCESS = 'CREATE_OFFER_SUCCESS';
export const DELETE_OFFER_ERROR = 'DELETE_OFFER_ERROR';

export const DELETE_OFFERS_REQUEST = 'DELETE_OFFERS_REQUEST';
export const DELETE_OFFERS_SUCCESS = 'DELETE_OFFERS_SUCCESS';
export const DELETE_OFFERS_ERROR = 'DELETE_OFFERS_ERROR';

export const SUBMIT_OFFER_REQUEST = 'SUBMIT_OFFER_REQUEST';
export const SUBMIT_OFFER_SUCCESS = 'SUBMIT_OFFER_SUCCESS';
export const SUBMIT_OFFER_ERROR = 'SUBMIT_OFFER_ERROR';
export const SUBMIT_OFFER_RESET = 'SUBMIT_OFFER_RESET';
