import { ContactEnums } from 'src/-types/models/contact';
import { ModelEnumState, BaseEnumCollection } from 'src/-types/enums';

export type ContactEnumState = ModelEnumState<ContactEnums>;

export class ContactEnumCollection extends BaseEnumCollection<ContactEnumState> {
  protected defaultData: ContactEnums = {
    contactType: []
  };

  public constructor(state: ContactEnumState) {
    super(state);
  }
}
