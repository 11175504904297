import * as types from 'src/constants/actionTypes/files/RenameFileActionTypes';

const initialState = {
  sendRequest: false,
  error: false,
  errors: {}
}

export default store => {
  store.on('@init', () => ({ SubmitRenameFile: initialState }));

  store.on(types.RENAME_FILE_RESET, () => ({ SubmitRenameFile: initialState }));
  store.on(types.RENAME_FILE_REQUEST, ({ SubmitRenameFile }) =>
    ({ SubmitRenameFile: { ...SubmitRenameFile, ...initialState, sendRequest: true }}));
  store.on(types.RENAME_FILE_SUCCESS, ({ SubmitRenameFile }, { response }) => {
    return ({ SubmitRenameFile: { ...SubmitRenameFile, success: true, sendRequest: false }});
  });
  store.on(types.RENAME_FILE_ERROR, ({ SubmitRenameFile }, { response }) => {
    let errors = {};
    if (response && response.body && response.body.errors) errors = response.body.errors;
    return ({ SubmitRenameFile: {...SubmitRenameFile, error: true, errors: errors, sendRequest: false }});
  });
}
