import { snagNewPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { API_CALL } from 'src/middleware/API';
import { Route } from 'src/-types/routing';
import { fetchAllProperties } from 'src/actions/entities/property';
import { fetchSnagEnums } from 'src/actions/enums/snag';
import { fetchAllProjects } from 'src/actions/entities/project';
import { fetchFieldsLookup } from 'src/actions/lookups';

const SnagNew = lazy(() => import('src/components/views/snags/SnagNew'));

export const snagNewRoute: Route = {
  path: snagNewPath(),
  exact: true,
  strict: true,
  component: SnagNew,
  perform: 'snags:new',
  protected: true,
  prepareData: ({ store }) => {
    const { Auth: { connected } } = store.get();

    if (connected !== true) {
      return;
    }

    store.dispatch(API_CALL, fetchAllProjects());
    store.dispatch(API_CALL, fetchAllProperties());
    store.dispatch(API_CALL, fetchSnagEnums());
    store.dispatch(API_CALL, fetchFieldsLookup());
  }
};

export default snagNewRoute;
