import * as types from 'src/constants/actionTypes/ListingsActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  loaded: false,
  items: {}
}

export default store => {
  store.on('@init', () => ({ ListingCollections: initialState }));

  store.on(types.FETCH_LISTING_COLLECTION_REQUEST, ({ ListingCollections }) =>
    ({ ListingCollections: { ...ListingCollections, isFetching: true, loaded: false }}));
  store.on(types.FETCH_LISTING_COLLECTION_SUCCESS, ({ ListingCollections }, { response }) =>
    ({ ListingCollections: { ...ListingCollections, items: response.data, loaded: true }}));
  store.on(types.FETCH_LISTING_COLLECTION_ERROR, ({ ListingCollections }) =>
    ({ ListingCollections: {...ListingCollections, error: true }}));
}
