import React from 'react';
import useStoreon from 'src/helpers/storeon';
import { useTranslation } from 'react-i18next';
import { isFunction } from 'lodash';

const adminRoles = ["director", "salesManager", "salesAdmin", "superAdmin"];
const teamRoles = ["teamManager", "teamAdmin"];
const brokerRoles = ["broker"];
const accountantRoles = ["accountant"];

export const optionsByRole = ({ role, endpoint }) => {
  if (adminRoles.includes(role)) {
    return ["my", "all"];
  } else if (teamRoles.includes(role)) {
    return ["my", "team"];
  } else if (brokerRoles.includes(role) &&
    endpoint !== 'projects' &&
    endpoint !== 'listings') {
    return ["my"];
  } else if (
    brokerRoles.includes(role) && (endpoint === 'projects' || endpoint === 'listings')
  ) {
    return ["all"];
  } else if (accountantRoles.includes(role)) {
    return ["my", "all"];
  } else {
    return [];
  }
};

export default ({ onChange, endpoint }) => {
  const { Auth: { user: { role } } } = useStoreon('Auth');
  const { t } = useTranslation();
  const options = optionsByRole({ role, endpoint });

  const handlerOnChange = (event) => {
    event.preventDefault();
    const { target } = event;
    const { value } = target.options[target.selectedIndex];
    const role = { value, type: 'role' };
    onChange({ currFilters: { role } });
  };

  return (
    <>
      {options.length > 0 &&
        <div className="form-group">
          <label className="mr-2 form-label text-dark-blue">{t("datatable.ownedBy")}</label>
          <select className="form-control min-width-100" onChange={handlerOnChange} defaultValue={options[0]}>
            {options.map((view, index) =>
              <option key={`7wehvwevhgg-${index}`} value={view}>{isFunction(options?.textFormatter) ? options.textFormatter(view, t) : t(`dataTable.viewsByUser.${view}`)}</option>
            )}
          </select>
        </div>
      }
    </>
  )
};
