import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const HorizontalInput = ({
  labelSize = "col-2", inputSize = "col-9", inputClassNames = "",
  label, required = false, name, onChange, errors, value, type = 'text'
}) => {
  const { t } = useTranslation();
  const errorMessage = errors ? errors.join(', ') : t('form.provideValid', { label });
  return (
    <div className="form-row mb-1">
      <label className={`${labelSize} col-form-label form-label`}>{label}</label>
      <div className={`${inputSize}`}>
        <input
          type={type}
          className={`form-control ${inputClassNames}`}
          name={name}
          placeholder={label}
          required={required}
          onChange={onChange}
          value={value === null ? '' : value}
          autoComplete="new-password"
        />
        <div className='invalid-feedback'>{errorMessage}</div>
      </div>
    </div>
  )
}

HorizontalInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  labelSize: PropTypes.string,
  inputSize: PropTypes.string,
  inputClassNames: PropTypes.string
};

export default HorizontalInput;
