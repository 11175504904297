import React from 'react';

export default () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center flex-row-reverse">
          <div className="col-md-12 col-sm-12 mt-3 mt-lg-0 text-center">
            Copyright © 2020 <a href="https://propertynexus.co" target="_blank" rel="noopener noreferrer">Property Nexus</a>. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};
