import { SnagJsonApiEntity } from 'src/-types/models/snag';
import { map, mapKeys, snakeCase, isNil } from 'lodash';
import { BaseJsonApiResponse, BaseJsonApiCollectionResponse, ModelTypeOf } from 'src/-types/models/json-api';
import { toModelType } from 'src/-utils/model';

export class SnagSerializer {
  protected readonly modelType: ModelTypeOf<SnagJsonApiEntity> = 'snag';

  public normalizeResponse(response: BaseJsonApiResponse<SnagJsonApiEntity>): BaseJsonApiResponse<SnagJsonApiEntity> {
    const result = {
      ...response,
      data: this.normalizeResouceObject(response.data)
    };

    return result;
  }

  public normalizeCollectionResponse(response: BaseJsonApiCollectionResponse<SnagJsonApiEntity>): BaseJsonApiCollectionResponse<SnagJsonApiEntity> {
    const result = {
      ...response,
      data: map(response.data, resourceObject => this.normalizeResouceObject(resourceObject))
    };

    return result;
  }

  protected normalizeResouceObject(resourceObject: SnagJsonApiEntity): SnagJsonApiEntity {
    const result = this.normalizeAttributes(this.normalizeType(this.normalizeId(resourceObject)));

    return result;
  }

  protected normalizeId(resourceObject: SnagJsonApiEntity): SnagJsonApiEntity {
    const result = {
      ...resourceObject,
      id: resourceObject.id + ''
    };

    return result;
  }

  protected normalizeType(resourceObject: SnagJsonApiEntity): SnagJsonApiEntity {
    const type = toModelType(resourceObject.type);

    if (type !== this.modelType) {
      throw new Error(`Model type should be "${this.modelType}", but was "${type}".`);
    }

    const result = { ...resourceObject, type: this.modelType as ModelTypeOf<SnagJsonApiEntity> };

    return result;
  }

  protected normalizeAttributes(resourceObject: SnagJsonApiEntity): SnagJsonApiEntity {
    const result = {
      ...resourceObject,
      attributes: {
        ...resourceObject.attributes,
        files: map(resourceObject.attributes.files, file => ({
          ...file,
          meta: {
            ...file.meta,
            sort: isNil(file.meta.sort) ? undefined : mapKeys(file.meta.sort, (_value, key) => snakeCase(key))
          }
        })),
        images: map(resourceObject.attributes.images, image => ({
          ...image,
          meta: {
            ...image.meta,
            sort: isNil(image.meta.sort) ? undefined : mapKeys(image.meta.sort, (_value, key) => snakeCase(key))
          }
        }))
      }
    };

    return result;
  }
}
