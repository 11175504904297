import React from 'react';
import { checkPerform } from 'src/helpers/authorization';

interface AccessProps {
  yes: () => null;
  no: () => null;
  role: string;
  perform: string;
  data: any;
  route: string;
}

const Access: React.FunctionComponent<AccessProps> = ({ yes = () => null, no = () => null, role, perform, data = {}, route }) => checkPerform({ role, perform, data }) ? yes() : no();

export default Access;
