export interface StateLookup<T = any> {
  data?: T;
  error: any;
  fetchCount: number;
  hasLoaded: boolean;
}

type LookupDataType<T> = T extends StateLookup<infer A> ? A : never;

export abstract class BaseLookup<T extends StateLookup> {
  protected abstract defaultData: LookupDataType<T>;

  protected constructor(protected state: T) {
    // noop
  }

  public get data(): LookupDataType<T> {
    return this.state.data ?? this.defaultData;
  }

  public get error(): any {
    return this.state.error;
  }

  public get isFetching(): boolean {
    return this.state.fetchCount > 0;
  }

  public get hasLoaded(): boolean {
    return this.state.hasLoaded;
  }

  public get isReady(): boolean {
    return this.hasLoaded && !this.isFetching;
  }
}
