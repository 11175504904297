import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import 'src/styles/select-search.scss';

const VerticalMoneyInput = ({
  label, required = false, name, onChange, errors, value, type = 'text', nameSelect,
  collection = [], onChangeSelect, multiple, valueSelect, currency = '', inputName = undefined
}) => {

  const { t } = useTranslation();
  const errorMessage = errors ? errors.join(', ') : t('form.provideValid', { label });
  const moneyValue = value === null ? 0 : value / 100;

  return (
    <div className="form-group">
      <label className="form-label">{label} ({currency})</label>
      <div className="row">
        <div className="col">
          <input
            type="text"
            className="form-control"
            name={name}
            placeholder={label}
            required={required}
            onChange={onChange}
            value={moneyValue}
            autoComplete="new-password"
            data-money="true"
            data-input-name={inputName}
          />
          <div className='invalid-feedback'>{errorMessage}</div>
        </div>
      </div>
    </div>
  )
}

VerticalMoneyInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default VerticalMoneyInput;
