import { leadPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchLead, fetchLeadCollections } from 'src/actions/Leads';
import { API_CALL } from 'src/middleware/API';
import { fetchActivityCollections } from 'src/actions/Activities';
import Layout from 'src/components/views/showLayout';

const LeadShow = lazy(() => import('src/components/views/leads/Show'));

export default {
  path: leadPath(),
  exact: true,
  strict: true,
  component: LeadShow,
  perform: 'leads:show',
  layout: Layout,
  protected: true,
  prepareData: ({ store, query, params }) => {
    const state = store.get();

    if (state.Auth.connected === false) {
      return;
    }

    const accessToken = state.Auth.token;

    store.dispatch(API_CALL, fetchActivityCollections({ accessToken }));
    store.dispatch(API_CALL, fetchLeadCollections({ accessToken }));
    store.dispatch(API_CALL, fetchLead({ accessToken, id: params.id, query: { withProperties: true } }));
  }
};
