import React from 'react';
import UncontrolledCollapse from 'src/components/elements/shared/UncontrolledCollapse';
import Link from 'src/helpers/Link';
import { useTranslation } from 'react-i18next';
import { transactionPath, contactPath, userPath, listingPath } from 'src/helpers/routes';

const pad = (a, b) => ([1e15]+a).slice(-b);

export default ({ items = [] }) => {
  const { t } = useTranslation();

  return (
    <UncontrolledCollapse
      label={`${t("dashboard.subFolders.openTransactions")} (${items.length})`}
    >
      <div className="table-responsive">
        <table className="table card-table table-vcenter text-nowrap">
          <thead>
            <tr>
              <th>{t("transactions.id")}</th>
              <th>{t("transactions.typeOf")}</th>
              <th>{t("transactions.buyerTenant")}</th>
              <th>{t("transactions.listing")}</th>
              <th>{t("transactions.finalPrice")}</th>
              <th>{t("transactions.owner")}</th>
            </tr>
          </thead>
          <tbody>
            {items.length > 0 && items.map((transaction, index) =>
              <tr key={`transaction-${index}`}>
                <td>
                  <span className="link-block">
                    <Link to={transactionPath(transaction.id)} className="mr-2" target="_blank">
                      {`TS-${pad(transaction.id, 7)}`}
                    </Link>
                  </span>
                </td>
                <td>{transaction.typeOf}</td>
                <td>
                  <span className="link-block">
                    <Link to={contactPath(transaction.buyerId)} className="mr-2" target="_blank">
                      {transaction.buyer}
                    </Link>
                  </span>
                </td>
                <td>
                  <span className="link-block">
                    <Link to={listingPath(transaction.listingId)} className="mr-2" target="_blank">
                      {transaction.listing}
                    </Link>
                  </span>
                </td>
                <td>{transaction.finalPrice}</td>
                <td>
                  <span className="link-block">
                    <Link to={userPath(transaction.ownerId)} className="mr-2" target="_blank">
                      {transaction.owner}
                    </Link>
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </UncontrolledCollapse>
  )
}
