import APICall from 'src/helpers/api';
import { getI18n } from 'react-i18next';
import defaultLanguage from 'src/helpers/defaultLang';
import { UPDATE_ACCESS_TOKEN, SIGN_OUT_SUCCESS } from 'src/constants/actionTypes/AuthActionTypes';
import history from 'src/helpers/history';
import { notFoundPath } from 'src/helpers/routes';
import downloadBlob from 'src/helpers/downloadBlob';
import { hasString } from 'src/helpers/string';
import { isPlainObject, map, camelCase, reduce, trim, isFunction } from 'lodash';
import { ADD_NOTIFICATION } from 'src/constants/actionTypes/notifications';
import flatten from 'flat';

export const API_CALL = 'API_CALL';

const XLXS_MIME_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const s2ab = (s) => {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
};
const filenameFromHeader = ({ data }) => {
  const disposition = data.headers['content-disposition'];
  const filenameRegex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/;
  const matches = filenameRegex.exec(disposition);
  return matches[1]
};
const getTypes = (types) => {
  let requestType = undefined;
  let successType = undefined;
  let failureType = undefined;
  let uploadProgressType = undefined;
  let downloadProgressType = undefined;

  if (isPlainObject(types)) {
    ({ request: requestType, success: successType, error: failureType, uploadProgress: uploadProgressType, downloadProgress: downloadProgressType } = types);
  } else {
    [requestType, successType, failureType, uploadProgressType, downloadProgressType] = types;
  }

  return { requestType, successType, failureType, uploadProgressType, downloadProgressType };
};
const normalizeJsonApiInclude = (include) => {
  const result = map(include.split(','), (path) => map(trim(path).split('.'), a => camelCase(trim(a))).join('.')).join(',');

  return result;
};
const normalizeJsonApiFields = (fields) => {
  const result = reduce(fields, (acc, fields, entityType) => {
    acc[entityType] = map(trim(fields).split(','), f => camelCase(trim(f))).join(',');
  }, {});

  return result;
};
const normalizeJsonApiQueryParams = (query) => {
  const result = { ...query };
  const { include, fields } = result

  if (include) {
    result.include = normalizeJsonApiInclude(include);
  }

  if (fields) {
    result.fields = normalizeJsonApiFields(fields);
  }

  return result;
};
const normalizeQueryParams = (query) => {
  if (hasString(query?.locale)) {
    return { ...query };
  }

  const locale = (getI18n() && getI18n().language) || defaultLanguage;

  return { ...query, locale: locale };
};

export default (store) => {
  store.on(API_CALL, function(state, data) {
    const { thenFn, catchFn } = data;

    try {
      const { apiVersion = 'v1', endpoint, method, credentials, attach, arrayFormat, responseType, bearer, metadata, entityId, body, accessToken } = data;
      const { requestType, successType, failureType, uploadProgressType, downloadProgressType } = getTypes(data.types);
      const namespace = metadata && metadata.namespace ? metadata.namespace : undefined;

      let { uploadKey } = data;

      if (!hasString(uploadKey) && attach) {
        uploadKey = attach.get('key');
      }

      const files = hasString(uploadKey) ? attach.getAll(uploadKey) : [];
      const [file] = files;

      let { query } = data;

      query = normalizeJsonApiQueryParams(query);
      query = normalizeQueryParams(query);

      if (files.length > 1) {
        throw new Error(`File uploads are limited to single files only.`);
      }

      if (file && !hasString(namespace)) {
        throw new Error(`All uploads should contain "namespace" metadata.`);
      }

      store.dispatch(requestType, { namespace, file, entityId });

      const updateToken = (newToken) => store.dispatch(UPDATE_ACCESS_TOKEN, newToken);
      const sendNotification = (notifications) => {
        const keys = Object.keys(notifications);
        keys.forEach(key => {
          const values = notifications[key];
          if (Array.isArray(values)) {
            values.forEach(value => {
              store.dispatch(ADD_NOTIFICATION, { type: key, message: value });
            });
          } else {
            store.dispatch(ADD_NOTIFICATION, {
              type: key,
              message: typeof values === 'object' ? Object.values(values).join('.\n') : values
            });
          }
        });
      };

      let apiCallArgs = { apiVersion, endpoint, method, query, credentials, accessToken: accessToken ?? state.Auth?.token, attach, arrayFormat, responseType, bearer, body };

      if (file && hasString(uploadProgressType)) {
        apiCallArgs.onUploadProgress = (event) => {
          store.dispatch(uploadProgressType, { namespace, file, event });
        };
      }

      const filename = metadata?.filename;

      if (hasString(filename) && hasString(downloadProgressType)) {
        apiCallArgs.onDownloadProgress = (event) => {
          store.dispatch(downloadProgressType, { namespace, filename, event });
        };
      }

      const onResolve = (data) => {
        const newToken = data.headers['access-token'];

        if (newToken) {
          updateToken(newToken);
        }

        const contentType = data.headers['content-type'];

        if (contentType && contentType.indexOf('application/pdf') !== -1) {
          const blob = new Blob([data.body], { type: 'application/pdf' });

          downloadBlob({ blob, name: filenameFromHeader({ data }) });
        } else if (contentType && contentType.indexOf(XLXS_MIME_TYPE) !== -1) {
          const bin = window.atob(data.text);
          const ab = s2ab(bin);
          const blob = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,ß' });

          downloadBlob({ blob, name: filenameFromHeader({ data }) });
        } else {
          const response = data.body;

          const { notifications } = response;

          if (notifications) {
            sendNotification(notifications);
          }

          const { message } = response;

          if (message) {
            sendNotification({ success: message });
          }

          store.dispatch(successType, { response, headers: data.headers, namespace, file, filename });
        }

        if (isFunction(thenFn)) {
          return data;
        }
      };
      const onReject = (error) => {
        const { response } = error;

        if (response?.body?.notifications) {
          sendNotification(response.body.notifications);
        }

        if (response?.body?.errors) {
          const { errors } = response.body;

          if (isPlainObject(errors)) {
            const danger = flatten(errors, { safe: true });

            sendNotification({ danger });
          } else {
            sendNotification({ danger: errors });
          }
        }

        const newToken = response?.headers?.['access-token'];

        if (newToken) {
          updateToken(newToken);
        }

        store.dispatch(failureType, { response, namespace, file, filename, entityId });

        if (error.status === 401 && !endpoint.includes('auth/')) {
          store.dispatch(SIGN_OUT_SUCCESS);
        } else if (error.status === 404 && !endpoint.includes('auth/')) {
          history.push(notFoundPath());
        }

        if (isFunction(catchFn)) {
          return Promise.reject(error);
        }
      };

      APICall(apiCallArgs).then(onResolve, onReject).then(isFunction(thenFn) ? thenFn : undefined, isFunction(catchFn) ? catchFn : undefined);
    } catch (err) {
      if (isFunction(catchFn)) {
        catchFn(err);
      }

      throw err;
    }
  });
};
