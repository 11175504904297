export const FETCH_SNAG = 'snag/fetch';
export const FETCH_SNAG_SUCCESS = 'snag/fetch|success';
export const FETCH_SNAG_ERROR = 'snag/fetch|error';

export const FETCH_ALL_SNAGS = 'snag/fetchAll';
export const FETCH_ALL_SNAGS_SUCCESS = 'snag/fetchAll|success';
export const FETCH_ALL_SNAGS_ERROR = 'snag/fetchAll|error';

export const CREATE_SNAG = 'snag/create';
export const CREATE_SNAG_SUCCESS = 'snag/create|success';
export const CREATE_SNAG_ERROR = 'snag/create|error';

export const UPDATE_SNAG = 'snag/update';
export const UPDATE_SNAG_SUCCESS = 'snag/update|success';
export const UPDATE_SNAG_ERROR = 'snag/update|error';

export const BATCH_UPDATE_SNAGS_SUCCESS = 'snag/batchUpdate|success';

export const DELETE_SNAG = 'snag/delete';
export const DELETE_SNAG_SUCCESS = 'snag/delete|success';
export const DELETE_SNAG_ERROR = 'snag/delete|error';

export const BATCH_DELETE_SNAGS_SUCCESS = 'snag/batchDelete|success';

export const RENAME_SNAG_FILE_SUCCESS = 'snag/file/rename|success';

export const DELETE_SNAG_FILE_SUCCESS = 'snag/file/delete|success';

export const UPDATE_SNAG_RECORD = 'snag/updateRecord';
