import { offerCounterOfferPath } from 'src/helpers/routes';
import offerNewPath from 'src/routes/offers/New';
import { fetchOfferCollections, fetchOffer } from 'src/actions/Offers';
import { API_CALL } from 'src/middleware/API';
import CounterOffer from 'src/components/views/offers/counterOffer';

const prepareData = ({ store, params }) => {
  const state = store.get();
  if (state.Auth.connected === false) return;
  const accessToken = state.Auth.token;
  if (Object.keys(state.OfferCollections.items).length > 0) return;
  store.dispatch(API_CALL, fetchOfferCollections({ accessToken }));
  const query = { noTypeCast: true, withProperties: true }
  store.dispatch(API_CALL, fetchOffer({ accessToken, id: params.id, query }));
};

export default Object.assign(
  {},
  offerNewPath,
  { path: offerCounterOfferPath(), prepareData, component: CounterOffer });
