import filterByRole, { optionsByRole } from './filterByRole';
import filterByView from './filterByView';

const endpoint = "projects";

const defaultView = [
  { data: "id", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true },
  { data: "title", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link show-link", path: 'projectPath', pathKey: 'id' },
  { data: "parentProject", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link parent-project-link", path: 'projectPath', pathKey: 'parentId' },
  { data: "plotNumber", searchable: true, orderable: false, search: { value: "", regex: "" }},
  { data: "plotArea", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "numberOfUnits", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "constructionStatus", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "parentId", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true }
];

const completedProjectsView = [
  { data: "id", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true },
  { data: "title", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link show-link", path: 'projectPath', pathKey: 'id' },
  { data: "parentProject", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link parent-project-link", path: 'projectPath', pathKey: 'parentId' },
  { data: "plotNumber", searchable: true, orderable: false, search: { value: "", regex: "" }},
  { data: "plotArea", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "numberOfUnits", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "completionDate", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "handoverDate", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "parentId", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true }
];

const underConstructionView = [
  { data: "id", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true },
  { data: "title", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link show-link", path: 'projectPath', pathKey: 'id' },
  { data: "parentProject", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link parent-project-link", path: 'projectPath', pathKey: 'parentId' },
  { data: "plotNumber", searchable: true, orderable: false, search: { value: "", regex: "" }},
  { data: "plotArea", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "numberOfUnits", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "parentId", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true }
];

const fieldsByView = (view) => {
  const fields = {
    "recentlyViewed": defaultView,
    "projects": defaultView,
    "completedProjects": completedProjectsView,
    "projectsUnderConstruction": underConstructionView
  };
  return fields[view];
};

export const optionsForFilterByView = {
  "projects": {},
  "completedProjects": { name: "construction_status", value: "completed", type: "eq" },
  "projectsUnderConstruction": { name: "construction_status", value: "under_construction", type: "eq" },
};

export const defaultFilter = ({ role, ids }) => {
  const filter = {};
  const byRole = optionsByRole({ role, endpoint });
  if (byRole.length > 0) filter.role = { value: byRole[0], type: "role" };
  if (ids.length > 0) filter.view = { name: "id", type: "eq", value: ids };
  return filter;
};

export const columnFilters = [
  // { filter: filterByList, dataField: "status", label: "Status", source: 'LeadCollections.items.status' },
  // { filter: filterByList, dataField: "stage", label: "Stage", source: 'LeadCollections.items.stage' },
  // { filter: filterByDateRange, dataField: "createdAt" }
];

export const outsideFilters = [
  { component: filterByView, endpoint, options: optionsForFilterByView },
  { component: filterByRole, endpoint }
];

export default ({ view = "projects", items = [] }) => {
  const columns = fieldsByView(view);
  const query = {
    columns,
    order: [{ column: 1, dir: "asc" }],
    start: 0,
    length: 10,
    endpoint
  };
  return { query, endpoint, columns };
}
