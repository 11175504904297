import * as types from 'src/constants/actionTypes/ContactsActionTypes';

const initialState = {
  isDestroing: false,
  error: false,
  successDestroy: false,
}

export default store => {
  store.on('@init', () => ({ ContactDestroy: initialState }));

  store.on(types.DELETE_CONTACTS_REQUEST, ({ ContactDestroy }) =>
    ({ ContactDestroy: { ...ContactDestroy, isDestroing: true, successDestroy: false }}));
  store.on(types.DELETE_CONTACTS_SUCCESS, ({ ContactDestroy }) =>
    ({ ContactDestroy: {...ContactDestroy, successDestroy: true }}));
  store.on(types.DELETE_CONTACTS_ERROR, ({ ContactDestroy }) =>
    ({ ContactDestroy: {...ContactDestroy, error: true }}));

  store.on(types.DELETE_CONTACT_REQUEST, ({ ContactDestroy }) =>
    ({ ContactDestroy: { ...ContactDestroy, isDestroing: true, successDestroy: false }}));
  store.on(types.DELETE_CONTACT_SUCCESS, ({ ContactDestroy }) =>
    ({ ContactDestroy: {...ContactDestroy, successDestroy: true }}));
  store.on(types.DELETE_CONTACT_ERROR, ({ ContactDestroy }) =>
    ({ ContactDestroy: {...ContactDestroy, error: true }}));
}
