import { restrictedPagePath } from 'src/helpers/routes';
import RestrictedPage from 'src/components/views/RestrictedPage';

export default {
  path: restrictedPagePath(),
  exact: true,
  strict: true,
  component: RestrictedPage,
  perform: 'restrictedPage:index',
  title: 'Restricted Page'
};
