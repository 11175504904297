import * as types from 'src/constants/actionTypes/InternalBrokersActionTypes';

const fetchInternalBrokers = ({ accessToken, query = {} }) => {
  return ({
    query,
    endpoint: 'internal_brokers',
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_INTERNAL_BROKERS_REQUEST,
      types.FETCH_INTERNAL_BROKERS_SUCCESS,
      types.FETCH_INTERNAL_BROKERS_ERROR
    ]
  })
}

const fetchInternalBroker = ({ accessToken, id, query = {} }) => {
  return ({
    query,
    endpoint: `internal_brokers/${id}`,
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_INTERNAL_BROKER_REQUEST,
      types.FETCH_INTERNAL_BROKER_SUCCESS,
      types.FETCH_INTERNAL_BROKER_ERROR
    ]
  })
};

const updateInternalBroker = ({ accessToken, query, id }) => {
  return ({
    query,
    endpoint: `internal_brokers/${id}`,
    method: 'PUT',
    credentials: true,
		accessToken,
    types: [
      types.SUBMIT_INTERNAL_BROKER_REQUEST,
      types.SUBMIT_INTERNAL_BROKER_SUCCESS,
      types.SUBMIT_INTERNAL_BROKER_ERROR
    ]
  })
};

const deleteInternalBrokers = ({ accessToken, query }) => {
  return ({
    query,
    endpoint: `internal_brokers`,
    method: 'DELETE',
    credentials: true,
		accessToken,
    types: [
      types.DELETE_INTERNAL_BROKERS_REQUEST,
      types.DELETE_INTERNAL_BROKERS_SUCCESS,
      types.DELETE_INTERNAL_BROKERS_ERROR
    ]
  })
};

const deleteInternalBroker = ({ accessToken, id }) => {
  return ({
    endpoint: `internal_brokers/${id}`,
    method: 'DELETE',
    credentials: true,
		accessToken,
    types: [
      types.DELETE_INTERNAL_BROKER_REQUEST,
      types.DELETE_INTERNAL_BROKER_SUCCESS,
      types.DELETE_INTERNAL_BROKER_ERROR
    ]
  })
};

const createInternalBroker = ({ accessToken, query }) => {
  return ({
    query: query,
    endpoint: 'internal_brokers',
    method: 'POST',
    credentials: true,
		accessToken,
    types: [
      types.SUBMIT_INTERNAL_BROKER_REQUEST,
      types.SUBMIT_INTERNAL_BROKER_SUCCESS,
      types.SUBMIT_INTERNAL_BROKER_ERROR
    ]
  })
};

const fetchInternalBrokerCollections = ({ accessToken }) => {
  return ({
    endpoint: 'internal_brokers/helper',
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_INTERNAL_BROKER_COLLECTION_REQUEST,
      types.FETCH_INTERNAL_BROKER_COLLECTION_SUCCESS,
      types.FETCH_INTERNAL_BROKER_COLLECTION_ERROR
    ]
  })
}

export {
  fetchInternalBrokers, fetchInternalBroker, fetchInternalBrokerCollections, createInternalBroker, deleteInternalBrokers,
  deleteInternalBroker, updateInternalBroker
};
