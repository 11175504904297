import * as types from 'src/constants/actionTypes/OffersActionTypes';

const fetchOffers = ({ accessToken, query = {} }) => {
  return ({
    query,
    endpoint: 'offers',
    method: 'GET',
    credentials: true,
    accessToken,
    types: [
      types.FETCH_OFFERS_REQUEST,
      types.FETCH_OFFERS_SUCCESS,
      types.FETCH_OFFERS_ERROR
    ]
  })
}

const fetchOffer = ({ accessToken, id, query = {} }) => {
  return ({
    query,
    endpoint: `offers/${id}`,
    method: 'GET',
    credentials: true,
    accessToken,
    types: [
      types.FETCH_OFFER_REQUEST,
      types.FETCH_OFFER_SUCCESS,
      types.FETCH_OFFER_ERROR
    ]
  })
};

const updateOffer = ({ accessToken, query, id, body }) => {
  return ({
    query,
    endpoint: `offers/${id}`,
    method: 'PUT',
    credentials: true,
    accessToken,
    types: [
      types.SUBMIT_OFFER_REQUEST,
      types.SUBMIT_OFFER_SUCCESS,
      types.SUBMIT_OFFER_ERROR
    ],
    body
  })
};

const deleteOffers = ({ accessToken, query }) => {
  return ({
    query,
    endpoint: `offers`,
    method: 'DELETE',
    credentials: true,
    accessToken,
    types: [
      types.DELETE_OFFERS_REQUEST,
      types.DELETE_OFFERS_SUCCESS,
      types.DELETE_OFFERS_ERROR
    ]
  })
};

const deleteOffer = ({ accessToken, id }) => {
  return ({
    endpoint: `offers/${id}`,
    method: 'DELETE',
    credentials: true,
    accessToken,
    types: [
      types.DELETE_OFFER_REQUEST,
      types.DELETE_OFFER_SUCCESS,
      types.DELETE_OFFER_ERROR
    ]
  })
};

const createOffer = ({ accessToken, query, body }) => {
  return ({
    query: query,
    endpoint: 'offers',
    method: 'POST',
    credentials: true,
    accessToken,
    types: [
      types.SUBMIT_OFFER_REQUEST,
      types.SUBMIT_OFFER_SUCCESS,
      types.SUBMIT_OFFER_ERROR
    ],
    body
  });
};

const fetchOfferCollections = ({ accessToken }) => {
  return ({
    endpoint: 'offers/helper',
    method: 'GET',
    credentials: true,
    accessToken,
    types: [
      types.FETCH_OFFER_COLLECTION_REQUEST,
      types.FETCH_OFFER_COLLECTION_SUCCESS,
      types.FETCH_OFFER_COLLECTION_ERROR
    ]
  })
}

export {
  fetchOffers, fetchOffer, fetchOfferCollections, createOffer, deleteOffers,
  deleteOffer, updateOffer
};
