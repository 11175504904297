import { internalBrokerPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchInternalBroker, fetchInternalBrokerCollections } from 'src/actions/InternalBrokers';
import { API_CALL } from 'src/middleware/API';
import { fetchActivityCollections } from 'src/actions/Activities';
import Layout from 'src/components/views/showLayout';

const InternalBrokerShow = lazy(() => import('src/components/views/internalBrokers/Show'));

export default {
  path: internalBrokerPath(),
  exact: true,
  strict: true,
  component: InternalBrokerShow,
  perform: "internalBrokers:show",
  layout: Layout,
  protected: true,
  prepareData: ({ store, query, params }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    if (Object.keys(state.ActivityCollections.items).length ===  0) {
      store.dispatch(API_CALL, fetchActivityCollections({ accessToken }));
    }
    if (Object.keys(state.InternalBrokerCollections.items).length ===  0) {
      store.dispatch(API_CALL, fetchInternalBrokerCollections({ accessToken }));
    }
    store.dispatch(API_CALL, fetchInternalBroker({ accessToken, id: params.id }));
  }
};
