export const FETCH_PROPERTIES_REQUEST = 'FETCH_PROPERTIES_REQUEST';
export const FETCH_PROPERTIES_SUCCESS = 'FETCH_PROPERTIES_SUCCESS';
export const FETCH_PROPERTIES_ERROR = 'FETCH_PROPERTIES_ERROR';

export const FETCH_PROPERTY_REQUEST = 'FETCH_PROPERTY_REQUEST';
export const FETCH_PROPERTY_SUCCESS = 'FETCH_PROPERTY_SUCCESS';
export const FETCH_PROPERTY_ERROR = 'FETCH_PROPERTY_ERROR';
export const FETCH_PROPERTY_RESET = 'FETCH_PROPERTY_RESET';

export const FETCH_PROPERTY_COLLECTION_REQUEST = 'FETCH_PROPERTY_COLLECTION_REQUEST';
export const FETCH_PROPERTY_COLLECTION_SUCCESS = 'FETCH_PROPERTY_COLLECTION_SUCCESS';
export const FETCH_PROPERTY_COLLECTION_ERROR = 'FETCH_PROPERTY_COLLECTION_ERROR';

export const DELETE_PROPERTY_REQUEST = 'DELETE_PROPERTY_REQUEST';
export const DELETE_PROPERTY_SUCCESS = 'CREATE_PROPERTY_SUCCESS';
export const DELETE_PROPERTY_ERROR = 'DELETE_PROPERTY_ERROR';

export const DELETE_PROPERTIES_REQUEST = 'DELETE_PROPERTIES_REQUEST';
export const DELETE_PROPERTIES_SUCCESS = 'DELETE_PROPERTIES_SUCCESS';
export const DELETE_PROPERTIES_ERROR = 'DELETE_PROPERTIES_ERROR';

export const SUBMIT_PROPERTY_REQUEST = 'SUBMIT_PROPERTY_REQUEST';
export const SUBMIT_PROPERTY_SUCCESS = 'SUBMIT_PROPERTY_SUCCESS';
export const SUBMIT_PROPERTY_ERROR = 'SUBMIT_PROPERTY_ERROR';
export const SUBMIT_PROPERTY_RESET = 'SUBMIT_PROPERTY_RESET';

export const FETCH_PROPERTY_LISTING_REQUEST = 'FETCH_PROPERTY_LISTING_REQUEST';
export const FETCH_PROPERTY_LISTING_SUCCESS = 'FETCH_PROPERTY_LISTING_SUCCESS';
export const FETCH_PROPERTY_LISTING_ERROR = 'FETCH_PROPERTY_LISTING_ERROR';
export const FETCH_PROPERTY_LISTING_RESET = 'FETCH_PROPERTY_LISTING_RESET';
