import React, { useMemo, useRef, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import { hasString } from 'src/helpers/string';
import { isArray, debounce, noop } from 'lodash';
import 'src/styles/select-search.scss';
import { logger } from 'src/-utils/logger';
import { applyDependentDropdownRule } from 'src/-utils/field-config';

const Input = ({ autoComplete, ...props }) => <components.Input {...props} autoComplete="new-password" />;

const VerticalSelectSearch = ({
  label = undefined, required = false, collection = [], name, onChange, errors = null,
  value, multiple = false, handlerOnMenuOpen = noop, isClearable = true, className = null,
  placeholder = undefined, mustShowDebugInformation = false, mustHandleValidity = false,
  isDisabled = false, fieldConfig = null, isSearchable = true, formData = undefined, setFormData = noop,
  inputName = undefined
}) => {
  const inputRef = useRef({});
  const selectEl = useRef(null);
  const { t } = useTranslation();
  const [options, setOptions] = useState([...collection]);
  const errorMessage = useMemo(() => errors?.join(', ') ?? t('form.provideValid', { label }), [errors, label, t]);
  const handleOnChange = useCallback((object) => {
    const input = inputRef.current;
    if (multiple) {
      const values = object ? object.map(i => i.value) : [];
      onChange({ [inputName ?? name]: values });
      input.value = values;
    } else {
      const value = object === null ? '' : object.value
      onChange(inputName ?? name, value);
      input.value = value;
    }
  }, [inputName, multiple, name, onChange]);

  const optionProperty = useMemo(() => collection.map(i => i.options ? i.options : i), [collection]);
  const valuesCollection = useMemo(() => optionProperty.flat(), [optionProperty]);
  const { classNamesInput, classNamesSelect } = useMemo(() => {
    const classNamesInput = ['form-control', 'd-none'];
    const classNamesSelect = ['basic-single'];

    if (mustHandleValidity && isArray(errors)) {
      classNamesInput.push('is-invalid');
      classNamesSelect.push('is-invalid');
    }

    return { classNamesInput, classNamesSelect };
  }, [errors, mustHandleValidity]);
  const defaultValues = useMemo(() => {
    if (isArray(value)) {
      return value.map(v => valuesCollection.find(item => item.value === v));
    } else if (value) {
      return valuesCollection.find(item => item.value === value);
    } else {
      return undefined;
    }
  }, [value, valuesCollection]);
  const applyFieldConfigRules = useCallback((value, collection, formData) => {
    setOptions([...collection]);

    if (!isArray(fieldConfig)) {
      return;
    }

    const ruleCount = new Map();

    for (const e of fieldConfig) {
      switch (e.rule) {
        case 'dependent': {
          if (ruleCount.has(e.rule)) {
            logger.warn(`Field "${name}" can only have a single "dependent" rule. This and additional "dependent" rules will be ignored.`);

            break;
          }

          applyDependentDropdownRule(e, name, value, collection, setOptions, formData, setFormData);

          break;
        }
        default:
          throw new Error(`Undefined rule: ${e.rule}`);
      }

      ruleCount.set(e.rule, (ruleCount.get(e.rule) ?? 0) + 1);
    }
  }, [fieldConfig, name, setFormData]);
  const debouncedApplyFieldConfigRules = useMemo(() => debounce(applyFieldConfigRules, 250), [applyFieldConfigRules]);

  useEffect(() => {
    debouncedApplyFieldConfigRules(value, collection, formData);

    return function cleanup() {
      debouncedApplyFieldConfigRules.cancel();
    }
  }, [collection, debouncedApplyFieldConfigRules, formData, value]);

  return (
    <div className={`form-group ${className}`}>
      {hasString(label) && <label className="form-label">{label}</label>}
      <input
        type="text"
        value={value ?? ''}
        required={required}
        name={name}
        onChange={noop}
        className={classNamesInput.join(' ')}
        ref={inputRef}
      />
      <Select
        ref={selectEl}
        className={classNamesSelect.join(' ')}
        classNamePrefix={`select-with-search-prefix`}
        value={defaultValues || null}
        isSearchable={isSearchable}
        isClearable={isClearable}
        name={name}
        options={options}
        isMulti={multiple}
        onChange={handleOnChange}
        required={required}
        components={{ Input }}
        placeholder={placeholder}
        isDisabled={isDisabled}
        menuPlacement='auto'
      />
      {mustShowDebugInformation && <code>{JSON.stringify(errors)}</code>}
      {hasString(errorMessage) && <div className='invalid-feedback'>{errorMessage}</div>}
    </div>
  );
};

VerticalSelectSearch.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onMenuOpen: PropTypes.func,
  mustShowDebugInformation: PropTypes.bool,
  mustHandleValidity: PropTypes.bool,
  clearValue: PropTypes.string
};

export default VerticalSelectSearch;
