const clearValidation = (formRef) => {
  const form = formRef.current;
  form && (Array.from(form.elements)).forEach(el => {
    if (el) {
      el.setCustomValidity('');
      const nextSubling = el.nextSibling;
      if (nextSubling && nextSubling.classList.contains('basic-single')) {
        nextSubling.children[0].classList.remove('invalid-element');
      }
    }
    el.setCustomValidity('');
  });
};

export default clearValidation;
