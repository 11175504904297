import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import DayJsLocaleUtils, { formatDate, parseDate } from 'src/helpers/dayJsLocaleUtils';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/fr';
dayjs.locale('fr');

const YearMonthForm = ({ date, localeUtils, onChange, fromMonth, toMonth, locale }) => {
  const months = localeUtils.getMonths(locale);

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <div className="DayPicker-Caption">
      <select name="month" onChange={handleChange} value={date.getMonth()} className="mr-2">
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select name="year" onChange={handleChange} value={date.getFullYear()}>
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
}

const VerticalDateInput = ({
  label, required = false, name, onChange, errors, value, defaultFromMonth = 2000, inputName = undefined
}) => {
  const { i18n, t } = useTranslation();
  const errorMessage = errors ? errors.join(', ') : t('form.provideValid', { label });
  const locale = i18n.language;
  const inputRef = useRef(null);
  const dayPickerRef = useRef(null);
  const hiddenRef = useRef(null);

  const defaultMonth = value ? new Date(value) : new Date();
  const [month, setMonth] = useState(defaultMonth);

  const currentYear = new Date().getFullYear();
  const fromMonth = new Date(defaultFromMonth, 11);
  const toMonth = new Date(currentYear + 10, 11);

  const handleYearMonthChange = (month) => {
    setMonth(month);
  };

  const handlerOnDayChange = (selectedDay, modifiers, dayPickerInput) => {
    const value = selectedDay ? dayjs(selectedDay).format('YYYY-MM-DD') : '';
    onChange({ [inputName ?? name]: value });
  };

  const classes = 'form-control w-100';

  if (errors && inputRef.current) {
    inputRef.current.setCustomValidity(errorMessage);
    hiddenRef.current.setCustomValidity(errorMessage);
  }

  return (
    <div className="form-group">
      <label className="form-label">{label}</label>
      <input
        ref={hiddenRef}
        type="text"
        defaultValue={value}
        required={required}
        name={name}
        className="form-control d-none"
      />
      <DayPickerInput
        ref={dayPickerRef}
        classNames={{
          container: "w-100",
          overlayWrapper: 'foo-overlay-wrapper',
          overlay: 'foo-overlay'
        }}
        placeholder={'DD-MM-YYYY'}
        required={required}
        inputProps={{ ref: inputRef, className: classes, name: name, autoComplete: "new-password", required }}
        format="DD-MM-YYYY"
        parseDate={parseDate}
        value={value ? dayjs(value).format('DD-MM-YYYY') : ''}
        formatDate={formatDate}
        onDayChange={handlerOnDayChange}
        dayPickerProps={{
          locale: locale,
          localeUtils: DayJsLocaleUtils,
          month,
          fromMonth,
          toMonth,
          captionElement: (opt) => {
            const { date, localeUtils } = opt;
            return (
              <YearMonthForm
                date={date}
                localeUtils={localeUtils}
                onChange={handleYearMonthChange}
                fromMonth={fromMonth}
                toMonth={toMonth}
                locale={locale}
              />
            );
          }
        }}
      />
      <div className='invalid-feedback'>{errorMessage}</div>
    </div>
  )
};

VerticalDateInput.propTypes = {
  label: PropTypes.string.isRequired,
  inputName: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultFromMonth: PropTypes.number
};

export default VerticalDateInput;
