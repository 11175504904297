import * as types from 'src/constants/actionTypes/BaseCompanyActionTypes';
import initialStateProfile from 'src/helpers/initialStateSubmit';

const initialState = {
  isFetching: false,
  error: false,
  baseCompany: {}
}

export default store => {
  store.on('@init', () => ({ BaseCompany: initialState }));
  store.on('@init', () => ({ SubmitBaseCompany: initialStateProfile }));

  store.on(types.FETCH_BASECOMPANY_REQUEST, ({ BaseCompany }) =>
    ({ BaseCompany: { ...initialState, isFetching: true }}));
  store.on(types.FETCH_BASECOMPANY_SUCCESS, ({ BaseCompany }, { response }) =>
    ({ BaseCompany: { ...BaseCompany, baseCompany: response.data }}));
  store.on(types.FETCH_BASECOMPANY_ERROR, ({ BaseCompany }) =>
    ({ BaseCompany: {...BaseCompany, error: true }}));

  store.on(types.SUBMIT_BASECOMPANY_RESET, () => ({ SubmitBaseCompany: initialState }));
  store.on(types.SUBMIT_BASECOMPANY_REQUEST, ({ SubmitBaseCompany }) =>
    ({ SubmitBaseCompany: { ...SubmitBaseCompany, ...initialState, sendRequest: true }}));
  store.on(types.SUBMIT_BASECOMPANY_SUCCESS, ({ SubmitBaseCompany }, { response }) => {
    return ({ SubmitBaseCompany: { ...SubmitBaseCompany, success: true, sendRequest: false }});
  });
  store.on(types.SUBMIT_BASECOMPANY_ERROR, ({ SubmitBaseCompany }, { response }) => {
    let errors = {};
    if (response && response.body && response.body.errors) errors = response.body.errors;
    return ({ SubmitBaseCompany: {...SubmitBaseCompany, error: true, errors: errors, sendRequest: false }});
  });
}
