import { listingEditPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchListingCollections, fetchListing } from 'src/actions/Listings';
import { API_CALL } from 'src/middleware/API';

const ListingEdit = lazy(() => import('src/components/views/listings/Edit'));

export default {
  path: listingEditPath(),
  exact: true,
  strict: true,
  component: ListingEdit,
  perform: 'listings:update',
  protected: true,
  prepareData: ({ store, params }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    if (Object.keys(state.ListingCollections.items).length ===  0) {
      store.dispatch(API_CALL, fetchListingCollections({ accessToken }));
    }
    const query = { noTypeCast: true, withProperties: true, act: 'edit' }
    store.dispatch(API_CALL, fetchListing({ accessToken, id: params.id, query }));
  }
};
