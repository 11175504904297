export interface BaseModelEnumState {
  __meta: {
    fetchCount: number;
    hasLoaded: boolean;
  };
  __error?: any | null;
}

export interface ModelEnumState<T = any> extends BaseModelEnumState {
  data?: T | null;
}

type EnumDataType<T> = T extends ModelEnumState<infer A> ? A : never;

export abstract class BaseEnumCollection<T extends ModelEnumState> {
  protected abstract defaultData: EnumDataType<T>;

  protected constructor(protected state: T) {
    // noop
  }

  public get data(): EnumDataType<T> {
    return this.state.data ?? this.defaultData;
  }

  public get error(): any {
    return this.state.__error;
  }

  public get isFetching(): boolean {
    return this.state.__meta.fetchCount > 0;
  }

  public get hasLoaded(): boolean {
    return this.state.__meta.hasLoaded;
  }

  public get isReady(): boolean {
    return this.hasLoaded && !this.isFetching;
  }
}
