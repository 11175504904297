import { performancesPath } from 'src/helpers/routes';
import { API_CALL } from 'src/middleware/API';
import { fetchPerformanceCollections, fetchPerformance } from 'src/actions/Performances';
import component from 'src/components/views/performance';
import { listOfViews, listOfPerformanceBy } from 'src/components/views/performance/helper';

export default {
  path: performancesPath(),
  exact: true,
  strict: true,
  component,
  protected: true,
  perform: 'performance:index',
  prepareData: ({ store, params }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;

    const accessToken = state.Auth.token;
    if (Object.keys(state.PerformanceCollections.items).length === 0) {
      store.dispatch(API_CALL, fetchPerformanceCollections({ accessToken }));
    };

    const endpoint = listOfViews[0];
    // const { role } = state.Auth.user;
    const performanceBy = listOfPerformanceBy[0];
    const yearValue = new Date().getFullYear();
    const query = {
      options: { performanceBy, yearValue }
    };
    store.dispatch(API_CALL, fetchPerformance({ accessToken, endpoint, query }));
  }
};
