import Root from './root';
import Login from './login';
import Confirm from './confirm';
import ForgotPassword from './forgotPassword';
import ResetPassword from './resetPassword';
import ProfileEdit from './profile/Edit';
import Profile from './profile';
import SettingsEdit from './admin/settings/Edit';
import Settings from './admin/settings';
// Companies
import Companies from './companies';
import CompanyNew from './companies/New';
import CompanyShow from './companies/Show';
import CompanyEdit from './companies/Edit';
import CompanyNewContact from './companies/NewContact';
import CompanyNewReferredContact from './companies/NewReferredContact';
import AdminCompanyDetails from './admin/company';
import AdminCompanyDetailsEdit from './admin/company/Edit';
// Users
import Users from './admin/users';
import UserNew from './admin/users/New';
import UserShow from './admin/users/Show';
import UserEdit from './admin/users/Edit';
// Contacts
import Contacts from './contacts';
import ContactNew from './contacts/New';
import ContactShow from './contacts/Show';
import ContactEdit from './contacts/Edit';
import ContactNewLead from './contacts/NewLead';
import ContactNewOwnerProperty from './contacts/NewOwnerProperty';
import ContactNewCoOwnerProperty from './contacts/NewCoOwnerProperty';
import ContactNewListing from './contacts/NewListing';
import ContactNewList from './contacts/NewList';
// Teams
import Teams from './admin/teams';
import TeamsNew from './admin/teams/New';
import TeamShow from './admin/teams/Show';
import TeamEdit from './admin/teams/Edit';
// Leads
import Leads from './leads';
import LeadNew from './leads/New';
import LeadNewOffer from './leads/NewOffer';
import LeadShow from './leads/Show';
import LeadEdit from './leads/Edit';
// Activity
import Activity from './activity';
import ActivityMonth from './activity/month';
import ActivityEdit from './activity/Edit';
import ActivityNew from './activity/New';
// Projects
import Projects from './projects';
import ProjectNew from './projects/New';
import ProjectShow from './projects/Show';
import ProjectEdit from './projects/Edit';
import ProjectPropertyNew from './projects/NewProperty';
import ProjectSnagNew from './projects/newSnag';
// Properties
import Properties from './properties';
import PropertyNew from './properties/New';
import PropertyShow from './properties/Show';
import PropertyEdit from './properties/Edit';
import PropertyListingNew from './properties/NewListing';
import PropertySnagNew from './properties/newSnag';
import PropertyClone from './properties/Clone';
// Listings
import Listings from './listings';
import ListingNew from './listings/New';
import ListingShow from './listings/Show';
import ListingEdit from './listings/Edit';
import ListingClone from './listings/Clone';
import ListingLeadNew from './listings/NewLead';
import ListingOfferNew from './listings/NewOffer';
import ListingTransactionNew from './listings/newTransaction';
// Offers
import Offers from './offers';
import OfferNew from './offers/New';
import OfferShow from './offers/Show';
import OfferEdit from './offers/Edit';
import OfferCounter from './offers/counterOffer';
import OfferTransactionNew from './offers/newTransaction';
// Transactions
import Transactions from './transactions';
import TransactionShow from './transactions/Show';
import TransactionEdit from './transactions/Edit';
import TransactionInternalBrokerNew from './transactions/newInternalBroker';
import TransactionPaymentNew from './transactions/newPayment';
// Internal Brokers
import InternalBrokers from './internalBrokers';
import InternalBrokerShow from './internalBrokers/Show';
import InternalBrokerEdit from './internalBrokers/Edit';
// Payments
import Payments from './payments';
import PaymentShow from './payments/Show';
import PaymentEdit from './payments/Edit';
// Reports
import Reports from './report';
// Snags
import SnagRoutes from './snags';
// Performance./snags/list
import Performance from './performance';

import RestrictedPage from './restrictedPage';
import NotFoundPage from './notFoundPage';

export default [
  Login, Confirm, ForgotPassword, Root, Profile, ProfileEdit, Companies, CompanyNew,
  CompanyShow, CompanyEdit, AdminCompanyDetails, AdminCompanyDetailsEdit, Users, UserNew,
  UserEdit, UserShow, Settings, SettingsEdit, Contacts, RestrictedPage, Teams, TeamsNew,
  TeamEdit, TeamShow, ContactNew, ContactShow, ContactEdit, CompanyNewContact, ResetPassword,
  CompanyNewReferredContact, Leads, LeadNew, LeadShow, LeadEdit, ContactNewLead, Activity,
  ActivityMonth, ActivityEdit, ActivityNew, Projects, ProjectNew, ProjectShow, ProjectEdit,
  Properties, PropertyNew, PropertyShow, PropertyEdit, Listings, ListingNew, ListingShow,
  ListingEdit, PropertyClone, NotFoundPage, PropertyListingNew, PropertySnagNew, ListingClone, ListingLeadNew,
  ProjectPropertyNew, ProjectSnagNew, ContactNewCoOwnerProperty, ContactNewOwnerProperty, ContactNewListing,
  ContactNewList, Offers, OfferNew, OfferShow, OfferEdit, OfferCounter, LeadNewOffer,
  ListingOfferNew, Transactions, OfferTransactionNew, TransactionShow, TransactionEdit,
  InternalBrokers, InternalBrokerShow, InternalBrokerEdit, TransactionInternalBrokerNew,
  Payments, PaymentShow, PaymentEdit, TransactionPaymentNew, ListingTransactionNew, Reports,
  ...SnagRoutes,
  Performance
];
