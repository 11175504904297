import filterByDateRange from './filterByDateRange';
import filterByRole, { optionsByRole } from './filterByRole';
import filterByView from './filterByView';
import { camelCase, includes } from 'lodash';

const endpoint = 'internal_brokers';

const camelize = (str) => includes(str, '_') ? camelCase(str) : str;

const defaultView = [
  { data: 'id', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  {
    data: 'idValue', searchable: true, orderable: false, search: { value: '', regex: '' },
    classes: 'table-link show-link', path: 'internalBrokerPath', pathKey: 'id'
  },
  {
    data: 'transaction', searchable: true, orderable: false, search: { value: '', regex: '' },
    classes: 'table-link transaction-link', path: 'transactionPath', pathKey: 'transactionId'
  },
  {
    data: 'broker', searchable: true, orderable: false, search: { value: '', regex: '' },
    classes: 'table-link broker-link', path: 'userPath', pathKey: 'brokerId'
  },
  { data: 'brokerRole', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'commissionEarned', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'commissionPaid', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'createdAt', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'brokerId', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  { data: 'transactionId', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true }
];

const fieldsByView = (view) => {
  const fields = {
    'recentlyViewed': defaultView,
    'internalBrokers': defaultView
  };
  return fields[camelize(view)];
};

export const optionsForFilterByView = {
  'internalBrokers': {}
};

export const defaultFilter = ({ role, ids }) => {
  const filter = {};
  const byRole = optionsByRole({ role, endpoint });
  if (!!byRole.length) filter.role = { value: byRole[0], type: 'role' };
  if (!!ids.length) filter.view = { name: 'id', type: 'eq', value: ids };
  return filter;
};

export const columnFilters = [
  { filter: filterByDateRange, dataField: 'createdAt' }
];

export const outsideFilters = [
  { component: filterByView, endpoint, options: optionsForFilterByView },
  { component: filterByRole }
];

export default ({ view = 'internalBrokers', items = [] }) => {
  const columns = fieldsByView(view);
  const query = {
    columns,
    order: [{ column: 1, dir: 'asc' }],
    start: 0,
    length: 10,
    endpoint
  };
  return { query, endpoint, columns };
}
