import * as types from 'src/constants/actionTypes/SettingsActionTypes';
import initialStateProfile from 'src/helpers/initialStateSubmit';

const initialState = {
  isFetching: false,
  error: false,
  items: {}
}

export default store => {
  store.on('@init', () => ({ Settings: initialState }));
  store.on('@init', () => ({ SubmitSettings: initialStateProfile }));
  store.on('@init', () => ({ SettingsCollections: initialState }));

  store.on(types.FETCH_SETTINGS_REQUEST, ({ Settings }) =>
    ({ Settings: { ...initialState, isFetching: true }}));
  store.on(types.FETCH_SETTINGS_SUCCESS, ({ Settings }, { response }) =>
    ({ Settings: { ...Settings, items: response.data }}));
  store.on(types.FETCH_SETTINGS_ERROR, ({ Settings }) =>
    ({ Settings: {...Settings, error: true }}));

  store.on(types.SUBMIT_SETTINGS_RESET, () => ({ SubmitSettings: initialState }));
  store.on(types.SUBMIT_SETTINGS_REQUEST, ({ SubmitSettings }) =>
    ({ SubmitSettings: { ...SubmitSettings, ...initialState, sendRequest: true }}));
  store.on(types.SUBMIT_SETTINGS_SUCCESS, ({ SubmitSettings }, { response }) => {
    return ({ SubmitSettings: { ...SubmitSettings, success: true, sendRequest: false }});
  });
  store.on(types.SUBMIT_SETTINGS_ERROR, ({ SubmitSettings }, { response }) => {
    let errors = {};
    if (response && response.body && response.body.errors) errors = response.body.errors;
    return ({ SubmitSettings: {...SubmitSettings, error: true, errors: errors, sendRequest: false }});
  });

  store.on(types.FETCH_SETTINGS_COLLECTION_REQUEST, ({ SettingsCollections }) =>
    ({ SettingsCollections: { ...initialState, isFetching: true }}));
  store.on(types.FETCH_SETTINGS_COLLECTION_SUCCESS, ({ SettingsCollections }, { response }) =>
    ({ SettingsCollections: { ...SettingsCollections, items: response.data }}));
  store.on(types.FETCH_SETTINGS_COLLECTION_ERROR, ({ SettingsCollections }) =>
    ({ SettingsCollections: {...SettingsCollections, error: true }}));
}
