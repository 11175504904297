import * as types from 'src/constants/actionTypes/InternalBrokersActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  loaded: false,
  items: {}
}

export default store => {
  store.on('@init', () => ({ InternalBrokerCollections: initialState }));

  store.on(types.FETCH_INTERNAL_BROKER_COLLECTION_REQUEST, ({ InternalBrokerCollections }) =>
    ({ InternalBrokerCollections: { ...InternalBrokerCollections, isFetching: true, loaded: false }}));
  store.on(types.FETCH_INTERNAL_BROKER_COLLECTION_SUCCESS, ({ InternalBrokerCollections }, { response }) =>
    ({ InternalBrokerCollections: { ...InternalBrokerCollections, items: response.data, loaded: true }}));
  store.on(types.FETCH_INTERNAL_BROKER_COLLECTION_ERROR, ({ InternalBrokerCollections }) =>
    ({ InternalBrokerCollections: {...InternalBrokerCollections, error: true }}));
}
