import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import sizeMe from 'react-sizeme';
import { some, remove, isFunction, map, indexOf, isEqual } from 'lodash';
import Link from 'src/helpers/Link';
import randomId from 'src/helpers/randomId';
import { useDebounce } from 'src/hooks/utility';
import { getMenuItems } from 'src/components/views/layout/menu-items';

import './HorizontalMenu.scss';

const DropdownMore = props => <a {...props}><i className="fa fa-angle-down horizontal-icon"></i></a>;
const mustUseDropdownMenu = ({ width }) => width <= 992;
const filterItems = (items, dimensions) => {
  if (mustUseDropdownMenu(dimensions)) {
    return [[...items], []];
  }

  const numberOfItems = dimensions.width > 1280 ? 9 : 7;
  const priorityItems = items.slice(0, numberOfItems);
  const moreItems = priorityItems.length !== items.length ? items.slice(numberOfItems, items.length) : [];

  return [priorityItems, moreItems];
};
const getWindowDimensions = () => {
  return {
    height: window.innerHeight,
    width: window.innerWidth
  };
};
const getModuleFromPath = path => {
  const i1 = indexOf(path, '?');
  const i2 = indexOf(path, '/', 1);

  if (i1 === -1 && i2 === -1) {
    return path;
  }

  if (i1 === -1) {
    return path.substr(0, i2);
  }

  if (i2 === -1) {
    return path.substr(0, i1);
  }

  return path.substr(0, Math.min(i1, i2));
};
const isSameModule = (path1, path2) => {
  const module1 = getModuleFromPath(path1);
  const module2 = getModuleFromPath(path2);
  const result = isEqual(module1, module2);

  return result;
};

const HorizontalMenu = ({ match }) => {
  const { t } = useTranslation();
  const moreMenu = useRef(null);
  const navigation = useRef(null);
  const navigationOuter = useRef(null);
  const [priorityItems, setPriorityItems] = useState([]);
  const [moreItems, setMoreItems] = useState([]);
  const [dimensions, setDimensions] = useState(getWindowDimensions());
  const updateItems = useCallback((items, dimensions) => {
    const [priorityItems, moreItems] = filterItems(items, dimensions);

    setPriorityItems(priorityItems);
    setMoreItems(moreItems);
  }, []);
  const debouncedDimensions = useDebounce(dimensions, 100);

  useEffect(() => {
    const items = getMenuItems();
    const dimensions = getWindowDimensions();

    updateItems(items, dimensions);
  }, [updateItems]);

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  });

  useEffect(() => {
    const isTabletOrMobile = mustUseDropdownMenu(debouncedDimensions);
    const items = getMenuItems({ isTabletOrMobile });

    updateItems(items, debouncedDimensions);
  }, [debouncedDimensions, updateItems]);

  const getChildPaths = useCallback((route) => {
    const { path, children = [] } = route;
    const paths = [];

    if (isFunction(path)) {
      paths.push(path());
    }

    for (const childRoute of children) {
      const childPaths = getChildPaths(childRoute);

      paths.push(...childPaths);
    }

    return paths;
  }, []);

  const checkActive = useCallback((route) => {
    const paths = getChildPaths(route);
    const hasRootPath = remove(paths, path => path === '/').length > 0;
    const result = (match.url === '/' && hasRootPath) || (match.url !== '/' && some(paths, path => isSameModule(path, match.url))) ? 'active' : '';

    return result;
  }, [getChildPaths, match.url]);

  const test = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handlerSubMenu = useCallback((event) => {
    const target = event.target.nodeName === 'I' ? event.target.parentNode : event.target;
    const subMenu = target.nextSibling.nextSibling;
    const uls = navigation.current.querySelectorAll('.sub-menu:not(.hide)');

    if (subMenu.classList.contains('hide')) {
      Array.from(uls).forEach(el => {
        el.classList.add('hide');
        el.style.display = '';
      });
      subMenu.classList.remove('hide');

      subMenu.style.display = 'block';
    } else {
      subMenu.classList.add('hide');

      subMenu.style.display = '';
    }
  }, []);

  return (
    <div>
      <nav className="horizontalMenu clearfix" ref={navigationOuter}>
        <ul className="horizontalMenu-list" ref={navigation}>
          {map(priorityItems, (item, index) => (
            <li key={randomId()} className={`dropdown nav-item ${checkActive(item)}`}>
              {item.children && item.children.length > 0 && (
                <span className="horizontalMenu-click" onClick={handlerSubMenu}>
                  <i className="horizontalMenu-arrow fa fa-angle-down"></i>
                </span>
              )}
              <Link to={item.path()} target={item.target} className="dropdown-item new-record" tabIndex={0} role="menuitem">
                <i className={`mr-2 ${item.icon}`}></i>
                <span className="horizontal-click">{t(`menu.${item.label}`)}</span>
                {item.children && item.children.length > 0 && <i onClick={test} className="ml-2 fa fa-angle-down horizontal-icon"></i>}
              </Link>
              {item.children && item.children.length > 0 && (
                <ul className={`sub-menu ${mustUseDropdownMenu(dimensions) && ' hide'}`} style={{ transition: 'all 0.4s ease-in-out' }}>
                  {map(item.children, (el, index) => (
                    <li aria-haspopup="true" key={randomId()}>
                      <Link to={el.path()} target={el.target} className={`dropdown-item ${el.className}`} tabIndex={0} role="menuitem">
                        {t(`menu.${el.label}`)}
                      </Link>
                      {item.children.length !== (index + 1) && <div tabIndex="-1" className="dropdown-divider"></div>}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
          {moreItems.length > 0 && (
            <li ref={moreMenu} className={`dropdown nav-item ${checkActive({ children: moreItems })}`}>
              <DropdownMore className="dropdown-item" />
              <ul className="sub-menu">
                {map(moreItems, (el, index) =>
                  <li aria-haspopup="true" key={randomId()}>
                    <Link to={el.path()} target={el.target} className={`dropdown-item ${el.className}`} tabIndex={0} role="menuitem">
                      {t(`menu.${el.label}`)}
                    </Link>
                    {moreItems.length !== (index + 1) && <div tabIndex="-1" className="dropdown-divider"></div>}
                  </li>
                )}
              </ul>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

const HorizontalMenuWrapper = sizeMe({ refreshMode: 'debounce' })(HorizontalMenu);

const ParentHorizontalMenu = (props) => {
  return (
    <div className="horizontal-main hor-menu clearfix">
      <div className="horizontal-mainwrapper container clearfix">
        <HorizontalMenuWrapper {...props} />
      </div>
    </div>
  );
};

export default ParentHorizontalMenu;
