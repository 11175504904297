import i18n, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from 'src/-data/i18n/en.json';
import fr from 'src/-data/i18n/fr.json';

use(initReactI18next).use(LanguageDetector).init({
  resources: {
    en: { translation: { ...en } },
    fr: { translation: { ...fr } }
  },
  fallbackLng: 'en',
  debug: false,
  whitelist: ['en', 'fr'],
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
