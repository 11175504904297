import React, { useState } from 'react';
import { UncontrolledCollapse as UncontrolledCollapseReactStrap } from 'reactstrap';
import randomId from 'src/helpers/randomId';
import IconDown from 'src/constants/icons/IconDown';

import 'src/styles/accordion.scss';

const UncontrolledCollapse = ({ buttons = [], children, label, withButton = false, buttonHeaderLabel = null, handlerOnClick = null, customButton = null }) => {
  const togglerId = randomId();
  const [activeCss, setActiveCss] = useState('');
  const [border, setBorder] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const handlerEntering = () => {
    setActiveCss('text-blue');
    setBorder('border-blue');
    setIsOpen(true);
  };
  const handlerExiting = () => {
    setActiveCss('');
    setBorder('');
    setIsOpen(false);
  };

  return (
    <ul className={`accordionjs m-0 mb-4`}>
      <li className={`acc_section ${border}`}>
        <div id={togglerId} className={`acc_head ${activeCss}`}>
          <h3>
            <IconDown isExpanded={isOpen} />
            {label}
          </h3>
          <div style={{ position: 'absolute', right: '10px', top: '10px' }}>
            {withButton &&
              <button className="btn btn-sm btn-header" onClick={handlerOnClick}>{buttonHeaderLabel}</button>
            }
            {buttons && buttons.map((button, index) =>
              <button.component key={`${randomId()}-${index}`} {...button.props} />
            )}
            {customButton}
          </div>
        </div>
        <UncontrolledCollapseReactStrap
          toggler={`#${togglerId}`}
          className="acc_content"
          onEntering={handlerEntering}
          onExiting={handlerExiting}
        >
          {children}
        </UncontrolledCollapseReactStrap>
      </li>
    </ul>
  )
}

export default UncontrolledCollapse;
