import React, { useEffect } from 'react';
import FormHooks from 'src/helpers/formHooks';
import { useTranslation } from 'react-i18next';
import useStoreon from 'src/helpers/storeon';
import { signInUser } from 'src/actions/Auth';
import { API_CALL } from 'src/middleware/API';
import { confirmPath, forgotPasswordPath } from 'src/helpers/routes';
import Link from 'src/helpers/Link';
import VerticalSelectSearch from 'src/components/elements/form/VerticalSelectSearch';
import { pick, filter } from 'lodash';
import { ADD_NOTIFICATION } from 'src/constants/actionTypes/notifications';
import { useCountriesLookup, useFeaturesLookup } from 'src/hooks/lookups';
import { LoadingSpinner } from 'src/components/elements/LoadingSpinner/LoadingSpinner';

const DEMO_COUNTRY_CODES = new Set(['BWA', 'GAB', 'GHA', 'KEN', 'MUS', 'NAB', 'RWA', 'TZA', 'ZMB', 'ZWE']);

export default (props) => {
  const { dispatch } = useStoreon();
  const signup = () => {
    const query = { ...pick(inputs, 'email', 'password', 'name', 'company', 'countryCode'), locale: i18n.language };

    dispatch(API_CALL, signInUser(query));
  };
  const { inputs, handleInputChange, handleSubmit, handleSelectSearch } = FormHooks({ email: '', password: '', name: '', company: '', countryCode: '' }, signup);
  const { t, i18n } = useTranslation();
  const { location: { state } } = props;
  const countries = useCountriesLookup();
  const features = useFeaturesLookup();
  const demoCountries = filter(countries?.data, ({ value }) => DEMO_COUNTRY_CODES.has(value));

  useEffect(() => {
    if (state && state.from.pathname === confirmPath()) {
      dispatch(ADD_NOTIFICATION, { message: t('sign.emails.confirmed') });
    }
  }, [dispatch, state, t]);

  return (
    <LoadingSpinner isLoading={!countries.isReady || !features.isReady}>
      <div className="card flex-grow-1 p-4">
        <div className="card-body">
          <h1>{t('sign.login')}</h1>
          <p className="text-muted">{t('sign.signInToYourAccount')}</p>
          <form onSubmit={handleSubmit}>
            {features.data.useDemoLogin &&
              <>
                <VerticalSelectSearch
                  collection={demoCountries}
                  value={inputs.countryCode}
                  onChange={handleSelectSearch}
                  name="countryCode"
                  className="mb-3"
                  placeholder={t('login.country')}
                />
                <input
                  type="text"
                  name="name"
                  placeholder={t('login.name')}
                  className="form-control mb-3"
                  value={inputs.name}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="text"
                  name="company"
                  placeholder={t('login.company')}
                  className="form-control mb-3"
                  value={inputs.company}
                  onChange={handleInputChange}
                  required
                />
              </>
            }
            <div className="input-group mb-3">
              <span className="input-group-addon"><i className="fa fa-user"></i></span>
              <input
                type="text"
                name="email"
                placeholder={t('login.email')}
                className="form-control"
                value={inputs.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="input-group mb-4">
              <span className="input-group-addon"><i className="fa fa-lock"></i></span>
              <input
                type="password"
                name="password"
                placeholder={t('login.password')}
                className="form-control"
                value={inputs.password}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="row">
              <div className="col-12">
                <button type="submit" className="btn btn-primary btn-block">{t('sign.login')}</button>
              </div>
              <div className="col-12">
                <Link to={forgotPasswordPath()} className="btn btn-link box-shadow-0 px-0">
                  {t('sign.forgotPassword?')}
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="card text-white bg-primary d-md-down-none flex-grow-1 p-4">
        <div className="card-body text-justify">
          <p>{t('login.introParagraph1')}</p>
          <p>{t('login.introParagraph2')}</p>
          <ul>
            <li>{t('general.phone')}: <a href="tel:+2302701377">+230 270 1377</a></li>
            <li>{t('general.mobile')}: <a href="tel:+23058069294">+230 5806 9294</a></li>
            <li>{t('general.whatsapp')}: <a href="https://wa.me/23058069294">+230 5806 9294</a></li>
            <li>{t('general.email')}: <a href="mailto:info@propertynexus.co">info@propertynexus.co</a></li>
          </ul>
        </div>
      </div>
    </LoadingSpinner>
  );
};
