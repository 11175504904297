import { loginPath } from 'src/helpers/routes';
import LoginPage from 'src/components/views/login';
import LoginLayout from 'src/components/views/loginLayout';
import { API_CALL } from 'src/middleware/API';
import { fetchCountriesLookup, fetchFeatureLookup } from 'src/actions/lookups';

export default {
  path: loginPath(),
  exact: true,
  strict: true,
  component: LoginPage,
  layout: LoginLayout,
  perform: 'user:signin',
  prepareData: ({ store }) => {
    store.dispatch(API_CALL, fetchCountriesLookup());
    store.dispatch(API_CALL, fetchFeatureLookup());
  }
};
