import { lazy } from 'react';
import { API_CALL } from 'src/middleware/API';
import { Route } from 'src/-types/routing';
import { fetchAllProjects } from 'src/actions/entities/project';
import { fetchAllProperties } from 'src/actions/entities/property';
import { fetchSnagEnums } from 'src/actions/enums/snag';
import { fetchSnag } from 'src/actions/entities/snag';
import { snagClonePath } from 'src/helpers/routes';

const SnagNew = lazy(() => import('src/components/views/snags/SnagNew'));

export interface SnagsCloneRouteParams {
  snagId: string;
}

export const snagEditRoute: Route<SnagsCloneRouteParams> = {
  path: snagClonePath(),
  exact: true,
  strict: true,
  component: SnagNew,
  perform: 'snags:new',
  protected: true,
  prepareData: ({ store, params }) => {
    const { Auth: { connected } } = store.get();

    if (connected !== true) {
      return;
    }

    store.dispatch(API_CALL, fetchSnag({ id: params!.snagId }));
    store.dispatch(API_CALL, fetchAllProjects());
    store.dispatch(API_CALL, fetchAllProperties());
    store.dispatch(API_CALL, fetchSnagEnums());
  }
};

export default snagEditRoute;
