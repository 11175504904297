import * as types from 'src/constants/actionTypes/files/UploadActionTypes';

const fetchingMeta = ({ accessToken, endpoint, id }) => {
  return ({
    endpoint: `${endpoint}/${id}/fetch_meta`,
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_META_REQUEST,
      types.FETCH_META_SUCCESS,
      types.FETCH_META_ERROR
    ]
  });
};

export default fetchingMeta;
