import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {
  loginPath, profilePath, adminCompanyDetails, adminSystemSetting, usersPath, teamsPath
} from 'src/helpers/routes';
import useStoreon from 'src/helpers/storeon';
import { signOutUser } from 'src/actions/Auth';
import { API_CALL } from 'src/middleware/API';
import { useTranslation } from 'react-i18next';
import { adminRoles } from 'src/rules';

const DropDownUserMenu = (props) => {
  const { dispatch, Auth } = useStoreon('Auth');
  const { t } = useTranslation();

  const { user: { fullName, role } } = Auth;

  const handlerSubmitSignOut = event => {
    event.preventDefault();
    dispatch(API_CALL, signOutUser(Auth.token));
  };

  return (
    <UncontrolledDropdown className="header-user">
      <DropdownToggle tag="a" className="btn btn-user nav-link icon">
        <i className="icon-pn-Contact"></i>
      </DropdownToggle>
      <DropdownMenu style={{zIndex: 99999, left: 'auto' }} right>
        <div className="text-center">
          <h5 tabIndex="-1" className="text-center py-1 text-dark-blue font-weight-bold dropdown-header">
            {fullName}
          </h5>
          <span className="text-center user-semi-title text-dark">{t(`profile.roles.${role}`)}</span>
          <DropdownItem divider />
        </div>
        <DropdownItem tag="a" href={profilePath()}>
          <i className="icon-pn-Profile dropdown-icon"></i>
          {t('navbar.profile')}
        </DropdownItem>
        {adminRoles.includes(role) &&
          <>
            <DropdownItem className="dropdown-item" tag="a" href={adminCompanyDetails()}>
              <i className="icon-pn-Company dropdown-icon"></i>
              {t('navbar.companyDetails')}
            </DropdownItem>
            <DropdownItem className="dropdown-item" tag="a" href={adminSystemSetting()}>
              <i className="icon-pn-Settings dropdown-icon"></i>
              {t('navbar.systemSettings')}
            </DropdownItem>
            <DropdownItem className="dropdown-item" tag="a" href={teamsPath()}>
              <i className="icon-pn-Teams dropdown-icon"></i>
              {t('navbar.teams')}
            </DropdownItem>
            <DropdownItem className="dropdown-item" tag="a" href={usersPath()}>
              <i className="icon-pn-Users dropdown-icon"></i>
              {t('navbar.users')}
            </DropdownItem>
          </>
        }
        <DropdownItem tag="a" href={loginPath()} onClick={handlerSubmitSignOut}>
          <i className="icon-pn-Sign-Out dropdown-icon"></i>
          {t('navbar.signOut')}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default DropDownUserMenu;
