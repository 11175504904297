import { paymentsPath } from 'src/helpers/routes';
import { lazy } from 'react';
import fetchDataTable from 'src/actions/DataTable';
import { API_CALL } from 'src/middleware/API';
import dataTableParams, { defaultFilter } from 'src/datatables/payments';
import { fetchPaymentCollections } from 'src/actions/Payments';

const Payments = lazy(() => import('src/components/views/payments'));

export default {
  path: paymentsPath(),
  exact: true,
  strict: true,
  component: Payments,
  perform: "payments:index",
  protected: true,
  prepareData: ({ store }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    if (Object.keys(state.PaymentCollections.items).length ===  0) {
      store.dispatch(API_CALL, fetchPaymentCollections({ accessToken }));
    }
    const { role } = state.Auth.user;
    const { items = [] } = state.RecentlyViewed;
    const endpoint = "payments";
    const ids = (items[endpoint] || []).map(i => i.id) || [];
    const { query } = dataTableParams({ role, endpoint, ids });
    query.filter = Object.values(defaultFilter({ role, ids }));
    store.dispatch(API_CALL, fetchDataTable({ accessToken, query, endpoint }));
  }
};
