import { contactPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchContact } from 'src/actions/Contacts';
import { API_CALL } from 'src/middleware/API';
import { fetchActivityCollections } from 'src/actions/Activities';
import { fetchContactCollections } from 'src/actions/Contacts';
import Layout from 'src/components/views/showLayout';

const ContactShow = lazy(() => import('src/components/views/contacts/Show'));

export default {
  path: contactPath(),
  exact: true,
  strict: true,
  component: ContactShow,
  perform: "contacts:show",
  layout: Layout,
  protected: true,
  prepareData: ({ store, params }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    if (Object.keys(state.ActivityCollections.items).length ===  0) {
      store.dispatch(API_CALL, fetchActivityCollections({ accessToken }));
    }
    if (Object.keys(state.ContactCollections.items).length ===  0) {
      store.dispatch(API_CALL, fetchContactCollections({
        accessToken, query: { onlyProperty: true }
      }));
    }
    store.dispatch(API_CALL, fetchContact({ accessToken, id: params.id }));
  }
};
