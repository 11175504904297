import { companyEditPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchCompanyCollections, fetchCompany } from 'src/actions/Companies';
import { API_CALL } from 'src/middleware/API';

const CompanyEdit = lazy(() => import('src/components/views/companies/Edit'));

export default {
  path: companyEditPath(),
  exact: true,
  strict: true,
  component: CompanyEdit,
  perform: 'companies:update',
  title: 'Edit Company',
  protected: true,
  prepareData: ({ store, params }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    if (Object.keys(state.CompanyCollections.items).length ===  0) {
      store.dispatch(API_CALL, fetchCompanyCollections({ accessToken }));
    }
    const query = { noTypeCast: true }
    store.dispatch(API_CALL, fetchCompany({ accessToken, id: params.id, query }));
  }
};
