import * as types from 'src/constants/actionTypes/ContactsActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  loaded: false,
  items: {}
}

export default store => {
  store.on('@init', () => ({ ContactCollections: initialState }));

  store.on(types.FETCH_CONTACT_COLLECTION_REQUEST, ({ ContactCollections }) =>
    ({ ContactCollections: { ...ContactCollections, isFetching: true, loaded: false }}));
  store.on(types.FETCH_CONTACT_COLLECTION_SUCCESS, ({ ContactCollections }, { response }) =>
    ({ ContactCollections: { ...ContactCollections, items: response.data, loaded: true }}));
  store.on(types.FETCH_CONTACT_COLLECTION_ERROR, ({ ContactCollections }) =>
    ({ ContactCollections: {...ContactCollections, error: true }}));
}
