import React from 'react';
import { useTranslation } from 'react-i18next';
import useStoreon from 'src/helpers/storeon';
import Form from 'src/components/views/offers/Form';
import { offersPath, } from 'src/helpers/routes';
import { createOffer } from 'src/actions/Offers';
import dayjs from 'dayjs';
import IconOfferCircle from 'src/constants/icons/IconOfferCircle';

const pad = (a, b) => ([1e15] + a).slice(-b);

export default (props) => {
  const { t } = useTranslation();
  const { SubmitOffer, Offer } = useStoreon('SubmitOffer', 'Offer');

  const { offer = {} } = Offer;
  const { match: { params: { id } } } = props;

  const serverSideValidation = new Promise(function(resolve, reject) {
    const { errors, error, success, sendRequest } = SubmitOffer;
    if (!sendRequest && success) resolve();
    if (!sendRequest && error) reject(errors);
  });

  let successPath = offersPath();

  return (
    <div className="row mb-5">
      <div className="col">
        <div className="card">
          <div className="card-header">
            <div className="card-title form-inline">
              <div className="mr-2 mt-1">
                <IconOfferCircle width={35} height={35} fill="#182852" />
              </div>{t('offers.newCounterOfferTitle')}
            </div>
          </div>
          <div className="card-body p-5">
            {Object.keys(offer).length > 0 && <Form
              defaultValues={{
                ...offer,
                date: dayjs(offer.date).format('YYYY-MM-DD'),
                amountCents: 0,
                status: 'active',
                parentId: id,
                parent: `OF-${pad(id, 7)}`,
                offeredBy: ''
              }}
              successPath={successPath}
              submitHandler={createOffer}
              serverSideValidation={serverSideValidation}
              {...props}
              defaultOfferType={offer.offerTypeRaw}
            >
              <div className="row pt-3">
                <div className="col">
                  <div className="float-right">
                    <button disabled={SubmitOffer.sendRequest} className="btn btn-primary float-right">
                      {t('buttons.create')}
                    </button>
                  </div>
                </div>
              </div>
            </Form>}
          </div>
        </div>
      </div>
    </div>
  )
}
