import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useStoreon from 'src/helpers/storeon';
import { TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import New from './activityNew';
import Items from 'src/components/views/activity/Items';
import Edit from './activityEdit';
import { useMediaQuery } from 'react-responsive';

import { API_CALL } from 'src/middleware/API';
import { fetchActivity } from 'src/actions/Activities';

import { tabs, listingActivity, communication } from 'src/components/views/activity/helperBlock';

export default ({ match, history, items = [], successCallbackParent }) => {
  const [tab, setTab] = useState('#all');
  const [viewEdit, setViewEdit] = useState(false);
  const { t } = useTranslation();
  const { Auth, Activity, dispatch } = useStoreon("Auth", "Activity");

  const handlerOnClickTab = (tab) => (event) => {
    event.preventDefault();
    setTab(tab);
  };

  const handlerOnDoubleClick = (id) => {
    setTab("#edit");
    setViewEdit(true);
    dispatch(API_CALL, fetchActivity({ accessToken: Auth.token, id, query: { noTypeCast: true } }));
  };

  const handlerCancelUpdate = () => {
    setTab("#all");
    setViewEdit(false);
  };

  const successCallback = () => {
    handlerCancelUpdate();
    successCallbackParent();
  }

  const newOrEdit = ({ tabEl, index }) => {
    if (index > 1) return false;
    if (index === 0) return viewEdit;
    return !viewEdit;
  };

  const { teamIds, role } = Auth.user;

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return (
    <div className="bg-white">
      <div className="panel panel-primary px-5">
        <h4 className="text-dark-blue mb-0 py-3">{t("leads.subFolders.activity")}</h4>
      </div>
      <div className="tab_wrapper">
        <ul className="tab_list">
          {tabs.map((tabEl, index) =>
            <li
              style={{fontSize: "0.9em"}}
              key={`activity-tab-${index}`}
              rel={tabEl}
              onClick={handlerOnClickTab(tabEl)}
              className={`px-${isTabletOrMobile ? 3: 4} py-3 ${classnames({ active: tab === tabEl, "d-none": newOrEdit({ tabEl, index }) })}`}
            >
              {t(`activities.subFolders.${tabEl}`)}
            </li>
          )}
        </ul>
      </div>
      <div className="panel panel-primary px-4">
        <div className="panel-body tabs-menu-body">
          <TabContent activeTab={tab}>
            <TabPane tabId="#new">
              <New
                history={history}
                successCallback={successCallback}
              />
            </TabPane>
            <TabPane tabId="#edit">
              {Activity.success &&
                <Edit
                  history={history}
                  item={Activity.item}
                  handlerCancelUpdate={handlerCancelUpdate}
                  successCallback={successCallback}
                />
              }
            </TabPane>
            <TabPane tabId="#all">
              <Items
                handlerOnDoubleClick={handlerOnDoubleClick}
                items={items}
                teamIds={teamIds}
                userRole={role}
              />
            </TabPane>
            <TabPane tabId="#activity">
              <Items
                handlerOnDoubleClick={handlerOnDoubleClick}
                items={items.filter(el => listingActivity.includes(el.typeOfRaw))}
                teamIds={teamIds}
                userRole={role}
              />
            </TabPane>
            <TabPane tabId="#communication">
              <Items
                handlerOnDoubleClick={handlerOnDoubleClick}
                items={items.filter(el => communication.includes(el.typeOfRaw))}
                teamIds={teamIds}
                userRole={role}
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  )
};
