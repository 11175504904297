import { propertyClonePath } from 'src/helpers/routes';
import propertyNew from 'src/routes/properties/New';
import { fetchPropertyCollections, fetchProperty } from 'src/actions/Properties';
import { API_CALL } from 'src/middleware/API';
import PropertyClone from 'src/components/views/properties/Clone';

const prepareData = ({ store, params }) => {
  const state = store.get();
  if (state.Auth.connected === false) return;
  const accessToken = state.Auth.token;
  if (Object.keys(state.ListingCollections.items).length ===  0) {
    store.dispatch(API_CALL, fetchPropertyCollections({ accessToken }));
  }
  const query = { noTypeCast: true, withProperties: true }
  store.dispatch(API_CALL, fetchProperty({ accessToken, id: params.id, query }));
};

export default Object.assign(
  {},
  propertyNew,
  { path: propertyClonePath(), prepareData, component: PropertyClone });
