import rules from 'src/rules';

type CheckPerformArgs = { role: string, perform: string; data?: any };

export const checkPerform: (args: CheckPerformArgs) => boolean = ({ role, perform, data }) => {
  if (perform === 'user:signin') {
    return true;
  }

  const permissions = rules[role];
  const staticPermissions = permissions?.static;
  const dynamicPermissions = permissions?.dynamic;

  return (staticPermissions?.includes(perform) ?? false) || (dynamicPermissions?.[perform]?.(data) ?? false);
};
