import { adminSystemSetting } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchSettings } from 'src/actions/Settings';
import { API_CALL } from 'src/middleware/API';

const Settings = lazy(() => import('src/components/views/admin/settings'));

export default {
  path: adminSystemSetting(),
  exact: true,
  strict: true,
  component: Settings,
  protected: true,
  perform: 'admin/settings:index',
  title: 'Settings',
  prepareData: ({ store }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    store.dispatch(API_CALL, fetchSettings({ accessToken }));
  }
};
