import React, { useCallback, useMemo } from 'react';
import useStoreon from 'src/helpers/storeon';

export default ({ onChange, label, source, type = 'eq' }) => {
  const [key, items, values] = useMemo(() => source?.split('.') ?? [], [source]);
  const fromStoreon = useStoreon(key);
  const optionsForSelect = useMemo(() => fromStoreon[key]?.[items]?.[values] ?? [], [fromStoreon, items, key, values]);

  const handlerOnChange = useCallback(event => {
    event.preventDefault();

    const { target } = event;
    const { value } = target.options[target.selectedIndex];
    const newValue = value === label ? {} : { value, type, name: values };

    onChange({ currFilters: { [values]: newValue } });
  }, [label, onChange, type, values]);

  return (
    <div className="ml-2">
      <select className="form-control form-control-sm min-width-100" onChange={handlerOnChange}>
        <option>{label}</option>
        {optionsForSelect.map((view, index) =>
          <option key={`wkshjwhg-${index}`} value={view.value}>{view.label}</option>
        )}
      </select>
    </div>
  );
};
