import { adminCompanyDetails } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchBaseCompany } from 'src/actions/BaseCompany';
import { API_CALL } from 'src/middleware/API';

const CompanyDetails = lazy(() => import('src/components/views/admin/company'));

export default {
  path: adminCompanyDetails(),
  exact: true,
  strict: true,
  component: CompanyDetails,
  protected: true,
  perform: 'admin/companyDetails:index',
  title: 'Company Details',
  prepareData: ({ store }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    store.dispatch(API_CALL, fetchBaseCompany({ accessToken }));
  }
};
