import React from 'react';
import dayjs from 'dayjs';
import { checkPerform } from 'src/helpers/authorization';

export default ({
  end, start, id, status, typeOf, objectiveOfActivity, handlerOnDoubleClick, userRole,
  ownerId, teamIds
}) => {
  const dateStart = dayjs(start);
  const dateEnd = dayjs(end);

  let eventValue;

  if (dateStart.isSame(dateEnd, 'day')) {
    eventValue = `${dateStart.format("HH:mm")} to ${dateEnd.format("HH:mm: DD/MM/YY")}`;
  } else {
    const diff = dateEnd.diff(dateStart, 'day');
    eventValue = `${dateStart.format("HH:mm DD/MM/YY")}: to ${dateStart.format("HH:mm")} (+${diff})`;
  }

  const isEdit = checkPerform({
    role: userRole,
    perform: 'activity:edit',
    data: { ownerId, teamIds }
  });

  const handlerClick = () => {
    if (isEdit) handlerOnDoubleClick(id);
  };

  return (
    <li className="list-group-item mb-1" onDoubleClick={handlerClick}>
      <i className={`fa fa-circle mr-2 ${status.toLowerCase()}`} />
      {`${typeOf}: ${objectiveOfActivity}`}
      {isEdit && <i className="fe fe-edit-2 float-right" style={{paddingTop: '2px'}}/>}
      <div className={`break-line-before`}>{eventValue}</div>
    </li>
  )
};
