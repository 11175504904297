import { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isFunction, isString } from 'lodash';

const index = (obj, is, value) => {
  if (isString(is)) {
    return index(obj, is.split('.'), value);
  } else if (is.length === 1 && value !== undefined) {
    return obj[is[0]] = value;
  } else if (is.length === 0) {
    return obj;
  } else {
    return index(obj[is[0]], is.slice(1), value);
  }
};

const FormHooks = (initialValues, callback, dynamicCallBack) => {
  const [inputs, setInputs] = useState(initialValues);
  const handleSubmit = useCallback((event) => {
    if (event) {
      event.preventDefault();
    }

    callback(inputs);
  }, [callback, inputs]);
  const handleInputChange = useCallback((event) => {
    event.persist();

    let { value } = event.target;

    if (event.target.dataset.money) {
      if (value.match(/^[+-]?\d+(\.\d+)?$/)) {
        value *= 100;
      } else {
        value = 0;
      }
    }

    if (event.target.type && event.target.type === 'checkbox') {
      value = event.target.checked;
    }

    setInputs(prev => {
      index(prev, event.target.getAttribute('data-input-name') ?? event.target.name, value);

      return { ...prev };
    });
  }, []);
  const handleDataChange = useCallback((object) => {
    setInputs(inputs => {
      const entryArg = isFunction(object) ? object(inputs) : object;

      Object.entries(entryArg).forEach(([path, value]) => {
        index(inputs, path, value);
      });

      return { ...inputs };
    });
  }, []);
  const handleDataArrayDecrease = useCallback((key) => {
    handleDataChange(inputs => {
      const object = inputs[key];

      object.pop();

      return { [key]: object }
    });
  }, [handleDataChange]);
  const handleDataArrayIncrease = useCallback((key) => {
    handleDataChange(inputs => {
      const object = inputs[key];

      object.push(0);

      return { [key]: object }
    });
  }, [handleDataChange]);
  const handleArrayField = useCallback((event, key, index) => {
    event.persist();

    setInputs(inputs => {
      inputs[key][index] = ({ ...(inputs[key][index]), [event.target.name]: event.target.value });

      return { ...inputs };
    });
  }, []);
  const handleSelectSearch = useCallback((name, value) => {
    setInputs(inputs => {
      index(inputs, name, value);

      return { ...inputs };
    });
  }, []);
  const handleFile = useCallback((event) => {
    event.persist();

    const { target } = event;
    const file = target.files[0];

    setInputs(inputs => ({ ...inputs, [target.name]: file }));
  }, []);
  const init = useCallback(() => {
    setInputs(initialValues);
  }, [initialValues]);
  const dynamicHandleChange = useCallback((name) => (event) => {
    event.persist();

    let { value } = event.target;

    if (event.target.type && event.target.type === 'checkbox') {
      value = event.target.checked;
    }

    setInputs(inputs => ({ ...inputs, [event.target.name]: value }));
    name.forEach(el => setInputs(inputs => ({ ...inputs, [el]: '' })));
    dynamicCallBack({ name: event.target.name, value });
  }, [dynamicCallBack]);
  const handleObject = useCallback(({ name, value }) => {
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }, []);
  const result = useMemo(() => ({
    handleSubmit,
    handleInputChange,
    handleDataChange,
    handleDataArrayDecrease,
    handleDataArrayIncrease,
    handleArrayField,
    handleSelectSearch,
    handleFile,
    dynamicHandleChange,
    inputs,
    init,
    setInputs,
    handleObject
  }), [dynamicHandleChange, handleArrayField, handleDataArrayDecrease, handleDataArrayIncrease, handleDataChange, handleFile, handleInputChange, handleObject, handleSelectSearch, handleSubmit, init, inputs]);

  return result;
}

FormHooks.propTypes = {
  initialValues: PropTypes.object,
  callback: PropTypes.object
};

export default FormHooks;
