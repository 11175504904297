export const FETCH_LOOKUPS_COUNTRIES_REQUEST = 'FETCH_LOOKUPS_COUNTRIES_REQUEST';
export const FETCH_LOOKUPS_COUNTRIES_SUCCESS = 'FETCH_LOOKUPS_COUNTRIES_SUCCESS';
export const FETCH_LOOKUPS_COUNTRIES_ERROR = 'FETCH_LOOKUPS_COUNTRIES_ERROR';

export const FETCH_LOOKUPS_FEATURES_REQUEST = 'FETCH_LOOKUPS_FEATURES_REQUEST';
export const FETCH_LOOKUPS_FEATURES_SUCCESS = 'FETCH_LOOKUPS_FEATURES_SUCCESS';
export const FETCH_LOOKUPS_FEATURES_ERROR = 'FETCH_LOOKUPS_FEATURES_ERROR';

export const FETCH_LOOKUPS_FIELDS_REQUEST = 'FETCH_LOOKUPS_FIELDS_REQUEST';
export const FETCH_LOOKUPS_FIELDS_SUCCESS = 'FETCH_LOOKUPS_FIELDS_SUCCESS';
export const FETCH_LOOKUPS_FIELDS_ERROR = 'FETCH_LOOKUPS_FIELDS_ERROR';
