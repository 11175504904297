import { hasString } from 'src/helpers/string';
import { formatDate } from 'src/-utils/date';
import { snagIdFormatter } from 'src/-utils/id';
import { assign } from 'lodash';

export interface FieldFilters {
  [key: string]: { name?: string; value: any; type: string; } | null | undefined;
  role?: {
    value: string;
    type: string;
  };
  view?: {
    name: string;
    value: number[];
    type: string;
  };
}

export type DatatableFilterOnChangeFunc = (args?: { currFilters: FieldFilters } | null) => void;

export interface DatatableViewColumn {
  data: string;
  searchable: boolean;
  orderable: boolean;
  search: {
    value: string;
    regex: string;
  }
  hidden?: boolean;
  classes?: string;
  path?: string;
  pathKey?: string;
  textFormatter?: (column: DatatableViewColumn, extra: any) => string;
  formatter?: (cell: any, row: any, rowIndex: number, extra: any) => any;
}

export type DatatableView = DatatableViewColumn[];

export interface OutsideFilterOptions {
  [key: string]: {
    textFormatter?: (key: string, t: any) => string;
  };
}

export interface OutsideFilter {
  component: React.FunctionComponent<any>;
  endpoint: string;
  options?: OutsideFilterOptions;
}

export type OutsideFilters = OutsideFilter[];

export function enumFormatterFactory(namespace: string) {
  return (cell: any, _row: any, _rowIndex: number, t: any) => hasString(cell) ? t(`${namespace}.${cell}`) : '';
}

export function dateFormatter(cell: any, _row: any, _rowIndex: number, _t: any) {
  return formatDate(cell);
}

const idFormatter = (cell: any, _row: any, _rowIndex: number, _t: any) => snagIdFormatter(cell);
const attributeTextFormatterFactory = (attribute: string) => (_column: DatatableViewColumn, t: any) => t(`snags.attributes.${attribute}.label`);
const relationshipTextFormatterFactory = (relationship: string) => (_column: DatatableViewColumn, t: any) => t(`snags.relationships.${relationship}.label`);

export function createIdColumn(options?: Partial<DatatableViewColumn> | null) {
  return assign({}, {
    searchable: true,
    orderable: false,
    search: { value: '', regex: '' },
    hidden: false,
    textFormatter: (_column: DatatableViewColumn, t: any) => t(`snags.id.label`),
    formatter: idFormatter,
    data: 'id'
  }, options ?? {});
}

export function createAttributeColumn(options: { data: string } & Partial<DatatableViewColumn>) {
  return assign({}, {
    searchable: true,
    orderable: false,
    search: { value: '', regex: '' },
    hidden: false,
    textFormatter: attributeTextFormatterFactory(options.data)
  }, options);
}

export function createRelationshipColumn(options: { data: string } & Partial<DatatableViewColumn>) {
  return assign({}, {
    searchable: true,
    orderable: false,
    search: { value: '', regex: '' },
    hidden: false,
    textFormatter: relationshipTextFormatterFactory(options.data)
  }, options);
}
