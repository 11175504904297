import React from 'react';

const IconDashboardCircle = ({
  width, style, height, className, fill
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 42.7 42.7"
      width={width}
      style={style}
      height={height}
      className={className}
    >
      <path fill={fill} d="M39.8,29.4c-0.3-0.1-0.6,0-0.7,0.3c-3.1,6.7-9.9,11.3-17.7,11.3c-5.4,0-10.3-2.2-13.8-5.7
    		C4,31.6,1.8,26.7,1.8,21.3C1.8,15.9,4,11,7.5,7.5C11,4,15.9,1.8,21.3,1.8c2.8,0,5.4,0.6,7.8,1.6c0.3,0.1,0.6,0,0.7-0.3
    		c0.1-0.3,0-0.6-0.3-0.7h0c-2.5-1.1-5.3-1.7-8.2-1.7C9.9,0.7,0.7,9.9,0.7,21.3C0.7,32.8,9.9,42,21.3,42c8.3,0,15.4-4.9,18.7-11.9
    		C40.2,29.8,40.1,29.5,39.8,29.4z"/>
    	<polygon fill={fill} points="30.3,31.7 30.3,31.7 30.3,31.7 	"/>
    	<polygon fill={fill} points="29.8,32.1 29.8,32.1 29.8,32.1 	"/>
    	<polygon fill={fill} points="33,28.5 33,28.5 33,28.5 	"/>
    	<polygon fill={fill} points="26.9,33.9 26.9,33.9 26.9,33.9 	"/>
    	<path fill={fill} d="M33.9,26.7L33.9,26.7c0-0.2-0.1-0.4-0.3-0.5l-11-5.6l4.6-11.1c0.1-0.3,0-0.6-0.3-0.7c-1.2-0.5-2.4-0.9-3.7-1.1
    		c-0.4-0.1-0.8-0.1-1.2-0.1c-0.2,0-0.5,0-0.8,0c-7.6,0-13.7,6.1-13.7,13.7c0,7.6,6.1,13.7,13.7,13.7c0.4,0,0.8,0,1.3-0.1
    		c0.1,0,0.3,0,0.4,0c0.3,0,0.6-0.1,0.9-0.1c0.1,0,0.2,0,0.4-0.1l0,0c0.4-0.1,0.8-0.2,1.1-0.3l0,0l0.2-0.1l0,0
    		c0.3-0.1,0.6-0.2,0.9-0.3l0,0c0.1-0.1,0.3-0.1,0.4-0.2c0.2-0.1,0.5-0.2,0.7-0.3l0,0c0.1-0.1,0.3-0.1,0.4-0.2
    		c0.3-0.2,0.6-0.4,0.9-0.5c0.2-0.1,0.3-0.2,0.5-0.3l0,0c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.3-0.3,0.5-0.4c0.1-0.1,0.2-0.2,0.3-0.3
    		l0,0c0.6-0.5,1.1-1.1,1.6-1.8l0,0l0.2-0.2l0,0c0.2-0.2,0.3-0.4,0.4-0.7l0,0l0.1-0.2c0.3-0.5,0.6-1,0.8-1.6L33.9,26.7z M32.1,27.9
    		L32.1,27.9L32,28.1c-0.1,0.2-0.3,0.4-0.4,0.6l-0.2,0.2c-0.4,0.6-0.9,1.1-1.5,1.6c-0.1,0.1-0.2,0.2-0.3,0.3l0,0
    		c-0.1,0.1-0.3,0.3-0.4,0.4l0,0c-0.1,0.1-0.3,0.2-0.4,0.3l0,0c-0.1,0.1-0.3,0.2-0.4,0.3l0,0c-0.3,0.2-0.5,0.3-0.8,0.5
    		c-0.1,0.1-0.2,0.1-0.4,0.2c-0.2,0.1-0.4,0.2-0.6,0.3l0,0c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3,0.1-0.5,0.2-0.8,0.3l0,0L25,33.4
    		c-0.3,0.1-0.7,0.2-1.1,0.3c-0.1,0-0.2,0-0.3,0.1c-0.3,0.1-0.6,0.1-0.8,0.1c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.1
    		c-3.5,0-6.6-1.4-8.9-3.7c-2.3-2.3-3.7-5.4-3.7-8.9c0-3.5,1.4-6.6,3.7-8.9c2.3-2.3,5.4-3.7,8.9-3.7c0.2,0,0.4,0,0.7,0
    		c0.4,0,0.7,0.1,1.1,0.1c1,0.1,2,0.4,2.9,0.8l-4.6,11.1c-0.1,0.3,0,0.6,0.3,0.7l10.9,5.5C32.4,27.3,32.3,27.6,32.1,27.9z"/>
    	<polygon fill={fill} points="28.9,32.8 28.9,32.8 28.9,32.8 	"/>
    	<path fill={fill} d="M42,21.3c0-0.7,0-1.4-0.1-2c0,0,0,0,0,0l0,0l0,0c-0.1-0.7-0.2-1.4-0.3-2c0,0,0,0,0,0l0,0l0,0
    		c-0.1-0.7-0.3-1.3-0.5-2c0,0,0,0,0,0l0,0l0,0c-0.3-0.9-0.6-1.7-1-2.5c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0-0.3,0-0.4,0l0,0c0,0,0,0,0,0
    		c0,0,0,0,0,0l-12.8,6.8c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.2,0.1,0.4,0.3,0.5l14.1,6.2c0.2,0.1,0.3,0.1,0.5,0c0.1-0.1,0.3-0.2,0.3-0.4
    		c0.2-1,0.4-2,0.5-3c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0-0.3,0-0.6,0-0.8c0-0.2,0-0.4,0-0.6c0,0,0,0,0,0L42,21.3L42,21.3L42,21.3L42,21.3
    		z M39.3,13.7c0.3,0.6,0.5,1.2,0.7,1.8l0,0c0.2,0.6,0.3,1.2,0.5,1.9l0,0l0,0c0.1,0.6,0.2,1.3,0.3,1.9v0l0,0v0c0,0,0,0,0,0
    		c0.1,0.6,0.1,1.3,0.1,1.9l0,0.1l0,0c0,0,0,0,0,0c0,0.2,0,0.4,0,0.6c0,0.3,0,0.5,0,0.8c-0.1,0.8-0.2,1.5-0.3,2.2l-12.4-5.4
    		L39.3,13.7z"/>
    	<path fill={fill} d="M25.7,17.7c0.2,0.2,0.5,0.2,0.7,0l10.6-9c0.1-0.1,0.2-0.2,0.2-0.4c0-0.1,0-0.3-0.1-0.4
    		c-1.2-1.5-2.7-2.7-4.3-3.8C32.7,4,32.5,4,32.4,4.1c-0.2,0-0.3,0.1-0.4,0.3L25.6,17C25.5,17.3,25.5,17.6,25.7,17.7z M32.7,5.4
    		c1.1,0.8,2.2,1.8,3.1,2.8l-8,6.8L32.7,5.4z"/>
    </svg>
 )
};

export default IconDashboardCircle;
