import { isString } from 'lodash';

export function hasString(obj: any): obj is string {
  return isString(obj) && obj.length > 0;
}

// https://stackoverflow.com/a/20732091
export function formatFileSize(bytes: number): string {
  const pow = Math.floor(Math.log(bytes) / Math.log(1024));
  const shortStr = (bytes / Math.pow(1024, pow)).toFixed(2);
  const shortNum = parseFloat(shortStr);
  const unit = ['B', 'kB', 'MB', 'GB', 'TB'][pow];

  return `${shortNum} ${unit}`;
};
