import { trim, trimEnd } from 'lodash';

const defaultApiRoot = 'http://localhost:5000/api';

export const API_ROOT = (version) => {
  const normalizedApiRoot = trimEnd(process.env.REACT_APP_API_ROOT || defaultApiRoot, '/');
  const normalizedVersion = trim(version, '/');

  return `${normalizedApiRoot}/${normalizedVersion}`;
};
