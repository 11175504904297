import * as types from 'src/constants/actionTypes/DashboardActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  items: {},
  success: false
}

export default store => {
  store.on('@init', () => ({ Dashboard: initialState }));

  store.on(types.FETCH_DASHBOARD_REQUEST, ({ Dashboard }) =>
    ({ Dashboard: { ...initialState, isFetching: true }}));
  store.on(types.FETCH_DASHBOARD_SUCCESS, ({ Dashboard }, { response }) => {
    const { data } = response;
    return ({ Dashboard: { ...Dashboard,  items: data, success: true } });
  });
  store.on(types.FETCH_DASHBOARD_ERROR, ({ Dashboard }) =>
    ({ Dashboard: {...Dashboard, error: true }}));
}
