export const FETCH_PROJECTS_REQUEST = 'FETCH_PROJECTS_REQUEST';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_ERROR = 'FETCH_PROJECTS_ERROR';

export const FETCH_PROJECT_REQUEST = 'FETCH_PROJECT_REQUEST';
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const FETCH_PROJECT_ERROR = 'FETCH_PROJECT_ERROR';
export const FETCH_PROJECT_RESET = 'FETCH_PROJECT_RESET';

export const FETCH_PROJECT_COLLECTION_REQUEST = 'FETCH_PROJECT_COLLECTION_REQUEST';
export const FETCH_PROJECT_COLLECTION_SUCCESS = 'FETCH_PROJECT_COLLECTION_SUCCESS';
export const FETCH_PROJECT_COLLECTION_ERROR = 'FETCH_PROJECT_COLLECTION_ERROR';

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const DELETE_PROJECT_ERROR = 'DELETE_PROJECT_ERROR';

export const DELETE_PROJECTS_REQUEST = 'DELETE_PROJECTS_REQUEST';
export const DELETE_PROJECTS_SUCCESS = 'DELETE_PROJECTS_SUCCESS';
export const DELETE_PROJECTS_ERROR = 'DELETE_PROJECTS_ERROR';

export const SUBMIT_PROJECT_REQUEST = 'SUBMIT_PROJECT_REQUEST';
export const SUBMIT_PROJECT_SUCCESS = 'SUBMIT_PROJECT_SUCCESS';
export const SUBMIT_PROJECT_ERROR = 'SUBMIT_PROJECT_ERROR';
export const SUBMIT_PROJECT_RESET = 'SUBMIT_PROJECT_RESET';
