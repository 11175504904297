import dayjs from 'dayjs';

const timeRoundBy = (value) => {
  const diff = value - (dayjs().minute() % value);
  return dayjs().add(diff, 'minute').second(0).millisecond(0).toISOString();
}

export default {
  status: 'open',
  typeOf: 'email',
  objectiveOfActivity: '',
  startDateAndTime: timeRoundBy(5),
  endDateAndTime: timeRoundBy(15),
  comments: '',
  attendees: [],
  notificationToAttendees: '',
  reminder: '',
  amountOfTimeBefore: '30_minutes'
};
