import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

const OverlayComponent = ({ input,
  selectedDay,
  month,
  children,
  classNames,
  ...props
}) => {
  const { timeValue, dayValue } = children.props;

  const handlerOnChange = (event) => {
    const { target: { value } } = event;
    const timeValue = value.padEnd(5, "00:00");
    children.props.handlerOnDayChange({ timeValue, dayValue });
  }

  return (
    <div className="custom-bg-datetime-container" {...props}>
      <div className="time-input-block">
        <div className="row">
          <label className="col col-form-label col-form-label-sm form-label">Time</label>
          <div className="col">
          <MaskedInput
            mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
            type="text"
            className="form-control form-control-sm"
            value={timeValue} onChange={handlerOnChange}
            guide={false}
          />
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

OverlayComponent.propTypes = {
  input: PropTypes.object.isRequired,
  selectedDay: PropTypes.string,
  month: PropTypes.instanceOf(Date).isRequired,
  children: PropTypes.object.isRequired,
  classNames: PropTypes.object.isRequired,
  inputClassNames: PropTypes.string
};

export default OverlayComponent;
