import filterByDateRange from './filterByDateRange';
import filterByRole, { optionsByRole } from './filterByRole';
import filterByView from './filterByView';
import filterByList from './filterByList';

const endpoint = "payments";

const defaultView = [
  { data: "id", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true },
  { data: "idValue", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link show-link", path: 'paymentPath', pathKey: 'id' },
  { data: "typeOf", searchable: true, orderable: false, search: { value: "", regex: "" }},
  { data: "transaction", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link transaction-link", path: 'transactionPath', pathKey: 'transactionId' },
  { data: "amountDue", searchable: true, orderable: false, search: { value: "", regex: "" }},
  { data: "amountPaid", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "paymentDueDate", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "status", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "transactionId", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true }
];

const inOutView = [
  { data: "id", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true },
  { data: "idValue", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link show-link", path: 'paymentPath', pathKey: 'id' },
  { data: "transaction", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link transaction-link", path: 'transactionPath', pathKey: 'transactionId' },
  { data: "payer", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link payer-link", path: 'contactPath', pathKey: 'payerId' },
  { data: "amountDue", searchable: true, orderable: false, search: { value: "", regex: "" }},
  { data: "amountPaid", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "paymentDueDate", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "status", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "transactionId", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true },
  { data: "payerId", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true }
];

const internalsView = [
  { data: "id", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true },
  { data: "idValue", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link show-link", path: 'paymentPath', pathKey: 'id' },
  { data: "transaction", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link transaction-link", path: 'transactionPath', pathKey: 'transactionId' },
  { data: "relatedIncomingPayment", searchable: true, orderable: false, search: { value: "", regex: "" }},
  { data: "amountDue", searchable: true, orderable: false, search: { value: "", regex: "" }},
  { data: "amountPaid", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "paymentDueDate", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "status", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "transactionId", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true },
  { data: "payerId", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true }
];

const fieldsByView = (view) => {
  const fields = {
    "recentlyViewed": defaultView,
    "payments": defaultView,
    "incomingPayments": inOutView,
    "outgoingPayments": inOutView,
    "internalPayments": internalsView
  };
  return fields[view];
};

export const optionsForFilterByView = {
  "payments": {},
  "incomingPayments": {},
  "outgoingPayments": {},
  "internalPayments": {},
};

export const defaultFilter = ({ role, ids }) => {
  const filter = {};
  const byRole = optionsByRole({ role, endpoint });
  if (!!byRole.length) filter.role = { value: byRole[0], type: "role" };
  if (!!ids.length) filter.view = { name: "id", type: "eq", value: ids };
  return filter;
};

export const columnFilters = [
  { filter: filterByList, dataField: "status", label: "Status", source: 'PaymentCollections.items.status' },
  { filter: filterByDateRange, dataField: "createdAt" },
  { filter: filterByDateRange, dataField: "datePaidDate" }
];

export const outsideFilters = [
  { component: filterByView, endpoint, options: optionsForFilterByView },
  { component: filterByRole }
];

export default ({ view = "payments", items = [] }) => {
  const columns = fieldsByView(view);
  const query = {
    columns,
    order: [{ column: 0, dir: "asc" }],
    start: 0,
    length: 10,
    endpoint
  };
  return { query, endpoint, columns };
}
