import { companiesPath } from 'src/helpers/routes';
import { lazy } from 'react';
import fetchDataTable from 'src/actions/DataTable';
import { API_CALL } from 'src/middleware/API';
import dataTableParams, { defaultFilter } from 'src/datatables/companies';
import { fetchCompanyEnums } from 'src/actions/enums/company';

const Companies = lazy(() => import('src/components/views/companies'));

export default {
  path: companiesPath(),
  exact: true,
  strict: true,
  component: Companies,
  perform: 'companies:index',
  title: 'List of Companies',
  protected: true,
  prepareData: ({ store }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    const { role } = state.Auth.user;
    const { items = [] } = state.RecentlyViewed;
    const endpoint = 'companies';
    const ids = (items[endpoint] || []).map(i => i.id) || [];
    const { query } = dataTableParams({ role, endpoint, ids });
    query.filter = Object.values(defaultFilter({ role, ids }));
    store.dispatch(API_CALL, fetchDataTable({ accessToken, query, endpoint }));
    store.dispatch(API_CALL, fetchCompanyEnums());
  }
};
