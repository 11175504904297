import dayjs from 'dayjs';
import { isArray, map, isNil, omit } from 'lodash';
import { State } from 'src/store';
import { BaseJsonApiResourceObject, BaseRecord, BaseRecordCollection, BaseJsonApiResponse, JsonApiErrorResponseType, BaseJsonApiCollectionResponse } from 'src/-types/models/json-api';
import { ModelType } from 'src/-types/model';
import { normalizeEntityId, normalizeEntityType } from 'src/-utils/model';

const getTimestamp = () => dayjs().valueOf();
const defaultEntity = (type: ModelType, id: string): BaseRecord => ({ type, id, attributes: {}, relationships: {}, __meta: { isFetching: 0, isSaving: false } });
const defaultEntityCollection = (): BaseRecordCollection => ({ data: {}, __meta: { isFetching: 0 } });

export const getRecordCollection = (state: Readonly<State>, entityType: ModelType): BaseRecordCollection | null => state.entities?.[entityType] ?? null;
export const getRecord = (state: Readonly<State>, entityType: ModelType, id: string): BaseRecord | null => getRecordCollection(state, entityType)?.data?.[id] ?? null;

const generateStatePatchFromRecord = (state: Readonly<State>, entityType: ModelType, id: string, record: BaseRecord): Partial<State> => {
  const entityCollection = getRecordCollection(state, entityType) ?? defaultEntityCollection();
  const result: Partial<State> = {
    entities: {
      ...state?.entities,
      [entityType]: {
        ...entityCollection,
        data: {
          ...entityCollection.data,
          [id]: {
            ...record
          }
        }
      }
    }
  };

  return result;
};

const normalizeResourceObject = <T extends BaseJsonApiResourceObject>(resourceObject: T): T => ({ ...resourceObject, id: normalizeEntityId(resourceObject.id), type: normalizeEntityType(resourceObject.type) });

const generateStatePatchFromResourceObjects = (state: Readonly<State>, primary: BaseRecord[], included: BaseJsonApiResourceObject[] = []): Partial<State> => {
  const getNewRecordState = (currentState: Partial<State>, resourceObject: BaseJsonApiResourceObject) => {
    const normalizedResourceObject = normalizeResourceObject(resourceObject);
    const { id, type } = normalizedResourceObject;
    const record = currentState.entities?.[type]?.data?.[id] ?? defaultEntity(type, id);
    const newRecord: BaseRecord = {
      ...resourceObject,
      meta: { ...record.meta, ...resourceObject.meta },
      __meta: { ...record.__meta, lastLoadedAt: getTimestamp() }
    };

    return newRecord;
  };
  const generateNewStatePatch = (currentState: Partial<State>, record: BaseRecord) => {
    const { id, type } = record;
    const entities = currentState.entities!;
    const entityCollection = entities?.[type] ?? defaultEntityCollection();
    const stateRecords = entityCollection?.data;
    const newStatePatch: Partial<State> = {
      entities: {
        ...entities,
        [type]: {
          ...entityCollection,
          data: { ...stateRecords, [id]: { ...record } }
        }
      }
    };

    return newStatePatch;
  };

  let statePatch: Partial<State> = state;

  for (const record of primary) {
    statePatch = generateNewStatePatch(statePatch, record);
  }

  for (const resourceObject of included) {
    const newRecordState = getNewRecordState(statePatch, resourceObject);

    statePatch = generateNewStatePatch(statePatch, newRecordState);
  }

  return statePatch;
}

function generateStatePatchFromRecordCollection(state: Readonly<State>, entityType: ModelType, patch: BaseRecordCollection): Partial<State> {
  const stateEntityCollection = getRecordCollection(state, entityType);

  return {
    entities: {
      ...state.entities,
      [entityType]: {
        ...stateEntityCollection,
        ...patch
      }
    }
  };
}

function onSingleEntitySuccess(state: Readonly<State>, entityType: ModelType, response: BaseJsonApiResponse) {
  if (isArray(response.data)) {
    throw new Error('Got an array of resource objects when a single resource object was expected.');
  }

  const resourceObject = normalizeResourceObject(response.data);
  const record = defaultEntity(entityType, resourceObject.id);
  const newRecord: BaseRecord = {
    ...resourceObject,
    meta: { ...resourceObject.meta },
    __meta: { ...record.__meta, lastLoadedAt: getTimestamp() }
  };
  const statePatch = generateStatePatchFromResourceObjects(state, [newRecord], response.included);

  return statePatch;
}

export function onEntityStateInit(state: Readonly<State>, entityType: ModelType): Partial<State> {
  return { entities: { ...state.entities, [entityType]: defaultEntityCollection() } };
}

export function onFetch(state: Readonly<State>, entityType: ModelType, id: string): Partial<State> {
  const record = state.entities[entityType].data[id] ?? defaultEntity(entityType, id);
  const statePatch = generateStatePatchFromRecord(state, entityType, id, {
    ...record,
    __meta: { ...record.__meta, isFetching: record.__meta.isFetching + 1 }
  });

  return statePatch;
}

export function onFetchSuccess(state: Readonly<State>, entityType: ModelType, response: BaseJsonApiResponse): Partial<State> {
  if (isArray(response.data)) {
    throw new Error('Got an array of resource objects when a single resource object was expected.');
  }

  const resourceObject = normalizeResourceObject(response.data);
  const record = state.entities[entityType].data[resourceObject.id];

  if (!record) {
    throw new Error(`Entity state for "${entityType}:${resourceObject.id}" could not be found.`);
  }

  const newRecord: BaseRecord = {
    ...resourceObject,
    meta: { ...record.meta, ...resourceObject.meta },
    __meta: { ...record.__meta, isFetching: record.__meta.isFetching - 1, lastLoadedAt: getTimestamp() }
  };
  const statePatch = generateStatePatchFromResourceObjects(state, [newRecord], response.included);

  return statePatch;
}

export function onFetchError(state: Readonly<State>, entityType: ModelType, id: string, error: any): Partial<State> {
  const record = getRecord(state, entityType, id);

  if (!record) {
    throw new Error(`Entity state for "${entityType}:${id}" could not be found.`);
  }

  const statePatch: Partial<State> = generateStatePatchFromRecord(state, entityType, id, {
    ...record,
    __meta: { ...record.__meta, isFetching: record.__meta.isFetching - 1 }
  });

  return statePatch;
}

export function onCreateSuccess(state: Readonly<State>, entityType: ModelType, response: BaseJsonApiResponse) {
  return onSingleEntitySuccess(state, entityType, response);
}

export function onUpdate(state: Readonly<State>, entityType: ModelType, id: string): Partial<State> {
  const record = state.entities[entityType].data[id];
  const statePatch = generateStatePatchFromRecord(state, entityType, id, {
    ...record,
    __meta: { ...record.__meta, isSaving: true }
  });

  return statePatch;
}

export function onUpdateSuccess(state: Readonly<State>, entityType: ModelType, response: BaseJsonApiResponse): Partial<State> {
  return onSingleEntitySuccess(state, entityType, response);
}

export function onBatchUpdateSuccess(state: Readonly<State>, entityType: ModelType, response: BaseJsonApiCollectionResponse): Partial<State> {
  if (!isArray(response.data)) {
    throw new Error('Got a single resource object when an array of resource objects were expected.');
  }

  const recordCollection = getRecordCollection(state, entityType);

  if (!recordCollection) {
    throw new Error(`Entity state collection for "${entityType}" could not be found.`);
  }

  const records = map(response.data, resourceObject => {
    const normalizedResourceObject = normalizeResourceObject(resourceObject);
    const { id, type } = normalizedResourceObject;
    const record = getRecord(state, type, id) ?? defaultEntity(type, id);

    return {
      ...normalizedResourceObject,
      meta: { ...record.meta, ...normalizedResourceObject.meta },
      __meta: { ...record.__meta, lastLoadedAt: getTimestamp() }
    };
  });
  const statePatch = generateStatePatchFromResourceObjects(state, records, response.included);
  const entities = { ...state.entities, ...statePatch.entities };
  const entityCollection = entities[entityType];
  const finalStatePatch: Partial<State> = {
    entities: {
      ...entities,
      [entityType]: {
        ...entityCollection,
        meta: { ...response.meta }
      }
    }
  };

  return finalStatePatch;
}

export function onUpdateError<T extends BaseRecord>(state: Readonly<State>, entityType: ModelType, id: string, response: JsonApiErrorResponseType<T>): Partial<State> {
  const record = getRecord(state, entityType, id);

  if (!record) {
    throw new Error(`Entity state for "${entityType}:${id}" could not be found.`);
  }

  const statePatch: Partial<State> = generateStatePatchFromRecord(state, entityType, id, {
    ...record,
    ...response,
    __meta: { ...record.__meta, isSaving: false }
  });

  return statePatch;
}

export function onFetchAll(state: Readonly<State>, entityType: ModelType): Partial<State> {
  const recordCollection = getRecordCollection(state, entityType) ?? defaultEntityCollection();
  const statePatch = generateStatePatchFromRecordCollection(state, entityType, {
    ...recordCollection,
    __meta: { ...recordCollection.__meta, isFetching: recordCollection.__meta.isFetching + 1 }
  });

  return statePatch;
}

export function onFetchAllSuccess(state: Readonly<State>, entityType: ModelType, response: BaseJsonApiCollectionResponse): Partial<State> {
  if (!isArray(response.data)) {
    throw new Error('Got a single resource object when an array of resource objects were expected.');
  }

  const recordCollection = getRecordCollection(state, entityType);

  if (!recordCollection) {
    throw new Error(`Entity state collection for "${entityType}" could not be found.`);
  }

  const records = map(response.data, resourceObject => {
    const normalizedResourceObject = normalizeResourceObject(resourceObject);
    const { id, type } = normalizedResourceObject;
    const record = getRecord(state, type, id) ?? defaultEntity(type, id);

    return {
      ...normalizedResourceObject,
      meta: { ...record.meta, ...normalizedResourceObject.meta },
      __meta: { ...record.__meta, lastLoadedAt: getTimestamp() }
    };
  });

  const statePatch = generateStatePatchFromResourceObjects(state, records, response.included);
  const entities = { ...state.entities, ...statePatch.entities };
  const entityCollection = entities[entityType];
  const finalStatePatch: Partial<State> = {
    entities: {
      ...entities,
      [entityType]: {
        ...entityCollection,
        meta: { ...response.meta },
        __meta: { ...entityCollection.__meta, isFetching: entityCollection.__meta.isFetching - 1 }
      }
    }
  };

  return finalStatePatch;
}

export function onFetchAllError(state: Readonly<State>, entityType: ModelType, error: any): Partial<State> {
  const recordCollection = getRecordCollection(state, entityType);

  if (!recordCollection) {
    throw new Error(`Entity state collection for "${entityType}" could not be found.`);
  }

  const patch = {
    data: { ...recordCollection.data },
    __error: { ...error },
    __meta: { ...recordCollection.__meta, isFetching: recordCollection.__meta.isFetching - 1 }
  };
  const statePatch = generateStatePatchFromRecordCollection(state, entityType, patch);

  return statePatch;
}

export function onRenameFileSuccess(state: Readonly<State>, entityType: ModelType, response: BaseJsonApiResponse) {
  return onSingleEntitySuccess(state, entityType, response);
}

export function onDeleteFileSuccess(state: Readonly<State>, entityType: ModelType, response: BaseJsonApiResponse) {
  return onSingleEntitySuccess(state, entityType, response);
}

export function onUpdateRecord(state: Readonly<State>, entityType: ModelType, id: string, record: BaseRecord) {
  const recordCollection = getRecordCollection(state, entityType);

  if (!recordCollection) {
    return state;
  }

  const statePatch = generateStatePatchFromRecord(state, entityType, id, {
    ...record,
    __meta: {
      ...record.__meta,
      lastLoadedAt: getTimestamp()
    }
  });

  return statePatch;
}

export function onDeleteSuccess(state: Readonly<State>, entityType: ModelType, id: string) {
  const recordCollection = getRecordCollection(state, entityType);

  if (!recordCollection) {
    return state;
  }

  const statePatch = {
    entities: {
      ...state.entities,
      [entityType]: omit(recordCollection, id)
    }
  };

  return statePatch;
}

export function onBatchDeleteSuccess(state: Readonly<State>, entityType: ModelType, ids: string[]) {
  const recordCollection = getRecordCollection(state, entityType);

  if (!recordCollection) {
    return state;
  }

  const statePatch = {
    entities: {
      ...state.entities,
      [entityType]: omit(recordCollection, ids)
    }
  };

  return statePatch;
}

export const isFetching = (obj?: BaseRecord | BaseRecordCollection | null, defaultValue: boolean = false) => isNil(obj?.__meta?.isFetching) ? defaultValue : obj!.__meta!.isFetching > 0;
export const isSaving = (obj?: BaseRecord | null, defaultValue: boolean = false) => obj?.__meta?.isSaving ?? defaultValue;
export const getErrors = (obj?: BaseRecord | null, defaultValue: any = {}) => obj?.errors ?? defaultValue;
export const getLastFetchedAt = (record?: BaseRecord) => record?.__meta?.lastLoadedAt ?? null;
