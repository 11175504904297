import { FETCH_ALL_LEADS, FETCH_ALL_LEADS_SUCCESS, FETCH_ALL_LEADS_ERROR, UPDATE_LEAD_RECORD } from 'src/constants/actionTypes/entities/lead';
import { onEntityStateInit, onFetchAll, onFetchAllSuccess, onFetchAllError, onUpdateRecord } from 'src/helpers/entity-store';

export default store => {
  store.on('@init', (state) => onEntityStateInit(state, 'lead'));

  store.on(FETCH_ALL_LEADS, (state) => onFetchAll(state, 'lead'));
  store.on(FETCH_ALL_LEADS_SUCCESS, (state, { response }) => onFetchAllSuccess(state, 'lead', response));
  store.on(FETCH_ALL_LEADS_ERROR, (state, { error }) => onFetchAllError(state, 'lead', error));

  store.on(UPDATE_LEAD_RECORD, (state, { id, lead }) => onUpdateRecord(state, 'lead', id, lead));
};
