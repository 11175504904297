import React from 'react';
import PropTypes from 'prop-types';
import Link from 'src/helpers/Link';
import { userPath } from 'src/helpers/routes';

const LinkToUserWithDate = ({ userName, userId, date = null, isDate = false }) => {
  return (
    <span className="link-block">
      <Link to={userPath(userId)} className="mr-2" target="_blank">{userName}</Link>
      {isDate && <span className="badge badge-pill badge-info">{date}</span>}
    </span>
  )
}

LinkToUserWithDate.propTypes = {
  userName: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired
};

export default LinkToUserWithDate;
