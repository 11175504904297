import { StateLookup, BaseLookup } from 'src/-types/lookups';

export interface CountriesLookupElement {
  label: string;
  value: string;
};

export type CountriesLookupResponse = CountriesLookupElement[];

export type CountriesStateLookup = StateLookup<CountriesLookupResponse>;

export class CountriesLookup extends BaseLookup<CountriesStateLookup> {
  protected defaultData: CountriesLookupResponse = [];

  public constructor(state: CountriesStateLookup) {
    super(state);
  }
}
