import React from 'react';
// import 'chartjs-plugin-datalabels';
import ControlledCollapse from 'src/components/elements/shared/ControlledCollapse';
import { useTranslation } from 'react-i18next';
import { HorizontalBar } from 'react-chartjs-2';
import randomId from 'src/helpers/randomId';

const colors = ["#182852", "#4297CB", "#8DB8C9"];
const labels = ["Active", "Pending", "Off Market"];

const customLabels = [
  ['Active', 'Pending'],
  ['Open'],
  ['Active', 'Pending', 'Off Market'],
  ['Active', 'Countered'],
  ['Open']
]

export default ({ modules, items = [] }) => {
  const { t } = useTranslation();

  const test = colors.map((el, i) => {
    return ({
      label: labels[i],
      backgroundColor: el,
      data: items[i],
      stack: 1,
      barThickness: 'flex',
      maxBarThickness: 20,
    })
  });

  const legend = {
    leads: [
      { color: colors[0], label: "Active" },
      { color: colors[1], label: "Pending" },
      { color: '', label: "" }
    ],
    activities: [
      { color: colors[0], label: "Open" },
      { color: '', label: "" },
      { color: '', label: "" }
    ],
    listings: [
      { color: colors[0], label: "Active" },
      { color: colors[1], label: "Pending" },
      { color: colors[2], label: "Off Market" }
    ],
    offers: [
      { color: colors[0], label: "Active" },
      { color: colors[1], label: "Countered" },
      { color: '', label: "" }
    ],
    transactions: [
      { color: colors[0], label: "Open" },
      { color: '', label: "" },
      { color: '', label: "" }
    ]
  };

  const data = {
    labels: modules.map(el => (t(`dashboard.pipiline.${el}`))),
    datasets: test
  };

  const options = {
    scales: {
      yAxes: [{
        gridLines: { display: false },
        ticks: { beginAtZero: true, stepSize: 1, fontColor: "#182852" }
      }],
      xAxes: [{
        gridLines: { display: false },
        ticks: { beginAtZero: true, stepSize: 1, fontColor: "#182852" }
      }]
    },
    legend: {
      display: false
     },
     tooltips: {
        enabled: true,
        callbacks: {
          label: function(tooltipItem, data) {
            let label = customLabels[tooltipItem.index][tooltipItem.datasetIndex];
            if (label) label = ` ${label}: ${tooltipItem.value}`;
            return label;
          }
        }
     },
     plugins: {
       datalabels: {
         display: false,
         align: 'end',
         anchor: 'start',
         backgroundColor: null,
         borderColor: null,
         borderRadius: 4,
         borderWidth: 1,
         color: function(context) {
           // var value = context.dataset.data[context.dataIndex];
           return "#fff";
         },
         font: {
           size: 11,
           weight: 600
         },
         offset: 0,
         padding: {
           left: 2
         },
         formatter: function(value, ctx) {
          return ctx.datasetIndex === 0 ? "Sale" : "";
         }
       }
     }
  };

  return (
    <ControlledCollapse isOpen
      label={`${t("dashboard.subFolders.myPipeline")}`}
    >
      <div className="d-flex flex-nowrap" style={{overflow: 'auto'}}>
        <div style={{flex: '0 0 auto', width: 728}}>
          <HorizontalBar data={data} options={options}/>
          <h5 className="text-center text-uppercase mt-3 mb-5">NUMBER</h5>
          <div className="mt-3 px-7 pb-7 pt-0 border">
            <h5 className="text-center my-4 text-uppercase">STATUS</h5>
            {modules.map((module, i) =>
              <div className="d-flex justify-content-around my-2" key={randomId()}>
                <div className="col-3 text-uppercase font-weight-bold">{t(`dashboard.pipiline.${module}`)}</div>
                {legend[module].map(cell =>
                  <div className="col-3 d-flex" key={randomId()}>
                    <div className="mr-2" style={{ height: '20px', width: '20px', backgroundColor: cell.color }} />
                    {cell.label}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </ControlledCollapse>
  )
};
