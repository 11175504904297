import { teamNewPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchTeamCollections } from 'src/actions/Teams';
import { API_CALL } from 'src/middleware/API';

const TeamNewPage = lazy(() => import('src/components/views/admin/teams/New'));

export default {
  path: teamNewPath(),
  exact: true,
  strict: true,
  component: TeamNewPage,
  perform: 'admin/teams:new',
  protected: true,
  title: 'New Team',
  prepareData: ({ store }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    if (Object.keys(state.TeamCollections.items).length > 0) return;
    const accessToken = state.Auth.token;
    store.dispatch(API_CALL, fetchTeamCollections({ accessToken }));
  }
};
