export const FETCH_PAYMENTS_REQUEST = 'FETCH_PAYMENTS_REQUEST';
export const FETCH_PAYMENTS_SUCCESS = 'FETCH_PAYMENTS_SUCCESS';
export const FETCH_PAYMENTS_ERROR = 'FETCH_PAYMENTS_ERROR';

export const FETCH_PAYMENT_REQUEST = 'FETCH_PAYMENT_REQUEST';
export const FETCH_PAYMENT_SUCCESS = 'FETCH_PAYMENT_SUCCESS';
export const FETCH_PAYMENT_ERROR = 'FETCH_PAYMENT_ERROR';
export const FETCH_PAYMENT_RESET = 'FETCH_PAYMENT_RESET';

export const FETCH_PAYMENT_COLLECTION_REQUEST = 'FETCH_PAYMENT_COLLECTION_REQUEST';
export const FETCH_PAYMENT_COLLECTION_SUCCESS = 'FETCH_PAYMENT_COLLECTION_SUCCESS';
export const FETCH_PAYMENT_COLLECTION_ERROR = 'FETCH_PAYMENT_COLLECTION_ERROR';

export const DELETE_PAYMENT_REQUEST = 'DELETE_PAYMENT_REQUEST';
export const DELETE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const DELETE_PAYMENT_ERROR = 'DELETE_PAYMENT_ERROR';

export const DELETE_PAYMENTS_REQUEST = 'DELETE_PAYMENTS_REQUEST';
export const DELETE_PAYMENTS_SUCCESS = 'DELETE_PAYMENTS_SUCCESS';
export const DELETE_PAYMENTS_ERROR = 'DELETE_PAYMENTS_ERROR';

export const SUBMIT_PAYMENT_REQUEST = 'SUBMIT_PAYMENT_REQUEST';
export const SUBMIT_PAYMENT_SUCCESS = 'SUBMIT_PAYMENT_SUCCESS';
export const SUBMIT_PAYMENT_ERROR = 'SUBMIT_PAYMENT_ERROR';
export const SUBMIT_PAYMENT_RESET = 'SUBMIT_PAYMENT_RESET';
