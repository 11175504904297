import React, { useState, useMemo, useRef, useCallback, useEffect } from 'react';
import FormHooks from 'src/helpers/formHooks';
import { useTranslation } from 'react-i18next';
import useStoreon from 'src/helpers/storeon';
import { API_CALL } from 'src/middleware/API';
import { SUBMIT_LISTING_RESET } from 'src/constants/actionTypes/ListingsActionTypes';

import ControlledCollapse from 'src/components/elements/shared/ControlledCollapse';
import VerticalInput from 'src/components/elements/form/VerticalInput';
import VerticalSelectSearch from 'src/components/elements/form/VerticalSelectSearch';
import VerticalTextArea from 'src/components/elements/form/VerticalTextArea';
import VerticalCheckBox from 'src/components/elements/form/VerticalCheckBox';
import VerticalMoneyInput from 'src/components/elements/form/VerticalMoneyInput';
import VerticalDateInput from 'src/components/elements/form/VerticalDateInput';
import VerticalInputPlainText from 'src/components/elements/form/VerticalInputPlainText';
import LinkToProperty from 'src/components/elements/form/LinkToProperty';
import LinkToContactWithDate from 'src/components/elements/form/LinkToContactWithDate'
import clearValidation from 'src/components/elements/form/clearValidation';
import setServerSideErrors from 'src/components/elements/form/setServerSideErrors';
import AdditionallyJsonb from 'src/components/elements/shared/additionallyJsonb';
import { includes, isArray, map, cloneDeep } from 'lodash';
import { LocationPicker } from 'src/components/elements/LocationPicker/LocationPicker';
import { PortalPicker } from 'src/components/elements/PortalPicker/PortalPicker';

const RESIDENTIAL_WITHOUT_LAND = ['residential_apartment', 'residential_building', 'residential_house', 'residential_townhouse', 'residential_penthouse'];
const RESIDENTIAL_HOUSES = ['residential_house', 'residential_townhouse'];
const COMMERCIAL_OFFICE_RETAIL = ['commercial_office', 'commercial_retail'];
const COMMUNAL_FACILITIES = [
  'commercial_building', 'commercial_hotel', 'commercial_land', 'commercial_office',
  'commercial_retail', 'residential_apartment', 'residential_building', 'residential_house',
  'residential_land', 'residential_townhouse', 'residential_penthouse'
];
const ROOFINGS = ['residential_house', 'residential_townhouse', 'commercial_industrial', 'commercial_warehouse'];
const LANDS = ['commercial_land', 'residential_land'];

export const ListingForm = (props) => {
  const { defaultValues, children, submitHandler, id, history, successPath, clone = false } = props;
  const [validations, setValidations] = useState({ listingPropertyAttributes: {} });
  const formRef = useRef(null);
  const { ListingCollections, Auth, dispatch, SubmitListing } = useStoreon('ListingCollections', 'Auth', 'SubmitListing');
  const { t } = useTranslation();
  const { agency = false, currency } = Auth.user;
  const areListingPropertyFieldsReadonly = !agency;

  useEffect(() => {
    if (!SubmitListing) {
      return;
    }

    const { success, sendRequest } = SubmitListing;

    if (!sendRequest && success) {
      dispatch(SUBMIT_LISTING_RESET);
      history.push(successPath);
    }
  }, [SubmitListing, dispatch, history, successPath]);

  useEffect(() => {
    if (!SubmitListing) {
      return;
    }

    const { errors, error, sendRequest } = SubmitListing;

    if (!sendRequest && error) {
      setServerSideErrors({ formRef, errors, setValidations });
    }
  }, [SubmitListing]);

  const callBack = useCallback((inputs) => {
    clearValidation(formRef);

    if (formRef.current.checkValidity() !== false) {
      const listing = cloneDeep(inputs);
      const body = {
        listing: {
          ...listing,
          portals: isArray(listing.portals) ? map(listing.portals, ({ id }) => id) : []
        }
      };

      if (agency) {
        const { listingPropertyAttributes, ...listingAttrs } = body.listing;
        const { location } = listingPropertyAttributes;

        listingAttrs.propertyAttributes = {
          id: listingPropertyAttributes.propertyId,
          title: listingAttrs.title,
          listingPropertyAttributes: {
            ...listingPropertyAttributes,
            location: location?.id,
            view: isArray(listingPropertyAttributes.view) ? listingPropertyAttributes.view : []
          }
        };
        body.listing = { ...listingAttrs };
      }

      dispatch(API_CALL, submitHandler({ body, id }));
    }

    formRef.current.classList.add('was-validated');
  }, [agency, dispatch, id, submitHandler]);

  const { inputs, handleInputChange, handleSubmit, handleSelectSearch, handleDataChange, setInputs } = FormHooks(defaultValues, callBack);
  const label = useMemo(() => {
    if (id) {
      const { type = [] } = ListingCollections.items;
      const result = type.find(el => inputs.typeOf === el.value);

      if (result) {
        return result.label;
      }
    }
  }, [ListingCollections.items, id, inputs.typeOf]);

  const handlePropertyTypeSelectSearch = useCallback((name, value) => {
    handleSelectSearch(name, value);
    handleSelectSearch('listingPropertyAttributes.propertyTypeRaw', value);
  }, [handleSelectSearch]);

  const { propertyTypeRaw } = inputs.listingPropertyAttributes;
  const isResidentialWithOutLand = useMemo(() => includes(RESIDENTIAL_WITHOUT_LAND, propertyTypeRaw), [propertyTypeRaw]);
  const isEquippedKitchen = propertyTypeRaw === 'commercial_retail';
  const isResidentialHouses = useMemo(() => includes(RESIDENTIAL_HOUSES, propertyTypeRaw), [propertyTypeRaw]);
  const isCommercialOfficeRetail = useMemo(() => includes(COMMERCIAL_OFFICE_RETAIL, propertyTypeRaw), [propertyTypeRaw]);
  const isCommunalFacilities = useMemo(() => includes(COMMUNAL_FACILITIES, propertyTypeRaw), [propertyTypeRaw]);
  const isLand = useMemo(() => includes(LANDS, propertyTypeRaw), [propertyTypeRaw]);
  const isRoofing = useMemo(() => includes(ROOFINGS, propertyTypeRaw), [propertyTypeRaw]);
  const isFarm = propertyTypeRaw === 'commercial_farm';
  const rows = useMemo(() => ListingCollections.loaded ? ListingCollections.items.fields.rows : [], [ListingCollections.loaded, ListingCollections.items.fields.rows]);
  const onChanges = useMemo(() => ({
    'text': handleInputChange,
    'date': handleDataChange
  }), [handleDataChange, handleInputChange]);
  const onLocationChange = useCallback((location) => {
    setInputs(prev => ({ ...prev, listingPropertyAttributes: { ...prev.listingPropertyAttributes, location } }));
  }, [setInputs]);
  const onPortalsChange = useCallback((portals) => {
    setInputs(prev => ({ ...prev, portals }));
  }, [setInputs]);

  return (
    <form onSubmit={handleSubmit} ref={formRef} noValidate={true}>
      <div className="row mb-5">
        <div className="col-lg-6 col-md-12">
          {id > 0 && clone !== true
            ? <VerticalInputPlainText value={label} label={t('listings.typeOf')} icon={false} />
            : <VerticalSelectSearch
              collection={ListingCollections.items.type}
              value={inputs.typeOf}
              onChange={handleSelectSearch}
              label={t('listings.typeOf')}
              name="typeOf"
              includeBlank={false}
            />
          }
          {areListingPropertyFieldsReadonly &&
            <VerticalInputPlainText
              value={inputs.property && <LinkToProperty propertyName={inputs.property} propertyId={inputs.propertyId} />}
              label={t('listings.property')}
              icon={false}
            />
          }
          {areListingPropertyFieldsReadonly &&
            <VerticalInputPlainText
              value={inputs.listingPropertyAttributes.plotNumber}
              label={t('listings.plotNumber')}
              icon={false}
            />
          }
          <div className="mobile-margin" />
          {areListingPropertyFieldsReadonly
            ? <VerticalInputPlainText value={inputs.listingPropertyAttributes.location} label={t('listings.location')} icon={false} />
            : <LocationPicker
              label={t('listings.location')}
              name="property.listingProperty.location"
              onChange={onLocationChange}
              value={inputs.listingPropertyAttributes.location}
              inputName="listingPropertyAttributes.location"
              errors={validations.property?.listingProperty?.location}
            />
          }
          {areListingPropertyFieldsReadonly
            ? <VerticalInputPlainText value={inputs.listingPropertyAttributes.address} label={t('listings.address')} icon={false} />
            : <VerticalTextArea
              value={inputs.listingPropertyAttributes.address}
              inputName="listingPropertyAttributes.address"
              onChange={handleInputChange}
              label={t('listings.address')}
              name="property.listingProperty.address"
              errors={validations.property?.listingProperty?.address}
            />
          }
          {areListingPropertyFieldsReadonly
            ? <VerticalInputPlainText value={inputs.listingPropertyAttributes.ownership} label={t('listings.ownership')} icon={false} />
            : <VerticalSelectSearch
              collection={ListingCollections.items.ownership}
              value={inputs.listingPropertyAttributes.ownership}
              inputName="listingPropertyAttributes.ownership"
              onChange={handleSelectSearch}
              label={t('listings.ownership')}
              name="property.listingProperty.ownership"
              errors={validations.property?.listingProperty?.ownership}
              includeBlank={false}
            />
          }
          <div className="mobile-margin" />
          {areListingPropertyFieldsReadonly
            ? <VerticalInputPlainText
              value={
                inputs.listingPropertyAttributes.propertyOwner &&
                <LinkToContactWithDate
                  contactName={inputs.listingPropertyAttributes.propertyOwner}
                  contactId={inputs.listingPropertyAttributes.propertyOwnerId}
                />
              }
              label={t('listings.propertyOwner')}
              icon={false}
            />
            : <VerticalSelectSearch
              collection={ListingCollections.items.contact}
              value={inputs.listingPropertyAttributes.propertyOwnerId}
              inputName="listingPropertyAttributes.propertyOwnerId"
              onChange={handleSelectSearch}
              label={t('listings.propertyOwner')}
              name="property.listingProperty.propertyOwnerId"
              errors={validations.property?.listingProperty?.propertyOwnerId}
            />
          }
          <div className="mobile-margin" />
        </div>
        <div className="col-lg-6 col-md-12">
          {areListingPropertyFieldsReadonly
            ? <VerticalInputPlainText
              value={inputs.listingPropertyAttributes.propertyTypeHumanize}
              label={t('listings.propertyType')}
              icon={false}
            />
            : <VerticalSelectSearch
              collection={ListingCollections.items.propertyType}
              value={inputs.listingPropertyAttributes.propertyType}
              inputName="listingPropertyAttributes.propertyType"
              onChange={handlePropertyTypeSelectSearch}
              label={t('listings.propertyType')}
              name="property.listingProperty.propertyType"
              errors={validations.property?.listingProperty?.propertyType}
              includeBlank={false}
            />
          }
          {areListingPropertyFieldsReadonly
            ? <VerticalInputPlainText value={inputs.listingPropertyAttributes.floor} label={t('listings.floor')} icon={false} />
            : <VerticalInput
              value={inputs.listingPropertyAttributes.floor}
              inputName="listingPropertyAttributes.floor"
              onChange={handleInputChange}
              label={t('listings.floor')}
              name="property.listingProperty.floor"
              errors={validations.property?.listingProperty?.floor}
            />
          }
          {areListingPropertyFieldsReadonly
            ? <VerticalInputPlainText value={inputs.listingPropertyAttributes.numberOfFloors} label={t('listings.numberOfFloors')} icon={false} />
            : <VerticalInput
              value={inputs.listingPropertyAttributes.numberOfFloors}
              inputName="listingPropertyAttributes.numberOfFloors"
              onChange={handleInputChange}
              label={t('listings.numberOfFloors')}
              name="property.listingProperty.numberOfFloors"
              errors={validations.property?.listingProperty?.numberOfFloors}
            />
          }
          <div className="mobile-margin" />
          {areListingPropertyFieldsReadonly
            ? <VerticalInputPlainText value={inputs.listingPropertyAttributes.constructionStatus} label={t('listings.constructionStatus')} icon={false} />
            : <VerticalSelectSearch
              collection={ListingCollections.items.constructionStatus}
              value={inputs.listingPropertyAttributes.constructionStatus}
              inputName="listingPropertyAttributes.constructionStatus"
              onChange={handleSelectSearch}
              label={t('listings.constructionStatus')}
              name="property.listingProperty.constructionStatus"
              errors={validations.property?.listingProperty?.constructionStatus}
              includeBlank={false}
            />
          }
          {areListingPropertyFieldsReadonly
            ? <VerticalInputPlainText value={inputs.listingPropertyAttributes.completionDate} label={t('listings.completionDate')} icon={false} />
            : <VerticalDateInput
              value={inputs.listingPropertyAttributes.completionDate}
              inputName="listingPropertyAttributes.completionDate"
              onChange={handleDataChange}
              label={t('listings.completionDate')}
              name="property.listingProperty.completionDate"
              errors={validations.property?.listingProperty?.completionDate}
              defaultFromMonth={1900}
            />
          }
          {areListingPropertyFieldsReadonly &&
            <VerticalInputPlainText
              value={inputs.listingPropertyAttributes.handoverDate}
              label={t('listings.handoverDate')}
              icon={false}
            />
          }
          {areListingPropertyFieldsReadonly
            ? <VerticalInputPlainText
              value={inputs.listingPropertyAttributes.communityFees}
              label={ListingCollections.items.communityFees}
              icon={false}
            />
            : <VerticalMoneyInput
              collection={ListingCollections.items.currency}
              value={inputs.listingPropertyAttributes.communityFeesCents}
              inputName="listingPropertyAttributes.communityFeesCents"
              valueSelect={inputs.listingPropertyAttributes.communityFeesCurrency}
              onChange={handleInputChange}
              onChangeSelect={handleSelectSearch}
              label={ListingCollections.items.communityFees}
              name="property.listingProperty.communityFeesCents"
              nameSelect="listingPropertyAttributes.communityFeesCurrency"
              errors={validations.property?.listingProperty?.communityFees}
              currency={currency}
            />
          }
        </div>
      </div>
      <ControlledCollapse isOpen label={t("listings.subFolders.unitDetails")}>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            {areListingPropertyFieldsReadonly
              ? <VerticalInputPlainText
                value={inputs.listingPropertyAttributes.unitType}
                label={ListingCollections.items.unitType}
                icon={false}
              />
              : <VerticalInput
                value={inputs.listingPropertyAttributes.unitType}
                inputName="listingPropertyAttributes.unitType"
                onChange={handleInputChange}
                label={ListingCollections.items.unitType}
                name="property.listingProperty.unitType"
                errors={validations.property?.listingProperty?.unitType}
              />
            }
            {areListingPropertyFieldsReadonly
              ? <VerticalInputPlainText value={inputs.listingPropertyAttributes.bedrooms} label={t('listings.bedrooms')} icon={false} />
              : <VerticalInput
                value={inputs.listingPropertyAttributes.bedrooms}
                inputName="listingPropertyAttributes.bedrooms"
                onChange={handleInputChange}
                label={t('listings.bedrooms')}
                name="property.listingProperty.bedrooms"
                errors={validations.property?.listingProperty?.bedrooms}
              />
            }
            {areListingPropertyFieldsReadonly
              ? <VerticalInputPlainText value={inputs.listingPropertyAttributes.bathrooms} label={t('listings.bathrooms')} icon={false} />
              : <VerticalInput
                value={inputs.listingPropertyAttributes.bathrooms}
                inputName="listingPropertyAttributes.bathrooms"
                onChange={handleInputChange}
                label={t('listings.bathrooms')}
                name="property.listingProperty.bathrooms"
                errors={validations.property?.listingProperty?.bathrooms}
              />
            }
            {areListingPropertyFieldsReadonly
              ? <VerticalInputPlainText value={inputs.listingPropertyAttributes.otherRooms} label={t('listings.otherRooms')} icon={false} />
              : <VerticalSelectSearch
                collection={ListingCollections.items.otherRooms}
                value={inputs.listingPropertyAttributes.otherRooms}
                inputName="listingPropertyAttributes.otherRooms"
                onChange={handleDataChange}
                label={t('listings.otherRooms')}
                name="property.listingProperty.otherRooms"
                multiple
              />
            }
            <div className="mobile-margin" />
            {areListingPropertyFieldsReadonly
              ? <VerticalInputPlainText value={inputs.listingPropertyAttributes.parkingType} label={t('listings.parkingType')} icon={false} />
              : <VerticalSelectSearch
                collection={ListingCollections.items.parkingType}
                value={inputs.listingPropertyAttributes.parkingType}
                inputName="listingPropertyAttributes.parkingType"
                onChange={handleSelectSearch}
                label={t('listings.parkingType')}
                name="property.listingProperty.parkingType"
                errors={validations.property?.listingProperty?.parkingType}
                includeBlank={false}
              />
            }
            {areListingPropertyFieldsReadonly
              ? <VerticalInputPlainText value={inputs.listingPropertyAttributes.parkingSpace} label={t('listings.parkingSpace')} icon={false} />
              : <VerticalInput
                value={inputs.listingPropertyAttributes.parkingSpace}
                inputName="listingPropertyAttributes.parkingSpace"
                onChange={handleInputChange}
                label={t('listings.parkingSpace')}
                name="property.listingProperty.parkingSpace"
                errors={validations.property?.listingProperty?.parkingSpace}
              />
            }
          </div>
          <div className="col-lg-6 col-md-12">
            {areListingPropertyFieldsReadonly
              ? <VerticalInputPlainText value={inputs.listingPropertyAttributes.netInternalArea} label={t('listings.netInternalArea')} icon={false} />
              : <VerticalInput
                value={inputs.listingPropertyAttributes.netInternalArea}
                inputName="listingPropertyAttributes.netInternalArea"
                onChange={handleInputChange}
                label={t('listings.netInternalArea')}
                name="property.listingProperty.netInternalArea"
                errors={validations.property?.listingProperty?.netInternalArea}
              />
            }
            {areListingPropertyFieldsReadonly
              ? <VerticalInputPlainText value={inputs.listingPropertyAttributes.balconyArea} label={t('listings.balconyArea')} icon={false} />
              : <VerticalInput
                value={inputs.listingPropertyAttributes.balconyArea}
                inputName="listingPropertyAttributes.balconyArea"
                onChange={handleInputChange}
                label={t('listings.balconyArea')}
                name="property.listingProperty.balconyArea"
                errors={validations.property?.listingProperty?.balconyArea}
              />
            }
            {areListingPropertyFieldsReadonly
              ? <VerticalInputPlainText value={inputs.listingPropertyAttributes.grossInternalArea} label={t('listings.grossInternalArea')} icon={false} />
              : <VerticalInput
                value={inputs.listingPropertyAttributes.grossInternalArea}
                inputName="listingPropertyAttributes.grossInternalArea"
                onChange={handleInputChange}
                label={t('listings.grossInternalArea')}
                name="property.listingProperty.grossInternalArea"
                errors={validations.property?.listingProperty?.grossInternalArea}
              />
            }
            {areListingPropertyFieldsReadonly
              ? <VerticalInputPlainText value={inputs.listingPropertyAttributes.plotArea} label={t('listings.plotArea')} icon={false} />
              : <VerticalInput
                value={inputs.listingPropertyAttributes.plotArea}
                inputName="listingPropertyAttributes.plotArea"
                onChange={handleInputChange}
                label={t('listings.plotArea')}
                name="property.listingProperty.plotArea"
                errors={validations.property?.listingProperty?.plotArea}
              />
            }
            {areListingPropertyFieldsReadonly
              ? <VerticalInputPlainText value={inputs.listingPropertyAttributes.view} label={t('listings.view')} icon={false} />
              : <VerticalSelectSearch
                collection={ListingCollections.items.view}
                value={inputs.listingPropertyAttributes.view}
                inputName="listingPropertyAttributes.view"
                onChange={handleDataChange}
                label={t('listings.view')}
                name="property.listingProperty.view"
                errors={validations.property?.listingProperty?.view}
                multiple
              />
            }
            <VerticalSelectSearch
              collection={ListingCollections.items.propertyCondition}
              value={inputs.propertyCondition}
              onChange={handleSelectSearch}
              label={t('listings.propertyCondition')}
              name="propertyCondition"
              errors={validations.propertyCondition}
            />
          </div>
        </div>
      </ControlledCollapse>
      <ControlledCollapse isOpen label={t("listings.subFolders.features")}>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <VerticalSelectSearch
              collection={ListingCollections.items.furnished}
              value={inputs.furnished}
              onChange={handleSelectSearch}
              label={t('listings.furnished')}
              name="furnished"
            />
            <VerticalCheckBox
              value={inputs.airConditioning}
              onChange={handleInputChange}
              label={t('listings.airConditioning')}
              name="airConditioning"
              errors={validations.airConditioning}
            />
            <VerticalSelectSearch
              collection={ListingCollections.items.flooring}
              value={inputs.flooring}
              onChange={handleSelectSearch}
              label={t('listings.flooring')}
              name="flooring"
            />
            {areListingPropertyFieldsReadonly
              ? <VerticalInputPlainText value={inputs.listingPropertyAttributes.pool} label={t('listings.pool')} icon={false} />
              : <VerticalSelectSearch
                collection={ListingCollections.items.pool}
                value={inputs.listingPropertyAttributes.pool}
                inputName="listingPropertyAttributes.pool"
                onChange={handleSelectSearch}
                label={t('listings.pool')}
                name="property.listingProperty.pool"
                errors={validations.property?.listingProperty?.pool}
              />
            }
            <VerticalInput
              value={inputs.poolDimensions}
              onChange={handleInputChange}
              label={t('listings.poolDimensions')}
              name="poolDimensions"
              errors={validations.poolDimensions}
            />
            <VerticalSelectSearch
              collection={ListingCollections.items.security}
              value={inputs.security}
              onChange={handleDataChange}
              label={t('listings.security')}
              name="security"
              multiple
            />
            {isResidentialWithOutLand && <VerticalCheckBox
              value={inputs.petsAllowed}
              onChange={handleInputChange}
              label={t('listings.petsAllowed')}
              name="petsAllowed"
              errors={validations.petsAllowed}
            />}
          </div>
          <div className="col-lg-6 col-md-12">
            {isRoofing && <VerticalSelectSearch
              collection={ListingCollections.items.roofing}
              value={inputs.roofing}
              onChange={handleSelectSearch}
              label={t('listings.roofing')}
              name="roofing"
              includeBlank={false}
            />}
            {isResidentialHouses && <VerticalCheckBox
              value={inputs.solarPanels}
              onChange={handleInputChange}
              label={t('listings.solarPanels')}
              name="solarPanels"
              errors={validations.solarPanels}
            />}
            {isResidentialHouses && <VerticalCheckBox
              value={inputs.solarWaterHeating}
              onChange={handleInputChange}
              label={t('listings.solarWaterHeating')}
              name="solarWaterHeating"
              errors={validations.solarWaterHeating}
            />}
            {isResidentialHouses && <VerticalCheckBox
              value={inputs.waterTank}
              onChange={handleInputChange}
              label={t('listings.waterTank')}
              name="waterTank"
              errors={validations.waterTank}
            />}
            {isLand && <VerticalCheckBox
              value={inputs.planningPermission}
              onChange={handleInputChange}
              label={ListingCollections.items.planningPermission}
              name="planningPermission"
              errors={validations.planningPermission}
            />}
            {isFarm && <VerticalCheckBox
              value={inputs.arableLand}
              onChange={handleInputChange}
              label={t('listings.arableLand')}
              name="arableLand"
              errors={validations.arableLand}
            />}
            {isCommercialOfficeRetail && <VerticalCheckBox
              value={inputs.fitted}
              onChange={handleInputChange}
              label={t('listings.fitted')}
              name="fitted"
              errors={validations.fitted}
            />}
            {isEquippedKitchen && <VerticalCheckBox
              value={inputs.equippedKitchen}
              onChange={handleInputChange}
              label={t('listings.equippedKitchen')}
              name="equippedKitchen"
              errors={validations.equippedKitchen}
            />}
          </div>
        </div>
      </ControlledCollapse>
      <ControlledCollapse isOpen label={t("listings.subFolders.builtInAppliances")}>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            {isResidentialWithOutLand && <VerticalCheckBox
              value={inputs.oven}
              onChange={handleInputChange}
              label={t('listings.oven')}
              name="oven"
              errors={validations.oven}
            />}
            {isResidentialWithOutLand && <VerticalCheckBox
              value={inputs.ovenWithHobs}
              onChange={handleInputChange}
              label={t('listings.ovenWithHobs')}
              name="ovenWithHobs"
              errors={validations.ovenWithHobs}
            />}
            {isResidentialWithOutLand && <VerticalCheckBox
              value={inputs.cookTop}
              onChange={handleInputChange}
              label={t('listings.cookTop')}
              name="cookTop"
              errors={validations.cookTop}
            />}
            {isResidentialWithOutLand && <VerticalCheckBox
              value={inputs.extractorHood}
              onChange={handleInputChange}
              label={t('listings.extractorHood')}
              name="extractorHood"
              errors={validations.extractorHood}
            />}
            {isResidentialWithOutLand && <VerticalCheckBox
              value={inputs.refridgerator}
              onChange={handleInputChange}
              label={t('listings.refridgerator')}
              name="refridgerator"
              errors={validations.refridgerator}
            />}
            {isResidentialWithOutLand && <VerticalCheckBox
              value={inputs.freezer}
              onChange={handleInputChange}
              label={t('listings.freezer')}
              name="freezer"
              errors={validations.freezer}
            />}
            {isResidentialWithOutLand && <VerticalCheckBox
              value={inputs.fridgeFreezer}
              onChange={handleInputChange}
              label={t('listings.fridgeFreezer')}
              name="fridgeFreezer"
              errors={validations.fridgeFreezer}
            />}
          </div>
          <div className="col-lg-6 col-md-12">
            {isResidentialWithOutLand && <VerticalCheckBox
              value={inputs.microwave}
              onChange={handleInputChange}
              label={t('listings.microwave')}
              name="microwave"
              errors={validations.microwave}
            />}
            {isResidentialWithOutLand && <VerticalCheckBox
              value={inputs.wineCooler}
              onChange={handleInputChange}
              label={t('listings.wineCooler')}
              name="wineCooler"
              errors={validations.wineCooler}
            />}
            {isResidentialWithOutLand && <VerticalCheckBox
              value={inputs.coffeeMachine}
              onChange={handleInputChange}
              label={t('listings.coffeeMachine')}
              name="coffeeMachine"
              errors={validations.coffeeMachine}
            />}
            {isResidentialWithOutLand && <VerticalCheckBox
              value={inputs.washingMachine}
              onChange={handleInputChange}
              label={t('listings.washingMachine')}
              name="washingMachine"
              errors={validations.washingMachine}
            />}
            {isResidentialWithOutLand && <VerticalCheckBox
              value={inputs.dryer}
              onChange={handleInputChange}
              label={t('listings.dryer')}
              name="dryer"
              errors={validations.dryer}
            />}
            {isResidentialWithOutLand && <VerticalCheckBox
              value={inputs.washerDryer}
              onChange={handleInputChange}
              label={t('listings.washerDryer')}
              name="washerDryer"
              errors={validations.washerDryer}
            />}
          </div>
        </div>
      </ControlledCollapse>
      <ControlledCollapse isOpen label={t("listings.subFolders.communalFacilities")}>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            {isCommunalFacilities && <VerticalCheckBox
              value={inputs.gym}
              onChange={handleInputChange}
              label={t('listings.gym')}
              name="gym"
              errors={validations.gym}
            />}
            {isCommunalFacilities && <VerticalCheckBox
              value={inputs.fitnessStudio}
              onChange={handleInputChange}
              label={t('listings.fitnessStudio')}
              name="fitnessStudio"
              errors={validations.fitnessStudio}
            />}
            {isCommunalFacilities && <VerticalCheckBox
              value={inputs.sauna}
              onChange={handleInputChange}
              label={t('listings.sauna')}
              name="sauna"
              errors={validations.sauna}
            />}
            {isCommunalFacilities && <VerticalCheckBox
              value={inputs.steamRoom}
              onChange={handleInputChange}
              label={t('listings.steamRoom')}
              name="steamRoom"
              errors={validations.steamRoom}
            />}
            {isCommunalFacilities && <VerticalCheckBox
              value={inputs.swimmingPool}
              onChange={handleInputChange}
              label={t('listings.swimmingPool')}
              name="swimmingPool"
              errors={validations.swimmingPool}
            />}
          </div>
          <div className="col-lg-6 col-md-12">
            {isCommunalFacilities && <VerticalCheckBox
              value={inputs.kidsPlayArea}
              onChange={handleInputChange}
              label={t('listings.kidsPlayArea')}
              name="kidsPlayArea"
              errors={validations.kidsPlayArea}
            />}
            {isCommunalFacilities && <VerticalCheckBox
              value={inputs.runningTrack}
              onChange={handleInputChange}
              label={t('listings.runningTrack')}
              name="runningTrack"
              errors={validations.runningTrack}
            />}
            {isCommunalFacilities && <VerticalCheckBox
              value={inputs.tennisCourt}
              onChange={handleInputChange}
              label={t('listings.tennisCourt')}
              name="tennisCourt"
              errors={validations.tennisCourt}
            />}
            {isCommunalFacilities && <VerticalCheckBox
              value={inputs.squashCourt}
              onChange={handleInputChange}
              label={t('listings.squashCourt')}
              name="squashCourt"
              errors={validations.squashCourt}
            />}
            {isCommunalFacilities && <VerticalCheckBox
              value={inputs.park}
              onChange={handleInputChange}
              label={t('listings.park')}
              name="park"
              errors={validations.park}
            />}
            {isCommunalFacilities && <VerticalCheckBox
              value={inputs.beachAccess}
              onChange={handleInputChange}
              label={t('listings.beachAccess')}
              name="beachAccess"
              errors={validations.beachAccess}
            />}
          </div>
        </div>
      </ControlledCollapse>
      <ControlledCollapse isOpen label={t("listings.subFolders.occupation")}>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <VerticalSelectSearch
              collection={ListingCollections.items.occupation}
              value={inputs.occupation}
              onChange={handleSelectSearch}
              label={t('listings.occupation')}
              name="occupation"
              errors={validations.occupation}
              includeBlank={false}
            />
            <VerticalSelectSearch
              collection={ListingCollections.items.accessibility}
              value={inputs.accessibility}
              onChange={handleSelectSearch}
              label={t('listings.accessibility')}
              name="accessibility"
              errors={validations.accessibility}
              includeBlank={false}
            />
            <div className="mobile-margin" />
          </div>
          <div className="col-lg-6 col-md-12">
            <VerticalDateInput
              value={inputs.rentedUntil}
              onChange={handleDataChange}
              label={t('listings.rentedUntil')}
              name="rentedUntil"
              errors={validations.rentedUntil}
            />
            <VerticalMoneyInput
              collection={ListingCollections.items.currency}
              value={inputs.rentedForCents}
              valueSelect={inputs.rentedForCurrency}
              onChange={handleInputChange}
              onChangeSelect={handleSelectSearch}
              label={t('listings.rentedFor')}
              name="rentedForCents"
              nameSelect="rentedForCurrency"
              errors={validations.rentedFor}
              currency={currency}
            />
            <VerticalSelectSearch
              collection={ListingCollections.items.contact}
              value={inputs.tenantId}
              onChange={handleSelectSearch}
              label={t('listings.tenant')}
              name="tenantId"
              errors={validations.tenantId}
              includeBlank={false}
            />
          </div>
        </div>
      </ControlledCollapse>
      <ControlledCollapse isOpen label={t("listings.subFolders.availability")}>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <VerticalSelectSearch
              collection={ListingCollections.items.status}
              value={inputs.status}
              onChange={handleSelectSearch}
              label={t('listings.status')}
              name="status"
              errors={validations.status}
            />
            <VerticalDateInput
              value={inputs.dateListed}
              onChange={handleDataChange}
              label={t('listings.dateListed')}
              name="dateListed"
              errors={validations.dateListed}
            />
            {inputs.typeOf === 'rent' &&
              <VerticalDateInput
                value={inputs.availableFrom}
                onChange={handleDataChange}
                label={t('listings.availableFrom')}
                name="availableFrom"
                errors={validations.availableFrom}
              />
            }
            <VerticalMoneyInput
              collection={ListingCollections.items.currency}
              value={inputs.listedPriceCents}
              valueSelect={inputs.listedPriceCurrency}
              onChange={handleInputChange}
              onChangeSelect={handleSelectSearch}
              label={t('listings.listedPrice')}
              name="listedPriceCents"
              nameSelect="listedPriceCurrency"
              errors={validations.listedPrice}
              currency={currency}
            />
            <div className="mobile-margin" />
            {1 === 2 && <VerticalMoneyInput
              collection={ListingCollections.items.currency}
              value={inputs.estimatedVarketValueCents}
              valueSelect={inputs.estimatedVarketValueCurrency}
              onChange={handleInputChange}
              onChangeSelect={handleSelectSearch}
              label={t('listings.estimatedVarketValue')}
              name="estimatedVarketValueCents"
              nameSelect="estimatedVarketValueCurrency"
              errors={validations.estimatedVarketValue}
            />}
            {1 === 2 && <VerticalInput
              value={inputs.differentToMarketValue}
              onChange={handleInputChange}
              label={t('listings.differentToMarketValue')}
              name="differentToMarketValue"
              errors={validations.differentToMarketValue}
            />}
          </div>
          <div className="col-lg-6 col-md-12">
            <VerticalSelectSearch
              collection={ListingCollections.items.agreementType}
              value={inputs.agreementType}
              onChange={handleSelectSearch}
              label={t('listings.agreementType')}
              name="agreementType"
              errors={validations.agreementType}
            />
            <VerticalDateInput
              value={inputs.agreementStartDate}
              onChange={handleDataChange}
              label={t('listings.agreementStartDate')}
              name="agreementStartDate"
              errors={validations.agreementStartDate}
            />
            <VerticalInput
              value={inputs.commissionAgreed}
              onChange={handleInputChange}
              label={t('listings.commissionAgreed')}
              name="commissionAgreed"
              errors={validations.commissionAgreed}
            />
            <VerticalSelectSearch
              collection={ListingCollections.items.contact}
              value={inputs.externalBrokerId}
              onChange={handleSelectSearch}
              label={t('listings.externalBroker')}
              name="externalBrokerId"
              errors={validations.externalBrokerId}
            />
          </div>
        </div>
      </ControlledCollapse>
      <ControlledCollapse isOpen label={t("listings.subFolders.marketing")}>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <VerticalInput
              value={inputs.title}
              onChange={handleInputChange}
              label={t('listings.title')}
              name="title"
              errors={validations.title}
            />
            <VerticalSelectSearch
              collection={ListingCollections.items.user}
              value={inputs.secondBrokerId}
              onChange={handleSelectSearch}
              label={t('listings.secondBroker')}
              name="secondBrokerId"
              errors={validations.secondBrokerId}
            />
            <div className="mobile-margin" />
          </div>
          <div className="col-lg-6 col-md-12">
            <VerticalCheckBox
              value={inputs.advertiseOnOurWebsite}
              onChange={handleInputChange}
              label={t('listings.advertiseOnOurWebsite')}
              name="advertiseOnOurWebsite"
              errors={validations.advertiseOnOurWebsite}
            />
            <PortalPicker
              label={t('listings.advertiseOnPortals')}
              name="listings.portals"
              onChange={onPortalsChange}
              value={inputs.portals}
              errors={validations.portals}
              isMulti={true}
            />
            <div className="mobile-margin" />
            <VerticalTextArea
              value={inputs.description}
              onChange={handleInputChange}
              label={t('listings.description')}
              name="description"
              errors={validations.description}
            />
          </div>
        </div>
      </ControlledCollapse>
      <AdditionallyJsonb
        rows={rows}
        inputs={inputs}
        onChanges={onChanges}
        validations={validations}
        titleKey="listings.subFolders.frenchText"
      />
      {inputs.ownerId && <ControlledCollapse isOpen label={t("listings.subFolders.systemInformation")}>
        <div className="row">
          <div className="col-lg-6 col-md-12">
          </div>
          <div className="col-lg-6 col-md-12">
            <VerticalSelectSearch
              collection={ListingCollections.items.user}
              value={inputs.ownerId}
              onChange={handleSelectSearch}
              label={t('listings.owner')}
              name="ownerId"
              isClearable={false}
            />
          </div>
        </div>
      </ControlledCollapse>}
      {children}
    </form>
  );
};

export default ListingForm;
