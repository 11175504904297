import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useStoreon from 'src/helpers/storeon';
import { createProperty } from 'src/actions/Properties';
import { propertiesPath } from 'src/helpers/routes';
import Form from './Form';
import IconPropertyCircle from 'src/constants/icons/IconPropertyCircle';
import { cloneDeep, keys } from 'lodash';
import { LoadingSpinner } from 'src/components/elements/LoadingSpinner/LoadingSpinner';

export const PropertyClone = (props) => {
  const { Property, SubmitProperty } = useStoreon('Property', 'SubmitProperty');
  const { t } = useTranslation();
  const { property = {} } = Property;
  const defaultValues = useMemo(() => {
    const result = cloneDeep(property);

    if (result.listingPropertyAttributes && result.listingPropertyAttributes.propertyTypeRaw) {
      result.listingPropertyAttributes.propertyType = result.listingPropertyAttributes.propertyTypeRaw;
    }

    if (result.listingPropertyAttributes) {
      const { id, ...other } = result.listingPropertyAttributes;

      result.listingPropertyAttributes = other;
    }

    return result;
  }, [property]);
  const hasProperty = useMemo(() => keys(property).length > 0, [property]);
  const successPath = useMemo(() => propertiesPath(), []);

  return (
    <div className="row mb-5">
      <div className="col">
        <div className="card">
          <div className="card-header">
            <div className="card-title form-inline">
              <div className="mr-2 mt-1">
                <IconPropertyCircle width={35} height={35} fill="#182852" />
              </div>{t('properties.newTitle')}
            </div>
          </div>
          <div className="card-body p-5">
            <LoadingSpinner isLoading={!hasProperty}>
              <Form
                {...props}
                defaultValues={defaultValues}
                successPath={successPath}
                submitHandler={createProperty}
                id
                clone
              >
                <div className="row pt-3">
                  <div className="col">
                    <div className="float-right">
                      <button disabled={SubmitProperty.sendRequest} className="btn btn-primary float-right">
                        {t('buttons.create')}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </LoadingSpinner>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyClone;
