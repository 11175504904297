import { FETCH_ALL_PROJECTS, FETCH_ALL_PROJECTS_SUCCESS, FETCH_ALL_PROJECTS_ERROR, UPDATE_PROJECT_RECORD } from 'src/constants/actionTypes/entities/project';
import { onEntityStateInit, onFetchAll, onFetchAllSuccess, onFetchAllError, onUpdateRecord } from 'src/helpers/entity-store';

export default store => {
  store.on('@init', (state) => onEntityStateInit(state, 'project'));

  store.on(FETCH_ALL_PROJECTS, (state) => onFetchAll(state, 'project'));
  store.on(FETCH_ALL_PROJECTS_SUCCESS, (state, { response }) => onFetchAllSuccess(state, 'project', response));
  store.on(FETCH_ALL_PROJECTS_ERROR, (state, { error }) => onFetchAllError(state, 'project', error));

  store.on(UPDATE_PROJECT_RECORD, (state, { id, project }) => onUpdateRecord(state, 'project', id, project));
};
