import filterByDateRange from './filterByDateRange';
import filterByRole, { optionsByRole } from './filterByRole';
import filterByView from './filterByView';
import filterByList from './filterByList';

const endpoint = "transactions";

const defaultView = [
  { data: "id", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true },
  { data: "idValue", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link show-link", path: 'transactionPath', pathKey: 'id' },
  { data: "typeOf", searchable: true, orderable: false, search: { value: "", regex: "" }},
  { data: "buyer", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link buyer-link", path: 'contactPath', pathKey: 'buyerId' },
  { data: "listing", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link listing-link", path: 'listingPath', pathKey: 'listingId' },
  { data: "seller", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link seller-link", path: 'contactPath', pathKey: 'sellerId' },
  { data: "finalPrice", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "netCompanyCommission", searchable: true, orderable: false, search: { value: "", regex: "" }},
  { data: "status", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "buyerId", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true },
  { data: "listingId", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true },
  { data: "sellerId", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true }
];

const developerSaleTransactionsView = [
  { data: "id", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true },
  { data: "idValue", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link show-link", path: 'transactionPath', pathKey: 'id' },
  { data: "typeOf", searchable: true, orderable: false, search: { value: "", regex: "" }},
  { data: "buyer", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link buyer-link", path: 'contactPath', pathKey: 'buyerId' },
  { data: "listing", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link listing-link", path: 'listingPath', pathKey: 'listingId' },
  { data: "seller", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link seller-link", path: 'contactPath', pathKey: 'sellerId' },
  { data: "finalPrice", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "totalDeveloperPayout", searchable: true, orderable: false, search: { value: "", regex: "" }},
  { data: "status", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "buyerId", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true },
  { data: "listingId", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true },
  { data: "sellerId", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true }
];

const transactionPipelineView = [
  { data: "id", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true },
  { data: "idValue", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link show-link", path: 'transactionPath', pathKey: 'id' },
  { data: "typeOf", searchable: true, orderable: false, search: { value: "", regex: "" }},
  { data: "listing", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link listing-link", path: 'listingPath', pathKey: 'listingId' },
  { data: "finalPrice", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "netCompanyCommission", searchable: true, orderable: false, search: { value: "", regex: "" }},
  { data: "startDate", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "expectedClosureDate", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "closureDate", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "status", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "listingId", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true }
];

const fieldsByView = (view) => {
  const fields = {
    "recentlyViewed": defaultView,
    "transactions": defaultView,
    "saleTransactions": defaultView,
    "rentalTransactions": defaultView,
    "developerSaleTransactions": developerSaleTransactionsView,
    "transactionPipeline": transactionPipelineView
  };
  return fields[view];
};

export const optionsForFilterByView = {
  "transactions": {},
  "saleTransactions": { name: "transactions.typeOf", value: "sale", type: "eq" },
  "rentalTransactions": { name: "transactions.typeOf", value: "rental", type: "eq"},
  "developerSaleTransactions": { name: "transactions.typeOf", value: "developer_sale", type: "eq"},
  "transactionPipeline": {}
};

export const defaultFilter = ({ role, ids }) => {
  const filter = {};
  const byRole = optionsByRole({ role, endpoint });
  if (!!byRole.length) filter.role = { value: byRole[0], type: "role" };
  if (!!ids.length) filter.view = { name: "id", type: "eq", value: ids };
  return filter;
};

export const columnFilters = [
  { filter: filterByList, dataField: "status", label: "Status", source: 'TransactionCollections.items.status' },
  { filter: filterByDateRange, dataField: "createdAt" }
];

export const outsideFilters = [
  { component: filterByView, endpoint, options: optionsForFilterByView },
  { component: filterByRole }
];

export default ({ view = "transactions", items = [] }) => {
  const columns = fieldsByView(view);
  const query = {
    columns,
    order: [{ column: 1, dir: "asc" }],
    start: 0,
    length: 10,
    endpoint
  };
  return { query, endpoint, columns };
}
