import { userPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchUserCollections } from 'src/actions/Users';
import { fetchUser, fetchAllUsers } from 'src/actions/entities/user';
import { API_CALL } from 'src/middleware/API';
import { fetchActivityCollections } from 'src/actions/Activities';

const UserShow = lazy(() => import('src/components/views/admin/users/Show'));

export default {
  path: userPath(),
  exact: true,
  strict: true,
  component: UserShow,
  perform: 'admin/users:show',
  title: 'User',
  protected: true,
  prepareData: ({ store, params }) => {
    const state = store.get();

    if (state.Auth.connected === false) {
      return;
    }

    const accessToken = state.Auth.token;

    if (Object.keys(state.ActivityCollections.items).length === 0) {
      store.dispatch(API_CALL, fetchActivityCollections({ accessToken }));
    }

    if (Object.keys(state.UserCollections.items).length === 0) {
      store.dispatch(API_CALL, fetchUserCollections({ accessToken, query: { country: true } }));
    }

    store.dispatch(API_CALL, fetchUser({
      accessToken,
      id: params.id,
      query: {
        include: 'userContact,userAttribute'
      }
    }));
    store.dispatch(API_CALL, fetchAllUsers({ accessToken }));
  }
};
