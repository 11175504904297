import { FETCH_ALL_PROPERTIES, FETCH_ALL_PROPERTIES_SUCCESS, FETCH_ALL_PROPERTIES_ERROR, UPDATE_PROPERTY_RECORD } from 'src/constants/actionTypes/entities/property';
import { onEntityStateInit, onFetchAll, onFetchAllSuccess, onFetchAllError, onUpdateRecord } from 'src/helpers/entity-store';

export default store => {
  store.on('@init', (state) => onEntityStateInit(state, 'property'));

  store.on(FETCH_ALL_PROPERTIES, (state) => onFetchAll(state, 'property'));
  store.on(FETCH_ALL_PROPERTIES_SUCCESS, (state, { response }) => onFetchAllSuccess(state, 'property', response));
  store.on(FETCH_ALL_PROPERTIES_ERROR, (state, { error }) => onFetchAllError(state, 'property', error));

  store.on(UPDATE_PROPERTY_RECORD, (state, { id, property }) => onUpdateRecord(state, 'property', id, property));
};
