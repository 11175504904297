import React from 'react';

const IconPropertyCircle = ({
  width, style, height, className, fill
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 42.7 42.7"
      width={width}
      style={style}
      height={height}
      className={className}
    >
      <path fill={fill} d="M21.3,0.5C9.8,0.5,0.5,9.8,0.5,21.3c0,11.5,9.3,20.8,20.8,20.8c11.5,0,20.8-9.3,20.8-20.8
    		C42.1,9.8,32.8,0.5,21.3,0.5z M35.3,35.3c-3.6,3.6-8.5,5.8-13.9,5.8c-5.4,0-10.4-2.2-13.9-5.8c-3.6-3.6-5.8-8.5-5.8-13.9
    		c0-5.4,2.2-10.4,5.8-13.9c3.6-3.6,8.5-5.8,13.9-5.8c5.4,0,10.4,2.2,13.9,5.8C38.8,11,41,15.9,41,21.3C41,26.8,38.8,31.7,35.3,35.3z
    		"/>
    	<path fill={fill} d="M31.3,11.3c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-4.1c-0.1,0-0.3,0.1-0.4,0.2
    		c-0.1,0.1-0.2,0.2-0.2,0.4v1.2l-4.4-3.7c-0.2-0.2-0.5-0.2-0.7,0L6.9,20.7c-0.2,0.2-0.2,0.4-0.2,0.6s0.3,0.4,0.5,0.4h4.2V32
    		c0,0.1,0.1,0.3,0.2,0.4s0.2,0.2,0.4,0.2h7.3c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.2-0.4v-7.2h3l0,7.2c0,0.1,0.1,0.3,0.2,0.4
    		s0.2,0.2,0.4,0.2h7.3c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.2-0.4V21.7h4.2c0.2,0,0.4-0.1,0.5-0.4s0-0.5-0.2-0.6l-4.5-3.8V11.3z
    		 M33.9,20.6l-3.2,0c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.2,0.4v10.3h-6.2l0-7.2c0-0.1-0.1-0.3-0.2-0.4
    		c-0.1-0.1-0.2-0.2-0.4-0.2h-4.1c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.2,0.4v7.2h-6.2V21.1c0-0.1-0.1-0.3-0.2-0.4
    		c-0.1-0.1-0.2-0.2-0.4-0.2H8.7L21.3,9.9l5,4.2c0.2,0.1,0.4,0.2,0.6,0.1s0.3-0.3,0.3-0.5v-1.8h3l0,5.3c0,0.2,0.1,0.3,0.2,0.4
    		L33.9,20.6z"/>
    </svg>
 )
};

export default IconPropertyCircle;
