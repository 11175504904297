import React, { useLayoutEffect } from 'react';
import 'chartjs-plugin-datalabels';
import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import randomId from 'src/helpers/randomId';

export default ({
  items = {}, options = {}, legendOptions = {}, colors = {}, view, graphType, years,
  yearValue, handlerOnChangeYear
}) => {
  let chartReference = {};
  const { t } = useTranslation();
  const { padding = 0, legends = [], subLabels = [] } = options;

  const defaultOptions = {
    scaleStepWidth: 1,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        gridLines: { display: false },
        stacked: true,
        ticks: {
          beginAtZero: true,
          fontColor: "#182852",
          fontStyle: "bold"
        }
      }],
      xAxes: [{
        gridLines: { display: false },
        stacked: true,
        ticks: {
          beginAtZero: true,
          stepSize: 1,
          padding,
          fontColor: "#182852",
          fontStyle: "bold"
        }
      }]
    },
    legend: {
      display: false
     },
    tooltips: {
      enabled: false,
      mode: 'x',
      position: 'average',
      bodyAlign: "left",
      xAlign: "below",
      callbacks: {
        label: function(tooltipItem, data) {
          return data['datasets'][0]['data'][tooltipItem['index']];
        }
      },
      custom: function(tooltipModel) {
        let tooltipEl = document.getElementById('chartjs-tooltip');

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML = '<table></table>';
          document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }

        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }

        const getBody= (bodyItem) => bodyItem.lines;

        // Set Text
        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || [];
          let innerHtml = '<thead>';
          titleLines.forEach(function(title) {
            innerHtml += `<tr><th>${title.replace(/,/g, ' ')}</th></tr>`;
          });
          innerHtml += '</thead><tbody>';
          const flatLegends = legends.flat(Infinity);

          tooltipModel.dataPoints.forEach((body, i) => {
            const labelIndex = legendOptions.o === true ? body.datasetIndex : i;
            let label = flatLegends[graphType === 'pie' ? body.index: labelIndex];
            if (label) {
              label =  label.replace(/#/g, '');
              label = legendOptions.t ? t(`performance.legends.${label}`) : label;
            }
            let { value } = body;
            if (value === '') value = tooltipModel.body.map(getBody);
            label = label ? `${label}: ${value}` : value;
            const colors = tooltipModel.labelColors[i];
            const span = `<span class="tooltip-color-block" style="background: ${colors.backgroundColor}"></span>`;
            innerHtml += `<tr><td>${span}${label}</td></tr>`;
          });
          innerHtml += '</tbody>';

          const tableRoot = tooltipEl.querySelector('table');
          tableRoot.innerHTML = innerHtml;
        }

        // `this` will be the overall tooltip
        const position = this._chart.canvas.getBoundingClientRect();

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - 100 + 'px';
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.backgroundColor = 'rgba(0,0,0,0.8)';
        tooltipEl.style.color = "#fff";
        tooltipEl.style.borderRadius = "4px";
      }
    },
    plugins: {
      datalabels: {
        display: true,
        align: 'start',
        anchor: 'start',
        color: "#182852",
        font: { size: 11 },
        offset: 0,
        padding: { left: 5 },
        textAlign: 'right',
        formatter: function(value, ctx) {
          const indexRow = legends.flat(Infinity)
                                  .filter(e => typeof e === 'string' && e !== '')
                                  .length;
          if (subLabels.length > 0 && ctx.datasetIndex % indexRow === 0 && value !== null ) {
            return subLabels[ctx.dataset.stack - 1];
          }
          return '';
        },
        rotation: function(context) {
          const { width } = context.chart;
          const { length } = context.dataset.data;
          if (width / length >= 34) return 0;
          return -60;
        }
      }
    }
  };

  useLayoutEffect(() => {
    if (chartReference && Object.keys(items).length > 0) {
      const { chart } = chartReference.chartInstance;
      const { labels, datasets } = items;
      datasets.forEach(el => {
        Object.assign(el, {
          maxBarThickness: 30,
          categoryPercentage: 0.95,
          barPercentage: 0.8,
          barThickness: 'flex'
        });
      });
      chart.data.datasets = datasets;
      chart.data.labels = labels;
      chart.config.options.scales.xAxes.forEach(el => {
        el.ticks.padding = padding;
        el.ticks.major.padding = padding;
        el.ticks.minor.padding = padding;
      });
      chart.update();
    }
  }, [chartReference, items, padding]);

  let index = 0;



  return (
    <div>
      <h5 className="my-4 text-uppercase text-dark-blue font-weight-bold">
        {legendOptions.y && t(`performance.legendsOptions.${legendOptions.y}`, { currency: legendOptions.currency })}
      </h5>
      <div style={{ position: 'relative', height: 400 }} className="canvas-container">
        <Bar ref={(reference) => chartReference = reference } data={{}} options={defaultOptions} />
      </div>
      <div className="d-flex mt-3 mb-5 justify-content-center">
        <h5 className="mb-0 align-self-center text-center text-uppercase text-dark-blue font-weight-bold mr-3">
          {legendOptions.x && t(`performance.legendsOptions.${legendOptions.x || ''}`, { currency: legendOptions.currency })}
        </h5>
        <select
          style={{width: 'auto'}}
          className="form-control align-self-center form-control-sm"
          value={yearValue}
          onChange={handlerOnChangeYear}
        >
          {years.map(el =>
            <option key={randomId()} value={el}>{el}</option>
          )}
        </select>
      </div>
      {legendOptions.l &&
        <div className="mt-3 px-7 pb-7 pt-7 border">
          <h5 className="text-center my-4 text-uppercase text-dark-blue font-weight-bold">
            {1===2 && t('performance.legendsOptions.' + legendOptions.l)}
          </h5>
          {legends.map((group) =>
            <div className="d-flex justify-content-around my-2" key={randomId()}>
              {group.filter(el => el !== '').map((cell, cellIndex) =>
                <div className="col-3 d-flex mb-2" key={randomId()}>
                  <div
                    className="mr-2"
                    style={{ height: '20px', width: '20px', backgroundColor: colors[index] }}
                  />
                  <span className="d-none">{index += 1}</span>
                  {legendOptions.t ? t(`performance.legends.${cell}`) : cell}
                </div>
              )}
            </div>
          )}

        </div>
      }
    </div>
  )
};
