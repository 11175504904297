import { FETCH_USER, FETCH_USER_SUCCESS, FETCH_USER_ERROR, FETCH_ALL_USERS, FETCH_ALL_USERS_SUCCESS, FETCH_ALL_USERS_ERROR, UPDATE_USER_RECORD } from 'src/constants/actionTypes/entities/user';
import { onEntityStateInit, onFetch, onFetchSuccess, onFetchError, onFetchAll, onFetchAllSuccess, onFetchAllError, onUpdateRecord } from 'src/helpers/entity-store';

export default store => {
  store.on('@init', (state) => onEntityStateInit(state, 'user'));

  store.on(FETCH_USER, (state, { entityId }) => onFetch(state, 'user', entityId));
  store.on(FETCH_USER_SUCCESS, (state, { response }) => onFetchSuccess(state, 'user', response));
  store.on(FETCH_USER_ERROR, (state, { entityId, error }) => onFetchError(state, 'user', entityId, error));

  store.on(FETCH_ALL_USERS, (state) => onFetchAll(state, 'user'));
  store.on(FETCH_ALL_USERS_SUCCESS, (state, { response }) => onFetchAllSuccess(state, 'user', response));
  store.on(FETCH_ALL_USERS_ERROR, (state, { error }) => onFetchAllError(state, 'user', error));

  store.on(UPDATE_USER_RECORD, (state, { id, user }) => onUpdateRecord(state, 'user', id, user));
};
