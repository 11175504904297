import { store } from 'src/store/index';

export const adminRoles = ['director', 'salesManager', 'salesAdmin', 'superAdmin'];
export const adminRolesHumanize = ['Director', 'Sales Manager', 'Sales Admin', 'Super Admin'];
export const teamRoles = ['teamManager', 'teamAdmin', 'broker'];
export const teamRolesHumanize = ['Team Manager', 'Team Admin', 'Broker'];
export const teamAdminRoles = ['teamManager', 'teamAdmin'];
export const brokerRoles = ['broker'];
export const accountantRoles = ['accountant'];

const accessAllowedPages = ['dashboard:index', 'report:index', 'performance:index', 'admin/settings:index', 'admin/users:index', 'admin/users:show', 'admin/companyDetails:index', 'restrictedPage:index', 'notFoundPage:index', 'profile:index', 'profile:edit'];
const samePerforms = {};
const { Auth } = store.get();
const { agency = false, project = false, id } = Auth.user;
const isAgency = agency === true;
const canUseProjectModule = (isAgency && project === true) || !isAgency;
const activityAdminRules = ['activity:index', 'activity:new', 'activity:destroyAll', 'activity:show', 'activity:update', 'activity:destroy', 'activity:edit'];
const activityTeamRules = ['activity:index', 'activity:new', 'activity:show', 'activity:update'];
const companyAdminRules = ['companies:index', 'companies:new', 'companies:destroyAll', 'companies:show', 'companies:update', 'companies:destroy', 'companies:edit'];
const companyTeamRules = ['companies:index', 'companies:new', 'companies:show', 'companies:update'];
const contactAdminRules = ['contacts:index', 'contacts:new', 'contacts:destroyAll', 'contacts:show', 'contacts:update', 'contacts:destroy', 'contacts:edit'];
const contactTeamRules = ['contacts:index', 'contacts:new', 'contacts:show', 'contacts:update'];
const leadAdminRules = ['leads:index', 'leads:new', 'leads:destroyAll', 'leads:show', 'leads:update', 'leads:destroy', 'leads:edit'];
const leadTeamRules = ['leads:index', 'leads:new', 'leads:show', 'leads:update'];
const projectAdminRules = canUseProjectModule ? ['projects:index', 'projects:new', 'projects:destroyAll', 'projects:show', 'projects:update', 'projects:destroy', 'projects:edit'] : [];
const projectTeamRules = ['projects:index', 'projects:show', 'projects:update'];
const propertyAdminRules = isAgency ? [] : ['properties:index', 'properties:new', 'properties:destroyAll', 'properties:show', 'properties:update', 'properties:destroy', 'properties:edit'];
const propertyTeamRules = isAgency ? [] : ['properties:index', 'properties:show', 'properties:update'];
const projectBrokerRules = ['projects:index', 'projects:show'];

if (isAgency && project === true) {
  projectTeamRules.push('projects:new');
  projectBrokerRules.push('projects:new');
}

const listingBrokerRules = isAgency ? ['listings:new'] : [];
const listingAdminRules = ['listings:index', 'listings:new', 'listings:destroyAll', 'listings:show', 'listings:update', 'listings:destroy', 'listings:edit'];
const listingTeamRules = ['listings:index', 'listings:new', 'listings:show', 'listings:update'];
const offerAdminRules = ['offers:index', 'offers:new', 'offers:destroyAll', 'offers:show', 'offers:update', 'offers:destroy', 'offers:edit'];
const offerTeamRules = ['offers:index', 'offers:new', 'offers:show', 'offers:update'];
const transactionAdminRules = ['transactions:index', 'transactions:new', 'transactions:destroyAll', 'transactions:show', 'transactions:update', 'transactions:destroy', 'transactions:edit'];
const transactionTeamRules = ['transactions:index', 'transactions:new', 'transactions:show', 'transactions:update'];
const internalBrokerAdminRules = ['internalBrokers:index', 'internalBrokers:new', 'internalBrokers:destroyAll', 'internalBrokers:show', 'internalBrokers:update', 'internalBrokers:destroy', 'internalBrokers:edit'];
const internalBrokerTeamRules = ['internalBrokers:index', 'internalBrokers:new', 'internalBrokers:show', 'internalBrokers:update'];
const paymentAdminRules = ['payments:index', 'payments:new', 'payments:destroyAll', 'payments:show', 'payments:update', 'payments:destroy', 'payments:edit'];
const paymentTeamRules = ['payments:index', 'payments:new', 'payments:show', 'payments:update'];
const snagRules = ['snags:index', 'snags:show', 'snags:new', 'snags:edit', 'snags:destroy'];

interface Rules {
  [key: string]: {
    static?: string[];
    dynamic?: {
      [key: string]: (data: any) => boolean;
    }
  }
}

const rules: Rules = {
  'superAdmin': {
    static: [
      ...accessAllowedPages,
      ...companyAdminRules,
      ...contactAdminRules,
      ...leadAdminRules,
      ...listingAdminRules,
      ...offerAdminRules,
      ...transactionAdminRules,
      ...internalBrokerAdminRules,
      ...paymentAdminRules,
      ...projectAdminRules,
      ...propertyAdminRules,
      ...activityAdminRules,
      ...snagRules,
      ...[
        'admin/users:edit', 'admin/users:new', 'admin/users:destroy', 'admin/users:destroyAll',
        'admin/teams:index', 'admin/teams:new', 'admin/teams:destroyAll', 'admin/teams:edit',
        'admin/teams:show', 'admin/companyDetails:edit', 'admin/settings:edit',
        'admin/teams:destroy'
      ]
    ],
    dynamic: {
      ...samePerforms,
      'admin/users:destroy': ({ currentUserId, userId }: { currentUserId: number, userId: number }) => +currentUserId !== +userId,
    }
  },
  'director': {
    static: [
      ...accessAllowedPages,
      ...companyAdminRules,
      ...contactAdminRules,
      ...leadAdminRules,
      ...listingAdminRules,
      ...offerAdminRules,
      ...transactionAdminRules,
      ...internalBrokerAdminRules,
      ...paymentAdminRules,
      ...projectAdminRules,
      ...propertyAdminRules,
      ...activityAdminRules,
      ...snagRules,
      ...[
        'admin/users:new', 'admin/users:destroyAll',
        'admin/teams:index', 'admin/teams:new', 'admin/teams:destroyAll', 'admin/teams:edit',
        'admin/teams:show', 'admin/companyDetails:edit', 'admin/settings:edit',
        'admin/teams:destroy'
      ]
    ],
    dynamic: {
      ...samePerforms,
      'admin/users:destroy': ({ currentUserId, userId, userRole }: { currentUserId: number, userId: number, userRole: string }) => +currentUserId !== +userId && userRole !== 'superAdmin',
      'admin/users:edit': ({ userRole }: { userRole: string }) => userRole !== 'superAdmin',
    }
  },
  'salesManager': {
    static: [
      ...accessAllowedPages,
      ...companyAdminRules,
      ...contactAdminRules,
      ...leadAdminRules,
      ...listingAdminRules,
      ...offerAdminRules,
      ...transactionAdminRules,
      ...internalBrokerAdminRules,
      ...paymentAdminRules,
      ...projectAdminRules,
      ...propertyAdminRules,
      ...activityAdminRules,
      ...snagRules,
      ...[
        'admin/users:edit', 'admin/users:new', 'admin/users:destroyAll',
        'admin/teams:index', 'admin/teams:new', 'admin/teams:destroyAll', 'admin/teams:edit',
        'admin/teams:show', 'admin/companyDetails:edit', 'admin/settings:edit',
        'admin/teams:destroy'
      ]
    ],
    dynamic: {
      ...samePerforms,
      'admin/users:destroy': ({ currentUserId, userId, userRole }: { currentUserId: number, userId: number, userRole: string }) => +currentUserId !== +userId && userRole !== 'superAdmin',
    }
  },
  'salesAdmin': {
    static: [
      ...accessAllowedPages,
      ...companyAdminRules,
      ...contactAdminRules,
      ...leadAdminRules,
      ...listingAdminRules,
      ...offerAdminRules,
      ...transactionAdminRules,
      ...internalBrokerAdminRules,
      ...paymentAdminRules,
      ...projectAdminRules,
      ...propertyAdminRules,
      ...activityAdminRules,
      ...snagRules,
      ...[
        'admin/users:edit', 'admin/users:new', 'admin/users:destroyAll',
        'admin/teams:index', 'admin/teams:new', 'admin/teams:destroyAll', 'admin/teams:edit',
        'admin/teams:show', 'admin/companyDetails:edit', 'admin/settings:edit',
        'admin/teams:destroy'
      ]
    ],
    dynamic: {
      ...samePerforms,
      'admin/users:destroy': ({ currentUserId, userId, userRole }: { currentUserId: number, userId: number, userRole: string }) => +currentUserId !== +userId && userRole !== 'superAdmin',
    }
  },
  'teamManager': {
    static: [
      ...accessAllowedPages,
      ...companyTeamRules,
      ...contactTeamRules,
      ...leadTeamRules,
      ...listingTeamRules,
      ...offerTeamRules,
      ...transactionTeamRules,
      ...internalBrokerTeamRules,
      ...paymentTeamRules,
      ...projectTeamRules,
      ...propertyTeamRules,
      ...activityTeamRules
    ],
    dynamic: {
      ...samePerforms,
      'companies:edit': ({ ownerId, teamIds }: { ownerId: number, teamIds: number[] }) => (teamIds.includes(ownerId)),
      'contacts:edit': ({ ownerId, teamIds }: { ownerId: number, teamIds: number[] }) => (teamIds.includes(ownerId)),
      'leads:edit': ({ ownerId, teamIds }: { ownerId: number, teamIds: number[] }) => (teamIds.includes(ownerId)),
      'listings:edit': ({ ownerId, teamIds }: { ownerId: number, teamIds: number[] }) => (teamIds.includes(ownerId)),
      'offers:edit': ({ ownerId, teamIds }: { ownerId: number, teamIds: number[] }) => (teamIds.includes(ownerId)),
      'transactions:edit': ({ ownerId }: { ownerId: number }) => (ownerId === id),
      'internalBrokers:edit': ({ ownerId, teamIds }: { ownerId: number, teamIds: number[] }) => (teamIds.includes(ownerId)),
      'payments:edit': ({ ownerId, teamIds }: { ownerId: number, teamIds: number[] }) => (teamIds.includes(ownerId)),
      'projects:edit': ({ ownerId, teamIds }: { ownerId: number, teamIds: number[] }) => (teamIds.includes(ownerId)),
      'properties:edit': ({ ownerId, teamIds }: { ownerId: number, teamIds: number[] }) => (teamIds.includes(ownerId) && agency === false),
      'activity:edit': ({ ownerId, teamIds }: { ownerId: number, teamIds: number[] }) => (teamIds.includes(ownerId))
    }
  },
  'teamAdmin': {
    static: [
      ...accessAllowedPages,
      ...companyTeamRules,
      ...contactTeamRules,
      ...leadTeamRules,
      ...listingTeamRules,
      ...offerTeamRules,
      ...transactionTeamRules,
      ...internalBrokerTeamRules,
      ...paymentTeamRules,
      ...projectTeamRules,
      ...propertyTeamRules,
      ...activityTeamRules
    ],
    dynamic: {
      ...samePerforms,
      'companies:edit': ({ ownerId, teamIds }: { ownerId: number, teamIds: number[] }) => (teamIds.includes(ownerId)),
      'contacts:edit': ({ ownerId, teamIds }: { ownerId: number, teamIds: number[] }) => (teamIds.includes(ownerId)),
      'leads:edit': ({ ownerId, teamIds }: { ownerId: number, teamIds: number[] }) => (teamIds.includes(ownerId)),
      'listings:edit': ({ ownerId, teamIds }: { ownerId: number, teamIds: number[] }) => (teamIds.includes(ownerId)),
      'offers:edit': ({ ownerId, teamIds }: { ownerId: number, teamIds: number[] }) => (teamIds.includes(ownerId)),
      'transactions:edit': ({ ownerId }: { ownerId: number }) => (ownerId === id),
      'internalBrokers:edit': ({ ownerId, teamIds }: { ownerId: number, teamIds: number[] }) => (teamIds.includes(ownerId)),
      'payments:edit': ({ ownerId, teamIds }: { ownerId: number, teamIds: number[] }) => (teamIds.includes(ownerId)),
      'projects:edit': ({ ownerId, teamIds }: { ownerId: number, teamIds: number[] }) => (teamIds.includes(ownerId)),
      'properties:edit': ({ ownerId, teamIds }: { ownerId: number, teamIds: number[] }) => (teamIds.includes(ownerId) && agency === false),
      'activity:edit': ({ userId, ownerId, teamIds }: { userId: number, ownerId: number, teamIds: number[] }) => (teamIds.includes(ownerId))
    }
  },
  'broker': {
    static: [
      ...accessAllowedPages,
      ...projectBrokerRules,
      ...listingBrokerRules,
      ...[
        'companies:index', 'companies:new', 'companies:show', 'companies:update',
        'contacts:index', 'contacts:new', 'contacts:show', 'contacts:update',
        'leads:index', 'leads:new', 'leads:show', 'leads:update',
        'listings:index', 'listings:show', 'listings:update',
        'offers:index', 'offers:new', 'offers:show', 'offers:update',
        'activity:index', 'activity:new', 'activity:show', 'activity:update',
      ]
    ],
    dynamic: {
      ...samePerforms,
      'companies:edit': ({ ownerId }: { ownerId: number }) => (id === ownerId),
      'contacts:edit': ({ ownerId }: { ownerId: number }) => (id === ownerId),
      'leads:edit': ({ ownerId }: { ownerId: number }) => (id === ownerId),
      'listings:edit': ({ ownerId }: { ownerId: number }) => (id === ownerId),
      'offers:edit': ({ ownerId }: { ownerId: number }) => (id === ownerId),
      'projects:edit': ({ ownerId }: { ownerId: number }) => (id === ownerId && agency === true),
      'properties:index': () => (agency === true),
      'activity:edit': ({ userId, ownerId }: { userId: number, ownerId: number }) => (userId === ownerId)
    }
  },
  'accountant': {
    static: [
      ...accessAllowedPages,
      ...[
        'offers:index', 'offers:new', 'offers:show',
        'transactions:index', 'transactions:new', 'transactions:show', 'transactions:update',
        'transactions:edit',
        'internalBrokers:index', 'internalBrokers:new', 'internalBrokers:show', 'internalBrokers:update',
        'internalBrokers:edit',
        'payments:index', 'payments:new', 'payments:show', 'payments:update',
        'payments:edit',
        'activity:index', 'activity:new', 'activity:show', 'activity:update'
      ]
    ],
    dynamic: {
      ...samePerforms,
      'activity:edit': ({ userId, ownerId }: { userId: number, ownerId: number }) => (userId === ownerId)
    }
  }
};

export default rules;
