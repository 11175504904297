import * as types from 'src/constants/actionTypes/OffersActionTypes';
import initialState from 'src/helpers/initialStateSubmit';
import { SUBMIT_RECENTLY_VIEWED } from 'src/constants/actionTypes/RecentlyViewedActionTypes';

export default store => {
  store.on('@init', () => ({ SubmitOffer: initialState }));

  store.on(types.SUBMIT_OFFER_RESET, () => ({ SubmitOffer: initialState }));

  store.on(types.SUBMIT_OFFER_REQUEST, ({ SubmitOffer }) =>
    ({ SubmitOffer: { ...initialState, sendRequest: true }}));
  store.on(types.SUBMIT_OFFER_SUCCESS, ({ SubmitOffer, Offer }, { response }) => {
    store.dispatch(SUBMIT_RECENTLY_VIEWED, {
      data: { id: response.data.id, name: response.data.name, time: Date.now()},
      endpoint: 'offers'
    });
    return ({
      Offer: { ...Offer, offer: response.data },
      SubmitOffer: { ...SubmitOffer, success: true, sendRequest: false }
    });
  });
  store.on(types.SUBMIT_OFFER_ERROR, ({ SubmitOffer }, { response }) => {
    let errors = {};
    if (response && response.body && response.body.errors) errors = response.body.errors;
    return ({ SubmitOffer: {...SubmitOffer, error: true, errors: errors, sendRequest: false }});
  });
}
