import * as types from 'src/constants/actionTypes/DataTableActionTypes';
import { FetchFunc } from 'src/-types/request';

const clearColumns = (query: any) => {
  const columns = query.columns.map((column: any) => {
    const { customFilter, ...clearColumn } = column;

    return clearColumn;
  });

  return { ...query, columns };
}

const fetchDataTable: FetchFunc = ({ query, endpoint, apiVersion = 'v1' }) => ({
  arrayFormat: 'indices',
  apiVersion,
  credentials: true,
  endpoint: `${endpoint}/datatable`,
  method: 'GET',
  query: clearColumns(query),
  types: [
    types.FETCH_DATATABLE_REQUEST,
    types.FETCH_DATATABLE_SUCCESS,
    types.FETCH_DATATABLE_ERROR
  ]
});

export default fetchDataTable;
