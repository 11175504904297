import filterByDateRange from './filterByDateRange';
import filterByRole, { optionsByRole } from './filterByRole';
import filterByView from './filterByView';
import filterByList from './filterByList';
import { FilterByText } from './FilterByText';

const ENDPOINT = 'leads';

const DEFAULT_VIEW = [
  { data: 'id', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  { data: 'idValue', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link show-link', path: 'leadPath', pathKey: 'id' },
  { data: 'leadType', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'contact', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link contact-link', path: 'contactPath', pathKey: 'contactId' },
  { data: 'listing', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link listing-link', path: 'listingPath', pathKey: 'contactId' },
  { data: 'qualityScore', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'sourceChannel', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'updatedAt', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'createdAt', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'contactId', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  { data: 'listingId', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true }
];

const SALE_LEADS_VIEW = [
  { data: 'id', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  { data: 'idValue', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link show-link', path: 'leadPath', pathKey: 'id' },
  { data: 'contact', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link contact-link', path: 'contactPath', pathKey: 'contactId' },
  { data: 'listing', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link listing-link', path: 'listingPath', pathKey: 'contactId' },
  { data: 'qualityScore', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'sourceChannel', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'timeToPurchase', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'updatedAt', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'createdAt', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'contactId', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  { data: 'listingId', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true }
];

const GOOD_AND_EXCELLENT_LEADS_VIEW = [
  { data: 'id', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  { data: 'idValue', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link show-link', path: 'companyPath', pathKey: 'id' },
  { data: 'leadType', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'contact', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link contact-link', path: 'contactPath', pathKey: 'contactId' },
  { data: 'listing', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link listing-link', path: 'listingPath', pathKey: 'contactId' },
  { data: 'sourceChannel', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'timeToPurchase', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'updatedAt', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'createdAt', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'contactId', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  { data: 'listingId', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true }
];

const REFERRING_CONTACT_VIEW = [
  { data: 'id', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  { data: 'idValue', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link show-link', path: 'companyPath', pathKey: 'id' },
  { data: 'leadType', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'contact', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link contact-link', path: 'contactPath', pathKey: 'contactId' },
  { data: 'referringContact', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link referring-contact-link', path: 'contactPath', pathKey: 'referringContactId' },
  { data: 'listing', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link listing-link', path: 'listingPath', pathKey: 'contactId' },
  { data: 'qualityScore', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'updatedAt', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'createdAt', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'contactId', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  { data: 'referringContactId', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  { data: 'listingId', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true }
];

const REFERRING_COMPANY_VIEW = [
  { data: 'id', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  { data: 'idValue', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link show-link', path: 'companyPath', pathKey: 'id' },
  { data: 'leadType', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'contact', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link contact-link', path: 'contactPath', pathKey: 'contactId' },
  { data: 'referringCompany', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link referring-company-link', path: 'companyPath', pathKey: 'referringCompanyId' },
  { data: 'listing', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link listing-link', path: 'listingPath', pathKey: 'contactId' },
  { data: 'qualityScore', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'updatedAt', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'createdAt', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'contactId', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  { data: 'referringCompanyId', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  { data: 'listingId', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true }
];

const LEAD_SUMMARY_VIEW = [
  { data: 'id', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  { data: 'idValue', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link show-link', path: 'companyPath', pathKey: 'id' },
  { data: 'leadType', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'contact', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link contact-link', path: 'contactPath', pathKey: 'contactId' },
  { data: 'referringCompany', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link referring-company-link', path: 'companyPath', pathKey: 'referringCompanyId' },
  { data: 'sourceChannel', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'stage', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'qualityScore', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'minBedsRequired', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'propertyTypes', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'projectIds', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'maximumBudget', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'comments', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'updatedAt', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'contactId', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  { data: 'referringCompanyId', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  { data: 'listingId', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true }
];

const fieldsByView = (view) => {
  const fields = {
    'recentlyViewed': DEFAULT_VIEW,
    'leads': DEFAULT_VIEW,
    'saleLeads': SALE_LEADS_VIEW,
    'rentalLeads': SALE_LEADS_VIEW,
    'goodAndExcellentLeads': GOOD_AND_EXCELLENT_LEADS_VIEW,
    'referringContact': REFERRING_CONTACT_VIEW,
    'referringCompany': REFERRING_COMPANY_VIEW,
    'leadSummary': LEAD_SUMMARY_VIEW
  };

  return fields[view];
};

export const optionsForFilterByView = {
  'leads': {},
  'saleLeads': { name: 'leads.leadType', value: 'sale', type: 'eq' },
  'rentalLeads': { name: 'leads.leadType', value: 'rent', type: 'eq' },
  'goodAndExcellentLeads': { name: 'leads.qualityScore', value: 'good||excellent', type: 'or' },
  'referringContact': { name: 'leads.sourceChannel', value: 'agency_referral||partner_referral', type: 'or' },
  'referringCompany': { name: 'leads.sourceChannel', value: 'agency_referral||partner_referral', type: 'or' },
  'leadSummary': {}
};

export const defaultFilter = ({ role, ids }) => {
  const filter = {};
  const byRole = optionsByRole({ role, endpoint: ENDPOINT });

  if (byRole.length) filter.role = { value: byRole[0], type: 'role' };
  if (ids.length) filter.view = { name: 'id', type: 'eq', value: ids };

  return filter;
};

export const columnFilters = [
  { filter: filterByList, dataField: 'status', label: 'Status', source: 'LeadCollections.items.status' },
  { filter: filterByList, dataField: 'stage', label: 'Stage', source: 'LeadCollections.items.stage' },
  { filter: filterByDateRange, dataField: 'createdAt' },
  { filter: FilterByText, dataField: 'wildcard', label: t => t('leads.search') }
];

export const outsideFilters = [
  { component: filterByView, endpoint: ENDPOINT, options: optionsForFilterByView },
  { component: filterByRole }
];

export default ({ view = 'leads' }) => {
  const columns = fieldsByView(view);
  const query = {
    columns,
    order: [{ column: 0, dir: 'asc' }],
    start: 0,
    length: 10,
    endpoint: ENDPOINT
  };

  return { query, endpoint: ENDPOINT, columns };
}
