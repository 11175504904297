import {
  FETCH_PROJECT, FETCH_PROJECT_SUCCESS, FETCH_PROJECT_ERROR,
  FETCH_ALL_PROJECTS, FETCH_ALL_PROJECTS_SUCCESS, FETCH_ALL_PROJECTS_ERROR
} from 'src/constants/actionTypes/entities/project';
import { FetchEntityFunc, QueryParams, FetchRelatedEntitiesFunc } from 'src/-types/request';
import { FETCH_ALL_SNAGS, FETCH_ALL_SNAGS_SUCCESS, FETCH_ALL_SNAGS_ERROR } from 'src/constants/actionTypes/entities/snag';

export const fetchProject: FetchEntityFunc = ({ id, query }) => ({
  query,
  apiVersion: 'v2',
  endpoint: `projects/${id}`,
  entityId: id,
  method: 'GET',
  credentials: true,
  types: [
    FETCH_PROJECT,
    FETCH_PROJECT_SUCCESS,
    FETCH_PROJECT_ERROR
  ]
});

export const fetchAllProjects = ({ query }: { query?: QueryParams } = {}) => ({
  query,
  apiVersion: 'v2',
  endpoint: `projects`,
  method: 'GET',
  credentials: true,
  types: [
    FETCH_ALL_PROJECTS,
    FETCH_ALL_PROJECTS_SUCCESS,
    FETCH_ALL_PROJECTS_ERROR
  ]
});

export const fetchProjectSnags: FetchRelatedEntitiesFunc = ({ id, query }) => ({
  query,
  apiVersion: 'v2',
  endpoint: `projects/${id}/snags`,
  entityId: id,
  method: 'GET',
  credentials: true,
  types: [
    FETCH_ALL_SNAGS,
    FETCH_ALL_SNAGS_SUCCESS,
    FETCH_ALL_SNAGS_ERROR
  ]
});
