import * as types from 'src/constants/actionTypes/InternalBrokersActionTypes';
import initialState from 'src/helpers/initialStateSubmit';
import { SUBMIT_RECENTLY_VIEWED } from 'src/constants/actionTypes/RecentlyViewedActionTypes';

export default store => {
  store.on('@init', () => ({ SubmitInternalBroker: initialState }));

  store.on(types.SUBMIT_INTERNAL_BROKER_RESET, () => ({ SubmitInternalBroker: initialState }));

  store.on(types.SUBMIT_INTERNAL_BROKER_REQUEST, ({ SubmitInternalBroker }) =>
    ({ SubmitInternalBroker: { ...initialState, sendRequest: true }}));
  store.on(types.SUBMIT_INTERNAL_BROKER_SUCCESS, ({ SubmitInternalBroker, InternalBroker }, { response }) => {
    store.dispatch(SUBMIT_RECENTLY_VIEWED, {
      data: { id: response.data.id, name: response.data.name, time: Date.now()},
      endpoint: 'internal_brokers'
    });
    return ({
      InternalBroker: { ...InternalBroker, offer: response.data },
      SubmitInternalBroker: { ...SubmitInternalBroker, success: true, sendRequest: false }
    });
  });
  store.on(types.SUBMIT_INTERNAL_BROKER_ERROR, ({ SubmitInternalBroker }, { response }) => {
    let errors = {};
    if (response && response.body && response.body.errors) errors = response.body.errors;
    return ({ SubmitInternalBroker: {...SubmitInternalBroker, error: true, errors: errors, sendRequest: false }});
  });
}
