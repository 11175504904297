import { resetPasswordPath } from 'src/helpers/routes';
import ResetPasswordPage from 'src/components/views/resetPassword';
import LoginLayout from 'src/components/views/loginLayout';

export default {
  path: resetPasswordPath(),
  exact: true,
  strict: true,
  component: ResetPasswordPage,
  layout: LoginLayout,
  perform: 'user:signin',
};
