import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import DayJsLocaleUtils, { formatDate } from 'src/helpers/dayJsLocaleUtils';
import { useTranslation } from 'react-i18next';
import OverlayComponent from './Overlay';
import YearMonthForm from './YearMonthForm';

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear, 11);
const toMonth = new Date(currentYear + 10, 11);

const DateTimeComponent = ({
  labelSize = "col-2", inputSize = "col-9", inputClassNames = "",
  label, required = false, name, onChange, errors, value
}) => {
  const { i18n, t } = useTranslation();
  const errorMessage = errors ? errors.join(', ') : t('form.provideValid', { label });
  const locale = i18n.language;
  const defaultMonth = value ? new Date(value) : new Date();
  const [month, setMonth] = useState(defaultMonth);
  const [time, setTime] = useState(dayjs(value).format('HH:mm'));

  const handleYearMonthChange = (month) => setMonth(month);

  const handlerOnDayChange = ({ dayValue, timeValue }) => {
    setTime(timeValue);
    const date = dayValue ? dayjs(dayValue) : dayjs();
    const currTimeSplit = timeValue.split(':');
    onChange({ [name]: date.hour(currTimeSplit[0]).minute(currTimeSplit[1]).toISOString() })
  }

  const dayPickerProps = {
    locale: locale,
    localeUtils: DayJsLocaleUtils,
    month,
    fromMonth,
    toMonth,
    handlerOnDayChange,
    dayValue: value,
    timeValue: time,
    captionElement: ({ date, localeUtils }) => (
      <YearMonthForm
        date={date}
        localeUtils={localeUtils}
        onChange={handleYearMonthChange}
        fromMonth={fromMonth}
        toMonth={toMonth}
      />
    )
  }

  return (
    <div className="form-row mb-1">
			<label className={`${labelSize} col-form-label form-label`}>{label}</label>
      <div className={`${inputSize} mr-2`}>
        <DayPickerInput
          classNames={{
            container: "custom-bg-datetime",
            overlayWrapper: 'foo-overlay-wrapper',
            overlay: 'foo-overlay'
          }}
          placeholder={label}
          required={required}
          inputProps={{ className: 'form-control', name: name, autocomplite: 'off' }}
          format="HH:mm - DD MMM YYYY"
          value={value ? new Date(value) : ''}
          formatDate={formatDate}
          onDayChange={dayValue => handlerOnDayChange({ dayValue, timeValue: time })}
          dayPickerProps={dayPickerProps}
          overlayComponent={OverlayComponent}
          keepFocus={false}
        />
        <div className='invalid-feedback'>{errorMessage}</div>
      </div>
		</div>
  )
}

DateTimeComponent.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  labelSize: PropTypes.string,
  inputSize: PropTypes.string,
  inputClassNames: PropTypes.string,
  required: PropTypes.bool,
  errors: PropTypes.array
};

export default DateTimeComponent;
