import * as types from 'src/constants/actionTypes/InternalBrokersActionTypes';

const initialState = {
  isDestroing: false,
  error: false,
  successDestroy: false,
}

export default store => {
  store.on('@init', () => ({ InternalBrokerDestroy: initialState }));

  store.on(types.DELETE_INTERNAL_BROKERS_REQUEST, ({ InternalBrokerDestroy }) =>
    ({ InternalBrokerDestroy: { ...InternalBrokerDestroy, isDestroing: true, successDestroy: false }}));
  store.on(types.DELETE_INTERNAL_BROKERS_SUCCESS, ({ InternalBrokerDestroy }) =>
    ({ InternalBrokerDestroy: {...InternalBrokerDestroy, successDestroy: true }}));
  store.on(types.DELETE_INTERNAL_BROKERS_ERROR, ({ InternalBrokerDestroy }) =>
    ({ InternalBrokerDestroy: {...InternalBrokerDestroy, error: true }}));

  store.on(types.DELETE_INTERNAL_BROKER_REQUEST, ({ InternalBrokerDestroy }) =>
    ({ InternalBrokerDestroy: { ...InternalBrokerDestroy, isDestroing: true, successDestroy: false }}));
  store.on(types.DELETE_INTERNAL_BROKER_SUCCESS, ({ InternalBrokerDestroy }) =>
    ({ InternalBrokerDestroy: {...InternalBrokerDestroy, successDestroy: true }}));
  store.on(types.DELETE_INTERNAL_BROKER_ERROR, ({ InternalBrokerDestroy }) =>
    ({ InternalBrokerDestroy: {...InternalBrokerDestroy, error: true }}));
}
