import { State } from 'src/store';
import useStoreon from 'storeon/react';
import { useEffect, useState } from 'react';
import { CountriesLookup } from 'src/-types/lookups/countries';
import { FeaturesLookup } from 'src/-types/lookups/features';
import { FieldsLookup } from 'src/-types/lookups/fields';

export function useCountriesLookup() {
  const { lookups: { countries } } = useStoreon<State>('lookups');
  const [result, setResult] = useState<CountriesLookup>(new CountriesLookup(countries));

  useEffect(() => {
    setResult(new CountriesLookup(countries));
  }, [countries]);

  return result;
}

export function useFeaturesLookup() {
  const { lookups: { features } } = useStoreon<State>('lookups');
  const [result, setResult] = useState<FeaturesLookup>(new FeaturesLookup(features));

  useEffect(() => {
    setResult(new FeaturesLookup(features));
  }, [features]);

  return result;
}

export function useFieldsLookup() {
  const { lookups: { fields } } = useStoreon<State>('lookups');
  const [result, setResult] = useState<FieldsLookup>(new FieldsLookup(fields));

  useEffect(() => {
    setResult(new FieldsLookup(fields));
  }, [fields]);

  return result;
}
