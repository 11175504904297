import * as types from 'src/constants/actionTypes/ProjectsActionTypes';
import { SUBMIT_RECENTLY_VIEWED } from 'src/constants/actionTypes/RecentlyViewedActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  project: {}
}

export default store => {
  store.on('@init', () => ({ Project: initialState }));

  store.on(types.FETCH_PROJECT_REQUEST, ({ Project }) =>
    ({ Project: { ...initialState, isFetching: true }}));
  store.on(types.FETCH_PROJECT_SUCCESS, ({ Project }, { response }) => {
    store.dispatch(SUBMIT_RECENTLY_VIEWED, {
      data: { id: response.data.id, name: response.data.name, time: Date.now()},
      endpoint: 'projects'
    });
    return ({ Project: { ...Project, project: response.data }})
  });
  store.on(types.FETCH_PROJECT_ERROR, ({ Project }) =>
    ({ Project: {...Project, error: true }}));
}
