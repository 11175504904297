export const FETCH_COMPANIES_REQUEST = 'FETCH_COMPANIES_REQUEST';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_ERROR = 'FETCH_COMPANIES_ERROR';

export const FETCH_COMPANY_REQUEST = 'FETCH_COMPANY_REQUEST';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_ERROR = 'FETCH_COMPANY_ERROR';
export const FETCH_COMPANY_RESET = 'FETCH_COMPANY_RESET';

export const FETCH_COMPANY_COLLECTION_REQUEST = 'FETCH_COMPANY_COLLECTION_REQUEST';
export const FETCH_COMPANY_COLLECTION_SUCCESS = 'FETCH_COMPANY_COLLECTION_SUCCESS';
export const FETCH_COMPANY_COLLECTION_ERROR = 'FETCH_COMPANY_COLLECTION_ERROR';

export const DELETE_COMPANY_REQUEST = 'DELETE_COMPANY_REQUEST';
export const DELETE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const DELETE_COMPANY_ERROR = 'DELETE_COMPANY_ERROR';

export const DELETE_COMPANIES_REQUEST = 'DELETE_COMPANIES_REQUEST';
export const DELETE_COMPANIES_SUCCESS = 'DELETE_COMPANIES_SUCCESS';
export const DELETE_COMPANIES_ERROR = 'DELETE_COMPANIES_ERROR';

export const SUBMIT_COMPANY_REQUEST = 'SUBMIT_COMPANY_REQUEST';
export const SUBMIT_COMPANY_SUCCESS = 'SUBMIT_COMPANY_SUCCESS';
export const SUBMIT_COMPANY_ERROR = 'SUBMIT_COMPANY_ERROR';
export const SUBMIT_COMPANY_RESET = 'SUBMIT_COMPANY_RESET';
