import * as types from 'src/constants/actionTypes/DashboardActionTypes';

const fetchDashboard = ({ accessToken, query }) => {
  return ({
    query,
    endpoint: `dashboards`,
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_DASHBOARD_REQUEST,
      types.FETCH_DASHBOARD_SUCCESS,
      types.FETCH_DASHBOARD_ERROR
    ]
  })
};

export default fetchDashboard;
