import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonDropdown as ReactstrapButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import randomId from 'src/helpers/randomId';
import { map } from 'lodash';

import 'src/styles/accordion.scss';

const ButtonDropdownItems = ({ handlerOnClick, items, methodKey, methodValue, size }) => {
  const onClick = useCallback((item) => (_event) => {
    handlerOnClick(item);
  }, [handlerOnClick]);

  return map(items, item => {
    const key = methodKey(item);
    const value = methodValue(item);

    return (
      <DropdownItem
        key={`item-${randomId()}-${key}`}
        className={`${size}`}
        onClick={onClick(item)}
      >
        {value}
      </DropdownItem>
    );
  });
};

const ButtonDropdown = ({
  items = [], handlerOnClick, methodKey = item => item.id, methodValue = item => item.value, label, size = '',
  customClassName, disabled = false, groupClassName = undefined
}) => {
  const [toggle, setToggle] = useState(false);

  const handlerToggle = useCallback((e) => {
    e.stopPropagation();
    setToggle(!toggle)
  }, [toggle]);

  return (
    <ReactstrapButtonDropdown className={groupClassName} isOpen={toggle} toggle={handlerToggle}>
      <DropdownToggle
        caret
        className={`dropdown-toggle btn ${size} btn-header ${customClassName}`}
        disabled={disabled}
      >
        {label}
      </DropdownToggle>
      <DropdownMenu>
        <ButtonDropdownItems handlerOnClick={handlerOnClick} items={items} methodKey={methodKey} methodValue={methodValue} size={size} />
      </DropdownMenu>
    </ReactstrapButtonDropdown>
  );
};

ButtonDropdown.propTypes = {
  handlerOnClick: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  methodValue: PropTypes.func,
  methodKey: PropTypes.func,
  size: PropTypes.string,
  customClassName: PropTypes.string,
  groupClassName: PropTypes.string
};

export default ButtonDropdown;
