import dayjs from 'dayjs';
import { isNil, isString } from 'lodash';

export function formatDateTime(date?: string | number | Date | null) {
  return isNil(date) || (isString(date) && date.length === 0) ? '' : dayjs(date).format(`HH:mm DD-MM-YYYY`);
}

export function formatDate(date?: string | number | Date | null) {
  return isNil(date) || (isString(date) && date.length === 0) ? '' : dayjs(date).format(`DD-MM-YYYY`);
}
