import filterByRole, { optionsByRole } from './filterByRole';
import filterByView from './filterByView';
import FilterByDropdownSource from 'src/datatables/FilterByDropdownSource';
import { FilterByText } from './FilterByText';

const ENDPOINT = 'companies';

const defaultView = [
  { data: 'id', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  {
    data: 'name', searchable: true, orderable: false, search: { value: '', regex: '' },
    classes: 'table-link show-link', path: 'companyPath', pathKey: 'id'
  },
  { data: 'phone', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'email', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'website', searchable: true, orderable: false, search: { value: '', regex: '' } },
  {
    data: 'owner', searchable: true, orderable: false, search: { value: '', regex: '' },
    classes: 'table-link owner-link', path: 'userPath', pathKey: 'ownerId'
  },
  { data: 'createdAt', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'ownerId', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
];

const agreementView = [
  { data: 'id', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  {
    data: 'name', searchable: true, orderable: false, search: { value: '', regex: '' },
    classes: 'table-link show-link', path: 'companyPath', pathKey: 'id'
  },
  { data: 'agreementStatus', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'agreementType', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'agreementStartDate', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'agreementEndDate', searchable: true, orderable: false, search: { value: '', regex: '' } }
];

const agencyView = [
  { data: 'id', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  {
    data: 'name', searchable: true, orderable: false, search: { value: '', regex: '' },
    classes: 'table-link show-link', path: 'companyPath', pathKey: 'id'
  },
  { data: 'phone', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'email', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'agreementType', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'agreementStartDate', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'agreementEndDate', searchable: true, orderable: false, search: { value: '', regex: '' } }
];

const pendingAgencyView = [
  { data: 'id', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  {
    data: 'name', searchable: true, orderable: false, search: { value: '', regex: '' },
    classes: 'table-link show-link', path: 'companyPath', pathKey: 'id'
  },
  { data: 'phone', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'email', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'agreementType', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'agreementStatus', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'agreementStartDate', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'agreementEndDate', searchable: true, orderable: false, search: { value: '', regex: '' } }
];

const fieldsByView = (view) => {
  const fields = {
    'recentlyViewed': defaultView,
    'companies': defaultView,
    'realEstateAgencies': defaultView,
    'agreementStatus': agreementView,
    'activeAgencies': agencyView,
    'pendingAgencies': pendingAgencyView,
    'expiredAgencies': agencyView
  };
  return fields[view];
};

export const optionsForFilterByView = {
  'companies': {},
  'realEstateAgencies': { name: 'industry', value: 'real_estate', type: 'eq' },
  'agreementStatus': { name: 'agreementType', value: 'none', type: 'not:eq' },
  'activeAgencies': { name: 'agreementStatus', value: 'active', type: 'eq' },
  'pendingAgencies': { name: 'agreementStatus', value: 'pending', type: 'eq' },
  'expiredAgencies': { name: 'agreementStatus', value: 'expired', type: 'eq' }
};

export const columnFilters = (industryDropdownSource) => {
  const filters = [
    { filter: FilterByDropdownSource, dataField: 'industry', label: t => t('companies.attributes.industry.label'), source: industryDropdownSource },
    { filter: FilterByText, dataField: 'wildcard', label: t => t('companies.search') }
  ];

  return filters;
};

export const outsideFilters = [
  { component: filterByView, endpoint: ENDPOINT, options: optionsForFilterByView },
  { component: filterByRole, endpoint: ENDPOINT }
];

export const defaultFilter = ({ role, ids }) => {
  const filter = {};
  const byRole = optionsByRole({ role, endpoint: ENDPOINT });
  if (byRole.length) filter.role = { value: byRole[0], type: 'role' };
  if (ids.length) filter.view = { name: 'id', type: 'eq', value: ids };
  return filter;
};

export default ({ view = 'companies', items = [] }) => {
  const columns = fieldsByView(view);
  const query = {
    columns,
    order: [{ column: 1, dir: 'asc' }],
    start: 0,
    length: 10,
    endpoint: ENDPOINT
  };
  return { query, endpoint: ENDPOINT, columns };
};
