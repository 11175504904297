import { teamEditPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchTeam, fetchTeamCollections } from 'src/actions/Teams';
import { API_CALL } from 'src/middleware/API';

const editTeam = lazy(() => import('src/components/views/admin/teams/Edit'));

export default {
  path: teamEditPath(),
  exact: true,
  strict: true,
  component: editTeam,
  protected: true,
  perform: 'admin/teams:edit',
  title: 'Edit Team',
  prepareData: ({ store, params }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    if (Object.keys(state.TeamCollections.items).length ===  0) {
      store.dispatch(API_CALL, fetchTeamCollections({ accessToken }));
    }
    const query = { noTypeCast: true }
    store.dispatch(API_CALL, fetchTeam({ accessToken, id: params.id, query }));
  }
};
