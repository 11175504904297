import React from 'react';
import ReactDOM from 'react-dom';
import 'src/styles/index.scss';
import App from 'src/components/App';
import * as serviceWorker from 'src/serviceWorker';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css';
import StoreContext from 'storeon/react/context';
import { store } from 'src/store/index';
import history from 'src/helpers/history';
import historyCb from 'src/helpers/historyCb';
import { initialize } from 'src/helpers/google-analytics';
import 'src/i18n';

initialize();
history.listen(historyCb);
historyCb(window.location, 'PUSH');

require('src/constants/images/background.svg');

ReactDOM.render(
  <StoreContext.Provider value={store}>
    <>
      <ReactNotification />
      <App />
    </>
  </StoreContext.Provider>,
  document.querySelector('#app-root')
);

serviceWorker.unregister();
