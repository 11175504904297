import { FETCH_ALL_LISTINGS, FETCH_ALL_LISTINGS_SUCCESS, FETCH_ALL_LISTINGS_ERROR, UPDATE_LISTING_RECORD } from 'src/constants/actionTypes/entities/listing';
import { onEntityStateInit, onFetchAll, onFetchAllSuccess, onFetchAllError, onUpdateRecord } from 'src/helpers/entity-store';

export default store => {
  store.on('@init', (state) => onEntityStateInit(state, 'listing'));

  store.on(FETCH_ALL_LISTINGS, (state) => onFetchAll(state, 'listing'));
  store.on(FETCH_ALL_LISTINGS_SUCCESS, (state, { response }) => onFetchAllSuccess(state, 'listing', response));
  store.on(FETCH_ALL_LISTINGS_ERROR, (state, { error }) => onFetchAllError(state, 'listing', error));

  store.on(UPDATE_LISTING_RECORD, (state, { id, listing }) => onUpdateRecord(state, 'listing', id, listing));
};
