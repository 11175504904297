import { propertiesPath } from 'src/helpers/routes';
import { fetchPropertyCollections } from 'src/actions/Properties';
import { lazy } from 'react';
import fetchDataTable from 'src/actions/DataTable';
import { API_CALL } from 'src/middleware/API';
import dataTableParams, { defaultFilter } from 'src/datatables/properties';

const Properties = lazy(() => import('src/components/views/properties'));

export default {
  path: propertiesPath(),
  exact: true,
  strict: true,
  component: Properties,
  perform: "properties:index",
  protected: true,
  prepareData: ({ store }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    const { role } = state.Auth.user;
    const { items = [] } = state.RecentlyViewed;
    const endpoint = "properties";
    const ids = (items[endpoint] || []).map(i => i.id) || [];
    const { query } = dataTableParams({ role, endpoint, ids });
    if (Object.keys(state.PropertyCollections.items).length ===  0) {
      store.dispatch(API_CALL, fetchPropertyCollections({ accessToken }));
    }
    query.filter = Object.values(defaultFilter({ role, ids }));
    store.dispatch(API_CALL, fetchDataTable({ accessToken, query, endpoint }));
  }
};
