import * as types from 'src/constants/actionTypes/AuthActionTypes';
import { getI18n } from 'react-i18next';


const initialState = {
  connected: false,
  isLoading: false,
  isUpdating: false,
  isFetching: false,
  token: {},
  user: {}
};

const SetLocale = ({ locale }) => {
  const i18n = getI18n();
  i18n.changeLanguage(locale);
  window.localStorage.setItem('i18nextLng', locale);
};

const fetchingSuccess = ({ response, headers }) => {
  const {
    id, email, fullName, role, avatar, agency, project, teamIds, locale, currency
  } = response.data;
  SetLocale({ locale });
  return {
    user: {
      id, email, fullName, role, avatar, agency, project, teamIds, locale, currency
    },
    isLoading: false,
    connected: true,
    error: false,
    token: { "access-token": headers['access-token'], client: headers.client, uid: headers.uid }
  }
};

const tokenSuccess = (response) => {
  const {
    id, email, fullName, role, avatar, agency, project, teamIds, locale, currency
  } = response.data;
  SetLocale({ locale });
  return {
    user: {
      id, email, fullName, role, avatar, agency, project, teamIds, locale, currency
    },
    isLoading: false,
    connected: true,
    isVerified: true
  }
};

const tokenUpdate = (Auth, newToken) => {
  return ({ token: Object.assign({}, Auth.token, { "access-token": newToken }) });
};

const initialStateForgotPassword = {
  isFetching: false,
  error: false,
  success: false
};

export default store => {
  store.on('@init', (state) => ({ Auth: initialState }));
  store.on('@init', (state) => ({ forgotPassword: initialStateForgotPassword }));

  store.on(types.SIGN_IN_REQUEST, ({ Auth }) =>
    ({ Auth: { ...Auth, isLoading: true }}));
  store.on(types.SIGN_IN_SUCCESS, ({ Auth }, { response, headers }) => {
    return ({ Auth: { ...Auth, ...fetchingSuccess({ response, headers }) }});
  });
  store.on(types.SIGN_IN_ERROR, ({ Auth }) =>
    ({ Auth: { ...Auth, isLoading: false, error: true }}));
  store.on(types.SIGN_OUT_REQUEST, ({ Auth }) =>
    ({ Auth: { ...Auth, isLoading: true }}));
  store.on(types.SIGN_OUT_SUCCESS, ({ Auth }) => {
    return ({ Auth: { ...Auth, ...initialState }})
  });
  store.on(types.SIGN_OUT_ERROR, ({ Auth }) =>
    ({ Auth: { ...Auth, isLoading: false, error: true }}));
  store.on(types.VERIFY_TOKEN_REQUEST, ({ Auth }) =>
    ({ Auth: { ...Auth, isLoading: true }}));
  store.on(types.VERIFY_TOKEN_SUCCESS, ({ Auth }, { response }) =>
    ({ Auth: { ...Auth, ...tokenSuccess(response) }}));
  store.on(types.VERIFY_TOKEN_ERROR, ({ Auth }) =>
    ({ Auth: { ...initialState, isLoading: false, error: true }}));

  store.on(types.UPDATE_ACCESS_TOKEN, ({ Auth }, newToken) =>
    ({ Auth: { ...Auth, ...tokenUpdate(Auth, newToken) }}));

  store.on(types.FORGOT_PASSWORD_REQUEST, ({ forgotPassword }) =>
    ({ forgotPassword: { ...initialStateForgotPassword, isFetching: true }}));
  store.on(types.FORGOT_PASSWORD_SUCCESS, ({ forgotPassword }) =>
    ({ forgotPassword: {...forgotPassword, success: true, isFetching: false }}));
  store.on(types.FORGOT_PASSWORD_ERROR, ({ forgotPassword }) =>
    ({ forgotPassword: {...forgotPassword, error: true }}));

  store.on(types.CHANGE_PASSWORD_REQUEST, ({ changePassword }) =>
    ({ changePassword: { ...initialStateForgotPassword, isFetching: true }}));
  store.on(types.CHANGE_PASSWORD_SUCCESS, ({ changePassword, Auth }, { response }) =>
    ({
      changePassword: {...changePassword, success: true, isFetching: false },
      Auth: { ...Auth, ...initialState }
    }));
  store.on(types.CHANGE_PASSWORD_ERROR, ({ changePassword }) =>
    ({ changePassword: {...changePassword, error: true }}));
};
