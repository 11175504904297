import React from 'react';

const IconLeadCircle = ({
  width, style, height, className, fill
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 42.7 42.7"
      width={width}
      style={style}
      height={height}
      className={className}
    >
      <path fill={fill} d="M21.3,0.5C9.8,0.5,0.5,9.8,0.5,21.3c0,6.1,2.7,11.7,6.9,15.5c0,0,0,0,0,0.1c0,0,0.1,0.1,0.2,0.1
    		c3.7,3.2,8.5,5.2,13.7,5.2c11.5,0,20.8-9.3,20.8-20.8C42.1,9.8,32.8,0.5,21.3,0.5z M7.4,35.3c-2.7-2.7-4.6-6.1-5.4-10l2.3-2.1
    		l0.6,0.7c0,0,0,0,0,0s0,0,0,0l6.4,6.9c0,0,0,0,0,0s0,0,0,0l0.9,1l-4.3,4C7.7,35.6,7.6,35.4,7.4,35.3z M13.2,20.5l6,0.3c0,0,0,0,0,0
    		l1.6,0.1l0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.3c0,0.1,0,0.2-0.1,0.4c-0.2,0.5-0.6,0.9-1.1,1.2c-0.5,0.3-1,0.4-1.1,0.4l0,0l0,0l-3.5-0.1
    		c-0.3,0-0.5,0.2-0.6,0.5l0,0c0,0-0.1,0.6-0.5,1.2c-0.4,0.5-1,1.1-2.5,1.1l-0.2,0c-0.3,0-0.6,0.2-0.6,0.5c0,0.3,0.2,0.6,0.5,0.6
    		c0.1,0,0.2,0,0.3,0c0.9,0,1.6-0.2,2.2-0.5c0.3-0.2,0.6-0.4,0.8-0.6l2,2.1c-0.1,0.1-0.3,0.3-0.5,0.5c-0.4,0.4-1,0.7-1.7,0.7
    		c-0.4,0-0.9-0.1-1.4-0.5c-0.2-0.1-0.5-0.1-0.7,0.1l-0.8,0.7l-5.6-6.1l6.5-6l0,0c0,0,0.2-0.3,0.6-0.5c0.4-0.2,0.9-0.4,1.4-0.4
    		c0.1,0,0.3,0,0.4,0l0,0l1.3,0.2l-2.1,2.6l-1,0c-0.3,0-0.6,0.2-0.6,0.5C12.7,20.3,12.9,20.5,13.2,20.5z M28.3,8.8l8,8.7l-15,13.8
    		l-5.7-6.2c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.1-0.4,0.2-0.5l1.4,0l2.5,2.7c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3,0,0.4-0.1
    		c0.3-0.2,0.6-0.3,0.9-0.3c0.4,0,0.7,0.1,1,0.4c0.2,0.2,0.6,0.2,0.8,0l8.9-8.1c0.2-0.2,0.2-0.6,0-0.8c-0.2-0.3-0.3-0.6-0.3-0.9
    		c0-0.4,0.1-0.7,0.4-1c0.1-0.1,0.2-0.2,0.2-0.4c0-0.1,0-0.3-0.1-0.4l-2.4-2.6c-0.2-0.2-0.6-0.2-0.8,0c-0.3,0.2-0.6,0.3-0.9,0.3
    		c-0.4,0-0.7-0.1-1-0.4c-0.2-0.2-0.6-0.2-0.8,0l-7.4,6.8l-2.5-0.1L28.3,8.8z M17.7,17l7.1-8.7l1.3,1L17.7,17z M22.4,21.2
    		c0.1,0.3,0.3,0.5,0.5,0.7c0.5,0.6,1.3,0.9,2,0.9c0.6,0,1.3-0.2,1.8-0.7c0.6-0.5,0.9-1.3,0.9-2c0-0.6-0.2-1.3-0.7-1.8l-0.4,0.4
    		l0.4-0.4c-0.5-0.6-1.3-0.9-2-0.9c-0.6,0-1.3,0.2-1.8,0.7c-0.6,0.5-0.9,1.3-0.9,2c0,0.1,0,0.3,0,0.4c-0.2-0.3-0.5-0.5-0.7-0.6
    		c-0.2-0.1-0.4-0.1-0.4-0.1l-0.1,0l-0.4,0l6.2-5.7c0.4,0.3,0.9,0.4,1.4,0.4c0.4,0,0.8-0.1,1.2-0.3l1.7,1.8c-0.3,0.4-0.4,0.9-0.4,1.4
    		c0,0.4,0.1,0.8,0.3,1.2L22.8,26c-0.4-0.3-0.9-0.4-1.4-0.4c-0.4,0-0.8,0.1-1.2,0.3l-1.4-1.5l0,0v0l0.1,0c0.5,0,1.1-0.2,1.7-0.5
    		c0.6-0.4,1.2-0.9,1.6-1.8c0.1-0.3,0.2-0.6,0.2-0.8C22.4,21.2,22.4,21.2,22.4,21.2z M23.2,20.1c0-0.4,0.2-0.9,0.5-1.2
    		c0.3-0.3,0.7-0.4,1.1-0.4c0.4,0,0.9,0.2,1.2,0.5c0.3,0.3,0.4,0.7,0.4,1.1c0,0.4-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.4-1.1,0.4
    		c-0.4,0-0.9-0.2-1.2-0.5C23.4,20.8,23.2,20.4,23.2,20.1z M35.3,35.3c-3.6,3.6-8.5,5.8-13.9,5.8c-4.8,0-9.2-1.7-12.6-4.6l4.7-4.3
    		c0.1-0.1,0.2-0.2,0.2-0.4s0-0.3-0.1-0.4l-0.9-1l0.5-0.5c0.6,0.3,1.1,0.4,1.7,0.4c1.1,0,1.9-0.5,2.5-1c0.2-0.2,0.3-0.3,0.4-0.4
    		l3.2,3.5c0.2,0.2,0.6,0.2,0.8,0l15.8-14.5c0.1-0.1,0.2-0.2,0.2-0.4s0-0.3-0.1-0.4l-8.8-9.5c-0.2-0.2-0.6-0.2-0.8,0l-1,1l-1.8-1.5
    		c-0.2-0.2-0.6-0.2-0.8,0.1l-7.1,8.7l-1.9-0.3l0,0c-0.2,0-0.4-0.1-0.6-0.1c-0.8,0-1.5,0.3-2,0.6c-0.4,0.3-0.7,0.5-0.8,0.6l-6.5,5.9
    		L4.8,22c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.3,0-0.4,0.1l-2.2,2c-0.1-0.9-0.2-1.7-0.2-2.6c0-5.4,2.2-10.4,5.8-13.9
    		c3.6-3.6,8.5-5.8,13.9-5.8c5.4,0,10.4,2.2,13.9,5.8C38.8,11,41,15.9,41,21.3C41,26.8,38.8,31.7,35.3,35.3z"/>
    	<path fill={fill} d="M5.1,25.2l0.4-0.4c-0.3-0.3-0.7-0.5-1.1-0.5c-0.4,0-0.7,0.1-1,0.4C3,25,2.8,25.4,2.8,25.9c0,0.4,0.1,0.7,0.4,1
    		c0.3,0.3,0.7,0.5,1.1,0.5c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.5-0.7,0.5-1.1c0-0.4-0.1-0.7-0.4-1L5.1,25.2z M4.7,26.2
    		c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3-0.1C4,26.1,3.9,26,3.9,25.9c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1
    		c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3C4.8,26,4.8,26.1,4.7,26.2z"/>
    </svg>
 )
};

export default IconLeadCircle;
