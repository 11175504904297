import React from 'react';
import ControlledCollapse from 'src/components/elements/shared/ControlledCollapse';
import { useTranslation } from 'react-i18next';
import { HorizontalBar } from 'react-chartjs-2';
import randomId from 'src/helpers/randomId';

const colors = ["#182852", "#4297CB", "#8DB8C9", "#58595B", "#A7A9AC"];
const labels = ["Leads", "Viewings", "Listings", "Offers", "Transactions"];

export default ({ modules, items = [] }) => {
  const { t } = useTranslation();
  const test = colors.map((el, i) => {
    return ({
      label: labels[i],
      backgroundColor: el,
      data: items[i] || [],
      stack: 1
    })
  });

  const data = {
    labels: modules,
    datasets: test
  };

  const options = {
    scales: {
      yAxes: [{
        barThickness: 'flex',
        maxBarThickness: 20,
        gridLines: { display: false }
      }],
      xAxes: [{
        gridLines: { display: false }
      }]
    },
    legend: {
      display: false
     },
     tooltips: {
        enabled: true
     }
  };

  return (
    <ControlledCollapse isOpen
      label={`${t("dashboard.subFolders.myPipeline")}`}
    >
      <div>
        <HorizontalBar data={data} options={options}/>
        <h5 className="text-center text-uppercase mt-3 mb-5">NUMBER</h5>
        <div className="mt-3 px-7 pb-6 pt-0 border">
          <h5 className="text-center my-4 text-uppercase">MODULE</h5>
          <div className="d-flex justify-content-around my-2">
            {labels.map((label, i) =>
              <div className="d-flex" key={randomId()}>
                <div className="mr-2" style={{ height: '20px', width: '20px', backgroundColor: colors[i] }} />
                {label}
              </div>
            )}
          </div>
        </div>
      </div>

    </ControlledCollapse>
  )
};

// {legend[module].map(cell =>
//   <div className="col-3 d-flex" key={randomId()}>
//     <div className="mr-2" style={{ height: '20px', width: '20px', backgroundColor: cell.color }} />
//     {cell.label}
//   </div>
// )}
