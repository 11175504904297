import { FETCH_USER, FETCH_USER_SUCCESS, FETCH_USER_ERROR, FETCH_ALL_USERS, FETCH_ALL_USERS_SUCCESS, FETCH_ALL_USERS_ERROR } from 'src/constants/actionTypes/entities/user';
import { FETCH_ALL_COMPANIES, FETCH_ALL_COMPANIES_SUCCESS, FETCH_ALL_COMPANIES_ERROR } from 'src/constants/actionTypes/entities/company';
import { FETCH_CONTACT, FETCH_CONTACT_SUCCESS, FETCH_CONTACT_ERROR, FETCH_ALL_CONTACTS, FETCH_ALL_CONTACTS_SUCCESS, FETCH_ALL_CONTACTS_ERROR } from 'src/constants/actionTypes/entities/contact';
import { FETCH_ALL_LEADS, FETCH_ALL_LEADS_SUCCESS, FETCH_ALL_LEADS_ERROR } from 'src/constants/actionTypes/entities/lead';
import { FETCH_ALL_LISTINGS, FETCH_ALL_LISTINGS_SUCCESS, FETCH_ALL_LISTINGS_ERROR } from 'src/constants/actionTypes/entities/listing';
import { FETCH_ALL_OFFERS, FETCH_ALL_OFFERS_SUCCESS, FETCH_ALL_OFFERS_ERROR } from 'src/constants/actionTypes/entities/offer';
import { FETCH_ALL_PROJECTS, FETCH_ALL_PROJECTS_SUCCESS, FETCH_ALL_PROJECTS_ERROR } from 'src/constants/actionTypes/entities/project';
import { FETCH_ALL_PROPERTIES, FETCH_ALL_PROPERTIES_SUCCESS, FETCH_ALL_PROPERTIES_ERROR } from 'src/constants/actionTypes/entities/property';
import { FETCH_ALL_TRANSACTIONS, FETCH_ALL_TRANSACTIONS_SUCCESS, FETCH_ALL_TRANSACTIONS_ERROR } from 'src/constants/actionTypes/entities/transaction';
import { FETCH_USER_ATTRIBUTE, FETCH_USER_ATTRIBUTE_SUCCESS, FETCH_USER_ATTRIBUTE_ERROR } from 'src/constants/actionTypes/entities/user-attribute';
import { FetchEntityFunc, FetchRelatedEntityFunc, FetchRelatedEntitiesFunc, QueryParams } from 'src/-types/request';

export const fetchUser: FetchEntityFunc = ({ id, query }) => ({
  query,
  apiVersion: 'v2',
  endpoint: `users/${id}`,
  entityId: id,
  method: 'GET',
  credentials: true,
  types: [
    FETCH_USER,
    FETCH_USER_SUCCESS,
    FETCH_USER_ERROR
  ]
});

export const fetchAllUsers = ({ query }: { query?: QueryParams } = {}) => ({
  query,
  apiVersion: 'v2',
  endpoint: `users`,
  method: 'GET',
  credentials: true,
  types: [
    FETCH_ALL_USERS,
    FETCH_ALL_USERS_SUCCESS,
    FETCH_ALL_USERS_ERROR
  ]
});

export const fetchUserCompanies: FetchRelatedEntitiesFunc = ({ id, query }) => ({
  query,
  apiVersion: 'v2',
  endpoint: `users/${id}/companies`,
  entityId: id,
  method: 'GET',
  credentials: true,
  types: [
    FETCH_ALL_COMPANIES,
    FETCH_ALL_COMPANIES_SUCCESS,
    FETCH_ALL_COMPANIES_ERROR
  ]
});

export const fetchUserContacts: FetchRelatedEntitiesFunc = ({ id, query }) => ({
  query,
  apiVersion: 'v2',
  endpoint: `users/${id}/contacts`,
  entityId: id,
  method: 'GET',
  credentials: true,
  types: [
    FETCH_ALL_CONTACTS,
    FETCH_ALL_CONTACTS_SUCCESS,
    FETCH_ALL_CONTACTS_ERROR
  ]
});

export const fetchUserLeads: FetchRelatedEntitiesFunc = ({ id, query }) => ({
  query,
  apiVersion: 'v2',
  endpoint: `users/${id}/leads`,
  entityId: id,
  method: 'GET',
  credentials: true,
  types: [
    FETCH_ALL_LEADS,
    FETCH_ALL_LEADS_SUCCESS,
    FETCH_ALL_LEADS_ERROR
  ]
});

export const fetchUserListings: FetchRelatedEntitiesFunc = ({ id, query }) => ({
  query,
  apiVersion: 'v2',
  endpoint: `users/${id}/listings`,
  entityId: id,
  method: 'GET',
  credentials: true,
  types: [
    FETCH_ALL_LISTINGS,
    FETCH_ALL_LISTINGS_SUCCESS,
    FETCH_ALL_LISTINGS_ERROR
  ]
});

export const fetchUserOffers: FetchRelatedEntitiesFunc = ({ id, query }) => ({
  query,
  apiVersion: 'v2',
  endpoint: `users/${id}/offers`,
  entityId: id,
  method: 'GET',
  credentials: true,
  types: [
    FETCH_ALL_OFFERS,
    FETCH_ALL_OFFERS_SUCCESS,
    FETCH_ALL_OFFERS_ERROR
  ]
});

export const fetchUserProjects: FetchRelatedEntitiesFunc = ({ id, query }) => ({
  query,
  apiVersion: 'v2',
  endpoint: `users/${id}/projects`,
  entityId: id,
  method: 'GET',
  credentials: true,
  types: [
    FETCH_ALL_PROJECTS,
    FETCH_ALL_PROJECTS_SUCCESS,
    FETCH_ALL_PROJECTS_ERROR
  ]
});

export const fetchUserProperties: FetchRelatedEntitiesFunc = ({ id, query }) => ({
  query,
  apiVersion: 'v2',
  endpoint: `users/${id}/properties`,
  entityId: id,
  method: 'GET',
  credentials: true,
  types: [
    FETCH_ALL_PROPERTIES,
    FETCH_ALL_PROPERTIES_SUCCESS,
    FETCH_ALL_PROPERTIES_ERROR
  ]
});

export const fetchUserTransactions: FetchRelatedEntitiesFunc = ({ id, query }) => ({
  query,
  apiVersion: 'v2',
  endpoint: `users/${id}/transactions`,
  entityId: id,
  method: 'GET',
  credentials: true,
  types: [
    FETCH_ALL_TRANSACTIONS,
    FETCH_ALL_TRANSACTIONS_SUCCESS,
    FETCH_ALL_TRANSACTIONS_ERROR
  ]
});

export const fetchUserContact: FetchRelatedEntityFunc = ({ id, query }) => ({
  query,
  apiVersion: 'v2',
  endpoint: `users/${id}/contact`,
  entityId: id,
  method: 'GET',
  credentials: true,
  types: [
    FETCH_CONTACT,
    FETCH_CONTACT_SUCCESS,
    FETCH_CONTACT_ERROR
  ]
});

export const fetchUserAttributes: FetchRelatedEntityFunc = ({ id, query }) => ({
  query,
  apiVersion: 'v2',
  endpoint: `users/${id}/attribute`,
  entityId: id,
  method: 'GET',
  credentials: true,
  types: [
    FETCH_USER_ATTRIBUTE,
    FETCH_USER_ATTRIBUTE_SUCCESS,
    FETCH_USER_ATTRIBUTE_ERROR
  ]
});
