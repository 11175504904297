import { leadsPath } from 'src/helpers/routes';
import { lazy } from 'react';
import fetchDataTable from 'src/actions/DataTable';
import { API_CALL } from 'src/middleware/API';
import dataTableParams, { defaultFilter } from 'src/datatables/leads';
import { fetchLeadCollections } from 'src/actions/Leads';

const Leads = lazy(() => import('src/components/views/leads'));

export default {
  path: leadsPath(),
  exact: true,
  strict: true,
  component: Leads,
  perform: "leads:index",
  title: "List of Leads",
  protected: true,
  prepareData: ({ store }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    if (Object.keys(state.LeadCollections.items).length ===  0) {
      store.dispatch(API_CALL, fetchLeadCollections({ accessToken }));
    }
    const { role } = state.Auth.user;
    const { items = [] } = state.RecentlyViewed;
    const endpoint = "leads";
    const ids = (items[endpoint] || []).map(i => i.id) || [];
    const { query } = dataTableParams({ role, endpoint, ids });
    query.filter = Object.values(defaultFilter({ role, ids }));
    store.dispatch(API_CALL, fetchDataTable({ accessToken, query, endpoint }));
  }
};
