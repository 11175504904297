import * as types from 'src/constants/actionTypes/CompaniesActionTypes';
import initialState from 'src/helpers/initialStateSubmit';
import { SUBMIT_RECENTLY_VIEWED } from 'src/constants/actionTypes/RecentlyViewedActionTypes';

export default store => {
  store.on('@init', () => ({ SubmitCompany: initialState }));
  store.on(types.SUBMIT_COMPANY_RESET, () => ({ SubmitCompany: initialState }));
  store.on(types.SUBMIT_COMPANY_REQUEST, ({ SubmitCompany }) =>
    ({ SubmitCompany: { ...initialState, sendRequest: true }}));
  store.on(types.SUBMIT_COMPANY_SUCCESS, ({ SubmitCompany }, { response }) => {
    store.dispatch(SUBMIT_RECENTLY_VIEWED, {
      data: { id: response.data.id, name: response.data.name, time: Date.now()},
      endpoint: 'companies'
    });
    return ({ SubmitCompany: { ...SubmitCompany, success: true, sendRequest: false }});
  });
  store.on(types.SUBMIT_COMPANY_ERROR, ({ SubmitCompany }, { response }) => {
    let errors = {};
    if (response && response.body && response.body.errors) errors = response.body.errors;
    return ({ SubmitCompany: {...SubmitCompany, error: true, errors: errors, sendRequest: false }});
  });
};
