import { projectNewPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchProjectCollections } from 'src/actions/Projects';
import { API_CALL } from 'src/middleware/API';

const ProjectNew = lazy(() => import('src/components/views/projects/New'));

export default {
  path: projectNewPath(),
  exact: true,
  strict: true,
  component: ProjectNew,
  perform: 'projects:new',
  protected: true,
  prepareData: ({ store }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    if (Object.keys(state.ProjectCollections.items).length > 0) return;
    const accessToken = state.Auth.token;
    store.dispatch(API_CALL, fetchProjectCollections({ accessToken }));
  }
};
