import * as types from 'src/constants/actionTypes/TransactionsActionTypes';
import { SUBMIT_RECENTLY_VIEWED } from 'src/constants/actionTypes/RecentlyViewedActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  transaction: {}
}

export default store => {
  store.on('@init', () => ({ Transaction: initialState }));

  store.on(types.FETCH_TRANSACTION_REQUEST, ({ Transaction }) =>
    ({ Transaction: { ...initialState, isFetching: true }}));
  store.on(types.FETCH_TRANSACTION_SUCCESS, ({ Transaction }, { response }) => {
    store.dispatch(SUBMIT_RECENTLY_VIEWED, {
      data: { id: response.data.id, name: response.data.name, time: Date.now()},
      endpoint: 'transactions'
    });
    return ({ Transaction: { ...Transaction, transaction: response.data }})
  });
  store.on(types.FETCH_TRANSACTION_ERROR, ({ Transaction }) =>
    ({ Transaction: {...Transaction, error: true }}));
}
