import { verifyToken } from 'src/actions/Auth';
import { API_CALL } from 'src/middleware/API';
import { resetPasswordPath } from 'src/helpers/routes';

export default store => {
  store.on('@init', (state) => {
    if (
      state.Auth.token['access-token'] &&
        resetPasswordPath() !== window.location.pathname
    ) {
      store.dispatch(API_CALL, verifyToken({ accessToken: state.Auth.token }));
    }
  });
};
