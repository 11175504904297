import * as types from 'src/constants/actionTypes/OffersActionTypes';

const initialState = {
  isDestroing: false,
  error: false,
  successDestroy: false,
}

export default store => {
  store.on('@init', () => ({ OfferDestroy: initialState }));

  store.on(types.DELETE_OFFERS_REQUEST, ({ OfferDestroy }) =>
    ({ OfferDestroy: { ...OfferDestroy, isDestroing: true, successDestroy: false }}));
  store.on(types.DELETE_OFFERS_SUCCESS, ({ OfferDestroy }) =>
    ({ OfferDestroy: {...OfferDestroy, successDestroy: true }}));
  store.on(types.DELETE_OFFERS_ERROR, ({ OfferDestroy }) =>
    ({ OfferDestroy: {...OfferDestroy, error: true }}));

  store.on(types.DELETE_OFFER_REQUEST, ({ OfferDestroy }) =>
    ({ OfferDestroy: { ...OfferDestroy, isDestroing: true, successDestroy: false }}));
  store.on(types.DELETE_OFFER_SUCCESS, ({ OfferDestroy }) =>
    ({ OfferDestroy: {...OfferDestroy, successDestroy: true }}));
  store.on(types.DELETE_OFFER_ERROR, ({ OfferDestroy }) =>
    ({ OfferDestroy: {...OfferDestroy, error: true }}));
}
