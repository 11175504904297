import * as types from 'src/constants/actionTypes/UsersActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  items: {
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
    items: []
  }
}

export default store => {
  store.on('@init', () => ({ Users: initialState }));

  store.on(types.FETCH_USERS_REQUEST, ({ Users }) =>
    ({ Users: { ...Users, isFetching: true }}));
  store.on(types.FETCH_USERS_SUCCESS, ({ Users }, { response }) =>
    ({ Users: { ...Users, items: response.data }}));
  store.on(types.FETCH_USERS_ERROR, ({ Users }) =>
    ({ Users: {...Users, error: true }}));
}
