import * as types from 'src/constants/actionTypes/ProjectsActionTypes';

const fetchProjects = ({ accessToken, query = {} }) => {
  return ({
    query,
    endpoint: 'projects',
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_PROJECTS_REQUEST,
      types.FETCH_PROJECTS_SUCCESS,
      types.FETCH_PROJECTS_ERROR
    ]
  })
}

const fetchProject = ({ accessToken, id, query = {} }) => {
  return ({
    query,
    endpoint: `projects/${id}`,
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_PROJECT_REQUEST,
      types.FETCH_PROJECT_SUCCESS,
      types.FETCH_PROJECT_ERROR
    ]
  })
};

const updateProject = ({ accessToken, query, id }) => {
  return ({
    query,
    endpoint: `projects/${id}`,
    method: 'PUT',
    credentials: true,
		accessToken,
    types: [
      types.SUBMIT_PROJECT_REQUEST,
      types.SUBMIT_PROJECT_SUCCESS,
      types.SUBMIT_PROJECT_ERROR
    ]
  })
};

const deleteProjects = ({ accessToken, query }) => {
  return ({
    query,
    endpoint: `projects`,
    method: 'DELETE',
    credentials: true,
		accessToken,
    types: [
      types.DELETE_PROJECTS_REQUEST,
      types.DELETE_PROJECTS_SUCCESS,
      types.DELETE_PROJECTS_ERROR
    ]
  })
};

const deleteProject = ({ accessToken, id }) => {
  return ({
    endpoint: `projects/${id}`,
    method: 'DELETE',
    credentials: true,
		accessToken,
    types: [
      types.DELETE_PROJECT_REQUEST,
      types.DELETE_PROJECT_SUCCESS,
      types.DELETE_PROJECT_ERROR
    ]
  })
};

const createProject = ({ accessToken, query }) => {
  return ({
    query: query,
    endpoint: 'projects',
    method: 'POST',
    credentials: true,
		accessToken,
    types: [
      types.SUBMIT_PROJECT_REQUEST,
      types.SUBMIT_PROJECT_SUCCESS,
      types.SUBMIT_PROJECT_ERROR
    ]
  })
};

const fetchProjectCollections = ({ accessToken }) => {
  return ({
    endpoint: 'projects/helper',
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_PROJECT_COLLECTION_REQUEST,
      types.FETCH_PROJECT_COLLECTION_SUCCESS,
      types.FETCH_PROJECT_COLLECTION_ERROR
    ]
  })
}

export {
  fetchProjects, fetchProject, fetchProjectCollections, createProject, deleteProjects,
  deleteProject, updateProject
};
