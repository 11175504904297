import * as types from 'src/constants/actionTypes/UsersActionTypes';
import initialState from 'src/helpers/initialStateSubmit';
import { SUBMIT_RECENTLY_VIEWED } from 'src/constants/actionTypes/RecentlyViewedActionTypes';
import { isNil } from 'lodash';

export default store => {
  store.on('@init', () => ({ SubmitUser: initialState }));

  store.on(types.SUBMIT_USER_RESET, () => ({ SubmitUser: initialState }));

  store.on(types.SUBMIT_USER_REQUEST, ({ SubmitUser }) =>
    ({ SubmitUser: { ...initialState, sendRequest: true }}));
  store.on(types.SUBMIT_USER_SUCCESS, ({ SubmitUser }, { response }) => {
    if (!isNil(response.data)) {
      store.dispatch(SUBMIT_RECENTLY_VIEWED, {
        data: { id: response.data.id, name: response.data.name, time: Date.now()},
        endpoint: 'users'
      });
    }

    return ({ SubmitUser: { ...SubmitUser, success: true, sendRequest: false } });
  });
  store.on(types.SUBMIT_USER_ERROR, ({ SubmitUser }, { response }) => {
    let errors = {};
    if (response && response.body && response.body.errors) errors = response.body.errors;
    return ({ SubmitUser: {...SubmitUser, error: true, errors: errors, sendRequest: false }});
  });
  store.on(types.REMOVE_USER_OWNED_COMPANY_BY_IDS, ({ UserOwned }, { ids }) => {
    const { items, items: { ownedCompanies } } = UserOwned;
    const filtered = ownedCompanies.filter(el => !ids.includes(el.id));
    return ({ UserOwned: {...UserOwned, items: { ...items, ownedCompanies: filtered } } });
  });
  store.on(types.REMOVE_USER_OWNED_CONTACT_BY_IDS, ({ UserOwned }, { ids }) => {
    const { items, items: { ownedContacts } } = UserOwned;
    const filtered = ownedContacts.filter(el => !ids.includes(el.id));
    return ({ UserOwned: {...UserOwned, items: { ...items, ownedContacts: filtered } } });
  });

  store.on(types.REMOVE_USER_OWNED_LEAD_BY_IDS, ({ UserOwned }, { ids }) => {
    const { items, items: { ownedLeads } } = UserOwned;
    const filtered = ownedLeads.filter(el => !ids.includes(el.id));
    return ({ UserOwned: {...UserOwned, items: { ...items, ownedLeads: filtered } } });
  });

  store.on(types.REMOVE_USER_OWNED_LISTING_BY_IDS, ({ UserOwned }, { ids }) => {
    const { items, items: { ownedListings } } = UserOwned;
    const filtered = ownedListings.filter(el => !ids.includes(el.id));
    return ({ UserOwned: {...UserOwned, items: { ...items, ownedListings: filtered } } });
  });

  store.on(types.REMOVE_USER_OWNED_PROPERTY_BY_IDS, ({ UserOwned }, { ids }) => {
    const { items, items: { ownedProperties } } = UserOwned;
    const filtered = ownedProperties.filter(el => !ids.includes(el.id));
    return ({ UserOwned: {...UserOwned, items: { ...items, ownedProperties: filtered } } });
  });

  store.on(types.REMOVE_USER_OWNED_PROJECT_BY_IDS, ({ UserOwned }, { ids }) => {
    const { items, items: { ownedProjects } } = UserOwned;
    const filtered = ownedProjects.filter(el => !ids.includes(el.id));
    return ({ UserOwned: {...UserOwned, items: { ...items, ownedProjects: filtered } } });
  });

  store.on(types.REMOVE_USER_OWNED_OFFER_BY_IDS, ({ UserOwned }, { ids }) => {
    const { items, items: { ownedOffers } } = UserOwned;
    const filtered = ownedOffers.filter(el => !ids.includes(el.id));
    return ({ UserOwned: {...UserOwned, items: { ...items, ownedOffers: filtered } } });
  });

  store.on(types.REMOVE_USER_OWNED_TRANSACTION_BY_IDS, ({ UserOwned }, { ids }) => {
    const { items, items: { ownedTransactions } } = UserOwned;
    const filtered = ownedTransactions.filter(el => !ids.includes(el.id));
    return ({ UserOwned: {...UserOwned, items: { ...items, ownedTransactions: filtered } } });
  });

  store.on(types.DEACTIVATE_USER, ({ User }) => {
    const { item } = User;
    return ({ User: {...User, item: { ...item, active: false } } });
  });
}
