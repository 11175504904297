export const rootPath = () => '/';
export const loginPath = () => '/login';
export const confirmPath = () => '/confirm';
export const forgotPasswordPath = () => '/forgot-password';
export const resetPasswordPath = () => '/reset-password';

export const restrictedPagePath = () => '/restricted-page';
export const notFoundPath = () => '/not-found';

export const profileEditPath = (id = ':id') => `/profile/edit/${id}`;
export const profilePath = () => `/profile`;

export const companiesPath = () => `/companies`;
export const companyPath = (id = ':id') => `/companies/${id}`;
export const companyNewPath = () => `/companies/new`;
export const companyEditPath = (id = ':id') => `/companies/${id}/edit`;
export const companyContactNewPath = (id = ':companyId') => `/companies/${id}/contact/new`;
export const companyContactNewReferredPath = (id = ':referredCompanyId') => `/companies/${id}/referred-contact/new`;

export const usersPath = () => `/admin/users`;
export const userPath = (id = ':id') => `/admin/users/${id}`;
export const userNewPath = () => `/admin/users/new`;
export const userEditPath = (id = ':id') => `/admin/users/${id}/edit`;

export const adminCompanyDetails = () => `/admin/company/`;
export const adminCompanyDetailsEdit = () => `/admin/company/edit`;

export const adminSystemSetting = () => `/admin/settings/`;
export const adminSystemSettingEdit = () => `/admin/settings/edit`;

export const contactsPath = () => `/contacts`;
export const contactPath = (id = ':id') => `/contacts/${id}`;
export const contactNewPath = () => `/contacts/new`;
export const contactEditPath = (id = ':id') => `/contacts/${id}/edit`;
export const contactLeadNewPath = (id = ':contactId') => `/contacts/${id}/lead/new`;
export const contactPropertyOwnerNewPath = (id = ':propertyOwnerId') => `/contacts/${id}/property-owner/new`;
export const contactPropertyCoOwnerNewPath = (id = ':propertyCoOwnerId') => `/contacts/${id}/property-co-owner/new`;
export const contactListNewPath = (id = ':contactId') => `/contacts/${id}/listing/new`;
export const contactListingNewPath = (id = ':contactId', propertyId = ':propertyId') => `/contacts/${id}/${propertyId}/listing/new`;

export const teamsPath = () => `/admin/teams`;
export const teamPath = (id = ':id') => `/admin/teams/${id}`;
export const teamNewPath = () => `/admin/teams/new`;
export const teamEditPath = (id = ':id') => `/admin/teams/${id}/edit`;

export const leadsPath = () => `/leads`;
export const leadPath = (id = ':id') => `/leads/${id}`;
export const leadNewPath = () => `/leads/new`;
export const leadEditPath = (id = ':id') => `/leads/${id}/edit`;
export const leadOfferNewPath = (id = ':leadId') => `/leads/${id}/offer/new`;

export const activitiesPath = () => `/activity`;
export const activitiesMonthPath = () => `/activity/month`;
export const activitiesNewPath = () => `/activity/new`;
export const activitiesEditPath = (id = ':id') => `/activity/${id}/edit`;

export const projectsPath = () => `/projects`;
export const projectPath = (id = ':id') => `/projects/${id}`;
export const projectNewPath = () => `/projects/new`;
export const projectEditPath = (id = ':id') => `/projects/${id}/edit`;
export const projectPropertyNewPath = (id = ':projectId') => `/projects/${id}/property/new`;

export const propertiesPath = () => `/properties`;
export const propertyPath = (id = ':id') => `/properties/${id}`;
export const propertyNewPath = () => `/properties/new`;
export const propertyEditPath = (id = ':id') => `/properties/${id}/edit`;
export const propertyListingNewPath = (id = ':propertyId') => `/property/${id}/listing/new`;
export const propertyClonePath = (id = ':id') => `/properties/${id}/clone`;

export const listingsPath = () => `/listings`;
export const listingPath = (id = ':id') => `/listings/${id}`;
export const listingNewPath = () => `/listings/new`;
export const listingClonePath = (id = ':id') => `/listings/${id}/clone`;
export const listingEditPath = (id = ':id') => `/listings/${id}/edit`;
export const listingLeadNewPath = (id = ':listingId') => `/listings/${id}/lead/new`;
export const listingOfferNewPath = (id = ':listingId') => `/listings/${id}/offer/new`;
export const listingTransactionNewPath = (id = ':listingId', offerId = ':offerId') => `/listings/${id}/${offerId}/transaction/new`;

export const offersPath = () => `/offers`;
export const offerPath = (id = ':id') => `/offers/${id}`;
export const offerNewPath = () => `/offers/new`;
export const offerEditPath = (id = ':id') => `/offers/${id}/edit`;
export const offerCounterOfferPath = (id = ':id') => `/offers/${id}/counter-offer`;
export const offerNewTransactionPath = (id = ':offerId') => `/offers/${id}/transaction/new`;

export const transactionsPath = () => `/transactions`;
export const transactionPath = (id = ':id') => `/transactions/${id}`;
export const transactionEditPath = (id = ':id') => `/transactions/${id}/edit`;
export const transactionNewInternalBrokerPath = (id = ':transactionId') => `/transactions/${id}/internal-broker/new`;
export const transactionNewPaymentPath = (id = ':transactionId') => `/transactions/${id}/payment/new`;

export const internalBrokersPath = () => `/internal-brokers`;
export const internalBrokerPath = (id = ':id') => `/internal-brokers/${id}`;
export const internalBrokerEditPath = (id = ':id') => `/internal-brokers/${id}/edit`;

export const paymentsPath = () => `/payments`;
export const paymentPath = (id = ':id') => `/payments/${id}`;
export const paymentEditPath = (id = ':id') => `/payments/${id}/edit`;

export const reportsPath = () => '/reports';

export const snagsPath = () => `/snags`;
export const propertySnagsPath = () => `${snagsPath()}?mustShowProject=false`;
export const projectSnagsPath = () => `${snagsPath()}?mustShowProject=true`;
export const snagPath = (id = ':id') => `${snagsPath()}/${id}`;
export const snagNewPath = () => `${snagsPath()}/new`;
export const projectSnagNewPath = (id = ':projectId') => `/project/${id}/snag/new`;
export const propertySnagNewPath = (id = ':propertyId') => `/property/${id}/snag/new`;
export const snagEditPath = (id = ':id') => `${snagPath(id)}/edit`;
export const snagClonePath = (id = ':snagId') => `${snagPath(id)}/clone`;

export const performancesPath = () => '/performances';
