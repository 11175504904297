import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const VerticalTextArea = ({ label, required = false, name, onChange, errors, value, inputName = undefined }) => {
  const { t } = useTranslation();
  const errorMessage = useMemo(() => errors?.join(', ') ?? t('form.provideValid', { label }), [errors, label, t]);
  return (
    <div className="form-group">
      <label className="form-label">{label}</label>
      <textarea name={name} className="form-control" onChange={onChange} required={required} value={value ?? ''} data-input-name={inputName} />
      <div className='invalid-feedback'>{errorMessage}</div>
    </div>
  );
};

VerticalTextArea.propTypes = {
  inputName: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default VerticalTextArea;
