import { transactionPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchTransaction, fetchTransactionCollections } from 'src/actions/Transactions';
import { API_CALL } from 'src/middleware/API';
import { fetchActivityCollections } from 'src/actions/Activities';
import Layout from 'src/components/views/showLayout';

const TransactionShow = lazy(() => import('src/components/views/transactions/Show'));

export default {
  path: transactionPath(),
  exact: true,
  strict: true,
  component: TransactionShow,
  perform: "transactions:show",
  layout: Layout,
  protected: true,
  prepareData: ({ store, query, params }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    if (Object.keys(state.ActivityCollections.items).length ===  0) {
      store.dispatch(API_CALL, fetchActivityCollections({ accessToken }));
    }
    if (Object.keys(state.TransactionCollections.items).length ===  0) {
      store.dispatch(API_CALL, fetchTransactionCollections({ accessToken }));
    }
    store.dispatch(API_CALL, fetchTransaction({ accessToken, id: params.id }));
  }
};
