import { CompanyEnums } from 'src/-types/models/company';
import { ModelEnumState, BaseEnumCollection } from 'src/-types/enums';

export type CompanyEnumState = ModelEnumState<CompanyEnums>;

export class CompanyEnumCollection extends BaseEnumCollection<CompanyEnumState> {
  protected defaultData: CompanyEnums = {
    industry: []
  };

  public constructor(state: CompanyEnumState) {
    super(state);
  }
}
