import * as types from 'src/constants/actionTypes/PropertiesActionTypes';

const initialState = {
  isDestroing: false,
  error: false,
  successDestroy: false,
}

export default store => {
  store.on('@init', () => ({ PropertyDestroy: initialState }));

  store.on(types.DELETE_PROPERTIES_REQUEST, ({ PropertyDestroy }) =>
    ({ PropertyDestroy: { ...PropertyDestroy, isDestroing: true, successDestroy: false }}));
  store.on(types.DELETE_PROPERTIES_SUCCESS, ({ PropertyDestroy }) =>
    ({ PropertyDestroy: {...PropertyDestroy, successDestroy: true }}));
  store.on(types.DELETE_PROPERTIES_ERROR, ({ PropertyDestroy }) =>
    ({ PropertyDestroy: {...PropertyDestroy, error: true }}));

  store.on(types.DELETE_PROPERTY_REQUEST, ({ PropertyDestroy }) =>
    ({ PropertyDestroy: { ...PropertyDestroy, isDestroing: true, successDestroy: false }}));
  store.on(types.DELETE_PROPERTY_SUCCESS, ({ PropertyDestroy }) =>
    ({ PropertyDestroy: {...PropertyDestroy, successDestroy: true }}));
  store.on(types.DELETE_PROPERTY_ERROR, ({ PropertyDestroy }) =>
    ({ PropertyDestroy: {...PropertyDestroy, error: true }}));
}
