import * as types from 'src/constants/actionTypes/PaymentsActionTypes';
import initialState from 'src/helpers/initialStateSubmit';
import { SUBMIT_RECENTLY_VIEWED } from 'src/constants/actionTypes/RecentlyViewedActionTypes';

export default store => {
  store.on('@init', () => ({ SubmitPayment: initialState }));

  store.on(types.SUBMIT_PAYMENT_RESET, () => ({ SubmitPayment: initialState }));

  store.on(types.SUBMIT_PAYMENT_REQUEST, ({ SubmitPayment }) =>
    ({ SubmitPayment: { ...initialState, sendRequest: true }}));
  store.on(types.SUBMIT_PAYMENT_SUCCESS, ({ SubmitPayment, Payment }, { response }) => {
    store.dispatch(SUBMIT_RECENTLY_VIEWED, {
      data: { id: response.data.id, name: response.data.name, time: Date.now()},
      endpoint: 'payments'
    });
    return ({
      Payment: { ...Payment, offer: response.data },
      SubmitPayment: { ...SubmitPayment, success: true, sendRequest: false }
    });
  });
  store.on(types.SUBMIT_PAYMENT_ERROR, ({ SubmitPayment }, { response }) => {
    let errors = {};
    if (response && response.body && response.body.errors) errors = response.body.errors;
    return ({ SubmitPayment: {...SubmitPayment, error: true, errors: errors, sendRequest: false }});
  });
}
