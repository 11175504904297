import * as types from 'src/constants/actionTypes/ReportActionTypes';

const fetchReport = ({ accessToken, query, endpoint, apiVersion = 'v1' }) => {
  return ({
    query,
    apiVersion,
    endpoint: `reports/${endpoint}`,
    method: 'GET',
    credentials: true,
    accessToken,
    types: [
      types.FETCH_REPORT_REQUEST,
      types.FETCH_REPORT_SUCCESS,
      types.FETCH_REPORT_ERROR
    ]
  });
};

export default fetchReport;
