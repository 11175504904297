import * as types from 'src/constants/actionTypes/ProjectsActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  items: {},
  loaded: false
};

export default store => {
  store.on('@init', () => ({ ProjectCollections: initialState }));

  store.on(types.FETCH_PROJECT_COLLECTION_REQUEST, ({ ProjectCollections }) =>
    ({ ProjectCollections: { ...ProjectCollections, isFetching: true, loaded: false }}));
  store.on(types.FETCH_PROJECT_COLLECTION_SUCCESS, ({ ProjectCollections }, { response }) =>
    ({ ProjectCollections: { ...ProjectCollections, items: response.data, loaded: true }}));
  store.on(types.FETCH_PROJECT_COLLECTION_ERROR, ({ ProjectCollections }) =>
    ({ ProjectCollections: {...ProjectCollections, error: true }}));
};
