import filterByRole, { optionsByRole } from './filterByRole';
import filterByView from './filterByView';
import filterByList from './filterByList';
import { hasArray } from 'src/helpers/array';

const ENDPOINT = 'properties';

const DEFAULT_VIEW = [
  { data: 'id', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  { data: 'title', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link show-link', path: 'propertyPath', pathKey: 'id' },
  { data: 'project', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link project-link', path: 'projectPath', pathKey: 'projectId' },
  { data: 'propertyType', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'bedrooms', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'grossInternalArea', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'propertyOwner', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link property-owner-link', path: 'contactPath', pathKey: 'propertyOwnerId' },
  { data: 'propertyUse', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'lastPrice', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'projectId', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  { data: 'propertyOwnerId', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true }
];

const fieldsByView = (view) => {
  const fields = {
    'recentlyViewed': DEFAULT_VIEW,
    'properties': DEFAULT_VIEW
  };
  return fields[view];
};

export const optionsForFilterByView = {
  'properties': {}
};

export const defaultFilter = ({ role, ids }) => {
  const filter = {};
  const byRole = optionsByRole({ role, endpoint: ENDPOINT });

  if (hasArray(byRole)) {
    filter.role = { value: byRole[0], type: 'role' };
  }

  if (hasArray(ids)) {
    filter.view = { name: 'id', type: 'eq', value: ids };
  }

  return filter;
};

export const columnFilters = [
  { filter: filterByList, dataField: 'projectId', label: 'Project', source: 'PropertyCollections.items.project' }
];

export const outsideFilters = [
  { component: filterByView, endpoint: ENDPOINT, options: optionsForFilterByView },
  { component: filterByRole }
];

export default ({ view = 'properties', items = [] }) => {
  const columns = fieldsByView(view);
  const query = {
    columns,
    order: [{ column: 1, dir: 'asc' }],
    start: 0,
    length: 10,
    endpoint: ENDPOINT
  };
  return { query, endpoint: ENDPOINT, columns };
}
