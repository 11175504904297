import {
  FETCH_LOOKUPS_COUNTRIES_REQUEST, FETCH_LOOKUPS_COUNTRIES_SUCCESS, FETCH_LOOKUPS_COUNTRIES_ERROR,
  FETCH_LOOKUPS_FEATURES_REQUEST, FETCH_LOOKUPS_FEATURES_SUCCESS, FETCH_LOOKUPS_FEATURES_ERROR,
  FETCH_LOOKUPS_FIELDS_REQUEST, FETCH_LOOKUPS_FIELDS_SUCCESS, FETCH_LOOKUPS_FIELDS_ERROR
} from 'src/constants/actionTypes/lookups-action-types';

export function fetchCountriesLookup() {
  return ({
    endpoint: 'lookups/countries',
    method: 'GET',
    credentials: false,
    types: [FETCH_LOOKUPS_COUNTRIES_REQUEST, FETCH_LOOKUPS_COUNTRIES_SUCCESS, FETCH_LOOKUPS_COUNTRIES_ERROR]
  });
}

export function fetchFeatureLookup() {
  return ({
    endpoint: 'lookups/features',
    method: 'GET',
    credentials: false,
    types: [FETCH_LOOKUPS_FEATURES_REQUEST, FETCH_LOOKUPS_FEATURES_SUCCESS, FETCH_LOOKUPS_FEATURES_ERROR]
  });
}

export function fetchFieldsLookup() {
  return ({
    endpoint: 'lookups/fields',
    method: 'GET',
    credentials: true,
    types: [FETCH_LOOKUPS_FIELDS_REQUEST, FETCH_LOOKUPS_FIELDS_SUCCESS, FETCH_LOOKUPS_FIELDS_ERROR]
  });
}
