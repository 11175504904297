import * as types from 'src/constants/actionTypes/TeamsActionTypes';
import initialState from 'src/helpers/initialStateSubmit';

export default store => {
  store.on('@init', () => ({ SubmitTeam: initialState }));

  store.on(types.SUBMIT_TEAM_RESET, () => ({ SubmitTeam: initialState }));

  store.on(types.SUBMIT_TEAM_REQUEST, ({ SubmitTeam }) =>
    ({ SubmitTeam: { ...initialState, sendRequest: true }}));
  store.on(types.SUBMIT_TEAM_SUCCESS, ({ SubmitTeam }, { response }) => {
    return ({ SubmitTeam: { ...SubmitTeam, success: true, sendRequest: false }});
  });
  store.on(types.SUBMIT_TEAM_ERROR, ({ SubmitTeam }, { response }) => {
    let errors = {};
    if (response && response.body && response.body.errors) errors = response.body.errors;
    return ({ SubmitTeam: {...SubmitTeam, error: true, errors: errors, sendRequest: false }});
  });
}
