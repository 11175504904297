import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import _, { cloneDeep, map, take, slice, sumBy, reduce, lowerCase } from 'lodash';
import { ChartOptions, ChartData, ChartDataSets } from 'chart.js';
import { useTranslation } from 'react-i18next';
import { ReportV2Response } from 'src/-types/report';
import { ReportLegend, LegendEntryData } from 'src/components/elements/Report/Legend';
import { hasString } from 'src/helpers/string';
import { isNull, isUndefined } from 'util';
import { useCountriesLookup } from 'src/hooks/lookups';
import { logger } from 'src/-utils/logger';
import { fetchCountriesLookup } from 'src/actions/lookups';
import { fetchContactEnums } from 'src/actions/enums/contact';
import { useAsyncDispatch } from 'src/hooks/store';
import { useContactEnums, useEnumDropdown } from 'src/hooks/enums';
import { LoadingSpinner } from 'src/components/elements/LoadingSpinner/LoadingSpinner';
import { ContactEnums } from 'src/-types/models/contact';
import VerticalSelectSearch from 'src/components/elements/form/VerticalSelectSearch';

type CountryMap = Map<string, string>;

const ORDER_COLOURS: string[] = ['#162953', '#4196CC', '#8BB9C9', '#565A5B'];

const getCountryCodeLabel = (t: any, countryMap: CountryMap, countryCode?: string | null): string => {
  let label: string;

  if (isUndefined(countryCode)) {
    label = t('reports.contact.typeCountryCode.other');
  } else if (isNull(countryCode)) {
    label = t('reports.contact.typeCountryCode.unknown');
  } else {
    label = countryMap.get(lowerCase(countryCode)) ?? t('reports.contact.typeCountryCode.unknown');
  }

  return label;
};

const getChartData = (normalizedData: NormalizedData, countryMap: CountryMap, t: any) => {
  const labels: string[] = [];
  const data: number[] = [];

  for (const { count, countryCode } of normalizedData) {
    labels.push(getCountryCodeLabel(t, countryMap, countryCode));
    data.push(count);
  }

  const datasets: ChartDataSets[] = [{ data, backgroundColor: [...ORDER_COLOURS] }];
  const result: ChartData = { labels, datasets };

  return result;
};

interface ChartProps {
  data: ChartData;
  options: ChartOptions;
}

const Chart: React.FunctionComponent<ChartProps> = ({ data, options }) => {
  return (
    <div className="position-relative chart-container">
      <Pie data={data} options={options} height={100} />
    </div>
  );
};

type ReportFilter = { [key: string]: any };
type OnReportFilterChange = (filter: ReportFilter) => void;

interface ReportFilterProps {
  contactEnums: ContactEnums;
  filter: ReportFilter;
  onFilterChange: OnReportFilterChange;
}

const ReportFilter: React.FunctionComponent<ReportFilterProps> = ({ contactEnums, filter, onFilterChange }) => {
  const { t } = useTranslation();
  const contactTypeDropdownSource = useEnumDropdown(contactEnums.contactType, 'contacts.enums.contactType');
  const onChange = useCallback((_name, value) => {
    onFilterChange({ ...filter, contactType: hasString(value) ? value : undefined });
  }, [filter, onFilterChange]);

  return (
    <div className="row mt-3 justify-content-md-center">
      <div className="col-12 col-md-6 d-flex flex-row align-items-center">
        <label className="form-label text-dark-blue m-0 mr-2">{t('contacts.contactType')}:</label>
        <VerticalSelectSearch className="flex-grow-1 m-0" collection={contactTypeDropdownSource} name="contact-type" onChange={onChange} value={filter.contactType ?? ''} />
      </div>
    </div>
  );
};

interface CountryCodeCounts {
  [key: string]: number;
}

export interface ReportContactTypeCountryCodeProps {
  data: ReportV2Response<CountryCodeCounts>;
  filter: ReportFilter;
  onFilterChange: OnReportFilterChange;
}

interface NormalizedDataElement {
  count: number;
  countryCode?: string | null;
}

type NormalizedData = NormalizedDataElement[];

export const ReportContactTypeCountryCode: React.FunctionComponent<ReportContactTypeCountryCodeProps> = ({ data, filter, onFilterChange }) => {
  const [isWorking, setIsWorking] = useState(false);
  const { dispatchAsync } = useAsyncDispatch();

  useEffect(() => {
    async function fetchData() {
      try {
        setIsWorking(true);

        await dispatchAsync(fetchCountriesLookup());
        await dispatchAsync(fetchContactEnums());

        setIsWorking(false);
      } catch (err) {
        setIsWorking(false);
        logger.error(err);
      }
    }

    fetchData();
  }, [dispatchAsync]);

  const { t } = useTranslation();
  const rawData = useMemo(() => cloneDeep(data?.data ?? {}), [data]);
  const normalizedData = useMemo(() => {
    const ordered = _(rawData).map((count, countryCode) => {
      const lowerCountryCode = lowerCase(countryCode);
      const normalizedCountryCode = !hasString(lowerCountryCode) || lowerCountryCode === '[null]' ? null : lowerCountryCode;

      return { count, countryCode: normalizedCountryCode };
    }).orderBy(['count', 'countryCode'], ['desc', 'asc']).value();
    const top3 = take(ordered, 3);
    const other = slice(ordered, 3);
    const result: NormalizedData = other.length > 0 ? [...top3, { count: sumBy(other, 'count') }] : [...top3];

    return result;
  }, [rawData]);
  const countries = useCountriesLookup();
  const contactEnums = useContactEnums();
  const countryMap: CountryMap = useMemo(() => countries.isReady ? reduce(countries.data, (acc, { label, value }) => {
    acc.set(lowerCase(value), label);

    return acc;
  }, new Map<string, string>()) : new Map<string, string>(), [countries.data, countries.isReady]);
  const chartOptions: ChartOptions = useMemo(() => ({
    legend: { display: false },
    tooltips: { mode: 'point' },
    plugins: {
      datalabels: { display: false }
    }
  }), []);
  const legendData = useMemo(() => map(normalizedData, ({ countryCode }, i) => {
    const name = getCountryCodeLabel(t, countryMap, countryCode);
    const colour = ORDER_COLOURS[i];
    const result: LegendEntryData = { name, colour };

    return result;
  }), [countryMap, normalizedData, t]);
  const chartData = useMemo(() => getChartData(normalizedData, countryMap, t), [countryMap, normalizedData, t]);

  return (
    <div className="d-flex flex-column flex-fill">
      <LoadingSpinner isLoading={isWorking}>
        <h5 className="my-4 text-uppercase text-dark-blue font-weight-bold">{t('reports.contact.typeCountryCode.title')}</h5>
        <div className="chart-group">
          <Chart data={chartData} options={chartOptions} />
          <ReportLegend title={t('reports.contact.typeCountryCode.title')} data={legendData} />
          <ReportFilter contactEnums={contactEnums.data} filter={filter} onFilterChange={onFilterChange} />
        </div>
      </LoadingSpinner>
    </div>
  );
};
