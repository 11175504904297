import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useStoreon from 'src/helpers/storeon';
import { API_CALL } from 'src/middleware/API';
import FilterByDateRange from 'src/datatables/filterByDateRange';
import randomId from 'src/helpers/randomId';
import sales from './sales';
import commission from './commission';
import { fetchPerformance } from 'src/actions/Performances';
import { useMediaQuery } from 'react-responsive';

import { listOfViews, listOfPerformanceBy } from './helper';

import Icon from 'src/constants/icons/IconDashboardCircle';

const defaultFilter = { start: '', end: '' };

const currentYear = new Date().getFullYear();
const years = [];
for (let i = 1980; i <= currentYear + 10; i += 1) {
  years.push(i);
}

const PerformancePage = () => {
  const { t } = useTranslation();
  const [view] = useState(listOfViews[0]);
  const [performanceBy, setPerformanceBy] = useState(listOfPerformanceBy[0]);
  const [filter, setFilter] = useState(defaultFilter);
  const [yearValue, setYearValue] = useState(currentYear);
  const [teamId, setTeamId] = useState(undefined);
  const [brokerId, setBrokerId] = useState(undefined);


  const {
    PerformanceCollections, Performance, dispatch, Auth
  } = useStoreon('PerformanceCollections', 'Performance', 'Auth');
  const { teams = [], brokers = [] } = PerformanceCollections.items;
  const { items, options, legendOptions, colors } = Performance.items;

  const [listBrokers, setListBrokers] = useState(brokers);

  // const handleOnChangeView = (event) => {
  //   setView(event.target.value);
  //   const options = {
  //     endpoint: event.target.value,
  //     query: { performanceBy, filter, yearValue, teamId, brokerId }
  //   };
  //   handlerFetchReport(options);
  // };

  const handleOnChangePerformanceBy = (event) => {
    setPerformanceBy(event.target.value);
    const options = {
      endpoint: view,
      query: { performanceBy: event.target.value, filter, yearValue, teamId, brokerId }
    };
    handlerFetchReport(options);
  };

  const handleRefresh = (props) => {
    const options = {
      endpoint: view,
      query: { performanceBy, filter, yearValue, teamId, brokerId }
    };
    handlerFetchReport(options);
  };

  const handlerFetchReport = ({ endpoint, query }) => {
    dispatch(
      API_CALL, fetchPerformance({
        accessToken: Auth.token, endpoint, query: { options: query }
      })
    );
  };

  const handleFilter = ({ currFilters }) => {
    const { closureDate } = currFilters;
    const { name, type, ...params } = closureDate;
    const newFilter = params;
    setFilter(newFilter);
    const options = {
      endpoint: view,
      query: { performanceBy, filter: newFilter, yearValue, teamId, brokerId }
    };
    handlerFetchReport(options);
  };

  const handlerOnChangeYear = (event) => {
    setYearValue(event.target.value);
    const options = {
      endpoint: view,
      query: { performanceBy, filter, teamId, yearValue: event.target.value, brokerId }
    };
    handlerFetchReport(options);
  };

  const handleOnChangeTeam = (event) => {
    setTeamId(event.target.value);
    const options = {
      endpoint: view,
      query: { performanceBy, filter, yearValue, teamId: event.target.value, brokerId }
    };
    if (event.target.value === '') {
      setListBrokers(brokers);
    } else {
      setListBrokers(
        brokers.filter(el => (el.teamId === +event.target.value))
      );
    }
    handlerFetchReport(options);
  };

  const handleOnChangeBroker = (event) => {
    setBrokerId(event.target.value);
    const options = {
      endpoint: view,
      query: { performanceBy, filter, yearValue, teamId, brokerId: event.target.value }
    };
    handlerFetchReport(options);
  };

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const Component = performanceBy === listOfPerformanceBy[0] ? sales : commission;

  return (
    <div className="card bg-transparent">
      <div className="card-header mb-5">
        <div className="w-100">
          <div className="d-flex flex-column flex-md-row my-2">
            <div className="mr-1 mt-1 align-self-start">
              <Icon width={35} height={35} fill="#182852" />
            </div>
            <div className="m2-no-md align-self-md-center align-self-start d-flex form-inline flex-nowrap mx-1">
              <label
                className="form-label text-dark-blue mb-0 mx-2 align-self-center"
              >
                {t("performance.viewPerformanceBy")}
              </label>
              <select
                className="form-control align-self-center form-control-sm"
                onChange={handleOnChangePerformanceBy}
                value={performanceBy}
              >
                {listOfPerformanceBy.map(el =>
                  <option key={randomId()} value={el}>
                    {t(`performance.performanceByList.${el}`)}
                  </option>
                )}
              </select>
            </div>
            <div className="m2-no-md align-self-md-center align-self-start d-flex form-inline flex-nowrap mx-1">
              <label
                className="form-label text-dark-blue mb-0 mx-2 align-self-center"
              >
                {t("performance.forTeam")}
              </label>
              <select
                className="form-control align-self-center form-control-sm"
                onChange={handleOnChangeTeam}
                value={teamId}
              >
                <option value="">Select</option>
                {teams.map(el =>
                  <option key={randomId()} value={el.value}>
                    {el.label}
                  </option>
                )}
              </select>
            </div>
            <div className="m2-no-md align-self-md-center align-self-start d-flex form-inline flex-nowrap mx-1">
              <label
                className="form-label text-dark-blue mb-0 mx-2 align-self-center"
              >
                {t("performance.andBroker")}
              </label>
              <select
                className="form-control align-self-center form-control-sm"
                onChange={handleOnChangeBroker}
                value={brokerId}
              >
                <option value="">Select</option>
                {listBrokers.map(el =>
                  <option key={randomId()} value={el.value}>
                    {el.label}
                  </option>
                )}
              </select>
            </div>
            <div className="m2-no-md align-self-md-center align-self-start mr-auto d-flex form-inline flex-nowrap mx-1">
              <label
                className="form-label text-dark-blue mb-0 mx-2 align-self-center"
              >
                {t("performance.filterBy")}
              </label>
              <FilterByDateRange
                key={`table-filter-${1}`}
                onChange={handleFilter}
                dataField={'closureDate'}
                label={'label'}
                source={'source'}
                endpoint={'endpoint'}
                min={yearValue}
              />
            </div>
            <div className="m2-no-md align-self-md-center align-self-end">
              <button className="btn-primary btn btn-sm mx-1" onClick={handleRefresh}>
                <i className="fa fa-refresh" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body mb-5">
        <div className="row">
          <div className="col">
            <div className="panel panel-primary bg-white">
              <div className="panel-body tabs-menu-body px-5">
                <div className="d-flex flex-nowrap" style={{ overflow: 'auto' }}>
                  <div style={{ flex: '0 0 auto', width: (isTabletOrMobile ? 1024 : '100%') }}>
                    <Component
                      items={items}
                      options={options}
                      legendOptions={legendOptions}
                      colors={colors}
                      view={view}
                      years={years}
                      yearValue={yearValue}
                      handlerOnChangeYear={handlerOnChangeYear}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default PerformancePage;
