import { leadOfferNewPath } from 'src/helpers/routes';
import offerNew from 'src/routes/offers/New';
import { fetchOfferCollections } from 'src/actions/Offers';
import { fetchLead } from 'src/actions/Leads';
import { API_CALL } from 'src/middleware/API';
import leadOffer from 'src/components/views/offers/leadOffer';

const prepareData = ({ store, params }) => {
  const state = store.get();
  if (state.Auth.connected === false) return;
  if (Object.keys(state.OfferCollections.items).length > 0) return;
  const accessToken = state.Auth.token;
  store.dispatch(API_CALL, fetchOfferCollections({ accessToken }));
  const id = params.leadId;
  store.dispatch(API_CALL, fetchLead({ accessToken, id, query: { offer: true } }));
}

export default Object.assign(
  {},
  offerNew,
  { path: leadOfferNewPath(), prepareData, component: leadOffer });
