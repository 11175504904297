var React = require('react')

var StoreContext = require('storeon/react/context')

var useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect

/**
 * Hook to use Storeon in functional React component.
 *
 * @param {...string} fields List of state’s field.
 *
 * @return {object} The selected part of the state.
 *
 * @example
 * import useStoreon from 'src/helpers/storeon'
 * const Counter = () => {
 *   const { dispatch, count } = useStoreon('count')
 *   return <div>
 *     {count}
 *     <button onClick={() => dispatch('inc')}
 *   </div>
 * }
 *
 * @name useStoreon
 */
export default function () {
  var keys = [].slice.call(arguments);

  var store = React.useContext(StoreContext);
  const [rerender, setRerender] = React.useState({ });

   useIsomorphicLayoutEffect(() => {
    let didCancel = false;
    store.on('@changed', (_, changed) => {
      const changesInKeys = keys.some(function (key) {
        return key in changed
      });
      const { Auth = {} } = changed;
      const { connected, signOut } = Auth;
      if (signOut && connected === false) didCancel = true;
      if (changesInKeys && didCancel === false) setRerender({ });
    });
    return () => {
      didCancel = true;
    };
  }, []);

  return React.useMemo(function () {
    var state = store.get();
    var data = {};
    keys.forEach(function (key) {
      data[key] = state[key]
    });
    data.dispatch = store.dispatch;
    data.rerender = rerender;
    return data;
  }, [keys, store, rerender]);
};
