import { leadEditPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchLeadCollections, fetchLead } from 'src/actions/Leads';
import { API_CALL } from 'src/middleware/API';

const LeadEdit = lazy(() => import('src/components/views/leads/Edit'));

export default {
  path: leadEditPath(),
  exact: true,
  strict: true,
  component: LeadEdit,
  perform: 'leads:update',
  title: 'Edit Lead',
  protected: true,
  prepareData: ({ store, params }) => {
    const state = store.get();

    if (state.Auth.connected === false) {
      return;
    }

    const accessToken = state.Auth.token;

    if (Object.keys(state.LeadCollections.items).length === 0) {
      store.dispatch(API_CALL, fetchLeadCollections({ accessToken }));
    }

    store.dispatch(API_CALL, fetchLead({ accessToken, id: params.id, query: { noTypeCast: true } }));
  }
};
