import ReactGA from 'react-ga';
import { hasString } from './string';

function isEnabled() {
  return hasString(process.env.REACT_APP_GA_TRACKING_CODE);
}

export function initialize() {
  if (!isEnabled()) {
    return;
  }

  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);
}

export function pageView({ pathname }) {
  if (!isEnabled()) {
    return;
  }

  ReactGA.pageview(pathname);
}
