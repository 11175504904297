import {
  FETCH_LOOKUPS_COUNTRIES_REQUEST, FETCH_LOOKUPS_COUNTRIES_SUCCESS, FETCH_LOOKUPS_COUNTRIES_ERROR,
  FETCH_LOOKUPS_FEATURES_REQUEST, FETCH_LOOKUPS_FEATURES_SUCCESS, FETCH_LOOKUPS_FEATURES_ERROR,
  FETCH_LOOKUPS_FIELDS_REQUEST, FETCH_LOOKUPS_FIELDS_SUCCESS, FETCH_LOOKUPS_FIELDS_ERROR
} from 'src/constants/actionTypes/lookups-action-types';
import { cloneDeep } from 'lodash';
import { State, Events } from 'src/store';
import { Module } from 'storeon';

const initialState = (): Partial<State> => ({
  lookups: {
    countries: {
      error: false,
      fetchCount: 0,
      hasLoaded: false
    },
    features: {
      error: false,
      fetchCount: 0,
      hasLoaded: false
    },
    fields: {
      error: false,
      fetchCount: 0,
      hasLoaded: false
    }
  }
});

export const lookupModule: Module<State, Events> = store => {
  // init
  store.on('@init', () => initialState());

  // countries
  store.on(FETCH_LOOKUPS_COUNTRIES_REQUEST, ({ lookups }) => ({
    lookups: {
      ...lookups,
      countries: {
        ...lookups.countries,
        fetchCount: lookups.countries.fetchCount + 1
      }
    }
  }));
  store.on(FETCH_LOOKUPS_COUNTRIES_SUCCESS, ({ lookups }, { response }) => ({
    lookups: {
      ...lookups,
      countries: {
        ...lookups.countries,
        data: cloneDeep(response.data),
        fetchCount: lookups.countries.fetchCount - 1,
        hasLoaded: true
      }
    }
  }));
  store.on(FETCH_LOOKUPS_COUNTRIES_ERROR, ({ lookups }, { response }) => ({
    lookups: {
      ...lookups,
      countries: {
        ...lookups.countries,
        error: response,
        fetchCount: lookups.countries.fetchCount - 1
      }
    }
  }));

  // features
  store.on(FETCH_LOOKUPS_FEATURES_REQUEST, ({ lookups }) => ({
    lookups: {
      ...lookups,
      features: {
        ...lookups.features,
        fetchCount: lookups.features.fetchCount + 1
      }
    }
  }));
  store.on(FETCH_LOOKUPS_FEATURES_SUCCESS, ({ lookups }, { response }) => ({
    lookups: {
      ...lookups,
      features: {
        ...lookups.features,
        data: cloneDeep(response.data),
        fetchCount: lookups.features.fetchCount - 1,
        hasLoaded: true
      }
    }
  }));
  store.on(FETCH_LOOKUPS_FEATURES_ERROR, ({ lookups }, { response }) => ({
    lookups: {
      ...lookups,
      features: {
        ...lookups.features,
        error: response,
        fetchCount: lookups.features.fetchCount - 1
      }
    }
  }));

  // fields
  store.on(FETCH_LOOKUPS_FIELDS_REQUEST, ({ lookups }) => {
    return ({
      lookups: {
        ...lookups,
        fields: {
          ...lookups.fields,
          fetchCount: lookups.fields.fetchCount + 1
        }
      }
    })
  });
  store.on(FETCH_LOOKUPS_FIELDS_SUCCESS, ({ lookups }, { response }) => ({
    lookups: {
      ...lookups,
      fields: {
        ...lookups.fields,
        data: cloneDeep(response.data),
        fetchCount: lookups.fields.fetchCount - 1,
        hasLoaded: true
      }
    }
  }));
  store.on(FETCH_LOOKUPS_FIELDS_ERROR, ({ lookups }, { response }) => ({
    lookups: {
      ...lookups,
      fields: {
        ...lookups.fields,
        error: response,
        fetchCount: lookups.fields.fetchCount - 1
      }
    }
  }));
};
