import * as types from 'src/constants/actionTypes/CompaniesActionTypes';
import { SUBMIT_RECENTLY_VIEWED } from 'src/constants/actionTypes/RecentlyViewedActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  company: {}
}

export default store => {
  store.on('@init', () => ({ Company: initialState }));

  store.on(types.FETCH_COMPANY_REQUEST, ({ Company }) =>
    ({ Company: { ...initialState, isFetching: true }}));
  store.on(types.FETCH_COMPANY_SUCCESS, ({ Company }, { response }) => {
    store.dispatch(SUBMIT_RECENTLY_VIEWED, {
      data: { id: response.data.id, name: response.data.name, time: Date.now()},
      endpoint: 'companies'
    });
    return ({ Company: { ...Company, company: response.data }})
  });
  store.on(types.FETCH_COMPANY_ERROR, ({ Company }) =>
    ({ Company: {...Company, error: true }}));
}
