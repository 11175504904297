import { userNewPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchUserCollections } from 'src/actions/Users';
import { API_CALL } from 'src/middleware/API';

const UserNewPage = lazy(() => import('src/components/views/admin/users/New'));

export default {
  path: userNewPath(),
  exact: true,
  strict: true,
  component: UserNewPage,
  perform: 'admin/users:new',
  title: 'New User',
  protected: true,
  prepareData: ({ store }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    if (Object.keys(state.UserCollections.items).length > 0) return;
    const accessToken = state.Auth.token;
    store.dispatch(API_CALL, fetchUserCollections({ accessToken }));
  }
};
