import { listingOfferNewPath } from 'src/helpers/routes';
import offerNew from 'src/routes/offers/New';
import { fetchOfferCollections } from 'src/actions/Offers';
import { fetchListing } from 'src/actions/Listings';
import { API_CALL } from 'src/middleware/API';
import component from 'src/components/views/offers/listingOffer';

const prepareData = ({ store, params }) => {
  const state = store.get();
  if (state.Auth.connected === false) return;
  if (Object.keys(state.OfferCollections.items).length > 0) return;
  const accessToken = state.Auth.token;
  store.dispatch(API_CALL, fetchOfferCollections({ accessToken }));
  const id = params.listingId;
  const query = { offer: true };
  store.dispatch(API_CALL, fetchListing({ accessToken, id, query }));
}

export default Object.assign(
  {},
  offerNew,
  { path: listingOfferNewPath(), prepareData, component });
