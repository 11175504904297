import * as types from 'src/constants/actionTypes/files/UploadActionTypes';
import { omit, size, padStart } from 'lodash';

const initialState = {
  sendRequest: false,
  error: false,
  success: false,
  errors: {},
  item: {}
};
const initialStateMeta = {
  sendRequest: false,
  error: false,
  success: false,
  errors: {},
  items: []
};
const generateUniqueId = (namespaceState) => {
  const idNum = size(namespaceState) + 1;
  const idStr = padStart(idNum, 4, '0');
  const result = `upload_${idStr}`;

  return result;
};
const mergeUploadState = (state, namespace, { name, size: fileSize }, fileState) => {
  const namespaceState = state[namespace] || {};
  const pathState = namespaceState[name] || {};
  const uploadId = pathState.uploadId || generateUniqueId(namespaceState);
  const result = {
    SubmitUpload: {
      ...state,
      [namespace]: {
        ...namespaceState,
        [name]: {
          name,
          size: fileSize,
          uploadId,
          ...fileState
        }
      }
    }
  };

  return result;
};

export default store => {
  store.on('@init', () => ({ SubmitUpload: {} }));
  store.on('@init', () => ({ SubmitUpdateMeta: initialState }));
  store.on('@init', () => ({ SubmitVideoLink: initialState }));
  store.on('@init', () => ({ FetchingMeta: initialStateMeta }));

  store.on(types.SUBMIT_UPLOAD_RESET, ({ SubmitUpload }, { namespace }) => ({ SubmitUpload: omit(SubmitUpload, namespace) }));
  store.on(types.SUBMIT_UPLOAD_REQUEST, ({ SubmitUpload }, { namespace, file }) => mergeUploadState(SubmitUpload, namespace, file, { isWorking: true }));
  store.on(types.SUBMIT_UPLOAD_SUCCESS, ({ SubmitUpload }, { namespace, file }) => mergeUploadState(SubmitUpload, namespace, file, { isWorking: false }));
  store.on(types.SUBMIT_UPLOAD_ERROR, ({ SubmitUpload }, { response, namespace, file }) => mergeUploadState(SubmitUpload, namespace, file, {
    isWorking: false,
    errors: response && response.body && response.body.errors ? response.body.errors : {}
  }));
  store.on(types.SUBMIT_UPLOAD_UPLOAD_PROGRESS, ({ SubmitUpload }, { namespace, file, event: upload }) => mergeUploadState(SubmitUpload, namespace, file, { isWorking: true, upload }));

  store.on(types.SUBMIT_UPDATE_META_RESET, () => ({ SubmitUpdateMeta: initialState }));
  store.on(types.SUBMIT_UPDATE_META_REQUEST, ({ SubmitUpdateMeta }) =>
    ({ SubmitUpdateMeta: { ...SubmitUpdateMeta, ...initialState, sendRequest: true } }));
  store.on(types.SUBMIT_UPDATE_META_SUCCESS, ({ SubmitUpdateMeta }, { response }) => {
    return ({ SubmitUpdateMeta: { ...SubmitUpdateMeta, success: true, sendRequest: false } });
  });
  store.on(types.SUBMIT_UPDATE_META_ERROR, ({ SubmitUpdateMeta }, { response }) => {
    let errors = {};
    if (response && response.body && response.body.errors) errors = response.body.errors;
    return ({ SubmitUpdateMeta: { ...SubmitUpdateMeta, error: true, errors: errors, sendRequest: false } });
  });

  store.on(types.SUBMIT_VIDEO_LINK_RESET, () => ({ SubmitVideoLink: initialState }));
  store.on(types.SUBMIT_VIDEO_LINK_REQUEST, ({ SubmitVideoLink }) =>
    ({ SubmitVideoLink: { ...SubmitVideoLink, ...initialState, sendRequest: true } }));
  store.on(types.SUBMIT_VIDEO_LINK_SUCCESS, ({ SubmitVideoLink }, { response }) => {
    return ({ SubmitVideoLink: { ...SubmitVideoLink, success: true, sendRequest: false } });
  });
  store.on(types.SUBMIT_VIDEO_LINK_ERROR, ({ SubmitVideoLink }, { response }) => {
    let errors = {};
    if (response && response.body && response.body.errors) errors = response.body.errors;
    return ({ SubmitVideoLink: { ...SubmitVideoLink, error: true, errors: errors, sendRequest: false } });
  });


  store.on(types.FETCH_META_REQUEST, ({ FetchingMeta }) =>
    ({ FetchingMeta: { ...FetchingMeta, ...initialStateMeta, sendRequest: true } }));
  store.on(types.FETCH_META_SUCCESS, ({ FetchingMeta }, { response }) => {
    return ({ FetchingMeta: { ...FetchingMeta, success: true, sendRequest: false, items: response.data } });
  });
  store.on(types.FETCH_META_ERROR, ({ FetchingMeta }, { response }) => {
    let errors = {};
    if (response && response.body && response.body.errors) errors = response.body.errors;
    return ({ FetchingMeta: { ...FetchingMeta, error: true, errors: errors, sendRequest: false } });
  });
}
