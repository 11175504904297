import * as types from 'src/constants/actionTypes/ListingsActionTypes';

const fetchListings = ({ accessToken, query = {} }) => {
  return ({
    query,
    endpoint: 'listings',
    method: 'GET',
    credentials: true,
    accessToken,
    types: [
      types.FETCH_LISTINGS_REQUEST,
      types.FETCH_LISTINGS_SUCCESS,
      types.FETCH_LISTINGS_ERROR
    ]
  })
}

const fetchListing = ({ accessToken, id, query = {} }) => {
  return ({
    query,
    endpoint: `listings/${id}`,
    method: 'GET',
    credentials: true,
    accessToken,
    types: [
      types.FETCH_LISTING_REQUEST,
      types.FETCH_LISTING_SUCCESS,
      types.FETCH_LISTING_ERROR
    ]
  })
};

const updateListing = ({ accessToken, query, id, body }) => {
  return ({
    body,
    query,
    endpoint: `listings/${id}`,
    method: 'PUT',
    credentials: true,
    accessToken,
    types: [
      types.SUBMIT_LISTING_REQUEST,
      types.SUBMIT_LISTING_SUCCESS,
      types.SUBMIT_LISTING_ERROR
    ]
  })
};

const deleteListings = ({ accessToken, query }) => {
  return ({
    query,
    endpoint: `listings`,
    method: 'DELETE',
    credentials: true,
    accessToken,
    types: [
      types.DELETE_LISTINGS_REQUEST,
      types.DELETE_LISTINGS_SUCCESS,
      types.DELETE_LISTINGS_ERROR
    ]
  })
};

const deleteListing = ({ accessToken, id }) => {
  return ({
    endpoint: `listings/${id}`,
    method: 'DELETE',
    credentials: true,
    accessToken,
    types: [
      types.DELETE_LISTING_REQUEST,
      types.DELETE_LISTING_SUCCESS,
      types.DELETE_LISTING_ERROR
    ]
  })
};

const createListing = ({ accessToken, query, body }) => {
  return ({
    body,
    query,
    endpoint: 'listings',
    method: 'POST',
    credentials: true,
    accessToken,
    types: [
      types.SUBMIT_LISTING_REQUEST,
      types.SUBMIT_LISTING_SUCCESS,
      types.SUBMIT_LISTING_ERROR
    ]
  })
};

const fetchListingCollections = ({ accessToken }) => {
  return ({
    endpoint: 'listings/helper',
    method: 'GET',
    credentials: true,
    accessToken,
    types: [
      types.FETCH_LISTING_COLLECTION_REQUEST,
      types.FETCH_LISTING_COLLECTION_SUCCESS,
      types.FETCH_LISTING_COLLECTION_ERROR
    ]
  })
}

export {
  fetchListings, fetchListing, fetchListingCollections, createListing, deleteListings,
  deleteListing, updateListing
};
