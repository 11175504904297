import { paymentEditPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchPaymentCollections, fetchPayment } from 'src/actions/Payments';
import { API_CALL } from 'src/middleware/API';

const PaymentEdit = lazy(() => import('src/components/views/payments/Edit'));

export default {
  path: paymentEditPath(),
  exact: true,
  strict: true,
  component: PaymentEdit,
  perform: 'payments:update',
  protected: true,
  prepareData: ({ store, params }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    if (Object.keys(state.PaymentCollections.items).length ===  0) {
      store.dispatch(API_CALL, fetchPaymentCollections({ accessToken }));
    }
    const query = { noTypeCast: true };
    store.dispatch(API_CALL, fetchPayment({ accessToken, id: params.id, query }));
  }
};
