import * as types from 'src/constants/actionTypes/PerformancesActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  items: {},
  success: false
};

export default store => {
  store.on('@init', () => ({ Performance: initialState }));

  store.on(types.FETCH_PERFORMANCE_REQUEST, ({ Performance }) =>
    ({ Performance: { ...initialState, isFetching: true }}));
  store.on(types.FETCH_PERFORMANCE_SUCCESS, ({ Performance }, { response }) => {
    const { data } = response;
    return ({ Performance: { ...Performance,  items: data, success: true } });
  });
  store.on(types.FETCH_PERFORMANCE_ERROR, ({ Performance }) =>
    ({ Performance: {...Performance, error: true }}));
};
