import React, { useMemo, useCallback } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { isEmpty, cloneDeep, filter, values, map, sum, maxBy, includes } from 'lodash';
import { ChartOptions, ChartData, ChartDataSets } from 'chart.js';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { TransactionType, TransactionStatus, TransactionCompanyRole } from 'src/-types/models/transaction';
import { ReportV2Response } from 'src/-types/report';
import { ReportLegend, LegendEntryData } from 'src/components/elements/Report/Legend';

const STATUSES: TransactionStatus[] = ['cancelled', 'open', 'closed'];
const STATUS_COLOURS: { [key in TransactionStatus]: string } = {
  'cancelled': '#EE1D23',
  'open': '#F7931D',
  'closed': '#3AB54A'
};
const getChartData: (data: CompanyRoleGrouping, t: i18next.TFunction) => ChartData = (data: CompanyRoleGrouping, t: i18next.TFunction) => {
  const labels = map(data, (_, k) => t(`transactions.${k}`));
  const datasets: ChartDataSets[] = [];

  for (const statusGrouping of values(data)) {
    for (let i = 0; i < STATUSES.length; i++) {
      const status = STATUSES[i];
      const count = statusGrouping[status];

      if (datasets[i]?.data?.push(count)) {
        continue;
      }

      datasets.push({
        label: t(`transactions.statuses.${status}`),
        stack: 'stack-0',
        backgroundColor: STATUS_COLOURS[status],
        data: [count],
        barThickness: 25
      });
    }
  }

  const result: ChartData = { labels, datasets };

  return result;
};
const getMaxStackValue = (data: TransactionTypeGrouping) => {
  if (isEmpty(data)) {
    return 0;
  }

  const sums = [];

  for (const companyRoleGrouping of values(data)) {
    for (const statusGrouping of values(companyRoleGrouping)) {
      sums.push(sum(filter(statusGrouping, (_, key) => includes(STATUSES, key))));
    }
  }

  return maxBy(sums);
};

interface TransactionTypeChartProps {
  title: string;
  data: ChartData;
  options: ChartOptions;
}

const TransactionTypeChart: React.FunctionComponent<TransactionTypeChartProps> = ({ title, data, options }: TransactionTypeChartProps) => {
  return (
    <div className="chart-container">
      <h5 className="my-4 text-uppercase text-dark-blue font-weight-bold">{title}</h5>
      <HorizontalBar data={data} options={options} height={50} />
    </div>
  );
};

export type StatusGrouping = { [key in TransactionStatus]: number };
export type CompanyRoleGrouping = { [key in TransactionCompanyRole]: StatusGrouping };
export type TransactionTypeGrouping = { [key in TransactionType]: CompanyRoleGrouping };

export interface ReportTransactionTypeCompanyRoleStatusProps {
  data: ReportV2Response<TransactionTypeGrouping>;
}

export const ReportTransactionTypeCompanyRoleStatus: React.FunctionComponent<ReportTransactionTypeCompanyRoleStatusProps> = (props) => {
  const { t } = useTranslation();
  const rawData = useMemo(() => cloneDeep(props?.data?.data ?? {}), [props]);
  const hasRawData = useMemo(() => !isEmpty(rawData), [rawData]);
  const maxTickValue = useMemo(() => getMaxStackValue(rawData), [rawData]);
  const getChartOptions = useCallback(() => {
    const result: ChartOptions = {
      legend: { display: false },
      tooltips: { mode: 'point' },
      scales: {
        xAxes: [{
          stacked: true,
          gridLines: { display: false },
          ticks: { beginAtZero: true, stepSize: 1, suggestedMax: maxTickValue },
          type: 'linear'
        }],
        yAxes: [{
          stacked: true,
          gridLines: { display: false },
          afterFit: (scale) => {
            scale.width = 170;
          }
        }]
      },
      plugins: {
        datalabels: { display: false }
      }
    };

    return result;
  }, [maxTickValue]);
  const legendData: LegendEntryData[] = useMemo(() => map(STATUS_COLOURS, (colour, status) => ({ name: t(`transactions.statuses.${status}`), colour })), [t]);

  return (
    <div className="d-flex flex-column flex-fill">
      <h5 className="my-4 text-uppercase text-dark-blue font-weight-bold">{t('reports.legendsOptions.company_role_status')}</h5>
      <div className="chart-group">
        {hasRawData && map(rawData, (companyRoleGrouping, type) => <TransactionTypeChart key={type} title={t(`transactions.types.${type}`)} data={getChartData(companyRoleGrouping, t)} options={getChartOptions()} />)}
        <ReportLegend title={t('transactions.status')} data={legendData} />
      </div>
    </div>
  );
};
