import React, { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import DayJsLocaleUtils, { formatDate, parseDate } from 'src/helpers/dayJsLocaleUtils';
import { useTranslation } from 'react-i18next';

const CustomOverlay = ({ classNames, selectedDay, children, ...props }) => {
  return (
    <div
      className={`${classNames.overlayWrapper}`}
      style={{ marginLeft: -150 }}
      {...props}
    >
      <div className={classNames.overlay}>
        {children}
      </div>
    </div>
  );
};

export const FilterByDateRange = (props) => {
  const { onChange, dataField, min = false, classPy = true } = props;
  const { i18n, t } = useTranslation();
  const locale = useMemo(() => i18n.language, [i18n.language]);
  const [range, setRange] = useState(props.range);
  const base = useMemo(() => t(`filter.${dataField}`), [dataField, t]);

  const convertDay = useCallback((day) => (day ? dayjs(day).format('DD-MM-YYYY') : ''), []);
  const onChangeCurrent = useCallback((name) => (day) => {
    const data = { [name]: convertDay(day) };
    const newRange = { ...range, ...data, type: 'range', name: dataField };
    setRange(newRange);
    onChange({ currFilters: { [dataField]: newRange } });
  }, [convertDay, dataField, onChange, range]);
  const disabledDays = useCallback(() => {
    if (min === false) return [];
    return [
      { before: new Date(min, 0, 0) },
      { after: new Date(min + 2, 0, 0) }
    ];
  }, [min]);

  return (
    <div className={`d-flex ${classPy ? "py-2" : ""} ml-2`}>
      <div>
        <DayPickerInput
          inputProps={{ className: 'form-control-sm form-control form-control-sm-custom', name: 'start' }}
          format="DD-MM-YYYY"
          value={range?.start}
          placeholder={`${base} From`}
          formatDate={formatDate}
          parseDate={parseDate}
          onDayChange={onChangeCurrent('start')}
          dayPickerProps={{ locale, localeUtils: DayJsLocaleUtils, disabledDays: disabledDays() }}
        />
      </div>
      <div className="ml-2">
        <DayPickerInput
          overlayComponent={CustomOverlay}
          inputProps={{ className: 'form-control-sm form-control form-control-sm-custom', name: 'end' }}
          format="DD-MM-YYYY"
          value={range?.end}
          placeholder={`${base} To`}
          formatDate={formatDate}
          parseDate={parseDate}
          onDayChange={onChangeCurrent('end')}
          dayPickerProps={{ locale, localeUtils: DayJsLocaleUtils, disabledDays: disabledDays() }}
        />
      </div>
    </div>
  );
};

export default FilterByDateRange;
