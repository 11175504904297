import * as types from 'src/constants/actionTypes/PropertiesActionTypes';
import { SUBMIT_RECENTLY_VIEWED } from 'src/constants/actionTypes/RecentlyViewedActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  property: {}
}

const initialStatePropertyListing = {
  isFetching: false,
  error: false,
  item: null
}

export default store => {
  store.on('@init', () => ({ Property: initialState }));
  store.on('', () => ({ PropertyListing: initialStatePropertyListing }));

  store.on(types.FETCH_PROPERTY_REQUEST, ({ Property }) =>
    ({ Property: { ...initialState, isFetching: true }}));
  store.on(types.FETCH_PROPERTY_SUCCESS, ({ Property }, { response }) => {
    store.dispatch(SUBMIT_RECENTLY_VIEWED, {
      data: { id: response.data.id, name: response.data.name, time: Date.now()},
      endpoint: 'properties'
    });
    return ({ Property: { ...Property, property: response.data }})
  });
  store.on(types.FETCH_PROPERTY_ERROR, ({ Property }) =>
    ({ Property: {...Property, error: true }}));

  store.on(types.FETCH_PROPERTY_LISTING_REQUEST, ({ PropertyListing }) =>
    ({ PropertyListing: { ...initialStatePropertyListing, isFetching: true }}));
  store.on(types.FETCH_PROPERTY_LISTING_SUCCESS, ({ PropertyListing }, { response }) =>
    ({ PropertyListing: { ...PropertyListing, item: response.data }}));
  store.on(types.FETCH_PROPERTY_LISTING_ERROR, ({ PropertyListing }) =>
    ({ PropertyListing: {...PropertyListing, error: true }}));
}
