import { ApiRequest } from 'src/-types/request';
import { FETCH_CONTACT_ENUMS, FETCH_CONTACT_ENUMS_SUCCESS, FETCH_CONTACT_ENUMS_ERROR } from 'src/constants/actionTypes/enums/contact';

export const fetchContactEnums: () => ApiRequest = () => ({
  apiVersion: 'v2',
  endpoint: 'contacts/enums',
  method: 'GET',
  credentials: true,
  types: [
    FETCH_CONTACT_ENUMS,
    FETCH_CONTACT_ENUMS_SUCCESS,
    FETCH_CONTACT_ENUMS_ERROR
  ]
});
