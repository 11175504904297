import { ApiRequest } from 'src/-types/request';
import { FETCH_SNAG_ENUMS, FETCH_SNAG_ENUMS_SUCCESS, FETCH_SNAG_ENUMS_ERROR } from 'src/constants/actionTypes/enums/snag';

export const fetchSnagEnums: () => ApiRequest = () => ({
  apiVersion: 'v2',
  endpoint: 'snags/enums',
  method: 'GET',
  credentials: true,
  types: [
    FETCH_SNAG_ENUMS,
    FETCH_SNAG_ENUMS_SUCCESS,
    FETCH_SNAG_ENUMS_ERROR
  ]
});
