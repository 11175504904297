import { getI18n } from 'react-i18next';
import { store as notificationStore} from 'react-notifications-component';
import { ADD_NOTIFICATION } from 'src/constants/actionTypes/notifications';

const t = (type) => {
  const i18n = getI18n();
  const translate = i18n.getFixedT(null, i18n.options.ns[0]);
  return translate(type);
};

const typeOfByObject = object =>
  object.type === 'success' || object.type === undefined ? 'notifications.successTitle' : 'notifications.errorTitle';

export default store => {
  store.on(ADD_NOTIFICATION, (props, object) => {
    const typeOf = typeOfByObject(object);
    const { title = t(typeOf) , message = ' ', type = 'success' } = object;
    notificationStore.addNotification({
      title,
      type,
      message,
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 5000 },
      dismissable: { click: true }
    });
  });
};
