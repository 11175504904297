import * as types from 'src/constants/actionTypes/PaymentsActionTypes';
import { SUBMIT_RECENTLY_VIEWED } from 'src/constants/actionTypes/RecentlyViewedActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  payment: {}
};

export default store => {
  store.on('@init', () => ({ Payment: initialState }));

  store.on(types.FETCH_PAYMENT_REQUEST, ({ Payment }) =>
    ({ Payment: { ...initialState, isFetching: true }}));
  store.on(types.FETCH_PAYMENT_SUCCESS, ({ Payment }, { response }) => {
    store.dispatch(SUBMIT_RECENTLY_VIEWED, {
      data: { id: response.data.id, name: response.data.name, time: Date.now()},
      endpoint: 'payments'
    });
    return ({ Payment: { ...Payment, payment: response.data }})
  });
  store.on(types.FETCH_PAYMENT_ERROR, ({ Payment }) =>
    ({ Payment: {...Payment, error: true }}));
};
