import React from 'react';
import { useTranslation } from 'react-i18next';
import useStoreon from 'src/helpers/storeon';
import Form from 'src/components/views/activity/Form';
import { updateActivity } from 'src/actions/Activities';

export default ({ item, handlerCancelUpdate, successCallback }) => {
  const { t } = useTranslation();
  const { SubmitActivity } = useStoreon('SubmitActivity');

  const serverSideValidation = new Promise(function(resolve, reject) {
    const { errors, error, success, sendRequest } = SubmitActivity;
    if (!sendRequest && success) resolve();
    if (!sendRequest && error) reject(errors);
  });

  const defaultValues = {
    status: item.statusRaw,
    typeOf: item.typeOfRaw,
    objectiveOfActivity: item.objectiveOfActivity,
    startDateAndTime: item.start,
    endDateAndTime: item.end,
    comments: item.comments,
    attendees: item.attendees,
    notificationToAttendees: item.notificationToAttendees,
    reminder: item.reminder,
    amountOfTimeBefore: item.amountOfTimeBeforeRaw,
    activitybleType: item.activitybleType,
    activitybleId: item.activitybleId
  };

  return (
    <>
    {item && <Form
      defaultValues={defaultValues}
      serverSideValidation={serverSideValidation}
      submitHandler={updateActivity}
      id={item.id}
      successCallback={successCallback}
    >
      <div className="row pt-3">
        <div className="col">
          <div className="float-right">
            <button type="button" className="btn btn-header mr-2" onClick={handlerCancelUpdate}>
              {t('buttons.cancel')}
            </button>
            <button disabled={SubmitActivity.sendRequest} className="btn btn-primary">
              {t('buttons.update')}
            </button>
          </div>
        </div>
      </div>
    </Form>}
    </>
  );
};
