import { teamPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchTeam } from 'src/actions/Teams';
import { API_CALL } from 'src/middleware/API';
import Layout from 'src/components/views/showLayout';

const TeamShow = lazy(() => import('src/components/views/admin/teams/Show'));

export default {
  path: teamPath(),
  exact: true,
  strict: true,
  component: TeamShow,
  perform: "admin/teams:show",
  layout: Layout,
  protected: true,
  prepareData: ({ store, query, params }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    store.dispatch(API_CALL, fetchTeam({ accessToken, id: params.id }));
  }
};
