import { FETCH_ALL_OFFERS, FETCH_ALL_OFFERS_SUCCESS, FETCH_ALL_OFFERS_ERROR, UPDATE_OFFER_RECORD } from 'src/constants/actionTypes/entities/offer';
import { onEntityStateInit, onFetchAll, onFetchAllSuccess, onFetchAllError, onUpdateRecord } from 'src/helpers/entity-store';

export default store => {
  store.on('@init', (state) => onEntityStateInit(state, 'offer'));

  store.on(FETCH_ALL_OFFERS, (state) => onFetchAll(state, 'offer'));
  store.on(FETCH_ALL_OFFERS_SUCCESS, (state, { response }) => onFetchAllSuccess(state, 'offer', response));
  store.on(FETCH_ALL_OFFERS_ERROR, (state, { error }) => onFetchAllError(state, 'offer', error));

  store.on(UPDATE_OFFER_RECORD, (state, { id, offer }) => onUpdateRecord(state, 'offer', id, offer));
};
