import { Module } from 'storeon';
import { Events, State } from 'src/store';
import { FETCH_COMPANY_ENUMS, FETCH_COMPANY_ENUMS_SUCCESS, FETCH_COMPANY_ENUMS_ERROR } from 'src/constants/actionTypes/enums/company';
import { onInit, onFetch, onFetchSuccess, onFetchError } from 'src/-utils/enums';

export const companyEnumModule: Module<State, Events> = store => {
  store.on('@init', (state) => onInit(state, 'company'));

  store.on(FETCH_COMPANY_ENUMS, (state) => onFetch(state, 'company'));
  store.on(FETCH_COMPANY_ENUMS_SUCCESS, (state, { response }) => onFetchSuccess(state, 'company', response));
  store.on(FETCH_COMPANY_ENUMS_ERROR, (state, { error }) => onFetchError(state, 'company', error));
};

export default companyEnumModule;
