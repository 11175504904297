import { companyPath, companyContactNewPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchCompany, fetchCompanyCollections } from 'src/actions/Companies';
import { API_CALL } from 'src/middleware/API';
import contactNew from 'src/routes/contacts/New';
import Layout from 'src/components/views/showLayout';
import { fetchActivityCollections } from 'src/actions/Activities';

const CompanyShow = lazy(() => import('src/components/views/companies/Show'));

export default {
  path: companyPath(),
  exact: true,
  strict: true,
  component: CompanyShow,
  perform: "companies:show",
  layout: Layout,
  protected: true,
  prepareData: ({ store, query, params }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    store.dispatch(API_CALL, fetchCompany({ accessToken, id: params.id }));
    if (Object.keys(state.ActivityCollections.items).length ===  0) {
      store.dispatch(API_CALL, fetchActivityCollections({ accessToken }));
    }
    if (Object.keys(state.CompanyCollections.items).length === 0) {
      store.dispatch(API_CALL, fetchCompanyCollections({ accessToken, query: { country: true } }));
    }
  },
  routes: [
    Object.assign({}, contactNew, { path: companyContactNewPath() })
  ]
};
