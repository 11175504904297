import { SnagEnums } from 'src/-types/models/snag';
import { ModelEnumState, BaseEnumCollection } from 'src/-types/enums';

export type SnagEnumState = ModelEnumState<SnagEnums>;

export class SnagEnumCollection extends BaseEnumCollection<SnagEnumState> {
  protected defaultData: SnagEnums = {
    category: [],
    location: [],
    product: [],
    severity: [],
    snagClass: [],
    snagType: [],
    status: [],
  };

  public constructor(state: SnagEnumState) {
    super(state);
  }
}
