import { rootPath } from 'src/helpers/routes';
import { API_CALL } from 'src/middleware/API';
import { fetchActivityCollections } from 'src/actions/Activities';
import Dashboard from 'src/components/views/dashboards';

export default {
  path: rootPath(),
  exact: true,
  strict: true,
  component: Dashboard,
  protected: true,
  perform: 'dashboard:index',
  title: 'Dashboard',
  prepareData: ({ store, params }) => {
    const state = store.get();
    const accessToken = state.Auth.token;
    if (state.Auth.connected === false) return;
    if (Object.keys(state.ActivityCollections.items).length ===  0) {
      store.dispatch(API_CALL, fetchActivityCollections({ accessToken }));
    }
  }
};
