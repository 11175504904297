import {
  BaseJsonApiResourceObject, JsonApiResponseType, JsonApiCreateBodyType, JsonApiErrorResponseType,
  JsonApiUpdateBodyType, JsonApiBelongsToRelationship, JsonApiResourceIdentifier, BaseRecord, isBelongsTo,
  JsonApiHistoryResponseType, JsonApiBelongsToRelationshipNullable, JsonApiCollectionResponseType
} from 'src/-types/models/json-api';
import { ModelType } from 'src/-types/model';
import { camelCase, isNil, trimEnd, isUndefined, cloneDeep, set, get } from 'lodash';
import { store } from 'src/store';
import { ApiError } from 'src/-types/errors/api-error';
import { hasString } from 'src/helpers/string';
import { isNull } from 'util';
import { ActiveRecordFileMeta } from 'src/-types/active-record';
import { QueryParams } from 'src/-types/request';
import { joinQueryParams } from 'src/-utils/requests';

export const MODEL_TYPES: ModelType[] = ['company', 'contact', 'lead', 'listing', 'listingProperty', 'offer', 'project', 'property', 'snag', 'transaction', 'user', 'userAttribute'];
export const MODEL_TYPES_SET = new Set<ModelType>(MODEL_TYPES);

export function isModelType(modelType: any): modelType is ModelType {
  return MODEL_TYPES_SET.has(modelType);
}

export function toModelType(obj: any): ModelType {
  const normalized = camelCase(`${obj}`);

  if (isModelType(normalized)) {
    return normalized;
  }

  throw new Error(`Unknown model type ${normalized}`);
}

export function normalizeEntityId(id: string): string {
  return `${id}`;
}

export function normalizeEntityType(entityType: string): ModelType {
  const nType = toModelType(entityType);

  return nType;
}

export async function createEntity<T extends BaseJsonApiResourceObject>(endpoint: string, body: JsonApiCreateBodyType<T>): Promise<{ status: number, response?: JsonApiResponseType<T>, errors?: JsonApiErrorResponseType<T> }> {
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: { ...store.get().Auth.token, 'Content-Type': 'application/vnd.api+json', 'Accept': 'application/vnd.api+json' },
    body: JSON.stringify(body)
  });
  const { status } = response;

  return {
    status,
    response: status === 201 ? (await response.json() as JsonApiResponseType<T>) : undefined,
    errors: status === 400 ? (await response.json() as JsonApiErrorResponseType<T>) : undefined
  };
}

export async function updateEntity<T extends BaseJsonApiResourceObject>(endpoint: string, body: JsonApiUpdateBodyType<T>): Promise<JsonApiResponseType<T>> {
  const response = await fetch(endpoint, {
    method: 'PATCH',
    headers: { ...store.get().Auth.token, 'Content-Type': 'application/vnd.api+json', 'Accept': 'application/vnd.api+json' },
    body: JSON.stringify(body)
  });

  if (!response.ok) {
    return Promise.reject({ status: response.status, response: await response.json() })
  }

  return response.json();
}

export async function uploadFile<T extends BaseJsonApiResourceObject>(endpoint: string, body: FormData, method = 'POST'): Promise<JsonApiResponseType<T>> {
  const response = await fetch(endpoint, {
    method, body,
    headers: { ...store.get().Auth.token, 'Content-Type': 'multipart/form-data', 'Accept': 'application/vnd.api+json' },
  });
  const json = await response.json();

  if (!response.ok) {
    throw new ApiError(response.status, json);
  }

  return json;
}

export interface PatchFileBody {
  name?: string;
  meta?: ActiveRecordFileMeta;
}

export interface BulkPatchFileBodyElement extends PatchFileBody {
  id: number;
}

export type BulkPatchFileBody = BulkPatchFileBodyElement[];

export async function patchFile<T extends BaseJsonApiResourceObject>(endpoint: string, body: PatchFileBody, signal?: AbortSignal | null): Promise<JsonApiResponseType<T>> {
  const response = await fetch(endpoint, {
    method: 'PATCH',
    headers: { ...store.get().Auth.token, 'Content-Type': 'application/json', 'Accept': 'application/vnd.api+json' },
    body: JSON.stringify({ data: body }),
    signal
  });
  const json = await response.json();

  if (!response.ok) {
    throw new ApiError(response.status, json);
  }

  return json;
}

export async function bulkPatchFile<T extends BaseJsonApiResourceObject>(endpoint: string, body: BulkPatchFileBody, signal?: AbortSignal | null): Promise<JsonApiResponseType<T>> {
  const response = await fetch(endpoint, {
    method: 'PATCH',
    headers: { ...store.get().Auth.token, 'Content-Type': 'application/json', 'Accept': 'application/vnd.api+json' },
    body: JSON.stringify({ data: body }),
    signal
  });
  const json = await response.json();

  if (!response.ok) {
    throw new ApiError(response.status, json);
  }

  return json;
}

export async function deleteFile<T extends BaseJsonApiResourceObject>(endpoint: string, signal?: AbortSignal | null): Promise<JsonApiResponseType<T>> {
  const response = await fetch(endpoint, {
    method: 'DELETE',
    headers: { ...store.get().Auth.token, 'Content-Type': 'application/json', 'Accept': 'application/vnd.api+json' },
    signal
  });
  const json = await response.json();

  if (!response.ok) {
    throw new ApiError(response.status, json);
  }

  return json;
}

export function createResourceIdentifier<T extends ModelType = ModelType>(type: T, id: string): JsonApiResourceIdentifier<T> {
  return { id, type };
}

export function createBelongsToIdentifier<T extends ModelType = ModelType>(type: T, id?: string): JsonApiBelongsToRelationship<T> | undefined {
  return isUndefined(id) ? undefined : { data: createResourceIdentifier(type, id) };
}

export function createNullableBelongsToIdentifier<T extends ModelType = ModelType>(type: T, id?: string | null): JsonApiBelongsToRelationshipNullable<T> | undefined {
  return isUndefined(id) ? undefined : { data: hasString(id) ? createResourceIdentifier(type, id) : null };
}

export async function deleteEntity(endpoint: string, id: string): Promise<void> {
  const response = await fetch(`${trimEnd(endpoint, '/')}/${id}`, {
    method: 'DELETE',
    headers: { ...store.get().Auth.token, 'Accept': 'application/vnd.api+json' }
  });

  if (!response.ok) {
    return Promise.reject({ status: response.status, response: await response.json() });
  }
}

export async function makeJsonApiRequest<T>(endpoint: string, method: 'GET' | 'POST' | 'PATCH' | 'DELETE', body?: T): Promise<any> {
  const headers: HeadersInit = { ...store.get().Auth.token, 'Accept': 'application/vnd.api+json' };

  if (!isNil(body)) {
    headers['Content-Type'] = 'application/vnd.api+json';
  }

  const response = await fetch(endpoint, { method, headers, body: isNil(body) ? undefined : JSON.stringify(body) });
  const responseBodyText = await response.text();
  const responseBodyJson = hasString(responseBodyText) ? JSON.parse(responseBodyText) : undefined;

  if (!response.ok) {
    return Promise.reject({ status: response.status, response: responseBodyJson });
  }

  return responseBodyJson;
}

export function setBelongsTo(record: BaseRecord | null, relationship: string, id: string, type: string): BaseRecord | null {
  const rel = record?.relationships?.[relationship];

  if (isBelongsTo(rel)) {
    return {
      ...record!,
      relationships: {
        ...record!.relationships,
        [relationship]: {
          ...rel,
          data: isNull(id) ? id : { id, type }
        }
      }
    };
  }

  return record;
}

export function setOwner(record: BaseRecord | null, id: string): BaseRecord | null {
  return setBelongsTo(record, 'owner', id, 'user');
}

export function setMeta(record: BaseRecord | null, path: string, setFunc: (value: any) => any): BaseRecord | null {
  if (isNil(record)) {
    return record;
  }

  return { ...record, meta: cloneDeep(set(record.meta, path, setFunc(get(record.meta, path)))) };
}

export async function fetchHistory<T extends BaseRecord>(record: T, endpoint: string, options?: RequestInit): Promise<JsonApiHistoryResponseType<T>> {
  const response = await fetch(`/api/v2/${endpoint}/${record.id}/history`, {
    ...options,
    method: 'GET',
    headers: { ...store.get().Auth.token, 'Content-Type': 'application/json', 'Accept': 'application/json' }
  });
  const json = await response.json();

  if (!response.ok) {
    throw new ApiError(response.status, json);
  }

  return json;
}

export async function fetchEntities<T extends BaseRecord>(endpoint: string, queryParams?: QueryParams, options?: RequestInit): Promise<JsonApiCollectionResponseType<T>> {
  const queryString = queryParams ? `?${joinQueryParams(queryParams)}` : '';
  const response = await fetch(`/api/v2/${endpoint}${queryString}`, {
    ...options,
    method: 'GET',
    headers: { ...store.get().Auth.token, 'Accept': 'application/vnd.api+json' }
  });
  const json = await response.json();

  if (!response.ok) {
    throw new ApiError(response.status, json);
  }

  return json;
}
