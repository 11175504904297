import React from 'react';

const IconListingCircle = ({
  width, style, height, className, fill
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44.7 42.7"
      width={width}
      style={style}
      height={height}
      className={className}
    >
      <path fill={fill} d="M21.3,0.5C9.8,0.5,0.5,9.8,0.5,21.3c0,11.5,9.3,20.8,20.8,20.8c11.5,0,20.8-9.3,20.8-20.8
        C42.1,9.8,32.8,0.5,21.3,0.5z M35.3,35.3c-3.6,3.6-8.5,5.8-13.9,5.8c-5.4,0-10.4-2.2-13.9-5.8c-3.6-3.6-5.8-8.5-5.8-13.9
        c0-5.4,2.2-10.4,5.8-13.9c3.6-3.6,8.5-5.8,13.9-5.8c5.4,0,10.4,2.2,13.9,5.8C38.8,11,41,15.9,41,21.3C41,26.8,38.8,31.7,35.3,35.3z
        "/>
      <path fill={fill} d="M34.1,9.2H8.6c-0.1,0-0.3,0.1-0.4,0.2C8.1,9.5,8.1,9.6,8.1,9.8v19.3c0,0.1,0.1,0.3,0.2,0.4s0.2,0.2,0.4,0.2
        h12.2V38c0,0.3,0.2,0.6,0.6,0.6c0.3,0,0.6-0.2,0.6-0.6v-8.3h12.2c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.2-0.4V9.8
        c0-0.1-0.1-0.3-0.2-0.4C34.4,9.3,34.2,9.2,34.1,9.2z M9.2,10.3h24.3v18.2H9.2V10.3z"/>
      <path fill={fill} d="M22,26c0,0.1,0.1,0.3,0.2,0.4s0.2,0.2,0.4,0.2h4.2c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.2-0.4v-5.7h2.2
        c0.2,0,0.4-0.1,0.5-0.4s0-0.5-0.2-0.6l-2.5-2.1l0-3.1c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-2.3c-0.1,0-0.3,0.1-0.4,0.2
        c-0.1,0.1-0.2,0.2-0.2,0.4v0.2l-2.1-1.8c-0.2-0.2-0.5-0.2-0.7,0l-8.1,6.8c-0.2,0.2-0.2,0.4-0.2,0.6s0.3,0.4,0.5,0.4h2.2l0,5.7
        c0,0.1,0.1,0.3,0.2,0.4s0.2,0.2,0.4,0.2h4.2c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.2-0.4V22H22L22,26z M20.2,20.9
        c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.2,0.4v3.9h-3.1l0-5.7c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2h-1.2l6.6-5.6
        l2.7,2.3c0.2,0.1,0.4,0.2,0.6,0.1c0.2-0.1,0.3-0.3,0.3-0.5v-0.8h1.2v2.8c0,0.2,0.1,0.3,0.2,0.4l1.5,1.3h-1.2
        c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.2,0.4v5.7h-3.1v-3.9c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.2H20.2z"/>
    </svg>
 )
};

export default IconListingCircle;
