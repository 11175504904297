import React, { Suspense } from 'react';
import { Router, Switch, Redirect } from 'react-router-dom';
import history from 'src/helpers/history';
import routes from 'src/routes';
import RouteWithSubRoutes from 'src/components/RouteWithSubRoutes';
import { notFoundPath } from 'src/helpers/routes';
import { LoadingSpinner } from 'src/components/elements/LoadingSpinner/LoadingSpinner';
import { map } from 'lodash';

export const App: React.FunctionComponent = () => {
  return (
    <Suspense fallback={<LoadingSpinner className="flex-grow-1" />}>
      <Router history={history}>
        <Switch>
          {map(routes, (route, i) => <RouteWithSubRoutes key={`route-${i}`} {...route} />)}
          <Redirect to={notFoundPath()} />
        </Switch>
      </Router>
    </Suspense>
  );
};

export default App;
