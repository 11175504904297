import { contactListNewPath } from 'src/helpers/routes';
import listingNew from 'src/routes/listings/New';
import { fetchListingCollections } from 'src/actions/Listings';
import { API_CALL } from 'src/middleware/API';

const prepareData = ({ store, params }) => {
  const state = store.get();
  if (state.Auth.connected === false) return;
  if (Object.keys(state.ListingCollections.items).length > 0) return;
  const accessToken = state.Auth.token;
  store.dispatch(API_CALL, fetchListingCollections({ accessToken }));
};

export default Object.assign({}, listingNew, { path: contactListNewPath(), prepareData })
