export const FETCH_INTERNAL_BROKERS_REQUEST = 'FETCH_INTERNAL_BROKERS_REQUEST';
export const FETCH_INTERNAL_BROKERS_SUCCESS = 'FETCH_INTERNAL_BROKERS_SUCCESS';
export const FETCH_INTERNAL_BROKERS_ERROR = 'FETCH_INTERNAL_BROKERS_ERROR';

export const FETCH_INTERNAL_BROKER_REQUEST = 'FETCH_INTERNAL_BROKER_REQUEST';
export const FETCH_INTERNAL_BROKER_SUCCESS = 'FETCH_INTERNAL_BROKER_SUCCESS';
export const FETCH_INTERNAL_BROKER_ERROR = 'FETCH_INTERNAL_BROKER_ERROR';
export const FETCH_INTERNAL_BROKER_RESET = 'FETCH_INTERNAL_BROKER_RESET';

export const FETCH_INTERNAL_BROKER_COLLECTION_REQUEST = 'FETCH_INTERNAL_BROKER_COLLECTION_REQUEST';
export const FETCH_INTERNAL_BROKER_COLLECTION_SUCCESS = 'FETCH_INTERNAL_BROKER_COLLECTION_SUCCESS';
export const FETCH_INTERNAL_BROKER_COLLECTION_ERROR = 'FETCH_INTERNAL_BROKER_COLLECTION_ERROR';

export const DELETE_INTERNAL_BROKER_REQUEST = 'DELETE_INTERNAL_BROKER_REQUEST';
export const DELETE_INTERNAL_BROKER_SUCCESS = 'CREATE_INTERNAL_BROKER_SUCCESS';
export const DELETE_INTERNAL_BROKER_ERROR = 'DELETE_INTERNAL_BROKER_ERROR';

export const DELETE_INTERNAL_BROKERS_REQUEST = 'DELETE_INTERNAL_BROKERS_REQUEST';
export const DELETE_INTERNAL_BROKERS_SUCCESS = 'DELETE_INTERNAL_BROKERS_SUCCESS';
export const DELETE_INTERNAL_BROKERS_ERROR = 'DELETE_INTERNAL_BROKERS_ERROR';

export const SUBMIT_INTERNAL_BROKER_REQUEST = 'SUBMIT_INTERNAL_BROKER_REQUEST';
export const SUBMIT_INTERNAL_BROKER_SUCCESS = 'SUBMIT_INTERNAL_BROKER_SUCCESS';
export const SUBMIT_INTERNAL_BROKER_ERROR = 'SUBMIT_INTERNAL_BROKER_ERROR';
export const SUBMIT_INTERNAL_BROKER_RESET = 'SUBMIT_INTERNAL_BROKER_RESET';
