import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useStoreon from 'src/helpers/storeon';
import Form from 'src/components/views/offers/Form';
import { leadPath } from 'src/helpers/routes';
import { createOffer } from 'src/actions/Offers';
import dayjs from 'dayjs';
import IconOfferCircle from 'src/constants/icons/IconOfferCircle';

const defaultValues = {
  listingId: '',
  leadId: '',
  parentId: '',
  status: 'active',
  amountCents: 0,
  amountCurrency: '',
  financeMethod: '',
  date: dayjs().format('YYYY-MM-DD'),
  offeredBy: '',
  dateAccepted: '',
  additionalOptions: '',
  terms: '',
  seller: '',
  contactUser: {},
  listingPrice: '',
  potentialBuyer: '',
  buyersAgent: {},
  specific: {}
};

export default (props) => {
  const { t } = useTranslation();
  const {
    SubmitOffer, Lead, OfferCollections
  } = useStoreon('SubmitOffer', 'Lead', 'OfferCollections');
  const [specific, setSpecific] = useState(null);
  const [currListing, setCurrListing] = useState(null);
  const [contactUser, setContactUser] = useState(null);

  const serverSideValidation = new Promise(function(resolve, reject) {
    const { errors, error, success, sendRequest } = SubmitOffer;
    if (!sendRequest && success) resolve();
    if (!sendRequest && error) reject(errors);
  });

  const { match: { params: { leadId } } } = props;
  let successPath = leadPath(leadId);
  const { lead } = Lead;

  useEffect(() => {
    if (OfferCollections.loaded && Lead.loaded) {
      const custom = {};
      const { fields: { rows }, listing = [] } = OfferCollections.items;
      if (rows) {
        const arrOfNames = rows.map(row => Object.values(row));
        (arrOfNames.flat()).forEach(el => {
          (Object.keys(el)).forEach(key => {
            Object.assign(custom, { [key]: '' });
          });
        });
      }
      const filteredListing = listing.find(el => el.value === +Lead.lead.listingId ) || {};
      const curr = filteredListing.contactUser ? filteredListing.contactUser.value : ''
      setContactUser(curr);
      setCurrListing(filteredListing);
      setSpecific(custom);
    }
  }, [OfferCollections, Lead]);
  return (
    <div className="row mb-5">
      <div className="col">
        <div className="card">
          <div className="card-header">
          <div className="card-title form-inline">
            <div className="mr-2 mt-1">
              <IconOfferCircle width={35} height={35} fill="#182852" />
            </div>{t('offers.newTitle')}
          </div>
          </div>
          <div className="card-body p-5">
            {Object.keys(lead).length > 0 && currListing && specific &&
              <Form
                defaultValues={{
                  ...defaultValues,
                  specific,
                  leadId,
                  potentialBuyer: lead.contact,
                  buyersAgent: { value: lead.contactUser.value },
                  listingId: +lead.listingId,
                  seller: currListing.propertyOwner,
                  contactUser: { value: contactUser }
                }}
                successPath={successPath}
                submitHandler={createOffer}
                serverSideValidation={serverSideValidation}
                {...props}
                leadId={leadId}
                lead={lead.idValue}
                defaultOfferType={lead.leadTypeOrig}
              >
                <div className="row pt-3">
                  <div className="col">
                    <div className="float-right">
                    <button disabled={SubmitOffer.sendRequest} className="btn btn-primary float-right">
                      {t('buttons.create')}
                    </button>
                    </div>
                  </div>
                </div>
              </Form>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
