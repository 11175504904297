import { teamsPath } from 'src/helpers/routes';
import { lazy } from 'react';
import fetchDataTable from 'src/actions/DataTable';
import { API_CALL } from 'src/middleware/API';
import dataTableParams from 'src/datatables/teams';

const Teams = lazy(() => import('src/components/views/admin/teams'));

export default {
  path: teamsPath(),
  exact: true,
  strict: true,
  component: Teams,
  protected: true,
  perform: "admin/teams:index",
  title: "Teams",
  prepareData: ({ store }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    const { role } = state.Auth.user;
    const endpoint = "teams";
    const { query } = dataTableParams({ role });
    store.dispatch(API_CALL, fetchDataTable({ accessToken, query, endpoint }));
  }
};
