import { forgotPasswordPath } from 'src/helpers/routes';
import ForgotPasswordPage from 'src/components/views/forgotPassword';
import LoginLayout from 'src/components/views/loginLayout';

export default {
  path: forgotPasswordPath(),
  exact: true,
  strict: true,
  component: ForgotPasswordPage,
  layout: LoginLayout,
  perform: 'user:signin',
};
