import * as types from 'src/constants/actionTypes/ActivitiesActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  item: {},
  success: false
}

export default store => {
  store.on('@init', () => ({ Activity: initialState }));

  store.on(types.FETCH_ACTIVITY_REQUEST, ({ Activity }) =>
    ({ Activity: { ...initialState, isFetching: true }}));
  store.on(types.FETCH_ACTIVITY_SUCCESS, ({ Activity }, { response }) => {
    return ({ Activity: { ...Activity, isFetching: false, success: true, item: response.data }})
  });
  store.on(types.FETCH_ACTIVITY_ERROR, ({ Activity }) =>
    ({ Activity: {...Activity, error: true, isFetching: false }}));
}
