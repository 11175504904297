import React from 'react';
import UncontrolledCollapse from 'src/components/elements/shared/UncontrolledCollapse';
import Link from 'src/helpers/Link';
import { useTranslation } from 'react-i18next';
import { leadNewPath, leadPath, contactPath, userPath } from 'src/helpers/routes';

const pad = (a, b) => ([1e15]+a).slice(-b);

export default ({ items = [], isNewLead }) => {
  const { t } = useTranslation();

  const handleNewLead = (event) => {
    event.preventDefault();
    window.open(leadNewPath(), "_blank");
  };

  return (
    <UncontrolledCollapse
      label={`${t("dashboard.subFolders.pendingLeads")} (${items.length})`}
      buttonHeaderLabel={t('buttons.new')}
      handlerOnClick={handleNewLead}
      withButton={isNewLead}
    >
      <div className="table-responsive">
        <table className="table card-table table-vcenter text-nowrap">
          <thead>
            <tr>
              <th>{t("leads.id")}</th>
              <th>{t("leads.leadType")}</th>
              <th>{t("leads.contact")}</th>
              <th>{t("leads.qualityScore")}</th>
              <th>{t("leads.createdAt")}</th>
              <th>{t("leads.owner")}</th>
            </tr>
          </thead>
          <tbody>
            {items.length > 0 && items.map((lead, index) =>
              <tr key={`lead-${index}`}>
                <td>
                  <span className="link-block">
                    <Link to={leadPath(lead.id)} className="mr-2" target="_blank">
                      {`LE-${pad(lead.id, 7)}`}
                    </Link>
                  </span>
                </td>
                <td>{lead.leadType}</td>
                <td>
                  <span className="link-block">
                    <Link to={contactPath(lead.contactId)} className="mr-2" target="_blank">
                      {lead.contact}
                    </Link>
                  </span>
                </td>
                <td>{lead.qualityScore}</td>
                <td>{lead.createdAt}</td>
                <td>
                  <span className="link-block">
                    <Link to={userPath(lead.ownerId)} className="mr-2" target="_blank">
                      {lead.owner}
                    </Link>
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </UncontrolledCollapse>
  )
}
