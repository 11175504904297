import * as types from 'src/constants/actionTypes/TeamsActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  items: {},
  success: false
}

export default store => {
  store.on('@init', () => ({ TeamCollections: initialState }));

  store.on(types.FETCH_TEAM_COLLECTION_REQUEST, ({ TeamCollections }) =>
    ({ TeamCollections: { ...initialState, isFetching: true }}));
  store.on(types.FETCH_TEAM_COLLECTION_SUCCESS, ({ TeamCollections }, { response }) =>
    ({ TeamCollections: { ...TeamCollections, items: response.data, success: true }}));
  store.on(types.FETCH_TEAM_COLLECTION_ERROR, ({ TeamCollections }) =>
    ({ TeamCollections: {...TeamCollections, error: true, success: false }}));
}
