import { transactionNewInternalBrokerPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchInternalBrokerCollections } from 'src/actions/InternalBrokers';
import { fetchTransaction } from 'src/actions/Transactions';
import { API_CALL } from 'src/middleware/API';

const component = lazy(() => import('src/components/views/internalBrokers/New'));

export default {
  path: transactionNewInternalBrokerPath(),
  exact: true,
  strict: true,
  component,
  perform: 'internalBrokers:new',
  protected: true,
  prepareData: ({ store, params }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    if (Object.keys(state.InternalBrokerCollections.items).length === 0) {
      store.dispatch(API_CALL, fetchInternalBrokerCollections({ accessToken }));
    }
    store.dispatch(API_CALL, fetchTransaction({ accessToken, id: params.transactionId }));
  }
};
