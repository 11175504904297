import * as types from 'src/constants/actionTypes/ActivitiesActionTypes';

const initialState = {
  isFetching: false,
  success: false,
  error: false,
  items: []
}

export default store => {
  store.on('@init', () => ({ Events: initialState }));

  store.on(types.FETCH_EVENTS_REQUEST, ({ Events }) =>
    ({ Events: { ...Events, isFetching: true, success: false }}));
  store.on(types.FETCH_EVENTS_SUCCESS, ({ Events }, { response }) =>
    ({ Events: { ...Events, items: response.data, success: true, isFetching: false }}));
  store.on(types.FETCH_EVENTS_ERROR, ({ Events }) =>
    ({ Events: {...Events, error: true, isFetching: false }}));
}
