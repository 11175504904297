import * as types from 'src/constants/actionTypes/UsersActionTypes';

const initialState = {
  isDestroing: false,
  error: false,
  successDestroy: false,
}

export default store => {
  store.on('@init', () => ({ UserDestroy: initialState }));

  store.on(types.DELETE_USERS_REQUEST, ({ UserDestroy }) =>
    ({ UserDestroy: { ...UserDestroy, isDestroing: true, successDestroy: false }}));
  store.on(types.DELETE_USERS_SUCCESS, ({ UserDestroy }) =>
    ({ UserDestroy: {...UserDestroy, successDestroy: true }}));
  store.on(types.DELETE_USERS_ERROR, ({ UserDestroy }) =>
    ({ UserDestroy: {...UserDestroy, error: true }}));

  store.on(types.DELETE_USER_REQUEST, ({ UserDestroy }) =>
    ({ UserDestroy: { ...UserDestroy, isDestroing: true, successDestroy: false }}));
  store.on(types.DELETE_USER_SUCCESS, ({ UserDestroy }) =>
    ({ UserDestroy: {...UserDestroy, successDestroy: true }}));
  store.on(types.DELETE_USER_ERROR, ({ UserDestroy }) =>
    ({ UserDestroy: {...UserDestroy, error: true }}));
}
