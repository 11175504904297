import * as types from 'src/constants/actionTypes/BaseCompanyActionTypes';

const fetchBaseCompany = ({ accessToken, query }) => {
  return ({
    query,
    endpoint: `base_company`,
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_BASECOMPANY_REQUEST,
      types.FETCH_BASECOMPANY_SUCCESS,
      types.FETCH_BASECOMPANY_ERROR
    ]
  })
};

const updateBaseCompany = ({ accessToken, query, id, attach }) => {
  return ({
    query,
    attach,
    endpoint: `base_company/${id}`,
    method: 'PUT',
    credentials: true,
		accessToken,
    types: [
      types.SUBMIT_BASECOMPANY_REQUEST,
      types.SUBMIT_BASECOMPANY_SUCCESS,
      types.SUBMIT_BASECOMPANY_ERROR
    ]
  })
};

export { updateBaseCompany, fetchBaseCompany }
