import { FETCH_ALL_COMPANIES, FETCH_ALL_COMPANIES_SUCCESS, FETCH_ALL_COMPANIES_ERROR, UPDATE_COMPANY_RECORD } from 'src/constants/actionTypes/entities/company';
import { onEntityStateInit, onFetchAll, onFetchAllSuccess, onFetchAllError, onUpdateRecord } from 'src/helpers/entity-store';

export default store => {
  store.on('@init', (state) => onEntityStateInit(state, 'company'));

  store.on(FETCH_ALL_COMPANIES, (state) => onFetchAll(state, 'company'));
  store.on(FETCH_ALL_COMPANIES_SUCCESS, (state, { response }) => onFetchAllSuccess(state, 'company', response));
  store.on(FETCH_ALL_COMPANIES_ERROR, (state, { error }) => onFetchAllError(state, 'company', error));

  store.on(UPDATE_COMPANY_RECORD, (state, { id, company }) => onUpdateRecord(state, 'company', id, company));
};
