export const FETCH_TRANSACTIONS_REQUEST = 'FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'FETCH_TRANSACTIONS_ERROR';

export const FETCH_TRANSACTION_REQUEST = 'FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'FETCH_TRANSACTION_ERROR';
export const FETCH_TRANSACTION_RESET = 'FETCH_TRANSACTION_RESET';

export const FETCH_TRANSACTION_COLLECTION_REQUEST = 'FETCH_TRANSACTION_COLLECTION_REQUEST';
export const FETCH_TRANSACTION_COLLECTION_SUCCESS = 'FETCH_TRANSACTION_COLLECTION_SUCCESS';
export const FETCH_TRANSACTION_COLLECTION_ERROR = 'FETCH_TRANSACTION_COLLECTION_ERROR';

export const DELETE_TRANSACTION_REQUEST = 'DELETE_TRANSACTION_REQUEST';
export const DELETE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS';
export const DELETE_TRANSACTION_ERROR = 'DELETE_TRANSACTION_ERROR';

export const DELETE_TRANSACTIONS_REQUEST = 'DELETE_TRANSACTIONS_REQUEST';
export const DELETE_TRANSACTIONS_SUCCESS = 'DELETE_TRANSACTIONS_SUCCESS';
export const DELETE_TRANSACTIONS_ERROR = 'DELETE_TRANSACTIONS_ERROR';

export const SUBMIT_TRANSACTION_REQUEST = 'SUBMIT_TRANSACTION_REQUEST';
export const SUBMIT_TRANSACTION_SUCCESS = 'SUBMIT_TRANSACTION_SUCCESS';
export const SUBMIT_TRANSACTION_ERROR = 'SUBMIT_TRANSACTION_ERROR';
export const SUBMIT_TRANSACTION_RESET = 'SUBMIT_TRANSACTION_RESET';
