import { listingPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchListing, fetchListingCollections } from 'src/actions/Listings';
import fetchingMeta from 'src/actions/files/fetchMeta';
import { fetchActivityCollections } from 'src/actions/Activities';
import { API_CALL } from 'src/middleware/API';

import Layout from 'src/components/views/showLayout';
import { fetchContacts } from 'src/actions/Contacts';

const ListingShow = lazy(() => import('src/components/views/listings/Show'));

export default {
  path: listingPath(),
  exact: true,
  strict: true,
  component: ListingShow,
  perform: "listings:show",
  layout: Layout,
  prepareData: ({ store, params }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const { dispatch } = store;
    const { id } = params;
    const accessToken = state.Auth.token;
    if (Object.keys(state.ActivityCollections.items).length ===  0) {
      dispatch(API_CALL, fetchActivityCollections({ accessToken }));
    }
    if (Object.keys(state.ListingCollections.items).length === 0) {
      dispatch(API_CALL, fetchListingCollections({ accessToken }));
    };
    const query = { withProperties: true };
    dispatch(API_CALL, fetchListing({ accessToken, id, query }));
    dispatch(API_CALL, fetchContacts({ accessToken }));
    dispatch(API_CALL, fetchingMeta({ accessToken, endpoint: 'listings', id }));
  }
};
