import { profileEditPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchProfile } from 'src/actions/Profile';
import { fetchUserCollections } from 'src/actions/Users';
import { API_CALL } from 'src/middleware/API';

const editProfile = lazy(() => import('src/components/views/profile/Edit'));

export default {
  path: profileEditPath(),
  exact: true,
  strict: true,
  component: editProfile,
  protected: true,
  perform: 'profile:edit',
  title: 'Edit Profile',
  prepareData: ({ store }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    const { user: { id } } = state.Auth;
    if (Object.keys(state.UserCollections.items).length ===  0) {
      store.dispatch(API_CALL, fetchUserCollections({ accessToken }));
    }
    const query = { noTypeCast: true }
    store.dispatch(API_CALL, fetchProfile({ accessToken, id, query }));
  }
};
