import { reportsPath } from 'src/helpers/routes';
import { API_CALL } from 'src/middleware/API';
import fetchReport from 'src/actions/Report';
import component from 'src/components/views/reports';
import { listOfModels, listViewByRole, listFilterByViewAndRole } from 'src/components/views/reports/helper';

export default {
  path: reportsPath(),
  exact: true,
  strict: true,
  component,
  protected: true,
  perform: 'report:index',
  prepareData: ({ store }) => {
    const state = store.get();

    if (state.Auth.connected === false) {
      return;
    }

    const accessToken = state.Auth.token;
    const endpoint = listOfModels[0];
    const { role } = state.Auth.user;
    const by = listFilterByViewAndRole({ view: endpoint, role })[0].label;
    const ownedBy = listViewByRole({ view: endpoint, role, by })[0];
    const query = { options: { by, ownedBy } };

    store.dispatch(API_CALL, fetchReport({ accessToken, endpoint, query }));
  }
};
