export const FETCH_TEAMS_REQUEST = 'FETCH_TEAMS_REQUEST';
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS';
export const FETCH_TEAMS_ERROR = 'FETCH_TEAMS_ERROR';

export const FETCH_TEAM_REQUEST = 'FETCH_TEAM_REQUEST';
export const FETCH_TEAM_SUCCESS = 'FETCH_TEAM_SUCCESS';
export const FETCH_TEAM_ERROR = 'FETCH_TEAM_ERROR';
export const FETCH_TEAM_RESET = 'FETCH_TEAM_RESET';

export const FETCH_TEAM_COLLECTION_REQUEST = 'FETCH_TEAM_COLLECTION_REQUEST';
export const FETCH_TEAM_COLLECTION_SUCCESS = 'FETCH_TEAM_COLLECTION_SUCCESS';
export const FETCH_TEAM_COLLECTION_ERROR = 'FETCH_TEAM_COLLECTION_ERROR';

export const DELETE_TEAM_REQUEST = 'DELETE_TEAM_REQUEST';
export const DELETE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS';
export const DELETE_TEAM_ERROR = 'DELETE_TEAM_ERROR';

export const DELETE_TEAMS_REQUEST = 'DELETE_TEAMS_REQUEST';
export const DELETE_TEAMS_SUCCESS = 'DELETE_TEAMS_SUCCESS';
export const DELETE_TEAMS_ERROR = 'DELETE_TEAMS_ERROR';

export const SUBMIT_TEAM_REQUEST = 'SUBMIT_TEAM_REQUEST';
export const SUBMIT_TEAM_SUCCESS = 'SUBMIT_TEAM_SUCCESS';
export const SUBMIT_TEAM_ERROR = 'SUBMIT_TEAM_ERROR';
export const SUBMIT_TEAM_RESET = 'SUBMIT_TEAM_RESET';
