import React from 'react';
import { camelCase, isBoolean, intersection, isArray, isNil, filter, isEmpty, includes, find, isEqual } from 'lodash';
import { hasString } from 'src/helpers/string';

export interface DisabledRuleIntersection<T> {
  operator: 'intersection';
  value: T[];
}

export interface HiddenRuleIntersection<T> {
  operator: 'intersection';
  value: T[];
}

export interface DisabledRule<T> extends DisabledRuleIntersection<T>, HiddenRuleIntersection<T> {
  source: string;
  result: boolean;
}

export type HiddenRule<T> = DisabledRule<T>;

export interface DependentDropdownRule {
  rule: string;
  source: string;
  allowNil: boolean;
  config: {
    [sourceValue: string]: any[];
  };
}

export function applyDisabledRule<TValue, TFormData extends { [key: string]: any }>(rule: DisabledRule<TValue>, targetName: keyof TFormData, targetValue: TValue, formData: TFormData, setFormData: React.Dispatch<React.SetStateAction<TFormData>>, setResult: React.Dispatch<React.SetStateAction<boolean>>): void {
  const source = camelCase(rule.source);
  const sourceValue = formData[source] ?? [];
  const ruleResult = rule.result ?? true;

  if (!isBoolean(ruleResult)) {
    throw new Error(`Invalid result value for rule 'disabled' and operator '${rule.operator}' - expected boolean`)
  }

  switch (rule.operator) {
    case 'intersection': {
      const ruleValue = rule.value ?? [];

      if (!isArray(sourceValue)) {
        throw new Error(`Invalid source value for rule 'disabled' and operator '${rule.operator}': expected array`);
      }

      if (!isArray(ruleValue)) {
        throw new Error(`Invalid rule value for rule 'disabled' and operator '${rule.operator}': expected array`);
      }

      const result = intersection(ruleValue, sourceValue).length > 0 ? ruleResult : !ruleResult;

      setResult(result);

      if (result && !isNil(targetValue)) {
        setFormData(prev => ({ ...prev, [targetName]: null }));
      }

      break;
    }
    default:
      throw new Error(`Undefined operator for rule 'disabled': ${rule.operator}`);
  }
}

export function applyHiddenRule<TValue, TFormData extends { [key: string]: any }>(rule: HiddenRule<TValue>, targetName: keyof TFormData, targetValue: TValue, formData: TFormData, setFormData: React.Dispatch<React.SetStateAction<TFormData>>, setResult: React.Dispatch<React.SetStateAction<boolean>>): void {
  return applyDisabledRule(rule, targetName, targetValue, formData, setFormData, setResult);
}

export function applyDependentDropdownRule<TOption extends { value: any }, TFormData extends { [key: string]: any }>(rule: DependentDropdownRule, targetName: string, targetValue: string, options: TOption[], setOptions: React.Dispatch<React.SetStateAction<TOption[]>>, formData: TFormData, setFormData: React.Dispatch<React.SetStateAction<TFormData>>): void {
  const hasUnfilteredOptions = !isEmpty(options);
  const source = camelCase(rule.source);
  const sourceValue = formData[source];
  const isSourceValueNil = isNil(sourceValue);
  const hasValue = hasString(targetValue);

  let result: TOption[] = [...options];
  let mustSetEmptyValue = false;

  if (isSourceValueNil && isNil(rule.config.nil)) {
    result = [];
    mustSetEmptyValue = hasValue;
  } else {
    const validOptions = rule.config[isSourceValueNil ? 'nil' : sourceValue];

    result = filter(result, ({ value: oValue }) => includes(validOptions, oValue));
    mustSetEmptyValue = hasValue && !find(result, ({ value: oValue }) => isEqual(targetValue, oValue));
  }

  setOptions(result);

  if (hasUnfilteredOptions && mustSetEmptyValue) {
    setFormData(prev => ({ ...prev, [targetName]: null }));
  }
}
