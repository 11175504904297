import { hasString } from 'src/helpers/string';
import { isArray } from 'util';
import { isNil } from 'lodash';
import { ModelType } from 'src/-types/model';

export type RecordErrors<A = any, R = any> = {
  [key in keyof A | keyof R]: string[];
};

export interface RecordMeta {
  isFetching: number;
  isSaving: boolean;
  lastLoadedAt?: number;
}

export interface RecordCollectionErrors {
  [key: string]: RecordCollectionErrors | any;
}

export interface RecordCollectionMeta {
  isFetching: number;
}

export type ResourceObjectMeta = any;
export type TopLevelErrors = any;
export type TopLevelMeta = any;

export interface JsonApiResourceIdentifier<T extends ModelType = ModelType> {
  id: string;
  type: T;
}

export interface JsonApiBelongsToRelationship<T extends ModelType> {
  data: JsonApiResourceIdentifier<T>;
}

export interface JsonApiBelongsToRelationshipNullable<T extends ModelType> {
  data: JsonApiResourceIdentifier<T> | null;
}

export type JsonApiBelongsToType<T> =
  T extends Record<infer M, infer _A, infer _R> ? JsonApiBelongsToRelationship<M> :
  never;

export type JsonApiBelongsToNullableType<T> =
  T extends Record<infer M, infer _A, infer _R> ? JsonApiBelongsToRelationshipNullable<M> :
  never;

export interface JsonApiHasManyRelationship<T extends ModelType> {
  data: JsonApiResourceIdentifier<T>[] | null;
}

export interface BaseJsonApiResourceObject<T extends ModelType = ModelType, A = any, R = any> {
  id: string;
  attributes: A;
  relationships: R;
  type: T;
  meta?: ResourceObjectMeta;
  errors?: TopLevelErrors;
}

export interface JsonApiResourceObject<T extends ModelType, A, R> extends BaseJsonApiResourceObject<T, A, R> {
  // noop
}

interface PartialJsonApiResourceObject<T extends ModelType, A, R> {
  id: string;
  attributes?: Partial<A>;
  relationships?: Partial<R>;
  type: T;
}

export interface BaseRecord<T extends ModelType = ModelType, A = any, R = any> extends BaseJsonApiResourceObject<T, A, R> {
  __meta: RecordMeta;
}

export interface Record<T extends ModelType, A = any, R = any> extends BaseRecord<T, A, R> {
  // noop
}

export type RecordType<T> = T extends JsonApiResourceObject<infer M, infer A, infer R> ? Record<M, A, R> : never;

export interface BaseRecordCollection<T extends BaseRecord = BaseRecord> {
  data: { [key: string]: T };
  errors?: TopLevelErrors;
  meta?: TopLevelMeta;
  __meta: RecordCollectionMeta;
}

export interface RecordCollection<T extends Record<M, A, R>, M extends ModelType, A, R> extends BaseRecordCollection<T> {
  // noop
}

export type RecordCollectionType<T> = T extends Record<infer M, infer A, infer R> ? RecordCollection<T, M, A, R> : never;

export interface BaseJsonApiResponse<T extends BaseJsonApiResourceObject = BaseJsonApiResourceObject> {
  data: T;
  included?: BaseJsonApiResourceObject[];
  errors?: TopLevelErrors;
  meta?: TopLevelMeta;
}

export interface JsonApiResponse<M extends ModelType, A, R> extends BaseJsonApiResponse<JsonApiResourceObject<M, A, R>> {
  // noop
}

export interface BaseJsonApiCollectionResponse<T extends BaseJsonApiResourceObject = BaseJsonApiResourceObject> {
  data: T[];
  included?: BaseJsonApiResourceObject[];
  errors?: TopLevelErrors;
  meta?: TopLevelMeta;
}

export interface JsonApiCollectionResponse<M extends ModelType, A, R> extends BaseJsonApiCollectionResponse<JsonApiResourceObject<M, A, R>> {
  // noop
}

export interface JsonApiFormData<A, R> {
  attributes: Partial<A>;
  relationships: { [P in keyof R]?: string | null };
}

export function isResourceIdentifier(value?: any | null): value is JsonApiResourceIdentifier<ModelType> {
  return hasString(value?.id) && hasString(value?.type);
}

export function isBelongsTo(value?: any | null): value is JsonApiBelongsToRelationship<ModelType> {
  return !isNil(value?.data) && isResourceIdentifier(value.data);
}

export const isHasMany = (obj: any): obj is JsonApiHasManyRelationship<ModelType> => {
  return isArray(obj?.data);
}

export type ModelTypeOf<T> = T extends JsonApiResourceObject<infer A, infer _B, infer _C> ? A : never;
export type AttributesTypeOf<T> = T extends JsonApiResourceObject<infer _A, infer B, infer _C> ? B : never;
export type RelationshipsTypeOf<T> = T extends JsonApiResourceObject<infer _A, infer _B, infer C> ? C : never;

export type JsonApiCreateBody<M extends ModelType, A, R> = {
  data: {
    type: M;
    attributes?: Partial<A>;
    relationships?: { [P in keyof R]?: R[P] | null };
  };
}

type JsonApiUpdateBodyRelationships<R> = { [P in keyof R]?: R[P] | null };

export type JsonApiUpdateBody<M extends ModelType, A, R> = {
  data: {
    type: M;
    id: string;
    attributes?: Partial<A>;
    relationships?: JsonApiUpdateBodyRelationships<R>;
  };
}

export type JsonApiCreateBodyType<T> =
  T extends Record<infer EM, infer EA, infer ER> ? JsonApiCreateBody<EM, EA, ER> :
  T extends JsonApiResourceObject<infer OM, infer OA, infer OR> ? JsonApiCreateBody<OM, OA, OR> :
  never;

export type JsonApiUpdateBodyType<T> =
  T extends Record<infer EM, infer EA, infer ER> ? JsonApiUpdateBody<EM, EA, ER> :
  T extends JsonApiResourceObject<infer OM, infer OA, infer OR> ? JsonApiUpdateBody<OM, OA, OR> :
  never;

export type JsonApiUpdateBodyRelationshipsType<T> =
  T extends Record<infer _EM, infer _EA, infer ER> ? JsonApiUpdateBodyRelationships<ER> :
  T extends JsonApiResourceObject<infer _OM, infer _OA, infer OR> ? JsonApiUpdateBodyRelationships<OR> :
  never;

export type JsonApiResponseType<T> =
  T extends Record<infer EM, infer EA, infer ER> ? JsonApiResponse<EM, EA, ER> :
  T extends JsonApiResourceObject<infer OM, infer OA, infer OR> ? JsonApiResponse<OM, OA, OR> :
  never;

export type JsonApiCollectionResponseType<T> =
  T extends Record<infer EM, infer EA, infer ER> ? JsonApiCollectionResponse<EM, EA, ER> :
  T extends JsonApiResourceObject<infer OM, infer OA, infer OR> ? JsonApiCollectionResponse<OM, OA, OR> :
  never;

export type JsonApiFormDataType<T> =
  T extends Record<infer _EM, infer EA, infer ER> ? JsonApiFormData<EA, ER> :
  T extends JsonApiResourceObject<infer _OM, infer OA, infer OR> ? JsonApiFormData<OA, OR> :
  never;

export type JsonApiResourceObjectType<T> =
  T extends Record<infer M, infer A, infer R> ? JsonApiResourceObject<M, A, R> :
  never;

export type JsonApiErrorResponse<A = any, R = any> = {
  errors: RecordErrors<A, R>;
}

export type JsonApiFormErrors<A, R> = {
  [P in keyof A | keyof R]?: string[];
}

export type JsonApiErrorResponseType<T> =
  T extends Record<infer _EM, infer EA, infer ER> ? JsonApiErrorResponse<EA, ER> :
  T extends JsonApiResourceObject<infer _OM, infer OA, infer OR> ? JsonApiErrorResponse<OA, OR> :
  never;

export type JsonApiFormErrorsType<T> =
  T extends Record<infer _EM, infer EA, infer ER> ? JsonApiFormErrors<EA, ER> :
  T extends JsonApiResourceObject<infer _OM, infer OA, infer OR> ? JsonApiFormErrors<OA, OR> :
  never;

export type PartialJsonApiResourceObjectType<T> =
  T extends JsonApiResourceObject<infer OM, infer OA, infer OR> ? PartialJsonApiResourceObject<OM, OA, OR> :
  never;

export interface JsonApiBatchPatchRequestBody<T extends BaseJsonApiResourceObject> {
  data: PartialJsonApiResourceObjectType<T>[]
}

export interface JsonApiBatchDeleteRequestBody<T extends BaseJsonApiResourceObject> {
  data: JsonApiResourceIdentifier<ModelTypeOf<T>>[];
}

type JsonApiHistoryElementUpdateType<T> =
  T extends JsonApiBelongsToRelationship<infer _B> ? string :
  T extends JsonApiBelongsToRelationshipNullable<infer _A> ? string | null :
  T;

type JsonApiHistoryResponseHistoryElement<A, R> = {
  updates: {
    attributes: {
      [P in keyof A]: {
        old: JsonApiHistoryElementUpdateType<A[P]>;
        new: JsonApiHistoryElementUpdateType<A[P]>;
      } | undefined;
    };
    relationships: {
      [P in keyof R]: {
        old: JsonApiHistoryElementUpdateType<R[P]>;
        new: JsonApiHistoryElementUpdateType<R[P]>;
      } | undefined;
    };
  };
  updatedBy: string;
  updatedAt: number;
};

export type JsonApiHistoryResponseHistoryElementType<T> =
  T extends Record<infer _EM, infer EA, infer ER> ? JsonApiHistoryResponseHistoryElement<EA, ER> :
  T extends JsonApiResourceObject<infer _OM, infer OA, infer OR> ? JsonApiHistoryResponseHistoryElement<OA, OR> :
  never;

export interface JsonApiHistoryResponse<A, R> {
  history: JsonApiHistoryResponseHistoryElement<A, R>[];
  included: BaseJsonApiResourceObject[];
}

export type JsonApiHistoryResponseType<T> =
  T extends BaseRecord<infer _AA, infer AB, infer AC> ? JsonApiHistoryResponse<AB, AC> :
  T extends Record<infer _BA, infer BB, infer BC> ? JsonApiHistoryResponse<BB, BC> :
  T extends JsonApiResourceObject<infer _CA, infer CB, infer CC> ? JsonApiHistoryResponse<CB, CC> :
  never;

interface JsonApiAttributesReflectionEntry {
  type: 'string' | 'number' | 'date' | 'boolean' | 'time' | 'datetime' | 'enum' | 'any' | 'file';
  isArray: boolean;
  isNullable: boolean;
  isReadonly: boolean;
}

export function attr(options: Partial<JsonApiAttributesReflectionEntry>): JsonApiAttributesReflectionEntry {
  return { type: 'any', isArray: false, isNullable: true, isReadonly: false, ...options };
}
interface JsonApiRelationshipReflectionEntry {
  type: ModelType;
  relationshipType: 'belongsTo' | 'hasOne' | 'hasMany';
  isNullable: boolean;
  isReadonly: boolean;
}

export function belongsTo(options: Partial<JsonApiRelationshipReflectionEntry> & Pick<JsonApiRelationshipReflectionEntry, 'type'>): JsonApiRelationshipReflectionEntry {
  return { relationshipType: 'belongsTo', isNullable: true, isReadonly: false, ...options };
}

type JsonApiAttributesReflection<T> = {
  [P in keyof T]: JsonApiAttributesReflectionEntry;
};

type JsonApiRelationshipsReflection<T> = {
  [P in keyof T]: JsonApiRelationshipReflectionEntry;
};

interface JsonApiModelReflection<T> {
  attributes: JsonApiAttributesReflection<AttributesTypeOf<T>>;
  relationships: JsonApiRelationshipsReflection<RelationshipsTypeOf<T>>;
}

export type JsonApiModelReflectionType<T> =
  T extends BaseJsonApiResourceObject ? JsonApiModelReflection<T> :
  never;

export type JsonApiIncludedMap = {
  [P in ModelType]?: {
    [id: string]: BaseJsonApiResourceObject<P, any, any>;
  }
};
