import { FETCH_ALL_TRANSACTIONS, FETCH_ALL_TRANSACTIONS_SUCCESS, FETCH_ALL_TRANSACTIONS_ERROR, UPDATE_TRANSACTION_RECORD } from 'src/constants/actionTypes/entities/transaction';
import { onEntityStateInit, onFetchAll, onFetchAllSuccess, onFetchAllError, onUpdateRecord } from 'src/helpers/entity-store';

export default store => {
  store.on('@init', (state) => onEntityStateInit(state, 'transaction'));

  store.on(FETCH_ALL_TRANSACTIONS, (state) => onFetchAll(state, 'transaction'));
  store.on(FETCH_ALL_TRANSACTIONS_SUCCESS, (state, { response }) => onFetchAllSuccess(state, 'transaction', response));
  store.on(FETCH_ALL_TRANSACTIONS_ERROR, (state, { error }) => onFetchAllError(state, 'transaction', error));

  store.on(UPDATE_TRANSACTION_RECORD, (state, { id, transaction }) => onUpdateRecord(state, 'transaction', id, transaction));
};
