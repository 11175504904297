export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_ERROR = "FETCH_USERS_ERROR";

export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_ERROR = "FETCH_USER_ERROR";
export const FETCH_USER_RESET = "FETCH_USER_RESET";

export const FETCH_USER_COLLECTION_REQUEST = "FETCH_USER_COLLECTION_REQUEST";
export const FETCH_USER_COLLECTION_SUCCESS = "FETCH_USER_COLLECTION_SUCCESS";
export const FETCH_USER_COLLECTION_ERROR = "FETCH_USER_COLLECTION_ERROR";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

export const DELETE_USERS_REQUEST = "DELETE_USERS_REQUEST";
export const DELETE_USERS_SUCCESS = "DELETE_USERS_SUCCESS";
export const DELETE_USERS_ERROR = "DELETE_USERS_ERROR";

export const SUBMIT_USER_REQUEST = "SUBMIT_USER_REQUEST";
export const SUBMIT_USER_SUCCESS = "SUBMIT_USER_SUCCESS";
export const SUBMIT_USER_ERROR = "SUBMIT_USER_ERROR";
export const SUBMIT_USER_RESET = "SUBMIT_USER_RESET";

export const REMOVE_USER_OWNED_COMPANY_BY_IDS = "REMOVE_USER_OWNED_COMPANY_BY_IDS";
export const REMOVE_USER_OWNED_CONTACT_BY_IDS = "REMOVE_USER_OWNED_CONTACT_BY_IDS";
export const REMOVE_USER_OWNED_LEAD_BY_IDS = "REMOVE_USER_OWNED_LEAD_BY_IDS";
export const REMOVE_USER_OWNED_LISTING_BY_IDS = "REMOVE_USER_OWNED_LISTING_BY_IDS";
export const REMOVE_USER_OWNED_PROPERTY_BY_IDS = "REMOVE_USER_OWNED_PROPERTY_BY_IDS";
export const REMOVE_USER_OWNED_PROJECT_BY_IDS = "REMOVE_USER_OWNED_PROJECT_BY_IDS";
export const REMOVE_USER_OWNED_OFFER_BY_IDS = "REMOVE_USER_OWNED_OFFER_BY_IDS";
export const REMOVE_USER_OWNED_TRANSACTION_BY_IDS = "REMOVE_USER_OWNED_TRANSACTION_BY_IDS";

export const DEACTIVATE_USER = "DEACTIVATE_USER";

export const RESET_USER_PASSWORD_REQUEST = "RESET_USER_PASSWORD_REQUEST";
export const RESET_USER_PASSWORD_SUCCESS = "RESET_USER_PASSWORD_SUCCESS";
export const RESET_USER_PASSWORD_ERROR = "RESET_USER_PASSWORD_ERROR";

export const FETCH_USER_OWNED_REQUEST = "FETCH_USER_OWNED_REQUEST";
export const FETCH_USER_OWNED_SUCCESS = "FETCH_USER_OWNED_SUCCESS";
export const FETCH_USER_OWNED_ERROR = "FETCH_USER_OWNED_ERROR";
