import * as types from 'src/constants/actionTypes/LeadsActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  loaded: false,
  items: {}
}

export default store => {
  store.on('@init', () => ({ LeadCollections: initialState }));

  store.on(types.FETCH_LEAD_COLLECTION_REQUEST, ({ LeadCollections }) =>
    ({ LeadCollections: { ...LeadCollections, isFetching: true, loaded: false }}));
  store.on(types.FETCH_LEAD_COLLECTION_SUCCESS, ({ LeadCollections }, { response }) =>
    ({ LeadCollections: { ...LeadCollections, items: response.data, loaded: true }}));
  store.on(types.FETCH_LEAD_COLLECTION_ERROR, ({ LeadCollections }) =>
    ({ LeadCollections: {...LeadCollections, error: true }}));
}
