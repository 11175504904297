import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useStoreon from 'src/helpers/storeon';
import ActivityBlock from './activity';
import randomId from 'src/helpers/randomId';
import Icon from 'src/constants/icons/IconDashboardCircle';
import Leads from './leads';
import Listings from './listings';
import Offers from './offers';
import Transactions from './transactions';
import Payments from './payments';
import Graph from './graph';
import MyTeam from './myTeam';
import fetchDashboard from 'src/actions/Dashboard';
import { API_CALL } from 'src/middleware/API';
import { teamAdminRoles, adminRoles, accountantRoles } from 'src/rules';
import { checkPerform } from 'src/helpers/authorization';
import { reportsPath, performancesPath } from 'src/helpers/routes';

const labels = ["me", "everyone", "myTeam"];
const defaultModules = ['leads', 'activities', 'listings', 'offers', 'transactions', 'payments'];

const DashboardElement = (props) => {
  const { t } = useTranslation();
  const { Auth, dispatch, Dashboard } = useStoreon('Auth', 'Dashboard');
  const [view, setView] = useState(labels[0]);
  const [modules, setModules] = useState(defaultModules);
  const [graphModules, setGraphModules] = useState([]);
  const [optionsView, setOptionsView] = useState(labels);
  const { history } = props;

  const { role, agency } = Auth.user;
  useEffect(() => {
    if (role === 'broker' || role === 'accountant') {
      setOptionsView([]);
    } else if (role === 'teamManager' || role === 'teamAdmin') {
      setOptionsView(labels.filter(el => el !== 'everyone'));
    } else {
      setOptionsView(labels.filter(el => el !== 'myTeam'));
    }
  }, [modules, role]);

  useEffect(() => {
    const preFiltered = modules.filter(el => el !== 'payments');
    if (role === 'broker') {
      setGraphModules(preFiltered.filter(el => el !== 'transactions'));
    } else if (role === 'accountant') {
      setGraphModules([]);
    } else {
      setGraphModules(preFiltered);
    }
  }, [modules, role]);

  useEffect(() => {
    if (role === 'broker') {
      const broker = defaultModules.filter(el =>
        (el !== 'payments' || el !== 'transactions')
      );
      setModules(broker);
    } else if (role === 'accountant') {
      setModules([]);
    } else if (teamAdminRoles.includes(role)) {

    } else {
      setModules(defaultModules);
    }
  },[role]);

  const { isFetching } = Dashboard;
  const { token } = Auth;

  useEffect(() => {
    if (isFetching !== true && modules.length > 0) {
      dispatch(
        API_CALL,
        fetchDashboard({ accessToken: token, query: { modules, view } })
      );
    }
  }, [token, dispatch, modules, view, isFetching]);

  const changeView = (event) => {
    setView(view => (event.target.value));
    dispatch(
      API_CALL,
      fetchDashboard({
        accessToken: token,
        query: { modules, view: event.target.value }
      })
    );
  };

  const successCallbackParent = () => {
    dispatch(
      API_CALL,
      fetchDashboard({ accessToken: token, query: { modules, view } })
    );
  };

  const isReports = checkPerform({
    role: Auth.user.role,
    perform: 'report:index'
  });

  const isPerformances = checkPerform({
    role: Auth.user.role,
    perform: 'performance:index'
  });

  const handlePushReports = () => {
    if (isReports) history.push(reportsPath());
  };

  const handlePushPerformance = () => {
    if (isPerformances) history.push(performancesPath());

  };

  const { items } = Dashboard;
  const {
    leads, graph, listings, offers, transactions, payments, property, brokers = [],
    activities= []
  } = items;

  const isNewListing = checkPerform({ role, perform: "listings:new" });
  const isNewOffer = checkPerform({ role, perform: "offers:new" });
  const isNewLead = checkPerform({ role, perform: "leads:new" });

  return (
    <div className="card bg-transparent">
      <div className="card-header mb-5">
        <div className="w-100">
          <div className="card-title d-flex flex-column flex-md-row my-2">
            <div className="d-flex">
              <div className="mr-2 align-self-center">
                <Icon width={35} height={35} fill="#182852" />
              </div>
              <div className="align-self-center mr-4">{t('dashboard.myDashboard')}</div>
            </div>

              {optionsView.length > 0 &&
                <div className="d-flex form-inline flex-md-wrap flex-nowrap">
                  <label
                    className="mr-2 form-label text-dark-blue mb-0"
                  >
                    {t("dashboard.viewRecordsOwnedBy")}
                  </label>
                  <select
                    className="form-control align-self-center"
                    onChange={changeView}
                    value={view}
                  >
                    {optionsView.map(el =>
                      <option key={randomId()} value={el}>{t(`dashboard.${el}`)}</option>
                    )}
                  </select>
              </div>}

            <div className="card-options d-none d-md-block ml-auto align-self-center">
              <div className="btn-list mb-0">
                <button
                  onClick={handlePushReports}
                  className="btn btn-header mb-0"
                  disabled={!isReports}
                >
                  {t("buttons.reports")}
                </button>
                <button
                  onClick={handlePushPerformance}
                  className="btn btn-header mb-0"
                  disabled={!isPerformances}
                >
                  {t("buttons.performance")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="panel panel-primary bg-white">
              <div className="panel-body tabs-menu-body px-5" >
                {view !== "myTeam" &&
                 (teamAdminRoles.includes(role) ||
                  adminRoles.includes(role) ||
                  role === 'broker'
                ) &&
                  <Graph modules={graphModules} items={graph}/>
                }
                {view === "myTeam" && teamAdminRoles.includes(role)&&
                  <MyTeam modules={brokers} items={graph}/>
                }
                {role !== 'accountant' && <Leads items={leads} isNewLead={isNewLead}/>}
                <Listings items={listings} agency={agency} property={property} isNewListing={isNewListing}/>
                <Offers items={offers} isNewOffer={isNewOffer}/>
                {role !== 'broker' && <Transactions items={transactions} />}
                {(adminRoles.includes(role) || accountantRoles.includes(role)) &&
                  <Payments items={payments} />
                }
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 my-xl-0 my-lg-0 my-5">
            <ActivityBlock
              history={history}
              items={activities}
              successCallbackParent={successCallbackParent}
            />
          </div>
        </div>
      </div>
    </div>
  )
};

export default DashboardElement;
