import { Module } from 'storeon';
import { Events, State } from 'src/store';
import { FETCH_SNAG_ENUMS, FETCH_SNAG_ENUMS_SUCCESS, FETCH_SNAG_ENUMS_ERROR } from 'src/constants/actionTypes/enums/snag';
import { onInit, onFetch, onFetchSuccess, onFetchError } from 'src/-utils/enums';

export const snagEnumModule: Module<State, Events> = store => {
  store.on('@init', (state) => onInit(state, 'snag'));

  store.on(FETCH_SNAG_ENUMS, (state) => onFetch(state, 'snag'));
  store.on(FETCH_SNAG_ENUMS_SUCCESS, (state, { response }) => onFetchSuccess(state, 'snag', response));
  store.on(FETCH_SNAG_ENUMS_ERROR, (state, { error }) => onFetchError(state, 'snag', error));
};

export default snagEnumModule;
