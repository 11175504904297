export const FETCH_LISTINGS_REQUEST = 'FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'FETCH_LISTINGS_ERROR';

export const FETCH_LISTING_REQUEST = 'FETCH_LISTING_REQUEST';
export const FETCH_LISTING_SUCCESS = 'FETCH_LISTING_SUCCESS';
export const FETCH_LISTING_ERROR = 'FETCH_LISTING_ERROR';
export const FETCH_LISTING_RESET = 'FETCH_LISTING_RESET';

export const FETCH_LISTING_COLLECTION_REQUEST = 'FETCH_LISTING_COLLECTION_REQUEST';
export const FETCH_LISTING_COLLECTION_SUCCESS = 'FETCH_LISTING_COLLECTION_SUCCESS';
export const FETCH_LISTING_COLLECTION_ERROR = 'FETCH_LISTING_COLLECTION_ERROR';

export const DELETE_LISTING_REQUEST = 'DELETE_LISTING_REQUEST';
export const DELETE_LISTING_SUCCESS = 'CREATE_LISTING_SUCCESS';
export const DELETE_LISTING_ERROR = 'DELETE_LISTING_ERROR';

export const DELETE_LISTINGS_REQUEST = 'DELETE_LISTINGS_REQUEST';
export const DELETE_LISTINGS_SUCCESS = 'DELETE_LISTINGS_SUCCESS';
export const DELETE_LISTINGS_ERROR = 'DELETE_LISTINGS_ERROR';

export const SUBMIT_LISTING_REQUEST = 'SUBMIT_LISTING_REQUEST';
export const SUBMIT_LISTING_SUCCESS = 'SUBMIT_LISTING_SUCCESS';
export const SUBMIT_LISTING_ERROR = 'SUBMIT_LISTING_ERROR';
export const SUBMIT_LISTING_RESET = 'SUBMIT_LISTING_RESET';
