import * as types from 'src/constants/actionTypes/CompaniesActionTypes';

const initialState = {
  isDestroing: false,
  error: false,
  successDestroy: false,
}

export default store => {
  store.on('@init', () => ({ CompanyDestroy: initialState }));

  store.on(types.DELETE_COMPANIES_REQUEST, ({ CompanyDestroy }) =>
    ({ CompanyDestroy: { ...CompanyDestroy, isDestroing: true, successDestroy: false }}));
  store.on(types.DELETE_COMPANIES_SUCCESS, ({ CompanyDestroy }) =>
    ({ CompanyDestroy: {...CompanyDestroy, successDestroy: true }}));
  store.on(types.DELETE_COMPANIES_ERROR, ({ CompanyDestroy }) =>
    ({ CompanyDestroy: {...CompanyDestroy, error: true }}));

  store.on(types.DELETE_COMPANY_REQUEST, ({ CompanyDestroy }) =>
    ({ CompanyDestroy: { ...CompanyDestroy, isDestroing: true, successDestroy: false }}));
  store.on(types.DELETE_COMPANY_SUCCESS, ({ CompanyDestroy }) =>
    ({ CompanyDestroy: {...CompanyDestroy, successDestroy: true }}));
  store.on(types.DELETE_COMPANY_ERROR, ({ CompanyDestroy }) =>
    ({ CompanyDestroy: {...CompanyDestroy, error: true }}));
}
