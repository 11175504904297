const endpoint = "teams";

const defaultView = [
  { data: "id", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true },
  { data: "name", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link show-link", path: 'teamPath', pathKey: 'id' },
  { data: "teamManager", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link manager-link", path: 'userPath', pathKey: 'teamManagerId' },
  { data: "teamAdmin", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link admin-link", path: 'userPath', pathKey: 'teamAdminId' },
  { data: "teamManagerId", hidden: true, searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "teamAdminId", hidden: true, searchable: true, orderable: false, search: { value: "", regex: "" } }
];

const fieldsByView = (view) => {
  const fields = {
    "teams": defaultView,
  };
  return fields[view];
};

export default ({ view = "teams", items = [] }) => {
  const columns = fieldsByView(view);
  const query = {
    columns,
    order: [{ column: 0, dir: "asc" }],
    start: 0,
    length: 10,
    endpoint
  };
  return { query, endpoint, columns };
};
