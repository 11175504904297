import React from 'react';
import UncontrolledCollapse from 'src/components/elements/shared/UncontrolledCollapse';
import Link from 'src/helpers/Link';
import { useTranslation } from 'react-i18next';
import { offerNewPath, offerPath, contactPath, userPath, listingPath } from 'src/helpers/routes';

const pad = (a, b) => ([1e15]+a).slice(-b);

export default ({ items = [], isNewOffer }) => {
  const { t } = useTranslation();

  const handleNewOffer = (event) => {
    event.preventDefault();
    window.open(offerNewPath(), "_blank");
  };

  return (
    <UncontrolledCollapse
      label={`${t("dashboard.subFolders.openOffers")} (${items.length})`}
      buttonHeaderLabel={t('buttons.new')}
      handlerOnClick={handleNewOffer}
      withButton={isNewOffer}
    >
      <div className="table-responsive">
        <table className="table card-table table-vcenter text-nowrap">
          <thead>
            <tr>
              <th>{t("offers.id")}</th>
              <th>{t("offers.offerType")}</th>
              <th>{t("offers.potentialBuyerTenant")}</th>
              <th>{t("offers.listing")}</th>
              <th>{t("offers.offeredAmount")}</th>
              <th>{t("offers.owner")}</th>
            </tr>
          </thead>
          <tbody>
            {items.length > 0 && items.map((offer, index) =>
              <tr key={`offer-${index}`}>
                <td>
                  <span className="link-block">
                    <Link to={offerPath(offer.id)} className="mr-2" target="_blank">
                      {`OF-${pad(offer.id, 7)}`}
                    </Link>
                  </span>
                </td>
                <td>{offer.offerType}</td>
                <td>
                  <span className="link-block">
                    <Link to={contactPath(offer.potentialBuyerId)} className="mr-2" target="_blank">
                      {offer.potentialBuyer}
                    </Link>
                  </span>
                </td>
                <td>
                  <span className="link-block">
                    <Link to={listingPath(offer.listingId)} className="mr-2" target="_blank">
                      {offer.listing}
                    </Link>
                  </span>
                </td>
                <td>{offer.offeredAmount}</td>
                <td>
                  <span className="link-block">
                    <Link to={userPath(offer.ownerId)} className="mr-2" target="_blank">
                      {offer.owner}
                    </Link>
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </UncontrolledCollapse>
  )
};

// <span className="link-block">
//   <Link to={contactPath(offer.contactId)} className="mr-2" target="_blank">
//     {offer.contact}
//   </Link>
// </span>
