import { leadNewPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchLeadCollections } from 'src/actions/Leads';
import { fetchListing } from 'src/actions/Listings';
import { API_CALL } from 'src/middleware/API';

const LeadNew = lazy(() => import('src/components/views/leads/New'));

export default {
  path: leadNewPath(),
  exact: true,
  strict: true,
  component: LeadNew,
  perform: 'leads:new',
  protected: true,
  prepareData: ({ store, params }) => {
    const state = store.get();

    if (state.Auth.connected === false || Object.keys(state.LeadCollections.items).length > 0) {
      return;
    }

    const accessToken = state.Auth.token;

    store.dispatch(API_CALL, fetchLeadCollections({ accessToken }));

    if (params.listingId) {
      const query = { withProperties: true };

      store.dispatch(API_CALL, fetchListing({ accessToken, id: params.listingId, query }));
    }
  }
};
