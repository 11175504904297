import { propertyEditPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchPropertyCollections, fetchProperty } from 'src/actions/Properties';
import { API_CALL } from 'src/middleware/API';

const PropertyEdit = lazy(() => import('src/components/views/properties/Edit'));

export default {
  path: propertyEditPath(),
  exact: true,
  strict: true,
  component: PropertyEdit,
  perform: 'properties:update',
  title: 'Edit Property',
  protected: true,
  prepareData: ({ store, params }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    if (Object.keys(state.PropertyCollections.items).length ===  0) {
      store.dispatch(API_CALL, fetchPropertyCollections({ accessToken }));
    }
    const query = { noTypeCast: true }
    store.dispatch(API_CALL, fetchProperty({ accessToken, id: params.id, query }));
  }
};
