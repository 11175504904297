import * as types from 'src/constants/actionTypes/TransactionsActionTypes';

const initialState = {
  isDestroing: false,
  error: false,
  successDestroy: false,
}

export default store => {
  store.on('@init', () => ({ TransactionDestroy: initialState }));

  store.on(types.DELETE_TRANSACTIONS_REQUEST, ({ TransactionDestroy }) =>
    ({ TransactionDestroy: { ...TransactionDestroy, isDestroing: true, successDestroy: false }}));
  store.on(types.DELETE_TRANSACTIONS_SUCCESS, ({ TransactionDestroy }) =>
    ({ TransactionDestroy: {...TransactionDestroy, successDestroy: true }}));
  store.on(types.DELETE_TRANSACTIONS_ERROR, ({ TransactionDestroy }) =>
    ({ TransactionDestroy: {...TransactionDestroy, error: true }}));

  store.on(types.DELETE_TRANSACTION_REQUEST, ({ TransactionDestroy }) =>
    ({ TransactionDestroy: { ...TransactionDestroy, isDestroing: true, successDestroy: false }}));
  store.on(types.DELETE_TRANSACTION_SUCCESS, ({ TransactionDestroy }) =>
    ({ TransactionDestroy: {...TransactionDestroy, successDestroy: true }}));
  store.on(types.DELETE_TRANSACTION_ERROR, ({ TransactionDestroy }) =>
    ({ TransactionDestroy: {...TransactionDestroy, error: true }}));
}
