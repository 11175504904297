import { paymentPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchPayment, fetchPaymentCollections } from 'src/actions/Payments';
import { API_CALL } from 'src/middleware/API';
import { fetchActivityCollections } from 'src/actions/Activities';
import Layout from 'src/components/views/showLayout';

const PaymentShow = lazy(() => import('src/components/views/payments/Show'));

export default {
  path: paymentPath(),
  exact: true,
  strict: true,
  component: PaymentShow,
  perform: "payments:show",
  layout: Layout,
  protected: true,
  prepareData: ({ store, query, params }) => {
    const state = store.get();
    const accessToken = state.Auth.token;
    if (state.Auth.connected === false) return;
    if (Object.keys(state.ActivityCollections.items).length ===  0) {
      store.dispatch(API_CALL, fetchActivityCollections({ accessToken }));
    }
    if (Object.keys(state.PaymentCollections.items).length ===  0) {
      store.dispatch(API_CALL, fetchPaymentCollections({ accessToken }));
    }
    store.dispatch(API_CALL, fetchPayment({ accessToken, id: params.id }));
  }
};
