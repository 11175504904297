import { stringify } from 'qs';
import request from 'superagent';
import { API_ROOT } from 'src/constants/API';
import { isFunction, trimStart, toLower, isEmpty, trim, includes } from 'lodash';
import { hasString } from 'src/helpers/string';
import { AccessToken } from 'src/-types/authentication';
import { QueryParams, ArrayFormat, ApiVersion } from 'src/-types/request';

type Method = 'get' | 'post' | 'put' | 'patch' | 'delete';
type ProgressCallback = (progress: { percent: number | undefined, total: number | undefined, loaded: number }) => void;

interface ApiCallArgs {
  apiVersion: ApiVersion;
  endpoint: string;
  method: Method;
  query?: QueryParams;
  credentials?: boolean;
  accessToken?: AccessToken;
  attach?: FormData;
  arrayFormat: ArrayFormat;
  responseType?: string;
  bearer?: string;
  onUploadProgress?: ProgressCallback;
  onDownloadProgress?: ProgressCallback;
  body: any;
}

const VALID_METHODS = ['get', 'post', 'put', 'patch', 'delete'];
const VALID_ARRAY_FORMATS = ['brackets', 'indices', 'repeat', 'comma'];

const normalizeMethod = (method: string): Method => {
  method = toLower(trim(method));

  if (!includes(VALID_METHODS, method)) {
    throw new Error(`"${method}" is not a valid request method.`);
  }

  return method as Method;
};
const normalizeEndpoint = (endpoint: string): string => {
  return trimStart(endpoint, '/');
};
const normalizeArrayFormat = (arrayFormat?: string): ArrayFormat => {
  arrayFormat = toLower(trim(arrayFormat));

  if (!hasString(arrayFormat)) {
    return;
  }

  if (!includes(VALID_ARRAY_FORMATS, arrayFormat)) {
    throw new Error(`"${arrayFormat}" is not a valid array format.`);
  }

  return arrayFormat as ArrayFormat;
};
const getRequestObject = (method: Method, url: string) => {
  switch (method) {
    case 'post':
      return request.post(url);
    case 'put':
      return request.put(url);
    case 'patch':
      return request.patch(url);
    case 'delete':
      return request.delete(url);
    default:
      return request.get(url);
  }
};

const ApiCall = (args: ApiCallArgs): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      const { apiVersion, query, credentials = false, accessToken, attach, responseType, bearer, onUploadProgress, onDownloadProgress, body } = args;
      let { method, endpoint, arrayFormat = 'brackets' as ArrayFormat } = args;

      method = normalizeMethod(method);
      endpoint = normalizeEndpoint(endpoint);
      arrayFormat = normalizeArrayFormat(arrayFormat);

      const apiRoot = API_ROOT(apiVersion);
      const r = getRequestObject(method, `${apiRoot}/${endpoint}`);

      r.set('Accept', 'application/json');
      r.redirects(0);
      r.on('progress', ({ direction, percent, total, loaded }) => {
        if (direction === 'upload' && isFunction(onUploadProgress)) {
          onUploadProgress({ percent, total, loaded });
        } else if (direction === 'download' && isFunction(onDownloadProgress)) {
          onDownloadProgress({ percent, total, loaded });
        }
      });

      if (responseType) {
        r.responseType(responseType);
      }

      if (credentials) {
        r.withCredentials();
      }

      if (accessToken) {
        r.set(accessToken);
      }

      if (bearer) {
        r.set('Authorization', `Basic ${bearer}`);
      }

      if (!isEmpty(query)) {
        if (method === 'put' && !body && !attach) {
          r.send(query);
        } else {
          r.query(stringify(query, { arrayFormat }));
        }
      }

      if (body) {
        r.send(body);
      } else if (attach) {
        r.send(attach);
      }

      r.end((err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    } catch (err) {
      reject(err);
    }
  });
}

export default ApiCall;
