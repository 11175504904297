import * as types from 'src/constants/actionTypes/RecentlyViewedActionTypes';

const initialState = {
  items: {}
};

export default store => {
  store.on('@init', () => ({ RecentlyViewed: initialState }));

  store.on(types.SUBMIT_RECENTLY_VIEWED, ({ RecentlyViewed }, { data, endpoint }) => {
    const { items } = RecentlyViewed;
    const currentData = items[endpoint] || [];
    const index = currentData.findIndex(i => data.id === i.id);
    if (index !== -1) { currentData[index] = data
    } else { currentData.push(data); }
    items[endpoint] = currentData;
    return ({ RecentlyViewed: {...RecentlyViewed, items }});
  });
};
