import React, { useState, useRef } from 'react';
import FormHooks from 'src/helpers/formHooks';
import { useTranslation } from 'react-i18next';
import useStoreon from 'src/helpers/storeon';
import HorizontalInput from 'src/components/elements/form/HorizontalInput';
import HorizontalSelect from 'src/components/elements/form/HorizontalSelect';
import VerticalTextArea from 'src/components/elements/form/VerticalTextArea';
import HorizontalCheckBox from 'src/components/elements/form/HorizontalCheckBox';
import HorizontalGroupsSelectSearch from 'src/components/elements/form/HorizontalGroupsSelectSearch';
import HorizontalDateInputWithTime from 'src/components/elements/datetime';

import { API_CALL } from 'src/middleware/API';
import { SUBMIT_ACTIVITY_RESET } from 'src/constants/actionTypes/ActivitiesActionTypes';

const clearValidation = (formRef) => {
  const form = formRef.current;
  form && (Array.from(form.elements)).forEach(el => { if (el) el.setCustomValidity(''); });
};

const setServerSideErrors = ({ formRef, errors, validations, setValidations }) => {
  const form = formRef.current;
  setValidations(errors);
  form && (Object.keys(validations)).forEach(key => {
    const el = (Array.from(form.elements)).find(item => item.name === key);
    if (el) el.setCustomValidity(validations[key].join(', '));
  });
}

export default ({
  history, successPath, defaultValues, children, serverSideValidation, submitHandler, id,
  relateCollection, dynamicCallBack = () => {}, fullForm, successCallback = () => {},
  calendarRef
}) => {
  const formRef = useRef(null);
  const [validations, setValidations] = useState({});
  const { t } = useTranslation();
  const { ActivityCollections, dispatch, Auth } = useStoreon('ActivityCollections', 'Auth');

  serverSideValidation
    .then(
      () => {
        dispatch(SUBMIT_ACTIVITY_RESET);
        if (successPath) {
          let state = {};
          if (calendarRef) {
            const calendarApi = calendarRef.current.getApi();
            state = {
              type: calendarApi.view.type,
              renderRange: calendarApi.state.dateProfile.renderRange
            };
          }
          history.push(successPath, state);
        } else {
          successCallback();
        }
      },
      errors => setServerSideErrors({ formRef, errors, validations, setValidations })
    );

  const callBack = () => {
    clearValidation(formRef);

    if (formRef.current.checkValidity() !== false) {
      const accessToken = Auth.token;
      const query = { activity: Object.assign({}, inputs) };
      dispatch(API_CALL, submitHandler({ accessToken, query, id }));
    }
    formRef.current.classList.add('was-validated');
  };

  const {
    inputs, handleInputChange, handleSubmit, handleDataChange, dynamicHandleChange,
  } = FormHooks(defaultValues, callBack, dynamicCallBack);

  return (
    <form onSubmit={handleSubmit} noValidate={true} ref={formRef}>
      {fullForm &&
        <>
          <HorizontalSelect
            collection={ActivityCollections.items.relateTypeCollection}
            value={inputs.activitybleType}
            onChange={dynamicHandleChange(['activitybleId'])}
            label={t('activities.activitybleType')}
            name="activitybleType"
            labelSize="col"
            inputSize="col"
            required
          />
          <HorizontalSelect
            collection={relateCollection}
            value={inputs.activitybleId}
            onChange={handleInputChange}
            label={t('activities.activitybleId')}
            name="activitybleId"
            labelSize="col"
            inputSize="col"
            required
          />
        </>
      }
      <HorizontalSelect
        collection={ActivityCollections.items.statusCollection}
        value={inputs.status}
        onChange={handleInputChange}
        label={t('activities.status')}
        name="status"
        labelSize="col"
        inputSize="col"
      />
      <HorizontalSelect
        collection={ActivityCollections.items.typeCollection}
        value={inputs.typeOf}
        onChange={handleInputChange}
        label={t('activities.type')}
        name="typeOf"
        labelSize="col"
        inputSize="col"
      />
      <HorizontalInput
        value={inputs.objectiveOfActivity}
        onChange={handleInputChange}
        label={t('activities.objectiveOfActivity')}
        name="objectiveOfActivity"
        errors={validations.objectiveOfActivity}
        labelSize="col"
        inputSize="col"
      />
      <HorizontalDateInputWithTime
        value={inputs.startDateAndTime}
        onChange={handleDataChange}
        label={t('activities.startDateAndTime')}
        name="startDateAndTime"
        errors={validations.startDateAndTime}
        labelSize="col"
        inputSize="col"
      />
      <HorizontalDateInputWithTime
        value={inputs.endDateAndTime}
        onChange={handleDataChange}
        label={t('activities.endDateAndTime')}
        name="endDateAndTime"
        errors={validations.endDateAndTime}
        labelSize="col"
        inputSize="col"
      />
      <VerticalTextArea
        value={inputs.comments}
        onChange={handleInputChange}
        label={t('activities.comments')}
        name="comments"
        errors={validations.comments}
      />
      <HorizontalGroupsSelectSearch
        collection={ActivityCollections.items.attendeesCollection}
        value={inputs.attendees}
        onChange={handleDataChange}
        label={t('activities.otherAttendees')}
        name="attendees"
        labelSize="col"
        inputSize="col"
        multiple
      />
      <HorizontalCheckBox
        value={inputs.notificationToAttendees}
        onChange={handleInputChange}
        label={t('activities.notificationToAttendees')}
        name="notificationToAttendees"
        errors={validations.notificationToAttendees}
        labelSize="col"
        inputSize="col"
      />

      <HorizontalCheckBox
        value={inputs.reminder}
        onChange={handleInputChange}
        label={t('activities.reminder')}
        name="reminder"
        errors={validations.reminder}
        labelSize="col"
        inputSize="col"
      />
      <HorizontalSelect
        collection={ActivityCollections.items.amountOfTimeBeforeCollection}
        value={inputs.amountOfTimeBefore}
        onChange={handleInputChange}
        label={t('activities.amountOfTimeBefore')}
        name="amountOfTimeBefore"
        labelSize="col"
        inputSize="col"
      />
      {children}
    </form>
  );
};
