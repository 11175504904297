import * as types from 'src/constants/actionTypes/ProfilesActionTypes';
import initialStateProfile from 'src/helpers/initialStateSubmit';

const initialState = {
  isFetching: false,
  error: false,
  profile: {}
}

export default store => {
  store.on('@init', () => ({ Profile: initialState }));
  store.on('@init', () => ({ SubmitProfile: initialStateProfile }));

  store.on(types.FETCH_PROFILE_REQUEST, ({ Profile }) =>
    ({ Profile: { ...initialState, isFetching: true }}));
  store.on(types.FETCH_PROFILE_SUCCESS, ({ Profile }, { response }) =>
    ({ Profile: { ...Profile, profile: response.data }}));
  store.on(types.FETCH_PROFILE_ERROR, ({ Profile }) =>
    ({ Profile: {...Profile, error: true }}));

  store.on(types.SUBMIT_PROFILE_RESET, () => ({ SubmitProfile: initialState }));
  store.on(types.SUBMIT_PROFILE_REQUEST, ({ SubmitProfile }) =>
    ({ SubmitProfile: { ...SubmitProfile, ...initialState, sendRequest: true }}));
  store.on(types.SUBMIT_PROFILE_SUCCESS, ({ SubmitProfile }, { response }) => {
    return ({ SubmitProfile: { ...SubmitProfile, success: true, sendRequest: false }});
  });
  store.on(types.SUBMIT_PROFILE_ERROR, ({ SubmitProfile }, { response }) => {
    let errors = {};
    if (response && response.body && response.body.errors) errors = response.body.errors;
    return ({ SubmitProfile: {...SubmitProfile, error: true, errors: errors, sendRequest: false }});
  });
}
