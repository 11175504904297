import { State } from 'src/store';
import { ModelType } from 'src/-types/model';
import { cloneDeep } from 'lodash';
import { ModelEnum } from 'src/-types/enum';
import { toDropdownSource } from 'src/-utils/dropdown';
import { ModelEnumState } from 'src/-types/enums';

export function onInit(state: Readonly<State>, entityType: ModelType): Partial<State> {
  const enums = state.enums;
  const defaultState: ModelEnumState = {
    __meta: {
      fetchCount: 0,
      hasLoaded: false
    }
  };
  const statePatch: Partial<State> = {
    enums: {
      ...enums,
      [entityType]: defaultState
    }
  };

  return statePatch;
}

export function onFetch(state: Readonly<State>, entityType: ModelType): Partial<State> {
  const enums = state.enums;
  const enumCollection = state.enums[entityType];
  const meta = enumCollection.__meta;
  const statePatch: Partial<State> = {
    enums: {
      ...enums,
      [entityType]: {
        ...enumCollection,
        __error: null,
        __meta: { ...meta, fetchCount: meta.fetchCount + 1 }
      }
    }
  };

  return statePatch;
}

export function onFetchSuccess(state: Readonly<State>, entityType: ModelType, response: any): Partial<State> {
  const enums = state.enums;
  const enumCollection = state.enums[entityType];
  const meta = enumCollection.__meta;
  const statePatch: Partial<State> = {
    enums: {
      ...enums,
      [entityType]: {
        ...enumCollection,
        data: cloneDeep(response),
        __meta: { ...meta, fetchCount: meta.fetchCount - 1, hasLoaded: true }
      }
    }
  };

  return statePatch;
}

export function onFetchError(state: Readonly<State>, entityType: ModelType, error: any): Partial<State> {
  const enums = state.enums;
  const enumCollection = state.enums[entityType];
  const meta = enumCollection.__meta;
  const statePatch: Partial<State> = {
    enums: {
      ...enums,
      [entityType]: {
        ...enumCollection,
        __error: cloneDeep(error),
        __meta: { ...meta, fetchCount: meta.fetchCount - 1 }
      }
    }
  };

  return statePatch;
}

export function enumsToDropdownSource(enums: ModelEnum, label: (enumValue: string) => string) {
  return toDropdownSource(enums, v => ({ value: v, label: label(v) }));
}
