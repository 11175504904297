import { useState, useEffect } from 'react';
import useStoreon from 'storeon/react';
import { State } from 'src/store';
import { useTranslation } from 'react-i18next';
import { enumsToDropdownSource } from 'src/-utils/enums';
import { DropdownSource } from 'src/-utils/dropdown';
import { SnagEnumCollection } from 'src/-types/enums/snag';
import { ContactEnumCollection } from 'src/-types/enums/contact';
import { CompanyEnumCollection } from 'src/-types/enums/company';

export function useEnumDropdown(enums: string[], namespace: string): DropdownSource {
  const { t } = useTranslation();
  const [dropdownValues, setDropdownValues] = useState<DropdownSource>([]);

  useEffect(() => {
    setDropdownValues(enumsToDropdownSource(enums, e => t(`${namespace}.${e}`)));
  }, [t, enums, namespace]);

  return dropdownValues;
}

export function useCompanyEnums(): CompanyEnumCollection {
  const { enums: { company } } = useStoreon<State>('enums');
  const [result, setResult] = useState<CompanyEnumCollection>(new CompanyEnumCollection(company));

  useEffect(() => {
    setResult(new CompanyEnumCollection(company));
  }, [company]);

  return result;
}

export function useContactEnums(): ContactEnumCollection {
  const { enums: { contact } } = useStoreon<State>('enums');
  const [result, setResult] = useState<ContactEnumCollection>(new ContactEnumCollection(contact));

  useEffect(() => {
    setResult(new ContactEnumCollection(contact));
  }, [contact]);

  return result;
}

export function useSnagEnums(): SnagEnumCollection {
  const { enums: { snag } } = useStoreon<State>('enums');
  const [result, setResult] = useState<SnagEnumCollection>(new SnagEnumCollection(snag));

  useEffect(() => {
    setResult(new SnagEnumCollection(snag));
  }, [snag]);

  return result;
}
