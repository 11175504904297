import * as types from 'src/constants/actionTypes/LeadsActionTypes';

const fetchLeads = ({ accessToken, query = {} }) => {
  return ({
    query,
    endpoint: 'leads',
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_LEADS_REQUEST,
      types.FETCH_LEADS_SUCCESS,
      types.FETCH_LEADS_ERROR
    ]
  })
}

const fetchLead = ({ accessToken, id, query = {} }) => {
  return ({
    query,
    endpoint: `leads/${id}`,
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_LEAD_REQUEST,
      types.FETCH_LEAD_SUCCESS,
      types.FETCH_LEAD_ERROR
    ]
  })
};

const updateLead = ({ accessToken, query, id }) => {
  return ({
    query,
    endpoint: `leads/${id}`,
    method: 'PUT',
    credentials: true,
		accessToken,
    types: [
      types.SUBMIT_LEAD_REQUEST,
      types.SUBMIT_LEAD_SUCCESS,
      types.SUBMIT_LEAD_ERROR
    ]
  })
};

const deleteLeads = ({ accessToken, query }) => {
  return ({
    query,
    endpoint: `leads`,
    method: 'DELETE',
    credentials: true,
		accessToken,
    types: [
      types.DELETE_LEADS_REQUEST,
      types.DELETE_LEADS_SUCCESS,
      types.DELETE_LEADS_ERROR
    ]
  })
};

const deleteLead = ({ accessToken, id }) => {
  return ({
    endpoint: `leads/${id}`,
    method: 'DELETE',
    credentials: true,
		accessToken,
    types: [
      types.DELETE_LEAD_REQUEST,
      types.DELETE_LEAD_SUCCESS,
      types.DELETE_LEAD_ERROR
    ]
  })
};

const createLead = ({ accessToken, query }) => {
  return ({
    query: query,
    endpoint: 'leads',
    method: 'POST',
    credentials: true,
		accessToken,
    types: [
      types.SUBMIT_LEAD_REQUEST,
      types.SUBMIT_LEAD_SUCCESS,
      types.SUBMIT_LEAD_ERROR
    ]
  })
};

const fetchLeadCollections = ({ accessToken }) => {
  return ({
    endpoint: 'leads/helper',
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_LEAD_COLLECTION_REQUEST,
      types.FETCH_LEAD_COLLECTION_SUCCESS,
      types.FETCH_LEAD_COLLECTION_ERROR
    ]
  })
}

export {
  fetchLeads, fetchLead, fetchLeadCollections, createLead, deleteLeads,
  deleteLead, updateLead
};
