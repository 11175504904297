import * as types from 'src/constants/actionTypes/CompaniesActionTypes';

const fetchCompanies = ({ accessToken, query = {} }) => {
  return ({
    query,
    endpoint: 'companies',
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_COMPANIES_REQUEST,
      types.FETCH_COMPANIES_SUCCESS,
      types.FETCH_COMPANIES_ERROR
    ]
  })
}

const fetchCompany = ({ accessToken, id, query = {} }) => {
  return ({
    query,
    endpoint: `companies/${id}`,
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_COMPANY_REQUEST,
      types.FETCH_COMPANY_SUCCESS,
      types.FETCH_COMPANY_ERROR
    ]
  })
};

const updateCompany = ({ accessToken, query, id }) => {
  return ({
    query,
    endpoint: `companies/${id}`,
    method: 'PUT',
    credentials: true,
		accessToken,
    types: [
      types.SUBMIT_COMPANY_REQUEST,
      types.SUBMIT_COMPANY_SUCCESS,
      types.SUBMIT_COMPANY_ERROR
    ]
  })
};

const deleteCompanies = ({ accessToken, query }) => {
  return ({
    query,
    endpoint: `companies`,
    method: 'DELETE',
    credentials: true,
		accessToken,
    types: [
      types.DELETE_COMPANIES_REQUEST,
      types.DELETE_COMPANIES_SUCCESS,
      types.DELETE_COMPANIES_ERROR
    ]
  })
};

const deleteCompany = ({ accessToken, id }) => {
  return ({
    endpoint: `companies/${id}`,
    method: 'DELETE',
    credentials: true,
		accessToken,
    types: [
      types.DELETE_COMPANY_REQUEST,
      types.DELETE_COMPANY_SUCCESS,
      types.DELETE_COMPANY_ERROR
    ]
  })
};

const createCompany = ({ accessToken, query }) => {
  return ({
    query: query,
    endpoint: 'companies',
    method: 'POST',
    credentials: true,
		accessToken,
    types: [
      types.SUBMIT_COMPANY_REQUEST,
      types.SUBMIT_COMPANY_SUCCESS,
      types.SUBMIT_COMPANY_ERROR
    ]
  })
};

const fetchCompanyCollections = ({ accessToken }) => {
  return ({
    endpoint: 'companies/helper',
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_COMPANY_COLLECTION_REQUEST,
      types.FETCH_COMPANY_COLLECTION_SUCCESS,
      types.FETCH_COMPANY_COLLECTION_ERROR
    ]
  })
}

export {
  fetchCompanies, fetchCompany, fetchCompanyCollections, createCompany, deleteCompanies,
  deleteCompany, updateCompany
};
