import * as types from 'src/constants/actionTypes/TransactionsActionTypes';

const fetchTransactions = ({ accessToken, query = {} }) => {
  return ({
    query,
    endpoint: 'transactions',
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_TRANSACTIONS_REQUEST,
      types.FETCH_TRANSACTIONS_SUCCESS,
      types.FETCH_TRANSACTIONS_ERROR
    ]
  })
}

const fetchTransaction = ({ accessToken, id, query = {} }) => {
  return ({
    query,
    endpoint: `transactions/${id}`,
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_TRANSACTION_REQUEST,
      types.FETCH_TRANSACTION_SUCCESS,
      types.FETCH_TRANSACTION_ERROR
    ]
  })
};

const updateTransaction = ({ accessToken, query, id }) => {
  return ({
    query,
    endpoint: `transactions/${id}`,
    method: 'PUT',
    credentials: true,
		accessToken,
    types: [
      types.SUBMIT_TRANSACTION_REQUEST,
      types.SUBMIT_TRANSACTION_SUCCESS,
      types.SUBMIT_TRANSACTION_ERROR
    ]
  })
};

const deleteTransactions = ({ accessToken, query }) => {
  return ({
    query,
    endpoint: `transactions`,
    method: 'DELETE',
    credentials: true,
		accessToken,
    types: [
      types.DELETE_TRANSACTIONS_REQUEST,
      types.DELETE_TRANSACTIONS_SUCCESS,
      types.DELETE_TRANSACTIONS_ERROR
    ]
  })
};

const deleteTransaction = ({ accessToken, id }) => {
  return ({
    endpoint: `transactions/${id}`,
    method: 'DELETE',
    credentials: true,
		accessToken,
    types: [
      types.DELETE_TRANSACTION_REQUEST,
      types.DELETE_TRANSACTION_SUCCESS,
      types.DELETE_TRANSACTION_ERROR
    ]
  })
};

const createTransaction = ({ accessToken, query }) => {
  return ({
    query: query,
    endpoint: 'transactions',
    method: 'POST',
    credentials: true,
		accessToken,
    types: [
      types.SUBMIT_TRANSACTION_REQUEST,
      types.SUBMIT_TRANSACTION_SUCCESS,
      types.SUBMIT_TRANSACTION_ERROR
    ]
  })
};

const fetchTransactionCollections = ({ accessToken }) => {
  return ({
    endpoint: 'transactions/helper',
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_TRANSACTION_COLLECTION_REQUEST,
      types.FETCH_TRANSACTION_COLLECTION_SUCCESS,
      types.FETCH_TRANSACTION_COLLECTION_ERROR
    ]
  })
}

const changeStatus = ({ accessToken, query }) => {
  return ({
    query,
    endpoint: 'transactions/change_status',
    method: 'POST',
    credentials: true,
    accessToken,
    types: [
      types.SUBMIT_TRANSACTION_REQUEST,
      types.SUBMIT_TRANSACTION_SUCCESS,
      types.SUBMIT_TRANSACTION_ERROR
    ]
  })
}

export {
  fetchTransactions, fetchTransaction, fetchTransactionCollections, createTransaction, deleteTransactions,
  deleteTransaction, updateTransaction, changeStatus
};
