import {
  FETCH_PROPERTY, FETCH_PROPERTY_SUCCESS, FETCH_PROPERTY_ERROR,
  FETCH_ALL_PROPERTIES, FETCH_ALL_PROPERTIES_SUCCESS, FETCH_ALL_PROPERTIES_ERROR
} from 'src/constants/actionTypes/entities/property';
import { FetchEntityFunc, QueryParams, FetchRelatedEntitiesFunc } from 'src/-types/request';
import { FETCH_ALL_SNAGS, FETCH_ALL_SNAGS_SUCCESS, FETCH_ALL_SNAGS_ERROR } from 'src/constants/actionTypes/entities/snag';

export const fetchProperty: FetchEntityFunc = ({ id, query }) => ({
  query,
  apiVersion: 'v2',
  endpoint: `properties/${id}`,
  entityId: id,
  method: 'GET',
  credentials: true,
  types: [
    FETCH_PROPERTY,
    FETCH_PROPERTY_SUCCESS,
    FETCH_PROPERTY_ERROR
  ]
});

export const fetchAllProperties = ({ query }: { query?: QueryParams } = {}) => ({
  query,
  apiVersion: 'v2',
  endpoint: `properties`,
  method: 'GET',
  credentials: true,
  types: [
    FETCH_ALL_PROPERTIES,
    FETCH_ALL_PROPERTIES_SUCCESS,
    FETCH_ALL_PROPERTIES_ERROR
  ]
});

export const fetchPropertySnags: FetchRelatedEntitiesFunc = ({ id, query }) => ({
  query,
  apiVersion: 'v2',
  endpoint: `properties/${id}/snags`,
  entityId: id,
  method: 'GET',
  credentials: true,
  types: [
    FETCH_ALL_SNAGS,
    FETCH_ALL_SNAGS_SUCCESS,
    FETCH_ALL_SNAGS_ERROR
  ]
});
