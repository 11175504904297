import * as types from 'src/constants/actionTypes/LeadsActionTypes';

const initialState = {
  isDestroing: false,
  error: false,
  successDestroy: false,
}

export default store => {
  store.on('@init', () => ({ LeadDestroy: initialState }));

  store.on(types.DELETE_LEADS_REQUEST, ({ LeadDestroy }) =>
    ({ LeadDestroy: { ...LeadDestroy, isDestroing: true, successDestroy: false }}));
  store.on(types.DELETE_LEADS_SUCCESS, ({ LeadDestroy }) =>
    ({ LeadDestroy: {...LeadDestroy, successDestroy: true }}));
  store.on(types.DELETE_LEADS_ERROR, ({ LeadDestroy }) =>
    ({ LeadDestroy: {...LeadDestroy, error: true }}));

  store.on(types.DELETE_LEAD_REQUEST, ({ LeadDestroy }) =>
    ({ LeadDestroy: { ...LeadDestroy, isDestroing: true, successDestroy: false }}));
  store.on(types.DELETE_LEAD_SUCCESS, ({ LeadDestroy }) =>
    ({ LeadDestroy: {...LeadDestroy, successDestroy: true }}));
  store.on(types.DELETE_LEAD_ERROR, ({ LeadDestroy }) =>
    ({ LeadDestroy: {...LeadDestroy, error: true }}));
}
