import * as types from 'src/constants/actionTypes/InternalBrokersActionTypes';
import { SUBMIT_RECENTLY_VIEWED } from 'src/constants/actionTypes/RecentlyViewedActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  internalBroker: {}
}

export default store => {
  store.on('@init', () => ({ InternalBroker: initialState }));

  store.on(types.FETCH_INTERNAL_BROKER_REQUEST, ({ InternalBroker }) =>
    ({ InternalBroker: { ...initialState, isFetching: true }}));
  store.on(types.FETCH_INTERNAL_BROKER_SUCCESS, ({ InternalBroker }, { response }) => {
    store.dispatch(SUBMIT_RECENTLY_VIEWED, {
      data: { id: response.data.id, name: response.data.name, time: Date.now()},
      endpoint: 'internal_brokers'
    });
    return ({ InternalBroker: { ...InternalBroker, internalBroker: response.data }})
  });
  store.on(types.FETCH_INTERNAL_BROKER_ERROR, ({ InternalBroker }) =>
    ({ InternalBroker: {...InternalBroker, error: true }}));
}
