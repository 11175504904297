import * as types from 'src/constants/actionTypes/HistoryActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  items: {}
}

export default store => {
  store.on('@init', () => ({ History: initialState }));

  store.on(types.FETCH_HISTORY_REQUEST, ({ History }) =>
    ({ History: { ...initialState, isFetching: true }}));
  store.on(types.FETCH_HISTORY_SUCCESS, ({ History }, { response }) => {
    const { data, endpoint } = response;
    const items = Object.assign(
      {},
      History.items,
      { [endpoint]: { data }
    });
    return ({ History: { ...History, items }})
  });
  store.on(types.FETCH_HISTORY_ERROR, ({ History }) =>
    ({ History: {...History, error: true }}));
}
