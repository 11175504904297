import * as types from 'src/constants/actionTypes/PerformancesActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  items: {}
}

export default store => {
  store.on('@init', () => ({ PerformanceCollections: initialState }));

  store.on(types.FETCH_PERFORMANCE_COLLECTION_REQUEST, ({ PerformanceCollections }) =>
    ({ PerformanceCollections: { ...PerformanceCollections, isFetching: true }}));
  store.on(types.FETCH_PERFORMANCE_COLLECTION_SUCCESS, ({ PerformanceCollections }, { response }) =>
    ({ PerformanceCollections: { ...PerformanceCollections, items: response.data }}));
  store.on(types.FETCH_PERFORMANCE_COLLECTION_ERROR, ({ PerformanceCollections }) =>
    ({ PerformanceCollections: {...PerformanceCollections, error: true }}));
};
