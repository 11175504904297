import * as types from 'src/constants/actionTypes/TeamsActionTypes';

const initialState = {
  isDestroing: false,
  error: false,
  successDestroy: false,
}

export default store => {
  store.on('@init', () => ({ TeamDestroy: initialState }));

  store.on(types.DELETE_TEAMS_REQUEST, ({ TeamDestroy }) =>
    ({ TeamDestroy: { ...TeamDestroy, isDestroing: true, successDestroy: false }}));
  store.on(types.DELETE_TEAMS_SUCCESS, ({ TeamDestroy }) =>
    ({ TeamDestroy: {...TeamDestroy, successDestroy: true }}));
  store.on(types.DELETE_TEAMS_ERROR, ({ TeamDestroy }) =>
    ({ TeamDestroy: {...TeamDestroy, error: true }}));

  store.on(types.DELETE_TEAM_REQUEST, ({ TeamDestroy }) =>
    ({ TeamDestroy: { ...TeamDestroy, isDestroing: true, successDestroy: false }}));
  store.on(types.DELETE_TEAM_SUCCESS, ({ TeamDestroy }) =>
    ({ TeamDestroy: {...TeamDestroy, successDestroy: true }}));
  store.on(types.DELETE_TEAM_ERROR, ({ TeamDestroy }) =>
    ({ TeamDestroy: {...TeamDestroy, error: true }}));
}
