import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useTranslation } from 'react-i18next'

import 'src/styles/select-search.scss';

const HorizontalSelectSearch = ({
  labelSize = "col-2", inputSize = "col-9", inputClassNames = "",
  label, required = false, collection = [], name, onChange, errors, value, multiple
}) => {
  const inputRef = React.createRef();
  const { t } = useTranslation();
  const errorMessage = errors ? errors.join(', ') : t('form.provideValid', { label });
  const handleOnChange = (object) => {
    const input = inputRef.current;
    if (multiple) {
      const values = object ? object.map(i => i.value) : [];
      onChange({ [name]: values });
      input.value = values;
    } else {
      const value = object === null ? '' : object.value
      onChange(name, value);
      input.value = value;
    }
  };

  const optionProperty = collection.map(i => i.options);
  const valuesCollection = optionProperty.flat();

  let defaultValues;
  if (Array.isArray(value)) {
    defaultValues = value.map(v => valuesCollection.find(item => item.value === v));
  } else if (value) {
    defaultValues = valuesCollection.find(item => item.value === value)
  }

  return (
    <div className="form-row mb-1">
			<label className={`${labelSize} col-form-label form-label`}>{label}</label>
      <div className={`${inputSize}`}>
        <input
          type="text"
          value={value === null ? '' : value}
          required={required}
          name={name}
          onChange={()=>{}}
          className="form-control d-none"
          ref={inputRef}
        />
        <Select
          className={`basic-single ${inputClassNames}`}
          classNamePrefix={`select-with-search-prefix`}
          value={defaultValues || null}
          isSearchable
          isClearable
          name={name}
          options={collection}
          isMulti={multiple}
          onChange={handleOnChange}
          required={required}
        />
      </div>
      {required && <div className='invalid-feedback'>{errorMessage}</div>}
		</div>
  )
}

HorizontalSelectSearch.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default HorizontalSelectSearch;
