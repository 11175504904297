import * as types from 'src/constants/actionTypes/PropertiesActionTypes';

const fetchProperties = ({ accessToken, query = {} }) => {
  return ({
    query,
    endpoint: 'properties',
    method: 'GET',
    credentials: true,
    accessToken,
    types: [
      types.FETCH_PROPERTIES_REQUEST,
      types.FETCH_PROPERTIES_SUCCESS,
      types.FETCH_PROPERTIES_ERROR
    ]
  });
};

const fetchProperty = ({ accessToken, id, query = {} }) => {
  return ({
    query,
    endpoint: `properties/${id}`,
    method: 'GET',
    credentials: true,
    accessToken,
    types: [
      types.FETCH_PROPERTY_REQUEST,
      types.FETCH_PROPERTY_SUCCESS,
      types.FETCH_PROPERTY_ERROR
    ]
  });
};

const updateProperty = ({ accessToken, body, query, id }) => {
  return ({
    body,
    query,
    endpoint: `properties/${id}`,
    method: 'PUT',
    credentials: true,
    accessToken,
    types: [
      types.SUBMIT_PROPERTY_REQUEST,
      types.SUBMIT_PROPERTY_SUCCESS,
      types.SUBMIT_PROPERTY_ERROR
    ]
  });
};

const deleteProperties = ({ accessToken, query }) => {
  return ({
    query,
    endpoint: `properties`,
    method: 'DELETE',
    credentials: true,
    accessToken,
    types: [
      types.DELETE_PROPERTIES_REQUEST,
      types.DELETE_PROPERTIES_SUCCESS,
      types.DELETE_PROPERTIES_ERROR
    ]
  });
};

const deleteProperty = ({ accessToken, id }) => {
  return ({
    endpoint: `properties/${id}`,
    method: 'DELETE',
    credentials: true,
    accessToken,
    types: [
      types.DELETE_PROPERTY_REQUEST,
      types.DELETE_PROPERTY_SUCCESS,
      types.DELETE_PROPERTY_ERROR
    ]
  });
};

const createProperty = ({ accessToken, body, query }) => {
  return ({
    body: body,
    query: query,
    endpoint: 'properties',
    method: 'POST',
    credentials: true,
    accessToken,
    types: [
      types.SUBMIT_PROPERTY_REQUEST,
      types.SUBMIT_PROPERTY_SUCCESS,
      types.SUBMIT_PROPERTY_ERROR
    ]
  });
};

const fetchPropertyCollections = ({ accessToken }) => {
  return ({
    endpoint: 'properties/helper',
    method: 'GET',
    credentials: true,
    accessToken,
    types: [
      types.FETCH_PROPERTY_COLLECTION_REQUEST,
      types.FETCH_PROPERTY_COLLECTION_SUCCESS,
      types.FETCH_PROPERTY_COLLECTION_ERROR
    ]
  });
};

const fetchPropertyListing = ({ accessToken, id, query = {} }) => {
  return ({
    query,
    endpoint: `properties/${id}/property_listing`,
    method: 'GET',
    credentials: true,
    accessToken,
    types: [
      types.FETCH_PROPERTY_LISTING_REQUEST,
      types.FETCH_PROPERTY_LISTING_SUCCESS,
      types.FETCH_PROPERTY_LISTING_ERROR
    ]
  });
};


export {
  fetchProperties, fetchProperty, fetchPropertyCollections, createProperty, deleteProperties,
  deleteProperty, updateProperty, fetchPropertyListing
};
