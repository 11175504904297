export class Logger {
  public debug<T>(value: T): void {
    console.debug(value);
  }

  public info<T>(value: T): void {
    console.info(value);
  }

  public error<T>(err: T): void {
    console.error(err);
  }
}

export const logger = new Logger();
