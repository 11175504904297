import React from 'react';
import { camelCase, map, toLower, trim } from 'lodash';

import VerticalDateInput from 'src/components/elements/form/VerticalDateInput';
import VerticalInput from 'src/components/elements/form/VerticalInput';
import VerticalTextArea from 'src/components/elements/form/VerticalTextArea';

const getInputType = ({ fieldType, inputType }) => {
/* eslint-disable default-case */
  switch (toLower(trim(inputType))) {
    case 'textarea':
      return VerticalTextArea;
  }

  switch (toLower(trim(fieldType))) {
    case 'date':
      return VerticalDateInput;
  }
/* eslint-enable default-case */

  return VerticalInput;
};

const Element = ({ field, inputs, name, onChanges, validations }) => {
  const Component = getInputType(field);
  const onChange = onChanges[field.fieldType] || onChanges['text'];
  const fieldName = `specific.${name}`;
  const component = <Component
    value={inputs.specific && inputs.specific[name] ? inputs.specific[name] : ''}
    onChange={onChange}
    label={field.label}
    name={fieldName}
    errors={validations[name]}
  />;
  return (
    <>
      {!field.layoutOptionName && component}
      {field.layoutOptionName && field.layoutOption.includes(inputs[camelCase(field.layoutOptionName)]) && component}
    </>
  );
}

export default ({ fields, inputs, onChanges, validations }) => {
  const keys = Object.keys(fields);
  return (
    map(keys, (key, index) =>
      <Element
        key={`${key}-${index}`}
        inputs={inputs}
        field={fields[key]}
        name={key}
        onChanges={onChanges}
        validations={validations}
      />
    )
  );
};
