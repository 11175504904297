import { listingClonePath } from 'src/helpers/routes';
import listingNew from 'src/routes/listings/New';
import { fetchListingCollections, fetchListing } from 'src/actions/Listings';
import { API_CALL } from 'src/middleware/API';
import ListingClone from 'src/components/views/listings/Clone';

const prepareData = ({ store, params }) => {
  const state = store.get();
  if (state.Auth.connected === false) return;
  const accessToken = state.Auth.token;
  if (Object.keys(state.ListingCollections.items).length ===  0) {
    store.dispatch(API_CALL, fetchListingCollections({ accessToken }));
  }
  const query = { noTypeCast: true, withProperties: true, act: 'edit' }
  store.dispatch(API_CALL, fetchListing({ accessToken, id: params.id, query }));
};

export default Object.assign(
  {},
  listingNew,
  { path: listingClonePath(), prepareData, component: ListingClone });
