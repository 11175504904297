import FilterByDateRange from './filterByDateRange';
import FilterByRole, { optionsByRole } from './filterByRole';
import FilterByView from './filterByView';
import { FilterByText } from './FilterByText';
import FilterByList from './filterByList';

const ENDPOINT = 'contacts';

const DEFAULT_VIEW = [
  { data: 'id', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  { data: 'name', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link show-link', path: 'contactPath', pathKey: 'id' },
  { data: 'companyName', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link company-link', path: 'companyPath', pathKey: 'companyId' },
  { data: 'email', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'workEmail', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'mobile', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'workMobile', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'leadSource', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'createdAt', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'companyId', searchable: false, orderable: false, search: { value: '', regex: '' }, hidden: true }
];

const BIRTHDAYS_VIEW = [
  { data: 'id', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  { data: 'name', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link show-link', path: 'contactPath', pathKey: 'id' },
  { data: 'birthdate', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'companyName', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link company-link', path: 'companyPath', pathKey: 'companyId' },
  { data: 'firstLanguage', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'email', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'workEmail', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'mobile', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'createdAt', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'companyId', searchable: false, orderable: false, search: { value: '', regex: '' }, hidden: true }
];

const fieldsByView = (view) => {
  const fields = {
    'recentlyViewed': DEFAULT_VIEW,
    'contacts': DEFAULT_VIEW,
    'birthdays': BIRTHDAYS_VIEW
  };
  return fields[view];
};

export const optionsForFilterByView = {
  'contacts': {},
  'birthdays': { name: 'birthdate', value: '3', type: 'birthdate' }
};

export const defaultFilter = ({ role, ids }) => {
  const filter = {};
  const byRole = optionsByRole({ role, endpoint: ENDPOINT });
  if (byRole.length) filter.role = { value: byRole[0], type: 'role' };
  if (ids.length) filter.view = { name: 'id', type: 'eq', value: ids };
  return filter;
};

export const columnFilters = [
  { filter: FilterByList, dataField: 'contactType', label: t => t('contacts.contactType'), source: 'ContactCollections.items.contactType', type: 'in' },
  { filter: FilterByDateRange, dataField: 'createdAt' },
  { filter: FilterByText, dataField: 'wildcard', label: t => t('contacts.search') }
];

export const outsideFilters = [
  { component: FilterByView, endpoint: ENDPOINT, options: optionsForFilterByView },
  { component: FilterByRole }
];

export default ({ view = 'contacts', items = [] }) => {
  const columns = fieldsByView(view);
  const query = {
    columns,
    order: [{ column: 0, dir: 'asc' }],
    start: 0,
    length: 10,
    endpoint: ENDPOINT
  };
  return { query, endpoint: ENDPOINT, columns };
};
