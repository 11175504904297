import { adminRoles, teamAdminRoles, brokerRoles } from 'src/rules';
import { without } from 'lodash';

export const listOfView = ['me', 'everyone', 'myTeam'];

const viewWithoutMe = without(listOfView, 'me');

export const listOfModels = ['activity', 'company', 'contact', 'lead', 'project', 'property', 'listing', 'offer', 'transaction', 'internalBroker'];

const adminWithTeamRoles = [...adminRoles, ...teamAdminRoles];
const allRoles = [...adminWithTeamRoles, ...brokerRoles];

const listActivityFilter = [
  { label: 'typeStatus', roles: allRoles, listOwnedBy: [listOfView[0]] },
  { label: 'brokerTypeListingActivityStatus', roles: adminWithTeamRoles, listOwnedBy: viewWithoutMe },
  { label: 'brokerTypeCommunicationStatus', roles: adminWithTeamRoles, listOwnedBy: viewWithoutMe }
];

const listCompanyFilter = [
  { label: 'industry', roles: allRoles, listOwnedBy: [listOfView[0]] },
  { label: 'brokerIndustry', roles: adminWithTeamRoles, listOwnedBy: viewWithoutMe }
];

const listContactFilter = [
  { label: 'contactType', roles: allRoles, listOwnedBy: [listOfView[0]] },
  { label: 'brokerContactType', roles: adminWithTeamRoles, listOwnedBy: viewWithoutMe },
  { label: 'typeCountryCode', roles: allRoles, listOwnedBy: listOfView }
];

const listLeadFilter = [
  { label: 'typeStatusStage', roles: allRoles, listOwnedBy: [listOfView[0]] },
  { label: 'brokerStatusStage', roles: adminWithTeamRoles, listOwnedBy: viewWithoutMe },
  { label: 'typeStatusQualityScore', roles: allRoles, listOwnedBy: [listOfView[0]] },
  { label: 'brokerStatusQualityScore', roles: adminWithTeamRoles, listOwnedBy: viewWithoutMe },
  { label: 'typeStatusSourceChannel', roles: allRoles, listOwnedBy: [listOfView[0]] },
  { label: 'brokerStatusSourceChannel', roles: adminWithTeamRoles, listOwnedBy: viewWithoutMe },
  { label: 'sourceChannelTypeQualityScore', roles: allRoles, listOwnedBy: listOfView },
  { label: 'referringCompanyTypeQualityScore', roles: allRoles, listOwnedBy: listOfView },
  { label: 'referringContactTypeQualityScore', roles: allRoles, listOwnedBy: listOfView },
  { label: 'propertyTypeTotalAverageLeads2', roles: adminWithTeamRoles, listOwnedBy: viewWithoutMe }
];

const listProjectFilter = [
  { label: 'constructionStatus', roles: allRoles, listOwnedBy: [listOfView[1]], type: 'pie' }
];

const listPropertyFilter = [
  { label: 'constructionStatus2', roles: allRoles, listOwnedBy: [listOfView[1]] },
  { label: 'propertyType', roles: allRoles, listOwnedBy: [listOfView[1]], type: 'pie' },
  { label: 'propertyUsePropertyType', roles: allRoles, listOwnedBy: [listOfView[1]] },
  { label: 'propertyTypeBedrooms', roles: allRoles, listOwnedBy: [listOfView[1]] }
];

const listListingFilter = [
  { label: 'listingScore', roles: allRoles, listOwnedBy: listOfView, type: 'pie' },
  { label: 'propertyTypeTotalAverageLeads', roles: allRoles, listOwnedBy: listOfView },
  { label: 'statusListingTypePropertyType', roles: allRoles, listOwnedBy: listOfView },
  { label: 'agreementTypeStatus', roles: allRoles, listOwnedBy: listOfView }
];

const listOfferFilter = [
  { label: 'status', roles: brokerRoles, listOwnedBy: [listOfView[0]], type: 'pie' },
  { label: 'brokerStatus', roles: adminWithTeamRoles, listOwnedBy: viewWithoutMe }
];

const listTransactionFilter = [
  { label: 'typeStatus2', roles: adminWithTeamRoles, listOwnedBy: viewWithoutMe },
  { label: 'typeCompanyRoleStatus', roles: adminWithTeamRoles, listOwnedBy: viewWithoutMe },
  { label: 'typeStatusTotalCommissionTotalCompanyCommissionC', roles: adminWithTeamRoles, listOwnedBy: viewWithoutMe },
  { label: 'typeStatusTotalCommissionTotalCompanyCommissionP', roles: adminWithTeamRoles, listOwnedBy: viewWithoutMe },
  { label: 'typePropertyType', roles: adminWithTeamRoles, listOwnedBy: viewWithoutMe },
  { label: 'typeContactTypeSourceChannel', roles: adminWithTeamRoles, listOwnedBy: viewWithoutMe },
  { label: 'referringCompanyTypeFinalPriceStatus', roles: adminWithTeamRoles, listOwnedBy: viewWithoutMe, agency: true }
];

const listInternalBrokerFilter = [
  { label: 'brokerTransactionTypeBrokerRole', roles: adminWithTeamRoles, listOwnedBy: viewWithoutMe },
  { label: 'brokerTransactionTypeCommissionEarnedC', roles: adminWithTeamRoles, listOwnedBy: viewWithoutMe },
  { label: 'brokerTransactionTypeCommissionEarnedP', roles: adminWithTeamRoles, listOwnedBy: viewWithoutMe }
];

const filters = {
  [listOfModels[0]]: listActivityFilter,
  [listOfModels[1]]: listCompanyFilter,
  [listOfModels[2]]: listContactFilter,
  [listOfModels[3]]: listLeadFilter,
  [listOfModels[4]]: listProjectFilter,
  [listOfModels[5]]: listPropertyFilter,
  [listOfModels[6]]: listListingFilter,
  [listOfModels[7]]: listOfferFilter,
  [listOfModels[8]]: listTransactionFilter,
  [listOfModels[9]]: listInternalBrokerFilter
};

export const listFilterByViewAndRole = ({ view, role, agency = false }) => {
  let filtered = filters[view].filter(el => el.roles.includes(role));
  return agency === true ? filtered.filter(el => el.agency !== true) : filtered
};

export const graphTypeByFilterBy = ({ by }) => {
  const obj = Object.values(filters).flat().find(el => el.label === by);
  return obj.type;
};

const exceptionView = [
  listOfModels[4], listOfModels[5], listOfModels[6]
];

export const listViewByRole = ({ view, role, by }) => {
  let list = [];
  if (listOfModels.includes(view)) {
    const obj = Object.values(filters).flat().find(el => el.label === by);
    list = obj.listOwnedBy;
    if (exceptionView.includes(view)) return list;
    if (brokerRoles.includes(role)) list = [listOfView[0]];
    if (teamAdminRoles.includes(role)) list = list.filter(el => el !== listOfView[1]);
    if (adminRoles.includes(role)) list = list.filter(el => el !== listOfView[2]);
  }
  return list;
};
