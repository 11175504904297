import * as types from 'src/constants/actionTypes/ListingsActionTypes';

const initialState = {
  isDestroing: false,
  error: false,
  successDestroy: false,
}

export default store => {
  store.on('@init', () => ({ ListingDestroy: initialState }));

  store.on(types.DELETE_LISTINGS_REQUEST, ({ ListingDestroy }) =>
    ({ ListingDestroy: { ...ListingDestroy, isDestroing: true, successDestroy: false }}));
  store.on(types.DELETE_LISTINGS_SUCCESS, ({ ListingDestroy }) =>
    ({ ListingDestroy: {...ListingDestroy, successDestroy: true }}));
  store.on(types.DELETE_LISTINGS_ERROR, ({ ListingDestroy }) =>
    ({ ListingDestroy: {...ListingDestroy, error: true }}));

  store.on(types.DELETE_LISTING_REQUEST, ({ ListingDestroy }) =>
    ({ ListingDestroy: { ...ListingDestroy, isDestroing: true, successDestroy: false }}));
  store.on(types.DELETE_LISTING_SUCCESS, ({ ListingDestroy }) =>
    ({ ListingDestroy: {...ListingDestroy, successDestroy: true }}));
  store.on(types.DELETE_LISTING_ERROR, ({ ListingDestroy }) =>
    ({ ListingDestroy: {...ListingDestroy, error: true }}));
}
