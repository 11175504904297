import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Collapse as CollapseReactStrap } from 'reactstrap';
import randomId from 'src/helpers/randomId';
import 'src/styles/accordion.scss';
import IconDown from 'src/constants/icons/IconDown';
import { noop } from 'lodash';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const ControlledCollapse = ({ children, label, openUploadTab = false, isOpen = false, onEntering = noop, onExiting = noop }) => {
  const id = randomId();
  const fileEl = useRef(null);

  const [activeCss, setActiveCss] = useState('');
  const [border, setBorder] = useState('');
  const [open, setOpen] = useState(isOpen);
  const handlerEntering = useCallback(() => {
    setActiveCss('text-blue');
    setBorder('border-blue');
    onEntering();
  }, [onEntering]);
  const handlerExiting = useCallback(() => {
    setActiveCss('');
    setBorder('');
    onExiting();
  }, [onExiting]);
  const toggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    if (openUploadTab) {
      setOpen(true);
      scrollToRef(fileEl);
    }
  }, [openUploadTab]);

  return (
    <ul className={`accordionjs m-0 mb-4`} ref={fileEl}>
      <li className={`acc_section ${border}`}>
        <div className={`acc_head ${activeCss}`} id={id} onClick={toggle}>
          <h3>
            <IconDown isExpanded={open} />
            {label}
          </h3>
        </div>
        <CollapseReactStrap
          isOpen={open}
          toggler={`#${id}`}
          className="acc_content"
          onEntering={handlerEntering}
          onExiting={handlerExiting}
        >
          {children}
        </CollapseReactStrap>
      </li>
    </ul>
  )
};

export default ControlledCollapse;
