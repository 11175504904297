import * as types from 'src/constants/actionTypes/UsersActionTypes';

const fetchUsers = ({ accessToken, query = {} }) => {
  return ({
    query,
    endpoint: 'users',
    method: 'GET',
    credentials: true,
    accessToken,
    types: [
      types.FETCH_USERS_REQUEST,
      types.FETCH_USERS_SUCCESS,
      types.FETCH_USERS_ERROR
    ]
  })
}

const fetchUser = ({ accessToken, id, query = {} }) => {
  return ({
    query,
    endpoint: `users/${id}`,
    method: 'GET',
    credentials: true,
    accessToken,
    types: [
      types.FETCH_USER_REQUEST,
      types.FETCH_USER_SUCCESS,
      types.FETCH_USER_ERROR
    ]
  })
};

const fetchUserOwned = ({ accessToken, id }) => {
  return ({
    endpoint: `users/${id}/owned`,
    method: 'GET',
    credentials: true,
    accessToken,
    types: [
      types.FETCH_USER_OWNED_REQUEST,
      types.FETCH_USER_OWNED_SUCCESS,
      types.FETCH_USER_OWNED_ERROR
    ]
  })
};

const updateUser = ({ accessToken, query, id, attach, thenFn, catchFn }) => {
  return ({
    query,
    attach,
    endpoint: `users/${id}`,
    method: 'PUT',
    credentials: true,
    accessToken,
    types: [
      types.SUBMIT_USER_REQUEST,
      types.SUBMIT_USER_SUCCESS,
      types.SUBMIT_USER_ERROR
    ],
    thenFn,
    catchFn
  })
};

const deleteUsers = ({ accessToken, query }) => {
  return ({
    query,
    endpoint: `users`,
    method: 'DELETE',
    credentials: true,
    accessToken,
    types: [
      types.DELETE_USERS_REQUEST,
      types.DELETE_USERS_SUCCESS,
      types.DELETE_USERS_ERROR
    ]
  })
};

const deleteUser = ({ accessToken, id }) => {
  return ({
    endpoint: `users/${id}`,
    method: 'DELETE',
    credentials: true,
    accessToken,
    types: [
      types.DELETE_USER_REQUEST,
      types.DELETE_USER_SUCCESS,
      types.DELETE_USER_ERROR
    ]
  })
};

const createUser = ({ accessToken, query, attach }) => {
  return ({
    query,
    attach,
    endpoint: 'users',
    method: 'POST',
    credentials: true,
    accessToken,
    types: [
      types.SUBMIT_USER_REQUEST,
      types.SUBMIT_USER_SUCCESS,
      types.SUBMIT_USER_ERROR
    ]
  })
};

const fetchUserCollections = ({ accessToken }) => {
  return ({
    endpoint: 'users/helper',
    method: 'GET',
    credentials: true,
    accessToken,
    types: [
      types.FETCH_USER_COLLECTION_REQUEST,
      types.FETCH_USER_COLLECTION_SUCCESS,
      types.FETCH_USER_COLLECTION_ERROR
    ]
  })
}

const deactivateUsers = ({ accessToken, query }) => {
  return ({
    query,
    endpoint: 'users/deactivate',
    method: 'POST',
    credentials: true,
    accessToken,
    types: [
      types.SUBMIT_USER_REQUEST,
      types.SUBMIT_USER_SUCCESS,
      types.SUBMIT_USER_ERROR
    ]
  })
}

const resetUserPassword = ({ accessToken, id }) => {
  return ({
    endpoint: `users/${id}/reset_password`,
    method: 'POST',
    credentials: true,
    accessToken,
    types: [
      types.RESET_USER_PASSWORD_REQUEST,
      types.RESET_USER_PASSWORD_SUCCESS,
      types.RESET_USER_PASSWORD_ERROR
    ]
  })
}

export {
  fetchUsers, deleteUsers, fetchUser, updateUser, fetchUserCollections, createUser, deleteUser,
  deactivateUsers, resetUserPassword, fetchUserOwned
};
