import * as types from 'src/constants/actionTypes/PerformancesActionTypes';

const fetchPerformanceCollections = ({ accessToken }) => {
  return ({
    endpoint: 'performances/helper',
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_PERFORMANCE_COLLECTION_REQUEST,
      types.FETCH_PERFORMANCE_COLLECTION_SUCCESS,
      types.FETCH_PERFORMANCE_COLLECTION_ERROR
    ]
  })
}

const fetchPerformance = ({ accessToken, query, endpoint }) => {
  return ({
    query,
    endpoint: `performances/${endpoint}`,
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_PERFORMANCE_REQUEST,
      types.FETCH_PERFORMANCE_SUCCESS,
      types.FETCH_PERFORMANCE_ERROR
    ]
  })
};

export { fetchPerformanceCollections, fetchPerformance };
