import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import FormHooks from 'src/helpers/formHooks';
import { useTranslation } from 'react-i18next';
import useStoreon from 'src/helpers/storeon';
import { API_CALL } from 'src/middleware/API';
import { SUBMIT_PROPERTY_RESET } from 'src/constants/actionTypes/PropertiesActionTypes';
import ControlledCollapse from 'src/components/elements/shared/ControlledCollapse';
import VerticalInput from 'src/components/elements/form/VerticalInput';
import VerticalSelectSearch from 'src/components/elements/form/VerticalSelectSearch';
import VerticalTextArea from 'src/components/elements/form/VerticalTextArea';
import VerticalCheckBox from 'src/components/elements/form/VerticalCheckBox';
import VerticalMoneyInput from 'src/components/elements/form/VerticalMoneyInput';
import VerticalDateInput from 'src/components/elements/form/VerticalDateInput';
import VerticalInputPlainText from 'src/components/elements/form/VerticalInputPlainText';
import LinkToUserWithDate from 'src/components/elements/form/LinkToUserWithDate';
import clearValidation from 'src/components/elements/form/clearValidation';
import setServerSideErrors from 'src/components/elements/form/setServerSideErrors';
import renderFields from 'src/helpers/renderFields';
import { LocationPicker } from 'src/components/elements/LocationPicker/LocationPicker';
import { find, map } from 'lodash';
import { hasArray } from 'src/helpers/array';

export const PropertyForm = (props) => {
  const { defaultValues, children, submitHandler, id, history, successPath, clone } = props;
  const [validations, setValidations] = useState({ listingPropertyAttributes: {} });
  const formRef = useRef(null);
  const { PropertyCollections, Auth, SubmitProperty, dispatch } = useStoreon('PropertyCollections', 'Auth', 'SubmitProperty');
  const { t } = useTranslation();
  const { match: { params: { projectId } } } = props;

  useEffect(() => {
    if (!SubmitProperty) {
      return;
    }

    const { success, sendRequest } = SubmitProperty;

    if (!sendRequest && success) {
      dispatch(SUBMIT_PROPERTY_RESET);
      history.push(successPath);
    }
  }, [SubmitProperty, dispatch, history, successPath]);

  useEffect(() => {
    if (!SubmitProperty) {
      return;
    }

    const { errors, error, sendRequest } = SubmitProperty;

    if (!sendRequest && error) {
      setServerSideErrors({ formRef, errors, setValidations });
    }
  }, [SubmitProperty]);

  const callBack = useCallback((inputs) => {
    clearValidation(formRef);

    if (formRef.current.checkValidity() !== false) {
      const { listingPropertyAttributes } = inputs;
      const { location } = listingPropertyAttributes;
      const body = { property: { ...inputs, listingPropertyAttributes: { ...listingPropertyAttributes, location: location?.id } } };

      dispatch(API_CALL, submitHandler({ body, id }));
    }

    formRef.current.classList.add('was-validated');
  }, [dispatch, formRef, id, submitHandler]);

  if (!defaultValues.specific) {
    defaultValues.specific = {};
  }

  const { inputs, handleInputChange, handleSubmit, handleSelectSearch, handleDataChange, setInputs } = FormHooks(defaultValues, callBack);
  const project = useMemo(() => {
    let result = '';

    if (projectId && PropertyCollections.items.project) {
      result = find(PropertyCollections.items.project, el => +projectId === el.value)?.label ?? '';
    }

    return result;
  }, [PropertyCollections.items.project, projectId]);
  const rows = useMemo(() => PropertyCollections.loaded ? PropertyCollections.items.fields.rows : [], [PropertyCollections.items, PropertyCollections.loaded]);
  const onChanges = useMemo(() => ({ 'text': handleInputChange, 'date': handleDataChange }), [handleDataChange, handleInputChange]);
  const { currency } = Auth.user;
  const onLocationChange = useCallback((location) => {
    setInputs(prev => ({ ...prev, listingPropertyAttributes: { ...prev.listingPropertyAttributes, location } }));
  }, [setInputs]);

  return (
    <form onSubmit={handleSubmit} ref={formRef} noValidate={true} data-attr={'listingPropertyAttributes'}>
      <div className="row mb-5">
        <div className="col-lg-6 col-md-12">
          <VerticalInput
            value={inputs.title}
            onChange={handleInputChange}
            label={t('properties.title')}
            name="title"
            errors={validations.title}
          />
          {projectId
            ? <VerticalInputPlainText
              value={project}
              label={t('listings.project')}
              icon={false}
            />
            : <VerticalSelectSearch
              collection={PropertyCollections.items.project}
              value={inputs.projectId}
              onChange={handleSelectSearch}
              label={t('properties.project')}
              name="projectId"
              includeBlank={false}
            />
          }
          <VerticalInput
            value={inputs.listingPropertyAttributes.plotNumber}
            onChange={handleInputChange}
            label={PropertyCollections.items.plotNumber}
            name="listingProperty.plotNumber"
            inputName="listingPropertyAttributes.plotNumber"
            errors={validations.listingProperty?.plotNumber}
          />
          <LocationPicker
            label={t('properties.location')}
            name="listingProperty.location"
            onChange={onLocationChange}
            value={inputs.listingPropertyAttributes.location}
            errors={validations.listingProperty?.location}
          />
          <VerticalTextArea
            value={inputs.listingPropertyAttributes.address}
            onChange={handleInputChange}
            label={t('properties.address')}
            name="listingProperty.address"
            inputName="listingPropertyAttributes.address"
            errors={validations.listingProperty?.address}
          />
          <VerticalSelectSearch
            collection={PropertyCollections.items.ownership}
            value={inputs.listingPropertyAttributes.ownership}
            onChange={handleSelectSearch}
            label={t('properties.ownership')}
            name="listingProperty.ownership"
            inputName="listingPropertyAttributes.ownership"
            errors={validations.listingProperty?.ownership}
            includeBlank={false}
          />
          <div className="mobile-margin" />
        </div>
        <div className="col-lg-6 col-md-12">
          <VerticalSelectSearch
            collection={PropertyCollections.items.propertyType}
            value={inputs.listingPropertyAttributes.propertyType}
            onChange={handleSelectSearch}
            label={t('properties.propertyType')}
            name="listingProperty.propertyType"
            inputName="listingPropertyAttributes.propertyType"
            errors={validations.listingProperty?.propertyType}
            includeBlank={false}
          />
          <VerticalInput
            value={inputs.listingPropertyAttributes.floor}
            onChange={handleInputChange}
            label={t('properties.floor')}
            name="listingProperty.floor"
            inputName="listingPropertyAttributes.floor"
            errors={validations.listingProperty?.floor}
          />
          <VerticalInput
            value={inputs.listingPropertyAttributes.numberOfFloors}
            onChange={handleInputChange}
            label={t('properties.numberOfFloors')}
            name="listingProperty.numberOfFloors"
            inputName="listingPropertyAttributes.numberOfFloors"
            errors={validations.listingProperty?.numberOfFloors}
          />
          <div className="mobile-margin" />
          <VerticalSelectSearch
            collection={PropertyCollections.items.constructionStatus}
            value={inputs.listingPropertyAttributes.constructionStatus}
            onChange={handleSelectSearch}
            label={t('properties.constructionStatus')}
            name="listingProperty.constructionStatus"
            inputName="listingPropertyAttributes.constructionStatus"
            errors={validations.listingProperty?.constructionStatus}
            includeBlank={false}
          />
          <VerticalDateInput
            value={inputs.listingPropertyAttributes.constructionStartDate}
            onChange={handleDataChange}
            label={t('properties.constructionStartDate')}
            name="listingProperty.constructionStartDate"
            inputName="listingPropertyAttributes.constructionStartDate"
            errors={validations.listingProperty?.constructionStartDate}
            defaultFromMonth={1900}
          />
          <VerticalDateInput
            value={inputs.listingPropertyAttributes.completionDate}
            onChange={handleDataChange}
            label={t('properties.completionDate')}
            name="listingProperty.completionDate"
            inputName="listingPropertyAttributes.completionDate"
            errors={validations.listingProperty?.completionDate}
            defaultFromMonth={1900}
          />
          <VerticalDateInput
            value={inputs.listingPropertyAttributes.handoverDate}
            onChange={handleDataChange}
            label={t('properties.handoverDate')}
            name="listingProperty.handoverDate"
            inputName="listingPropertyAttributes.handoverDate"
            errors={validations.listingProperty?.handoverDate}
          />
        </div>
      </div>
      <ControlledCollapse isOpen label={t("properties.subFolders.ownershipDetails")}>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <VerticalSelectSearch
              collection={PropertyCollections.items.contact}
              value={inputs.listingPropertyAttributes.propertyOwnerId}
              onChange={handleSelectSearch}
              label={t('properties.propertyOwner')}
              name="listingProperty.propertyOwnerId"
              inputName="listingPropertyAttributes.propertyOwnerId"
              errors={validations.listingProperty?.propertyOwnerId}
            />
            <VerticalSelectSearch
              collection={PropertyCollections.items.contact}
              value={inputs.propertyCoOwnerId}
              onChange={handleSelectSearch}
              label={t('properties.propertyCoOwner')}
              name="propertyCoOwnerId"
              errors={validations.propertyCoOwnerId}
            />
            <VerticalMoneyInput
              collection={PropertyCollections.items.currency}
              value={inputs.originalPriceCents}
              valueSelect={inputs.originalPriceCurrency}
              onChange={handleInputChange}
              onChangeSelect={handleSelectSearch}
              label={t('properties.originalPrice')}
              name="originalPriceCents"
              nameSelect="originalPriceCurrency"
              errors={validations.originalPrice}
              currency={currency}
            />
            <VerticalDateInput
              value={inputs.originalSaleDate}
              onChange={handleDataChange}
              label={t('properties.originalSaleDate')}
              name="originalSaleDate"
              errors={validations.originalSaleDate}
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <VerticalMoneyInput
              collection={PropertyCollections.items.currency}
              value={inputs.listingPropertyAttributes.communityFeesCents}
              valueSelect={inputs.listingPropertyAttributes.communityFeesCurrency}
              onChange={handleInputChange}
              onChangeSelect={handleSelectSearch}
              label={PropertyCollections.items.communityFees}
              name="listingProperty.communityFeesCents"
              inputName="listingPropertyAttributes.communityFeesCents"
              nameSelect="listingPropertyAttributes.communityFeesCurrency"
              errors={validations.listingProperty?.communityFees}
              currency={currency}
            />
            <VerticalSelectSearch
              collection={PropertyCollections.items.propertyUse}
              value={inputs.propertyUse}
              onChange={handleSelectSearch}
              label={t('properties.propertyUse')}
              name="propertyUse"
              errors={validations.propertyUse}
            />
            <VerticalMoneyInput
              collection={PropertyCollections.items.currency}
              value={inputs.lastPriceCents}
              valueSelect={inputs.lastPriceCurrency}
              onChange={handleInputChange}
              onChangeSelect={handleSelectSearch}
              label={t('properties.lastPrice')}
              name="lastPriceCents"
              nameSelect="lastPriceCurrency"
              errors={validations.lastPrice}
              currency={currency}
            />
            <VerticalDateInput
              value={inputs.lastSaleDate}
              onChange={handleDataChange}
              label={t('properties.lastSaleDate')}
              name="lastSaleDate"
              errors={validations.lastSaleDate}
            />
          </div>
        </div>
      </ControlledCollapse>
      <ControlledCollapse isOpen label={t("properties.subFolders.unitDetails")}>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <VerticalInput
              value={inputs.listingPropertyAttributes.unitType}
              onChange={handleInputChange}
              label={PropertyCollections.items.unitType}
              name="listingProperty.unitType"
              inputName="listingPropertyAttributes.unitType"
              errors={validations.listingProperty?.unitType}
            />
            <VerticalInput
              value={inputs.listingPropertyAttributes.bedrooms}
              onChange={handleInputChange}
              label={t('properties.bedrooms')}
              name="listingProperty.bedrooms"
              inputName="listingPropertyAttributes.bedrooms"
              errors={validations.listingProperty?.bedrooms}
            />
            <VerticalInput
              value={inputs.listingPropertyAttributes.bathrooms}
              onChange={handleInputChange}
              label={t('properties.bathrooms')}
              name="listingProperty.bathrooms"
              inputName="listingPropertyAttributes.bathrooms"
              errors={validations.listingProperty?.bathrooms}
            />
            <VerticalSelectSearch
              collection={PropertyCollections.items.otherRooms}
              value={inputs.listingPropertyAttributes.otherRooms}
              onChange={handleDataChange}
              label={t('properties.otherRooms')}
              name="listingProperty.otherRooms"
              inputName="listingPropertyAttributes.otherRooms"
              multiple
            />
            <div className="mobile-margin" />
            <VerticalCheckBox
              value={inputs.decoPack}
              onChange={handleInputChange}
              label={t('properties.decoPack')}
              name="decoPack"
              errors={validations.decoPack}
            />
            <VerticalSelectSearch
              collection={PropertyCollections.items.parkingType}
              value={inputs.listingPropertyAttributes.parkingType}
              onChange={handleSelectSearch}
              label={t('properties.parkingType')}
              name="listingProperty.parkingType"
              inputName="listingPropertyAttributes.parkingType"
              errors={validations.listingProperty?.parkingType}
              includeBlank={false}
            />
            <VerticalInput
              value={inputs.listingPropertyAttributes.parkingSpace}
              onChange={handleInputChange}
              label={t('properties.parkingSpace')}
              name="listingProperty.parkingSpace"
              inputName="listingPropertyAttributes.parkingSpace"
              errors={validations.listingProperty?.parkingSpace}
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <VerticalInput
              value={inputs.listingPropertyAttributes.netInternalArea}
              onChange={handleInputChange}
              label={t('properties.netInternalArea')}
              name="listingProperty.netInternalArea"
              inputName="listingPropertyAttributes.netInternalArea"
              errors={validations.listingProperty?.netInternalArea}
            />
            <VerticalInput
              value={inputs.listingPropertyAttributes.balconyArea}
              onChange={handleInputChange}
              label={t('properties.balconyArea')}
              name="listingProperty.balconyArea"
              inputName="listingPropertyAttributes.balconyArea"
              errors={validations.listingProperty?.balconyArea}
            />
            <VerticalInput
              value={inputs.listingPropertyAttributes.grossInternalArea}
              onChange={handleInputChange}
              label={t('properties.grossInternalArea')}
              name="listingProperty.grossInternalArea"
              inputName="listingPropertyAttributes.grossInternalArea"
              errors={validations.listingProperty?.grossInternalArea}
            />
            <VerticalInput
              value={inputs.listingPropertyAttributes.plotArea}
              onChange={handleInputChange}
              label={t('properties.plotArea')}
              name="listingProperty.plotArea"
              inputName="listingPropertyAttributes.plotArea"
              errors={validations.listingProperty?.plotArea}
            />
            <VerticalSelectSearch
              collection={PropertyCollections.items.pool}
              value={inputs.listingPropertyAttributes.pool}
              onChange={handleSelectSearch}
              label={t('properties.pool')}
              name="listingProperty.pool"
              inputName="listingPropertyAttributes.pool"
              errors={validations.listingProperty?.pool}
            />
            <VerticalSelectSearch
              collection={PropertyCollections.items.view}
              value={inputs.listingPropertyAttributes.view}
              onChange={handleDataChange}
              label={t('properties.view')}
              name="listingProperty.view"
              inputName="listingPropertyAttributes.view"
              errors={validations.listingProperty?.view}
              multiple
            />
          </div>
        </div>
      </ControlledCollapse>
      {hasArray(rows) && <ControlledCollapse isOpen label={t("properties.subFolders.projectInformation")}>
        {map(rows, ({ leftSide, rightSide }, rowIndex) =>
          <div className="row mb-5" key={`row-${rowIndex}`}>
            {leftSide && <div className="col-lg-6 col-md-12">
              {renderFields({ inputs, fields: leftSide, onChanges, validations })}
            </div>}
            {rightSide && <div className="col-lg-6 col-md-12">
              {renderFields({ inputs, fields: rightSide, onChanges, validations })}
            </div>}
          </div>
        )}
      </ControlledCollapse>}
      {inputs.ownerId && <ControlledCollapse isOpen label={t("properties.subFolders.systemInformation")}>
        <div className="row">
          <div className="col-lg-6 col-md-12">
          </div>
          <div className="col-lg-6 col-md-12">
            {clone
              ? <VerticalInputPlainText
                icon={false}
                value={inputs.owner && <LinkToUserWithDate userName={inputs.owner} userId={inputs.ownerId} />}
                label={t('properties.owner')}
              />
              : <VerticalSelectSearch
                collection={PropertyCollections.items.user}
                value={inputs.ownerId}
                onChange={handleSelectSearch}
                label={t('projects.owner')}
                name="ownerId"
                isClearable={false}
              />
            }
          </div>
        </div>
      </ControlledCollapse>}
      {children}
    </form>
  );
};

export default PropertyForm;
