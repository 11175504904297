import { companyNewPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchCompanyCollections } from 'src/actions/Companies';
import { API_CALL } from 'src/middleware/API';

const CompanyNew = lazy(() => import('src/components/views/companies/New'));

export default {
  path: companyNewPath(),
  exact: true,
  strict: true,
  component: CompanyNew,
  perform: 'companies:new',
  title: 'New Company',
  protected: true,
  prepareData: ({ store }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    if (Object.keys(state.CompanyCollections.items).length > 0) return;
    const accessToken = state.Auth.token;
    store.dispatch(API_CALL, fetchCompanyCollections({ accessToken }));
  }
};
