import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const VerticalSelect = ({
  labelSize = "col-2", inputSize = "col-9", inputClassNames = "",
  label, required = false, collection = [], name, onChange, errors, value, includeBlank = true
}) => {
  const { t } = useTranslation();
  const errorMessage = errors ? errors.join(', ') : t('form.provideValid', { label });
  return (
    <div className="form-row mb-1">
      <label className={`${labelSize} col-form-label form-label`}>{label}</label>
      <div className={`${inputSize}`}>
        <select
          name={name}
          className={`form-control custom-select ${inputClassNames}`}
          onChange={onChange}
          required={required}
          value={value === null ? '' : value}
        >
          {includeBlank && <option value=""></option>}
          {collection.length > 0 && collection.map((item, index) =>
            <option key={`${name}-${item[1]}-${index}`} value={item[1]}>{item[0]}</option>
          )}
        </select>
        <div className='invalid-feedback'>{errorMessage}</div>
      </div>
    </div>
  )
}

VerticalSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  labelSize: PropTypes.string,
  inputSize: PropTypes.string,
  inputClassNames: PropTypes.string
};

export default VerticalSelect;
