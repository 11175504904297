import React from 'react';
import { useTranslation } from 'react-i18next';
import ControlledCollapse from 'src/components/elements/shared/ControlledCollapse';
import renderFields from 'src/helpers/renderFields';
import { hasArray } from 'src/helpers/array';
import { map } from 'lodash';

export const RenderDynamicFieldInputs = ({ rows = [], inputs = {}, onChanges, validations = {}, titleKey = undefined }) => {
  const { t } = useTranslation();

  return (
    <>
      {hasArray(rows) && (
        <ControlledCollapse isOpen label={t(titleKey ?? 'additionally')}>
          {map(rows, (row, rowIndex) => (
            <div className="row mb-5" key={`row-${rowIndex}`}>
              {row.leftSide && <div className="col-lg-6 col-md-12">
                {renderFields({ inputs, fields: row.leftSide, onChanges, validations })}
              </div>}
              {row.rightSide && <div className="col-lg-6 col-md-12">
                {renderFields({ inputs, fields: row.rightSide, onChanges, validations })}
              </div>}
            </div>
          ))}
        </ControlledCollapse>
      )}
    </>
  );
};

export default RenderDynamicFieldInputs;
