import { offerEditPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchOfferCollections, fetchOffer } from 'src/actions/Offers';
import { API_CALL } from 'src/middleware/API';

const OfferEdit = lazy(() => import('src/components/views/offers/Edit'));

export default {
  path: offerEditPath(),
  exact: true,
  strict: true,
  component: OfferEdit,
  perform: 'offers:update',
  title: 'Edit Offer',
  protected: true,
  prepareData: ({ store, params }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    store.dispatch(API_CALL, fetchOfferCollections({ accessToken }));
    const query = { noTypeCast: true }
    store.dispatch(API_CALL, fetchOffer({ accessToken, id: params.id, query }));
  }
};
