import * as types from 'src/constants/actionTypes/LeadsActionTypes';
import { SUBMIT_RECENTLY_VIEWED } from 'src/constants/actionTypes/RecentlyViewedActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  lead: {},
  loaded: false
};

export default store => {
  store.on('@init', () => ({ Lead: initialState }));

  store.on(types.FETCH_LEAD_REQUEST, ({ Lead }) =>
    ({ Lead: { ...initialState, isFetching: true }}));
  store.on(types.FETCH_LEAD_SUCCESS, ({ Lead }, { response }) => {
    store.dispatch(SUBMIT_RECENTLY_VIEWED, {
      data: { id: response.data.id, name: response.data.name, time: Date.now()},
      endpoint: 'leads'
    });
    return ({ Lead: { ...Lead, lead: response.data, loaded: true }})
  });
  store.on(types.FETCH_LEAD_ERROR, ({ Lead }) =>
    ({ Lead: {...Lead, error: true }}));
};
