import { projectsPath } from 'src/helpers/routes';
import { lazy } from 'react';
import fetchDataTable from 'src/actions/DataTable';
import { API_CALL } from 'src/middleware/API';
import dataTableParams, { defaultFilter } from 'src/datatables/projects';

const Projects = lazy(() => import('src/components/views/projects'));

export default {
  path: projectsPath(),
  exact: true,
  strict: true,
  component: Projects,
  perform: "projects:index",
  protected: true,
  prepareData: ({ store }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    const { role } = state.Auth.user;
    const { items = [] } = state.RecentlyViewed;
    const endpoint = "projects";
    const ids = (items[endpoint] || []).map(i => i.id) || [];
    const { query } = dataTableParams({ role, endpoint, ids });
    query.filter = Object.values(defaultFilter({ role, ids }));
    store.dispatch(API_CALL, fetchDataTable({ accessToken, query, endpoint }));
  }
};
