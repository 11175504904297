import React, { ChangeEvent } from 'react';
import { DropdownSource } from 'src/-utils/dropdown';
import { kebabCase } from 'lodash';
import { DatatableFilterOnChangeFunc, FieldFilters } from 'src/-utils/datatable';

export interface FilterByDropdownSourceProps {
  label: string;
  dataField: string;
  source: DropdownSource;
  onChange: DatatableFilterOnChangeFunc;
}

export const FilterByDropdownSource: React.FunctionComponent<FilterByDropdownSourceProps> = ({ onChange, label, dataField, source }) => {
  const keyPrefix = kebabCase(label);
  const onSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();

    const { target } = event;
    const { value } = target.options[target.selectedIndex];
    const currFilters: FieldFilters = { [dataField]: target.selectedIndex > 0 ? { name: dataField, value, type: 'eq' } : undefined };

    onChange({ currFilters });
  };

  return (
    <div className="ml-2">
      <select className="form-control form-control-sm min-width-100" onChange={onSelectChange}>
        <option value="">{label}</option>
        {source.map((view, index) => <option key={`${keyPrefix}-${index}`} value={view.value}>{view.label}</option>)}
      </select>
    </div>
  )
}

export default FilterByDropdownSource;
