import dayjs from 'dayjs';

const startStr = new Date(dayjs().startOf('week').format('YYYY-MM-DD')).toISOString();
const endStr = new Date(dayjs().endOf('week').format('YYYY-MM-DD')).toISOString();

const startOfMonth = dayjs().startOf('month').startOf('week');
const startStrMonth = new Date(startOfMonth.format('YYYY-MM-DD')).toISOString();
const endOfMonth = dayjs().endOf('month').endOf('week');
const endStrMonth = new Date(endOfMonth.format('YYYY-MM-DD')).toISOString();

export { startStr, endStr, startStrMonth, endStrMonth };
