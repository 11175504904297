import filterByRole, { optionsByRole } from './filterByRole';
import filterByView from './filterByView';
import { cloneDeep } from 'lodash';
import filterByDateRange from 'src/datatables/filterByDateRange';
import { DropdownSource } from 'src/-utils/dropdown';
import { FieldFilters, DatatableView, OutsideFilterOptions, enumFormatterFactory, dateFormatter, OutsideFilters, createIdColumn, createRelationshipColumn, createAttributeColumn } from 'src/-utils/datatable';
import FilterByDropdownSource from 'src/datatables/FilterByDropdownSource';

const ENDPOINT = 'snags';

const projectColumnConfig = () => ({ data: 'project', classes: 'table-link project-link', path: 'projectPath', pathKey: 'projectId' });
const propertyColumnConfig = () => ({ data: 'property', classes: 'table-link property-link', path: 'propertyPath', pathKey: 'propertyId' });

const defaultView: (mustShowProject: boolean) => DatatableView = (mustShowProject) => [
  createIdColumn({ classes: 'table-link show-link', path: 'snagPath', pathKey: 'id' }),
  createRelationshipColumn(mustShowProject ? projectColumnConfig() : propertyColumnConfig()),
  createAttributeColumn({ data: 'title' }),
  createAttributeColumn({ data: 'location', formatter: enumFormatterFactory('snags.enums.location') }),
  createAttributeColumn({ data: 'severity', formatter: enumFormatterFactory('snags.enums.severity') }),
  createAttributeColumn({ data: 'completedAt', formatter: dateFormatter }),
  createAttributeColumn({ data: 'status', formatter: enumFormatterFactory('snags.enums.status') }),
  createAttributeColumn({ data: 'propertyId', hidden: true }),
  createAttributeColumn({ data: 'projectId', hidden: true })
];

const detailsOverviewView: (mustShowProject: boolean) => DatatableView = (mustShowProject) => [
  createIdColumn({ classes: 'table-link show-link', path: 'snagPath', pathKey: 'id' }),
  createRelationshipColumn(mustShowProject ? projectColumnConfig() : propertyColumnConfig()),
  createAttributeColumn({ data: 'title' }),
  createAttributeColumn({ data: 'description' }),
  createAttributeColumn({ data: 'category', formatter: enumFormatterFactory('snags.enums.category') }),
  createAttributeColumn({ data: 'location', formatter: enumFormatterFactory('snags.enums.location') }),
  createAttributeColumn({ data: 'snagType', formatter: enumFormatterFactory('snags.enums.snagType') }),
  createAttributeColumn({ data: 'severity', formatter: enumFormatterFactory('snags.enums.severity') }),
  createAttributeColumn({ data: 'status', formatter: enumFormatterFactory('snags.enums.status') }),
  createAttributeColumn({ data: 'propertyId', hidden: true }),
  createAttributeColumn({ data: 'projectId', hidden: true })
];

const completionStatusView: (mustShowProject: boolean) => DatatableView = (mustShowProject) => [
  createIdColumn({ classes: 'table-link show-link', path: 'snagPath', pathKey: 'id' }),
  createRelationshipColumn(mustShowProject ? projectColumnConfig() : propertyColumnConfig()),
  createAttributeColumn({ data: 'title' }),
  createAttributeColumn({ data: 'location', formatter: enumFormatterFactory('snags.enums.location') }),
  createAttributeColumn({ data: 'status', formatter: enumFormatterFactory('snags.enums.status') }),
  createAttributeColumn({ data: 'estimatedCompletedAt', formatter: dateFormatter }),
  createAttributeColumn({ data: 'completedAt', formatter: dateFormatter }),
  createAttributeColumn({ data: 'hasPurchaserSignedOff' }),
  createAttributeColumn({ data: 'propertyId', hidden: true }),
  createAttributeColumn({ data: 'projectId', hidden: true })
];

const severeSnagsView: (mustShowProject: boolean) => DatatableView = (mustShowProject) => [
  createIdColumn({ classes: 'table-link show-link', path: 'snagPath', pathKey: 'id' }),
  createRelationshipColumn(mustShowProject ? projectColumnConfig() : propertyColumnConfig()),
  createAttributeColumn({ data: 'title' }),
  createAttributeColumn({ data: 'category', formatter: enumFormatterFactory('snags.enums.category') }),
  createAttributeColumn({ data: 'location', formatter: enumFormatterFactory('snags.enums.location') }),
  createAttributeColumn({ data: 'snagType', formatter: enumFormatterFactory('snags.enums.snagType') }),
  createAttributeColumn({ data: 'severity', formatter: enumFormatterFactory('snags.enums.severity') }),
  createAttributeColumn({ data: 'estimatedCompletedAt', formatter: dateFormatter }),
  createAttributeColumn({ data: 'status', formatter: enumFormatterFactory('snags.enums.status') }),
  createAttributeColumn({ data: 'propertyId', hidden: true }),
  createAttributeColumn({ data: 'projectId', hidden: true })
];

const fieldsByView = (view: string, mustShowProject = false) => {
  const fields: { [key: string]: DatatableView } = {
    'recentlyViewed': defaultView(mustShowProject),
    'snags': defaultView(mustShowProject),
    'detailOverview': detailsOverviewView(mustShowProject),
    'completionStatus': completionStatusView(mustShowProject),
    'severeSnags': severeSnagsView(mustShowProject)
  };

  return fields[view];
};

const outsideFilterTextFormatter = (key: string, t: any) => t(`snags.datatable.views.${key}`);

export const optionsForFilterByView = (): OutsideFilterOptions => ({
  'snags': { textFormatter: outsideFilterTextFormatter },
  'detailOverview': { textFormatter: outsideFilterTextFormatter },
  'completionStatus': { textFormatter: outsideFilterTextFormatter },
  'severeSnags': { textFormatter: outsideFilterTextFormatter }
});

export const defaultFilter = ({ role, ids }: { role: string, ids: number[] }, filter?: FieldFilters | null) => {
  filter = cloneDeep(filter ?? {});

  const byRole = optionsByRole({ role, endpoint: ENDPOINT });

  if (byRole.length > 0) {
    filter.role = { value: byRole[0], type: 'role' };
  }

  if (ids.length > 0) {
    filter.view = { name: 'id', type: 'eq', value: ids };
  }

  return filter;
};

export const columnFilters = (mustShowProject: boolean, projectDropdownSource: DropdownSource, statusDropdownSource: DropdownSource) => {
  const projectFilter = { filter: FilterByDropdownSource, dataField: 'project', label: (t: any) => t('snags.relationships.project.label'), source: projectDropdownSource };
  const propertyProjectFilter = { filter: FilterByDropdownSource, dataField: 'propertyProjectId', label: (t: any) => t('snags.relationships.project.label'), source: projectDropdownSource };
  const filters: any[] = [
    mustShowProject ? projectFilter : propertyProjectFilter,
    { filter: FilterByDropdownSource, dataField: 'status', label: (t: any) => t('snags.attributes.status.label'), source: statusDropdownSource },
    { filter: filterByDateRange, dataField: 'createdAt' }
  ];

  return filters;
};

export const outsideFilters = (): OutsideFilters => ([
  { component: filterByView, endpoint: ENDPOINT, options: optionsForFilterByView() },
  { component: filterByRole, endpoint: ENDPOINT }
]);

export function snagDataTableParams(mustShowProject: boolean) {
  return ({ view = 'recentlyViewed' }: { view?: string }) => {
    const columns = fieldsByView(view, mustShowProject);
    const query = {
      columns,
      order: [{ column: 1, dir: 'asc' }],
      start: 0,
      length: 10,
      endpoint: ENDPOINT
    };

    return { query, endpoint: ENDPOINT, columns };
  };
}

export default snagDataTableParams;
