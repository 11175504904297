import React from 'react';
import { map } from 'lodash';

export interface LegendEntryData {
  name: string;
  colour: string;
}

const LegendEntry: React.FunctionComponent<LegendEntryData> = ({ name, colour }: LegendEntryData) => {
  return (
    <div className="d-flex align-items-center mb-2">
      <div className="mr-2" style={{ height: '20px', width: '20px', backgroundColor: colour }} />
      <span>{name}</span>
    </div>
  );
};

export interface ReportLegendProps {
  title: string;
  data: LegendEntryData[];
}

export const ReportLegend: React.FunctionComponent<ReportLegendProps> = ({ title, data }: ReportLegendProps) => {
  return (
    <div className="report-legend">
      <div className="mt-3 px-7 pb-7 pt-0 border">
        <h5 className="text-center my-4 text-uppercase text-dark-blue font-weight-bold">{title}</h5>
        <div className="d-flex justify-content-around my-2">
          {map(data, (({ name, colour }, i) => <LegendEntry key={i} name={name} colour={colour} />))}
        </div>
      </div>
    </div>
  );
};
