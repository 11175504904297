import { contactEditPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchContactCollections, fetchContact } from 'src/actions/Contacts';
import { API_CALL } from 'src/middleware/API';

const ContactEdit = lazy(() => import('src/components/views/contacts/Edit'));

export default {
  path: contactEditPath(),
  exact: true,
  strict: true,
  component: ContactEdit,
  perform: 'contacts:update',
  title: 'Edit Contact',
  protected: true,
  prepareData: ({ store, params }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    // if (Object.keys(state.ContactCollections.items).length ===  0) {
    //   store.dispatch(API_CALL, fetchContactCollections({ accessToken }));
    // }
    store.dispatch(API_CALL, fetchContactCollections({ accessToken }));
    const query = { noTypeCast: true }
    store.dispatch(API_CALL, fetchContact({ accessToken, id: params.id, query }));
  }
};
