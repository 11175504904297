import React from 'react';
import useStoreon from 'src/helpers/storeon';
import { useTranslation } from 'react-i18next';
import { isFunction } from 'lodash';

const today = new Date();
const lastMonthTime = new Date().setDate(today.getDate() - 30);

export const FilterByView = ({ onChange, endpoint, options }) => {
  const { RecentlyViewed: { items } } = useStoreon("RecentlyViewed");
  const { t } = useTranslation();
  let recentlyViewed = {};

  if (items[endpoint]) {
    const lastMonth = items[endpoint].filter(i => i.time >= lastMonthTime);
    recentlyViewed = { recentlyViewed: { name: "id", type: "eq", value: lastMonth.map(i => i.id) } };
  }

  const currOptions = Object.assign(recentlyViewed, options);
  const optionsForSelect = Object.keys(currOptions);

  const handlerOnChange = event => {
    event.preventDefault();
    const { target } = event;
    const { value } = target.options[target.selectedIndex];
    onChange({ currFilters: { view: currOptions[value] }, view: value });
  };

  return (
    <div className="form-group mr-4">
      <label className="mr-2 form-label text-dark-blue">{t("datatable.view")}</label>
      <select className="form-control min-width-100" onChange={handlerOnChange} defaultValue={optionsForSelect[0]}>
        {optionsForSelect.map((view, index) =>
          <option key={`wkshjwhg-${index}`} value={view}>{isFunction(currOptions[view]?.textFormatter) ? currOptions[view].textFormatter(view, t) : t(`dataTable.views.${view}`)}</option>
        )}
      </select>
    </div>
  );
};

export default FilterByView;
