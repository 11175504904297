import { FETCH_CONTACT, FETCH_CONTACT_SUCCESS, FETCH_CONTACT_ERROR, FETCH_ALL_CONTACTS, FETCH_ALL_CONTACTS_SUCCESS, FETCH_ALL_CONTACTS_ERROR, UPDATE_CONTACT_RECORD } from 'src/constants/actionTypes/entities/contact';
import { onEntityStateInit, onFetch, onFetchSuccess, onFetchError, onFetchAll, onFetchAllSuccess, onFetchAllError, onUpdateRecord } from 'src/helpers/entity-store';

export default store => {
  store.on('@init', (state) => onEntityStateInit(state, 'contact'));

  store.on(FETCH_CONTACT, (state, { entityId }) => onFetch(state, 'contact', entityId));
  store.on(FETCH_CONTACT_SUCCESS, (state, { response }) => onFetchSuccess(state, 'contact', response));
  store.on(FETCH_CONTACT_ERROR, (state, { entityId, error }) => onFetchError(state, 'contact', entityId, error));

  store.on(FETCH_ALL_CONTACTS, (state) => onFetchAll(state, 'contact'));
  store.on(FETCH_ALL_CONTACTS_SUCCESS, (state, { response }) => onFetchAllSuccess(state, 'contact', response));
  store.on(FETCH_ALL_CONTACTS_ERROR, (state, { error }) => onFetchAllError(state, 'contact', error));

  store.on(UPDATE_CONTACT_RECORD, (state, { id, contact }) => onUpdateRecord(state, 'contact', id, contact));
};
