import React from 'react';
import { useTranslation } from 'react-i18next';
import useStoreon from 'src/helpers/storeon';
import Form from 'src/components/views/activity/Form';
import { createActivity, fetchActivityRelated } from 'src/actions/Activities';
// import { activitiesPath } from 'src/helpers/routes';
import { API_CALL } from 'src/middleware/API';
import defaultValues from 'src/components/views/activity/defaultFormValues';
// import { rootPath } from 'src/helpers/routes';

export default ({ successCallback, history }) => {
  const { t } = useTranslation();
  const {
    SubmitActivity, Activity, dispatch, Auth
  } = useStoreon('SubmitActivity', 'Activity', 'Auth');

  const serverSideValidation = new Promise(function(resolve, reject) {
    const { errors, error, success, sendRequest } = SubmitActivity;
    if (!sendRequest && success) resolve();
    if (!sendRequest && error) reject(errors);
  });

  const dynamicCallBack = ({ name, value }) => {
    const accessToken = Auth.token;
    dispatch(API_CALL, fetchActivityRelated({ accessToken, id: value }));
  };

  const { item = {} } = Activity;

  return (
    <Form
      defaultValues={Object.assign(defaultValues, { activitybleId: '', activitybleType: '' })}
      serverSideValidation={serverSideValidation}
      submitHandler={createActivity}
      successCallback={successCallback}
      history={history}
      relateCollection={item.relateCollection}
      dynamicCallBack={dynamicCallBack}
      fullForm
    >
      <div className="row pt-3">
        <div className="col">
          <div className="float-right">
            <button disabled={SubmitActivity.sendRequest} className="btn btn-primary float-right">
              {t('buttons.add')}
            </button>
          </div>
        </div>
      </div>
    </Form>
  );
};
