import { keys, isArray } from 'lodash';
import { hasString } from 'src/helpers/string';

const setServerSideErrors = ({ formRef, errors, setValidations }) => {
  const form = formRef.current;

  Object.assign(errors, { ...errors.listingPropertyAttributes, listingPropertyAttributes: {} });
  setValidations(errors);

  if (!form) {
    return;
  }

  const setError = (key, errorValue) => {
    const errorStr = errorValue.join(', ');
    const el = (Array.from(form.elements)).find(item =>
      item.name === key ||
      item.name === `specific.${key}` ||
      item.name === `listingPropertyAttributes.${key}` ||
      item.name === `${key}Id`
    );

    if (el) {
      el.setCustomValidity(errorStr);

      const nextSubling = el.nextSibling;

      if (nextSubling && nextSubling.classList.contains('basic-single')) {
        nextSubling.children[0].classList.add('invalid-element');
      }
    } else if (key.indexOf('.') > -1) {
      const currKey = key.substring(key.indexOf('.') + 1, key.length);

      if (form.elements[currKey] && !form.elements[key]) {
        form.elements[currKey].setCustomValidity(errorStr);
      }
    }
  };
  const setErrors = (errors, path = undefined) => {
    for (const key of keys(errors)) {
      const keyPath = hasString(path) ? `${path}.${key}` : key;
      const errorValue = errors[key];

      if (isArray(errorValue)) {
        setError(keyPath, errorValue);
      } else {
        setErrors(errorValue, keyPath);
      }
    }
  };

  setErrors(errors);
};

export default setServerSideErrors;
