import { projectPropertyNewPath } from 'src/helpers/routes';
import propertyNew from 'src/routes/properties/New';
import { fetchPropertyCollections } from 'src/actions/Properties';
import { API_CALL } from 'src/middleware/API';

const prepareData = ({ store, params }) => {
  const state = store.get();
  if (state.Auth.connected === false) return;
  if (Object.keys(state.PropertyCollections.items).length > 0) return;
  const accessToken = state.Auth.token;
  store.dispatch(API_CALL, fetchPropertyCollections({ accessToken }));
}

export default Object.assign({}, propertyNew, { path: projectPropertyNewPath(), prepareData });
