import { snagsPath } from 'src/helpers/routes';
import { lazy } from 'react';
import fetchDataTable from 'src/actions/DataTable';
import { API_CALL } from 'src/middleware/API';
import dataTableParams, { defaultFilter } from 'src/datatables/snags';
import { Route } from 'src/-types/routing';
import { map, toLower, trim } from 'lodash';
import { fetchAllProjects } from 'src/actions/entities/project';
import { fetchSnagEnums } from 'src/actions/enums/snag';

const snagsRoute: Route = {
  path: snagsPath(),
  exact: true,
  strict: true,
  component: lazy(() => import('src/components/views/snags/SnagsList')),
  perform: 'snags:index',
  protected: true,
  prepareData: ({ store, query }) => {
    const state = store.get();
    const { Auth: { connected, user: { role } } } = state;

    if (connected === false) {
      return;
    }

    const endpoint = 'snags';
    const ids = map(state.RecentlyViewed?.items?.[endpoint], ({ id }) => id);
    const mustShowProject = toLower(trim(query?.mustShowProject ?? 'false')) === 'true';
    const dtParams = dataTableParams(mustShowProject)({});
    const filter = [...Object.values(defaultFilter({ role, ids })), { name: mustShowProject ? 'property_id' : 'project_id', value: null, type: 'eq' }];
    const dtQueryParams = { ...dtParams, filter };

    store.dispatch(API_CALL, fetchDataTable({ query: dtQueryParams, endpoint, apiVersion: 'v2' }));
    store.dispatch(API_CALL, fetchAllProjects());
    store.dispatch(API_CALL, fetchSnagEnums());
  }
};

export default snagsRoute;
