import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const HorizontalCheckbox = ({
  labelSize = "col-2", inputSize = "col-9", inputClassNames = "",
  label, labelMessage = '', required = false, name, onChange, errors, value
}) => {
  const { t } = useTranslation();
  const errorMessage = errors ? errors.join(', ') : t('form.provideValid', { label });
  return (
    <div className="form-row mb-1">
      <label className={`${labelSize} col-form-label form-label`}>{label}</label>
      <div className={`${inputSize} custom-controls-stacked`}>
        <label className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className={`custom-control-input ${inputClassNames}`}
            name={name}
            placeholder={label}
            required={required}
            onChange={onChange}
            checked={value === null ? false : value}
          />
          <span className="custom-control-label"></span>
        </label>
        <div className='invalid-feedback'>{errorMessage}</div>
      </div>
    </div>
  )
}

HorizontalCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default HorizontalCheckbox;
