import { checkPerform } from 'src/helpers/authorization';
import {
  rootPath, companiesPath, contactsPath, leadsPath, activitiesPath, projectsPath,
  propertiesPath, listingsPath, offersPath, transactionsPath, internalBrokersPath, paymentsPath,
  activitiesNewPath, activitiesMonthPath, companyNewPath, contactNewPath, leadNewPath,
  projectNewPath, propertyNewPath, listingNewPath, offerNewPath, reportsPath, performancesPath,
  snagNewPath, propertySnagsPath, projectSnagsPath
} from 'src/helpers/routes';
import { brokerRoles } from 'src/rules'
import { store } from 'src/store/index';

interface MenuItem {
  path: () => string;
  className?: string;
  label: string;
  divider?: boolean;
  target?: '_blank' | '_self';
  icon?: string;
  children?: MenuItem[];
}

export function getMenuItems(): MenuItem[] {
  const isTabletOrMobile = false;
  const { Auth: { user: { agency = false, project = false, role } } } = store.get();
  const rootChildren: MenuItem[] = [];

  rootChildren.push({ path: rootPath, className: '', label: 'myDashboard' });
  rootChildren.push({ path: reportsPath, className: '', label: 'reports' });

  if (role !== 'accountant' && role !== 'broker') {
    rootChildren.push({ path: performancesPath, className: '', label: 'performance' });
  }

  const activityChildren: MenuItem[] = [];

  if (checkPerform({ role, perform: 'activity:new' })) {
    activityChildren.push({ path: activitiesNewPath, className: 'new-record', label: 'addNewActivity', divider: true, target: '_blank' });
  }

  activityChildren.push({ path: activitiesMonthPath, className: '', label: 'calendar', target: '_self' });

  const companyChildren: MenuItem[] = [];

  if (checkPerform({ role, perform: 'companies:new' })) {
    companyChildren.push({ path: companyNewPath, className: 'new-record', label: 'addNewCompany', divider: true, target: '_blank' });
  }

  if (!isTabletOrMobile) {
    companyChildren.push({ path: companiesPath, className: '', label: 'companiesList', target: '_self' });
  }

  const contactChildren: MenuItem[] = [];

  if (checkPerform({ role, perform: 'contacts:new' })) {
    contactChildren.push({ path: contactNewPath, className: 'new-record', label: 'addNewContact', divider: true, target: '_blank' });
  }

  if (!isTabletOrMobile) {
    contactChildren.push({ path: contactsPath, className: '', label: 'contactsList', target: '_self' });
  }

  const leadChildren: MenuItem[] = [];

  if (checkPerform({ role, perform: 'leads:new' })) {
    leadChildren.push({ path: leadNewPath, className: 'new-record', label: 'addNewLead', divider: true, target: '_blank' });
  }

  if (!isTabletOrMobile) {
    leadChildren.push({ path: leadsPath, className: '', label: 'leadsList', target: '_self' });
  }

  const paths: MenuItem[] = [
    { path: rootPath, icon: 'icon-pn-Dashboard', label: 'dashboard', children: rootChildren, target: '_self' }
  ];

  if (checkPerform({ role, perform: 'activity:index' })) {
    paths.push({ path: activitiesPath, icon: 'icon-pn-Activity', label: 'activity', children: activityChildren, target: '_self' });
  }

  if (checkPerform({ role, perform: 'companies:index' })) {
    paths.push({ path: companiesPath, icon: 'icon-pn-Company', label: 'company', children: companyChildren, target: '_self' });
  }

  if (checkPerform({ role, perform: 'contacts:index' })) {
    paths.push({ path: contactsPath, icon: 'icon-pn-Contact', label: 'contact', children: contactChildren, target: '_self' });
  }

  if (checkPerform({ role, perform: 'leads:index' })) {
    paths.push({ path: leadsPath, icon: 'icon-pn-Lead', label: 'lead', children: leadChildren, target: '_self' });
  }

  if ((agency && project) || !agency) {
    const projectChildren: MenuItem[] = [];

    if (checkPerform({ role, perform: 'projects:new' })) {
      projectChildren.push({ path: projectNewPath, className: 'new-record', label: 'addNewProject', divider: true, target: '_blank' });
    }

    if (checkPerform({ role, perform: 'projects:index' })) {
      if (!isTabletOrMobile) {
        projectChildren.push({ path: projectsPath, className: '', label: 'projectsList', target: '_self' });
      }

      paths.push({ path: projectsPath, icon: 'icon-pn-Project', label: 'project', children: projectChildren, target: '_self' });
    }
  }

  if (!agency) {
    const propertyChildren: MenuItem[] = [];

    if (checkPerform({ role, perform: 'properties:new' })) {
      propertyChildren.push({ path: propertyNewPath, className: 'new-record', label: 'addNewProperty', divider: true, target: '_blank' });
    }

    if (!isTabletOrMobile) {
      propertyChildren.push({ path: propertiesPath, className: '', label: 'propertiesList', target: '_self' });
    }

    if (checkPerform({ role, perform: 'properties:index' })) {
      paths.push({ path: propertiesPath, icon: 'icon-pn-Property', label: 'property', children: propertyChildren, target: '_self' });
    }

    if (checkPerform({ role, perform: 'snags:index' })) {
      const snagChildren: MenuItem[] = [];

      if (checkPerform({ role, perform: 'snags:new' })) {
        snagChildren.push({ path: snagNewPath, className: 'new-record', label: 'addNewSnag', target: '_blank' });
      }

      snagChildren.push({ path: projectSnagsPath, label: 'projectSnagsList', target: '_self' });
      snagChildren.push({ path: propertySnagsPath, label: 'propertySnagsList', target: '_self' });
      paths.push({ path: projectSnagsPath, icon: 'icon-pn-Snag', label: 'snagsList', children: snagChildren, target: '_self' })
    }
  }

  const listingChildren: MenuItem[] = [];

  if (agency === true && checkPerform({ role, perform: 'listings:new' })) {
    listingChildren.push({ path: listingNewPath, className: 'new-record', label: 'addNewListing', divider: true, target: '_blank' })
  }

  if (checkPerform({ role, perform: 'listings:index' }) && !isTabletOrMobile) {
    listingChildren.push({ path: listingsPath, className: '', label: 'listingsList', target: '_self' });
  }

  const offerChildren: MenuItem[] = [];

  if (checkPerform({ role, perform: 'offers:new' })) {
    offerChildren.push({ path: offerNewPath, className: 'new-record', label: 'addNewOffer', divider: true, target: '_blank' })
  }

  if (checkPerform({ role, perform: 'offers:index' }) && !isTabletOrMobile) {
    offerChildren.push({ path: offersPath, className: '', label: 'offersList', target: '_self' });
  }

  if (checkPerform({ role, perform: 'listings:index' })) {
    paths.push({ path: listingsPath, icon: 'icon-pn-Listing', label: 'listing', children: listingChildren, target: '_self' })
  }

  if (checkPerform({ role, perform: 'offers:index' })) {
    paths.push({ path: offersPath, icon: 'icon-pn-Offer', label: 'offer', children: offerChildren, target: '_self' });
  }

  if (!brokerRoles.includes(role)) {
    paths.push({ path: transactionsPath, icon: 'icon-pn-Transaction', label: 'transaction', target: '_self' });
    paths.push({ path: internalBrokersPath, icon: 'icon-pn-Internal-Broker', label: 'internalBroker', target: '_self' });
    paths.push({ path: paymentsPath, icon: 'icon-pn-Payment', label: 'payment', target: '_self' });
  }

  return paths;
}
