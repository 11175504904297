import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

const VerticalInputPlainText = ({ label, value, icon = true, handlerDblClick = noop, allowed = true, hidden = false }) => {
  const inputValue = useMemo(() => value === null ? '' : value, [value]);

  return (
    <div className="form-group" hidden={hidden}>
      <label className="form-label">{label}</label>
      <p className="plaintext-label" onDoubleClick={handlerDblClick}>
        {inputValue}
        {icon && allowed && <i className={`fe fe-edit-2 float-right`}></i>}
      </p>
    </div>
  );
};

VerticalInputPlainText.propTypes = {
  label: PropTypes.string.isRequired
};

export default VerticalInputPlainText;
