import routes from 'src/routes';
import prepareData from 'src/helpers/prepareData';
import { store } from 'src/store/index';
import { matchPath } from 'react-router';
import { pageView } from 'src/helpers/google-analytics';

const historyCb = (req) => {
  pageView(window.location);

  const state = {
    params: {},
    routes: [],
    query: {},
    location: window.location
  };

  const { body } = window.document;
  if (body.classList.contains("active")) body.classList.remove("active");

  const nested = routes.map(route => route.routes).filter(Boolean).flat();

  (routes.concat(nested)).some(route => {
    const match = matchPath(req.pathname, route);
    if (match) {
      state.routes.push(route);
      Object.assign(state.params, match.params);
      Object.assign(state.query, req.query);
    }
    return match;
  });
  prepareData({ store, state });
};

export default historyCb;
