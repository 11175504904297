import filterByDateRange from './filterByDateRange';
import filterByRole, { optionsByRole } from './filterByRole';
import filterByView from './filterByView';
import filterByList from './filterByList';
import { hasArray } from 'src/helpers/array';

const ENDPOINT = 'listings';

const DEFAULT_VIEW = [
  { data: 'id', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  { data: 'title', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link show-link', path: 'listingPath', pathKey: 'id' },
  { data: 'typeOf', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'propertyType', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'bedrooms', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'grossInternalArea', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'listedPrice', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'listingScore', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'status', searchable: true, orderable: false, search: { value: '', regex: '' } }
];

const DEVELOPER_LISTINGS_VIEW = [
  { data: 'id', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  { data: 'title', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link show-link', path: 'listingPath', pathKey: 'id' },
  { data: 'project', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link project-link', path: 'projectPath', pathKey: 'projectId' },
  { data: 'typeOf', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'propertyType', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'bedrooms', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'grossInternalArea', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'listedPrice', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'listingScore', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'status', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'projectId', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true }
];

const LISTING_PERFORMANCE_VIEW = [
  { data: 'id', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  { data: 'title', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link show-link', path: 'listingPath', pathKey: 'id' },
  { data: 'typeOf', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'status', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'listedPrice', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'listingScore', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'totalLeads', searchable: true, orderable: false, search: { value: '', regex: '' } }
];

const RENTAL_LISTINGS_VIEW = [
  { data: 'id', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  { data: 'title', searchable: true, orderable: false, search: { value: '', regex: '' }, classes: 'table-link show-link', path: 'listingPath', pathKey: 'id' },
  { data: 'propertyType', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'bedrooms', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'grossInternalArea', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'listedPrice', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'listingScore', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'status', searchable: true, orderable: false, search: { value: '', regex: '' } }
];

const fieldsByView = (view) => {
  const fields = {
    'recentlyViewed': DEFAULT_VIEW,
    'listings': DEFAULT_VIEW,
    'listingPerformance': LISTING_PERFORMANCE_VIEW,
    'saleListings': RENTAL_LISTINGS_VIEW,
    'rentalListings': RENTAL_LISTINGS_VIEW,
    'developerListings': DEVELOPER_LISTINGS_VIEW,
    'resaleListings': DEFAULT_VIEW.filter(({ data }) => data !== 'typeOf')
  };
  return fields[view];
};

export const optionsForFilterByView = {
  'listings': {},
  'listingPerformance': {},
  'saleListings': { name: 'listings.typeOf', value: 'sale', type: 'eq' },
  'rentalListings': { name: 'listings.typeOf', value: 'rent', type: 'eq' },
  'developerListings': { name: 'listings.agreementType', value: 'developer_sale', type: 'eq' },
  'resaleListings': [
    { name: 'listings.agreementType', value: 'developer_sale', type: 'not:eq' },
    { name: 'listings.typeOf', value: 'sale', type: 'eq' }
  ]
};

export const defaultFilter = ({ role, ids }) => {
  const filter = {};
  const byRole = optionsByRole({ role, endpoint: ENDPOINT });

  if (hasArray(byRole)) {
    filter.role = { value: byRole[0], type: 'role' };
  }

  if (hasArray(ids)) {
    filter.view = { name: 'id', type: 'eq', value: ids };
  }

  return filter;
};

export const columnFilters = [
  { filter: filterByList, dataField: 'status', label: 'Status', source: 'ListingCollections.items.status' },
  { filter: filterByDateRange, dataField: 'createdAt' }
];

export const outsideFilters = [
  { component: filterByView, endpoint: ENDPOINT, options: optionsForFilterByView },
  { component: filterByRole, endpoint: ENDPOINT }
];

export default ({ view = 'listings', items = [] }) => {
  const columns = fieldsByView(view);
  const query = {
    columns,
    order: [{ column: 0, dir: 'asc' }],
    start: 0,
    length: 10,
    endpoint: ENDPOINT
  };
  return { query, endpoint: ENDPOINT, columns };
}
