import {
  FETCH_SNAG, FETCH_SNAG_SUCCESS, FETCH_SNAG_ERROR,
  FETCH_ALL_SNAGS, FETCH_ALL_SNAGS_SUCCESS, FETCH_ALL_SNAGS_ERROR,
  CREATE_SNAG_SUCCESS,
  UPDATE_SNAG, UPDATE_SNAG_SUCCESS, UPDATE_SNAG_ERROR, DELETE_SNAG_FILE_SUCCESS, RENAME_SNAG_FILE_SUCCESS, UPDATE_SNAG_RECORD, DELETE_SNAG_SUCCESS, BATCH_DELETE_SNAGS_SUCCESS, BATCH_UPDATE_SNAGS_SUCCESS
  // DELETE_SNAG, DELETE_SNAG_SUCCESS, DELETE_SNAG_ERROR
} from 'src/constants/actionTypes/entities/snag';
import { onEntityStateInit, onFetch, onFetchSuccess, onFetchError, onFetchAll, onFetchAllSuccess, onFetchAllError, onUpdate, onCreateSuccess, onUpdateSuccess, onUpdateError, onDeleteFileSuccess, onRenameFileSuccess, onUpdateRecord, onDeleteSuccess, onBatchDeleteSuccess, onBatchUpdateSuccess } from 'src/helpers/entity-store';
import { Module } from 'storeon';
import { Events, State } from 'src/store';
import { SnagRecord, SnagJsonApiEntity } from 'src/-types/models/snag';
import { SUBMIT_RECENTLY_VIEWED } from 'src/constants/actionTypes/RecentlyViewedActionTypes';
import { JsonApiResponseType } from 'src/-types/models/json-api';
import { SnagSerializer } from 'src/-lib/serializers/snag';

const snagSerializer = new SnagSerializer();

export const snagModule: Module<State, Events> = store => {
  const addToRecentlyViewed = (statePatch: Partial<State>, response: JsonApiResponseType<SnagJsonApiEntity>) => {
    const { id, __meta: { lastLoadedAt } } = statePatch.entities!.snag!.data[response.data.id];

    store.dispatch(SUBMIT_RECENTLY_VIEWED, { endpoint: 'snags', data: { id, time: lastLoadedAt } });

    return statePatch;
  };

  store.on('@init', (state) => onEntityStateInit(state, 'snag'));

  store.on(FETCH_SNAG, (state, { entityId }) => onFetch(state, 'snag', entityId));
  store.on(FETCH_SNAG_SUCCESS, (state, { response }) => {
    const normalizeResponse = snagSerializer.normalizeResponse(response);
    const result = addToRecentlyViewed(onFetchSuccess(state, 'snag', normalizeResponse), normalizeResponse);

    return result;
  });
  store.on(FETCH_SNAG_ERROR, (state, { entityId, error }) => onFetchError(state, 'snag', entityId, error));

  store.on(FETCH_ALL_SNAGS, (state) => onFetchAll(state, 'snag'));
  store.on(FETCH_ALL_SNAGS_SUCCESS, (state, { response }) => onFetchAllSuccess(state, 'snag', snagSerializer.normalizeCollectionResponse(response)));
  store.on(FETCH_ALL_SNAGS_ERROR, (state, { error }) => onFetchAllError(state, 'snag', error));

  store.on(CREATE_SNAG_SUCCESS, (state, { response }) => {
    const normalizeResponse = snagSerializer.normalizeResponse(response);
    const result = addToRecentlyViewed(onCreateSuccess(state, 'snag', normalizeResponse), normalizeResponse);

    return result;
  });

  store.on(UPDATE_SNAG, (state, { entityId }) => onUpdate(state, 'snag', entityId));
  store.on(UPDATE_SNAG_SUCCESS, (state, { response }) => {
    const normalizeResponse = snagSerializer.normalizeResponse(response);
    const result = addToRecentlyViewed(onUpdateSuccess(state, 'snag', normalizeResponse), normalizeResponse);

    return result;
  });
  store.on(UPDATE_SNAG_ERROR, (state, { entityId, response }) => onUpdateError<SnagRecord>(state, 'snag', entityId, response));

  store.on(BATCH_UPDATE_SNAGS_SUCCESS, (state, { response }) => onBatchUpdateSuccess(state, 'snag', snagSerializer.normalizeCollectionResponse(response)));

  store.on(RENAME_SNAG_FILE_SUCCESS, (state, { response }) => onRenameFileSuccess(state, 'snag', snagSerializer.normalizeResponse(response)));

  store.on(DELETE_SNAG_FILE_SUCCESS, (state, { response }) => onDeleteFileSuccess(state, 'snag', snagSerializer.normalizeResponse(response)));

  // store.on(DELETE_SNAG, (state, { entityId }) => onFetch(state, 'snag', entityId));
  store.on(DELETE_SNAG_SUCCESS, (state, { id }) => onDeleteSuccess(state, 'snag', id));
  // store.on(DELETE_SNAG_ERROR, (state, { entityId, error }) => onFetchError(state, 'snag', entityId, error));

  store.on(BATCH_DELETE_SNAGS_SUCCESS, (state, { ids }) => onBatchDeleteSuccess(state, 'snag', ids));

  store.on(UPDATE_SNAG_RECORD, (state, { id, snag }) => onUpdateRecord(state, 'snag', id, snag));
};

export default snagModule;
