import React from 'react';
import { isBoolean } from 'lodash';

import './LoadingSpinner.scss';

interface LoadingSpinnerProps {
  isLoading?: boolean;
  className?: string;
}

const LdsRoller = () => <div className="lds-roller"><div /><div /><div /><div /><div /><div /><div /><div /></div>;

export const LoadingSpinner: React.FunctionComponent<LoadingSpinnerProps> = ({ children, isLoading, className }) => (
  <>
    {isBoolean(isLoading) && isLoading
      ? <div className={`spinner-container ${className}`}><LdsRoller /></div>
      : children
    }
  </>
);
