import * as types from 'src/constants/actionTypes/CompaniesActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  loaded: false,
  items: {}
};

export default store => {
  store.on('@init', () => ({ CompanyCollections: initialState }));

  store.on(types.FETCH_COMPANY_COLLECTION_REQUEST, ({ CompanyCollections }) =>
    ({ CompanyCollections: { ...CompanyCollections, isFetching: true, loaded: false }}));
  store.on(types.FETCH_COMPANY_COLLECTION_SUCCESS, ({ CompanyCollections }, { response }) =>
    ({ CompanyCollections: { ...CompanyCollections, items: response.data, loaded: true }}));
  store.on(types.FETCH_COMPANY_COLLECTION_ERROR, ({ CompanyCollections }) =>
    ({ CompanyCollections: {...CompanyCollections, error: true }}));
};
