import { propertyPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchProperty, fetchPropertyCollections } from 'src/actions/Properties';
import { API_CALL } from 'src/middleware/API';
import { fetchActivityCollections } from 'src/actions/Activities';
import Layout from 'src/components/views/showLayout';
import { fetchPropertySnags } from 'src/actions/entities/property';

const PropertyShow = lazy(() => import('src/components/views/properties/Show'));

export default {
  path: propertyPath(),
  exact: true,
  strict: true,
  component: PropertyShow,
  perform: 'properties:show',
  layout: Layout,
  protected: true,
  prepareData: ({ store, query, params }) => {
    const state = store.get();

    if (state.Auth.connected === false) {
      return;
    }

    const accessToken = state.Auth.token;

    if (Object.keys(state.ActivityCollections.items).length === 0) {
      store.dispatch(API_CALL, fetchActivityCollections({ accessToken }));
    }

    if (Object.keys(state.PropertyCollections.items).length === 0) {
      store.dispatch(API_CALL, fetchPropertyCollections({ accessToken }));
    }

    store.dispatch(API_CALL, fetchProperty({ accessToken, id: params.id }));
    store.dispatch(API_CALL, fetchPropertySnags({ id: params.id }));
  }
};
