import * as types from 'src/constants/actionTypes/OffersActionTypes';
import { SUBMIT_RECENTLY_VIEWED } from 'src/constants/actionTypes/RecentlyViewedActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  offer: {}
};

export default store => {
  store.on('@init', () => ({ Offer: initialState }));

  store.on(types.FETCH_OFFER_REQUEST, ({ Offer }) =>
    ({ Offer: { ...initialState, isFetching: true }}));
  store.on(types.FETCH_OFFER_SUCCESS, ({ Offer }, { response }) => {
    store.dispatch(SUBMIT_RECENTLY_VIEWED, {
      data: { id: response.data.id, name: response.data.name, time: Date.now()},
      endpoint: 'offers'
    });
    return ({ Offer: { ...Offer, offer: response.data }})
  });
  store.on(types.FETCH_OFFER_ERROR, ({ Offer }) =>
    ({ Offer: {...Offer, error: true }}));
}
