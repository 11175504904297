import {
  FETCH_SNAG, FETCH_SNAG_SUCCESS, FETCH_SNAG_ERROR,
  FETCH_ALL_SNAGS, FETCH_ALL_SNAGS_SUCCESS, FETCH_ALL_SNAGS_ERROR,
  CREATE_SNAG, CREATE_SNAG_SUCCESS, CREATE_SNAG_ERROR,
  UPDATE_SNAG, UPDATE_SNAG_SUCCESS, UPDATE_SNAG_ERROR,
  DELETE_SNAG, DELETE_SNAG_SUCCESS, DELETE_SNAG_ERROR
} from 'src/constants/actionTypes/entities/snag';
import { FetchEntityFunc, CreateEntityFunc, UpdateEntityFunc, DeleteEntityFunc, ApiRequest, QueryParams } from 'src/-types/request';
import { SnagUpdateBody, SnagCreateBody } from 'src/-types/models/snag';

export const fetchSnag: FetchEntityFunc = ({ id, query }) => ({
  query,
  apiVersion: 'v2',
  endpoint: `snags/${id}`,
  entityId: id,
  method: 'GET',
  credentials: true,
  types: [
    FETCH_SNAG,
    FETCH_SNAG_SUCCESS,
    FETCH_SNAG_ERROR
  ]
});

export const fetchAllSnags = ({ query }: { query?: QueryParams } = {}): ApiRequest => ({
  query,
  apiVersion: 'v2',
  endpoint: `snags`,
  method: 'GET',
  credentials: true,
  types: [
    FETCH_ALL_SNAGS,
    FETCH_ALL_SNAGS_SUCCESS,
    FETCH_ALL_SNAGS_ERROR
  ]
});

export const createSnag: CreateEntityFunc<SnagCreateBody> = ({ body }) => ({
  apiVersion: 'v2',
  endpoint: `snags`,
  method: 'POST',
  body,
  credentials: true,
  types: [
    CREATE_SNAG,
    CREATE_SNAG_SUCCESS,
    CREATE_SNAG_ERROR
  ]
});

export const updateSnag: UpdateEntityFunc<SnagUpdateBody> = ({ id, body }) => ({
  entityId: id,
  apiVersion: 'v2',
  endpoint: `snags/${id}`,
  method: 'PATCH',
  body,
  credentials: true,
  types: [
    UPDATE_SNAG,
    UPDATE_SNAG_SUCCESS,
    UPDATE_SNAG_ERROR
  ]
});

export const deleteSnag: DeleteEntityFunc = ({ id }) => ({
  entityId: id,
  apiVersion: 'v2',
  endpoint: `snags/${id}`,
  method: 'DELETE',
  credentials: true,
  types: [
    DELETE_SNAG,
    DELETE_SNAG_SUCCESS,
    DELETE_SNAG_ERROR
  ]
});
