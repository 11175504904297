import React from 'react';
import filterByView from './filterByView';

const booleanFormatter = (cell, _row, _rowIndex, _t) => {
  return (
    <>
      {cell === 'true' ?
        <span className='badge badge-pill badge-success'>Active</span>
        :
        <span className='badge badge-pill badge-warning'>Deactive</span>
      }
    </>
  )
};

const endpoint = 'users';

const defaultView = [
  { data: 'id', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  {
    data: 'name', searchable: true, orderable: false, search: { value: '', regex: '' },
    classes: 'table-link show-link', path: 'userPath', pathKey: 'id'
  },
  { data: 'profile', searchable: true, orderable: false, search: { value: '', regex: '' } },
  {
    data: 'team', searchable: true, orderable: false, search: { value: '', regex: '' }, classes:
      'table-link team-link', path: 'teamPath', pathKey: 'teamId'
  },
  { data: 'reportsTo', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'active', searchable: true, orderable: false, search: { value: '', regex: '' }, formatter: booleanFormatter },
  { data: 'email', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'workMobile', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'startDate', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'teamId', hidden: true }
];

const adminOverviewView = [
  { data: 'id', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  {
    data: 'name', searchable: true, orderable: false, search: { value: '', regex: '' },
    classes: 'table-link show-link', path: 'userPath', pathKey: 'id'
  },
  { data: 'jobTitle', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'firstLanguage', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'nationality', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'birthdate', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'profile', searchable: true, orderable: false, search: { value: '', regex: '' } },
  {
    data: 'team', searchable: true, orderable: false, search: { value: '', regex: '' },
    classes: 'table-link team-link', path: 'teamPath', pathKey: 'teamId'
  },
  { data: 'reportsTo', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'active', searchable: true, orderable: false, search: { value: '', regex: '' }, formatter: booleanFormatter },
  { data: 'startDate', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'endDate', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'reasonForLeaving', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'teamId', hidden: true }
];

const contactDetailsView = [
  { data: 'id', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  {
    data: 'name', searchable: true, orderable: false, search: { value: '', regex: '' },
    classes: 'table-link show-link', path: 'userPath', pathKey: 'id'
  },
  { data: 'jobTitle', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'workEmail', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'mobile', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'homePhone', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'homeAddress', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'email', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'workMobile', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'workPhone', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'workFax', searchable: true, orderable: false, search: { value: '', regex: '' } }
];

const compensationOverviewView = [
  { data: 'id', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true },
  {
    data: 'name', searchable: true, orderable: false, search: { value: '', regex: '' },
    classes: 'table-link show-link', path: 'userPath', pathKey: 'id'
  },
  { data: 'jobTitle', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'profile', searchable: true, orderable: false, search: { value: '', regex: '' } },
  {
    data: 'team', searchable: true, orderable: false, search: { value: '', regex: '' },
    classes: 'table-link team-link', path: 'teamPath', pathKey: 'teamId'
  },
  { data: 'reportsTo', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'startDate', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'basicSalary', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'commissionSplit', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'commissionOverride', searchable: true, orderable: false, search: { value: '', regex: '' } },
  { data: 'teamId', searchable: true, orderable: false, search: { value: '', regex: '' }, hidden: true }
];

const fieldsByView = (view) => {
  const fields = {
    'recentlyViewed': defaultView,
    'users': defaultView,
    'activeUsers': defaultView,
    'adminOverview': adminOverviewView,
    'contactDetails': contactDetailsView,
    'compensationOverview': compensationOverviewView
  };
  return fields[view];
};

export const optionsForFilterByView = {
  'users': {},
  'activeUsers': { name: 'active', value: true, type: 'eq' },
  'adminOverview': { name: 'active', value: true, type: 'eq' },
  'contactDetails': { name: 'active', value: true, type: 'eq' },
  'compensationOverview': { name: 'active', value: true, type: 'eq' }
};

export const outsideFilters = [
  { component: filterByView, endpoint, options: optionsForFilterByView }
];

export const defaultFilter = ({ role, ids }) => {
  const filter = {};
  if (!!ids.length) filter.view = { name: 'id', type: 'eq', value: ids };
  return filter;
};

export default ({ view = 'users', items = [] }) => {
  const columns = fieldsByView(view);
  const query = {
    columns,
    order: [{ column: 0, dir: 'asc' }],
    start: 0,
    length: 10,
    endpoint
  };
  return { query, endpoint, columns };
};
