import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/fr';
dayjs.extend(localeData);
dayjs.extend(customParseFormat);
dayjs.locale('fr');

const weekdaysShort = 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_');
const weekdaysMin = 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_')

export function formatDay(day, locale = 'en') {
  return dayjs(day)
    .locale(locale)
    .format('ddd ll');
}

export function formatMonthTitle(date, locale = 'en') {
  return dayjs(date)
    .locale(locale)
    .format('MMMM YYYY');
}

export function formatWeekdayShort(day, locale = 'en') {
  const t = dayjs().locale(locale).$locale();
  return t.weekdaysMin ? t.weekdaysMin[day] : weekdaysMin[day];
}

export function formatWeekdayLong(day, locale = 'en') {
  const t = dayjs().locale(locale).$locale();
  return t.weekdaysShort ? t.weekdaysShort[day] : weekdaysShort[day];
}

export function getFirstDayOfWeek(locale = 'en') {
  const localeData = dayjs().localeData(locale);
  return localeData.firstDayOfWeek();
}

export function getMonths(locale = 'en') {
  const months = [];
  let i = 0;
  while (i < 12) {
    months.push(
      dayjs()
        .locale(locale)
        .month(i)
        .format('MMMM')
    );
    i += 1;
  }
  return months;
}

export function formatDate(date, format = 'L', locale = 'en') {
  return dayjs(date)
    .locale(locale)
    .format(Array.isArray(format) ? format[0] : format);
}

export function parseDate(str, format = 'L', locale = 'en') {
  const m = dayjs(str, format, locale, true);
  if (m.isValid()) {
    return m.toDate();
  }
  return undefined;
}

export default {
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong,
  getFirstDayOfWeek,
  getMonths,
  formatDate,
  parseDate,
};
