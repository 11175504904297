import React, { useCallback, useEffect, useRef } from 'react';

import DropDownUserMenu from 'src/components/elements/navbar/DropDownUserMenu';
import FullScreenButton from 'src/components/elements/navbar/FullScreenButton';

import logo from 'src/constants/images/logo.jpg';
import logoMobile from 'src/constants/images/logo-mobile.jpg';

const DropdownMobile = React.forwardRef((props, ref) =>
  <>
    <a  {...props} ref={ref}><i className="si si-menu"></i></a>
  </>
);

export default () => {
  const mobile = useRef(null);
  const { body } = window.document;
  const handleMobile = useCallback(() => {
    if (body.classList.contains("active")) {
      body.classList.remove("active");
    } else {
      body.classList.add("active");
    }
  },[body]);

  useEffect(() => {
    if (mobile) {
      const el = mobile.current;
      el.addEventListener('click', handleMobile);
      return () => {
        el.removeEventListener('click', handleMobile);
      };
    }
  },[handleMobile, mobile]);

  return (
    <div className="header top-header">
			<div className="container">
				<div className="d-flex">
          <a
            href="https://www.propertynexus.co/contact-us"
            className="header-brand mr-auto"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={logo} className="header-brand-img desktop-lgo" alt="Nexus Property logo" />
            <img src={logoMobile} className="header-brand-img mobile-logo" alt="Nexus Property logo" />
					</a>
					<div className="dropdown header-fullscreen">
            <FullScreenButton
              className="nav-link icon full-screen-link"
              title={`Full Screen`}
            >
							<i className="fe fe-minimize"></i>
						</FullScreenButton>
					</div>
          <DropdownMobile
            ref={mobile}
            className="btn nav-link icon d-lg-none"
            id="horizontal-navtoggle"
          >
          </DropdownMobile>
          <DropDownUserMenu />
				</div>
			</div>
		</div>
  );
};
