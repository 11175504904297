import * as types from 'src/constants/actionTypes/ProjectsActionTypes';

const initialState = {
  isDestroing: false,
  error: false,
  successDestroy: false,
}

export default store => {
  store.on('@init', () => ({ ProjectDestroy: initialState }));

  store.on(types.DELETE_PROJECTS_REQUEST, ({ ProjectDestroy }) =>
    ({ ProjectDestroy: { ...ProjectDestroy, isDestroing: true, successDestroy: false }}));
  store.on(types.DELETE_PROJECTS_SUCCESS, ({ ProjectDestroy }) =>
    ({ ProjectDestroy: {...ProjectDestroy, successDestroy: true }}));
  store.on(types.DELETE_PROJECTS_ERROR, ({ ProjectDestroy }) =>
    ({ ProjectDestroy: {...ProjectDestroy, error: true }}));

  store.on(types.DELETE_PROJECT_REQUEST, ({ ProjectDestroy }) =>
    ({ ProjectDestroy: { ...ProjectDestroy, isDestroing: true, successDestroy: false }}));
  store.on(types.DELETE_PROJECT_SUCCESS, ({ ProjectDestroy }) =>
    ({ ProjectDestroy: {...ProjectDestroy, successDestroy: true }}));
  store.on(types.DELETE_PROJECT_ERROR, ({ ProjectDestroy }) =>
    ({ ProjectDestroy: {...ProjectDestroy, error: true }}));
}
