import { offerNewPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchOfferCollections } from 'src/actions/Offers';
import { fetchListing } from 'src/actions/Listings';
import { API_CALL } from 'src/middleware/API';

const OfferNew = lazy(() => import('src/components/views/offers/New'));

export default {
  path: offerNewPath(),
  exact: true,
  strict: true,
  component: OfferNew,
  perform: 'offers:new',
  protected: true,
  prepareData: ({ store, params }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    if (Object.keys(state.OfferCollections.items).length > 0) return;
    const accessToken = state.Auth.token;
    store.dispatch(API_CALL, fetchOfferCollections({ accessToken }));
    if (params.listingId) {
      const query = { withProperties: true };
      store.dispatch(API_CALL, fetchListing({ accessToken, id: params.listingId, query }));
    }
  }
};
