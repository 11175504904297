import * as types from 'src/constants/actionTypes/files/DeleteFileActionTypes';

const initialState = {
  sendRequest: false,
  error: false,
  errors: {},
  success: false,
  item: null
}

export default store => {
  store.on('@init', () => ({ SubmitDeleteFile: initialState }));

  store.on(types.DELETE_FILE_RESET, () => ({ SubmitDeleteFile: initialState }));
  store.on(types.DELETE_FILE_REQUEST, ({ SubmitDeleteFile }) =>
    ({ SubmitDeleteFile: { ...SubmitDeleteFile, ...initialState, sendRequest: true }}));
  store.on(types.DELETE_FILE_SUCCESS, ({ SubmitDeleteFile }, { response }) => {
    return ({
      SubmitDeleteFile: {
        ...SubmitDeleteFile, success: true, sendRequest: false, item: response.data }
      });
  });
  store.on(types.DELETE_FILE_ERROR, ({ SubmitDeleteFile }, { response }) => {
    let errors = {};
    if (response && response.body && response.body.errors) errors = response.body.errors;
    return ({ SubmitDeleteFile: {...SubmitDeleteFile, error: true, errors: errors, sendRequest: false }});
  });
}
