import React from 'react';
import Item from './Item';

export default ({ handlerOnDoubleClick, items, teamIds, userRole }) => {
  return (
    <div className="">
      <ul className="list-group">
        {items.length > 0 && items.map((item, index) =>
          <Item
            key={`activity-${index}`}
            {...item}
            handlerOnDoubleClick={handlerOnDoubleClick}
            teamIds={teamIds}
            userRole={userRole}
          />
        )}
      </ul>
    </div>
  );
};
