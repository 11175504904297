import React from 'react';
import PropTypes from 'prop-types';

import logo from 'src/constants/images/logo.jpg';

const LoginLayout = ({ children }) => {
  return (
    <div className="page h-100vh">
      <div className="page-single">
        <div className="container">
          <div className="row">
            <div className="col mx-auto">
              <div className="text-center mb-6">
                <img src={logo} className="header-brand-img" alt="" />
              </div>
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="card-group mb-0">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

LoginLayout.propTypes = {
  children: PropTypes.element
};

export default LoginLayout;
