import { userEditPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchUser, fetchUserCollections } from 'src/actions/Users';
import { API_CALL } from 'src/middleware/API';

const editUser = lazy(() => import('src/components/views/admin/users/Edit'));

export default {
  path: userEditPath(),
  exact: true,
  strict: true,
  component: editUser,
  protected: true,
  perform: 'admin/users:edit',
  title: 'Edit User',
  prepareData: ({ store, params }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    if (Object.keys(state.UserCollections.items).length ===  0) {
      store.dispatch(API_CALL, fetchUserCollections({ accessToken }));
    }
    const query = { noTypeCast: true }
    store.dispatch(API_CALL, fetchUser({ accessToken, id: params.id, query }));
  }
};
