import Snags from './list';
import SnagShow from './show';
import SnagEdit from './edit';
import SnagNew from './new';
import SnagClone from './clone';

export default [
  Snags,
  SnagNew,
  SnagShow,
  SnagEdit,
  SnagClone
];
