import * as types from 'src/constants/actionTypes/UsersActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  items: {},
  loaded: false
};

const initialStateOwned = {
  isFetching: false,
  error: false,
  items: {},
  loaded: false
};

export default store => {
  store.on('@init', () => ({ UserCollections: initialState }));
  store.on('@init', () => ({ UserOwned: initialStateOwned }));

  store.on(types.FETCH_USER_COLLECTION_REQUEST, ({ UserCollections }) =>
    ({ UserCollections: { ...initialState, isFetching: true, loaded: false }}));
  store.on(types.FETCH_USER_COLLECTION_SUCCESS, ({ UserCollections }, { response }) =>
    ({ UserCollections: { ...UserCollections, items: response.data, loaded: true }}));
  store.on(types.FETCH_USER_COLLECTION_ERROR, ({ UserCollections }) =>
    ({ UserCollections: {...UserCollections, error: true }}));

  store.on(types.FETCH_USER_OWNED_REQUEST, ({ UserOwned }) =>
    ({ UserOwned: { ...initialState, isFetching: true, loaded: false }}));
  store.on(types.FETCH_USER_OWNED_SUCCESS, ({ UserOwned }, { response }) =>
    ({ UserOwned: { ...UserOwned, items: response.data, loaded: true }}));
  store.on(types.FETCH_USER_OWNED_ERROR, ({ UserOwned }) =>
    ({ UserOwned: {...UserOwned, error: true }}));
};
