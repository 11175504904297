import React from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  return (
    <div className="container text-center ">
  		<div className="display-1 text-primary mb-5"> 403</div>
  		<h1 className="h2 mb-3">{t("restrictedPage.title")}</h1>
  		<p className="h4 font-weight-normal mb-7 leading-normal">{t("restrictedPage.text")}</p>
  	</div>
  )
};
