import * as types from 'src/constants/actionTypes/ContactsActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  items: []
}

export default store => {
  store.on('@init', () => ({ Contacts: initialState }));

  store.on(types.FETCH_CONTACTS_REQUEST, ({ Contacts }) =>
    ({ Contacts: { ...Contacts, isFetching: true }}));
  store.on(types.FETCH_CONTACTS_SUCCESS, ({ Contacts }, { response }) =>
    ({ Contacts: { ...Contacts, items: response.data }}));
  store.on(types.FETCH_CONTACTS_ERROR, ({ Contacts }) =>
    ({ Contacts: {...Contacts, error: true }}));
}
