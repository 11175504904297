import * as types from 'src/constants/actionTypes/TransactionsActionTypes';
import initialState from 'src/helpers/initialStateSubmit';
import { SUBMIT_RECENTLY_VIEWED } from 'src/constants/actionTypes/RecentlyViewedActionTypes';

export default store => {
  store.on('@init', () => ({ SubmitTransaction: initialState }));

  store.on(types.SUBMIT_TRANSACTION_RESET, () => ({ SubmitTransaction: initialState }));

  store.on(types.SUBMIT_TRANSACTION_REQUEST, ({ SubmitTransaction }) =>
    ({ SubmitTransaction: { ...initialState, sendRequest: true }}));
  store.on(types.SUBMIT_TRANSACTION_SUCCESS, ({ SubmitTransaction, Transaction }, { response }) => {
    store.dispatch(SUBMIT_RECENTLY_VIEWED, {
      data: { id: response.data.id, name: response.data.name, time: Date.now()},
      endpoint: 'transactions'
    });
    return ({
      Transaction: { ...Transaction, offer: response.data },
      SubmitTransaction: { ...SubmitTransaction, success: true, sendRequest: false }
    });
  });
  store.on(types.SUBMIT_TRANSACTION_ERROR, ({ SubmitTransaction }, { response }) => {
    let errors = {};
    if (response && response.body && response.body.errors) errors = response.body.errors;
    return ({ SubmitTransaction: {...SubmitTransaction, error: true, errors: errors, sendRequest: false }});
  });
}
