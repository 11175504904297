import * as types from 'src/constants/actionTypes/SettingsActionTypes';

const fetchSettings = ({ accessToken, query = {} }) => {
  return ({
    query,
    endpoint: `settings`,
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_SETTINGS_REQUEST,
      types.FETCH_SETTINGS_SUCCESS,
      types.FETCH_SETTINGS_ERROR
    ]
  })
};

const updateSettings = ({ accessToken, query, id }) => {
  return ({
    query,
    endpoint: `settings/${id}`,
    method: 'PUT',
    credentials: true,
		accessToken,
    types: [
      types.SUBMIT_SETTINGS_REQUEST,
      types.SUBMIT_SETTINGS_SUCCESS,
      types.SUBMIT_SETTINGS_ERROR
    ]
  })
};

const fetchSettingsCollections = ({ accessToken }) => {
  return ({
    endpoint: 'settings/helper',
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_SETTINGS_COLLECTION_REQUEST,
      types.FETCH_SETTINGS_COLLECTION_SUCCESS,
      types.FETCH_SETTINGS_COLLECTION_ERROR
    ]
  })
}

export { updateSettings, fetchSettings, fetchSettingsCollections }
