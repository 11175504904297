// import * as AuthAction from 'src/constants/actionTypes/AuthActionTypes';
// import { SUBMIT_RECENTLY_VIEWED } from 'src/constants/actionTypes/RecentlyViewedActionTypes';

import createStore from 'storeon';
import persistState from '@storeon/localstorage';
import crossTab from '@storeon/crosstab';

import Auth from 'src/store/auth';
// import Companies from 'src/store/companies/';
import CompanyCollections from 'src/store/companies/collections';
import CompanySubmit from 'src/store/companies/submit';
import Company from 'src/store/companies/show';
import CompaniesDestroy from 'src/store/companies/destroy';
import Profile from 'src/store/profile';
import Settings from 'src/store/settings';
import BaseCompany from 'src/store/base_company';
import callBackAuth from 'src/store/callBackAuth';
import Notifications from 'src/store/notifications';
import Users from 'src/store/users/';
import User from 'src/store/users/show';
import UserSubmit from 'src/store/users/submit';
import UserCollections from 'src/store/users/collections';
import UsersDestroy from 'src/store/users/destroy';
import UserResetPassword from 'src/store/users/reset_password';
import Teams from 'src/store/teams';
import TeamCollections from 'src/store/teams/collections';
import TeamSubmit from 'src/store/teams/submit';
import Team from 'src/store/teams/show';
import TeamsDestroy from 'src/store/teams/destroy';
import DataTable from 'src/store/dataTable';
import UploadFiles from 'src/store/files/upload';
import RenameFile from 'src/store/files/renameFile';
import DeleteFile from 'src/store/files/deleteFile';
import recentlyViewed from 'src/store/recentlyViewed';
import ContactSubmit from 'src/store/contacts/submit';
import ContactCollections from 'src/store/contacts/collections';
import Contact from 'src/store/contacts/show';
import Contacts from 'src/store/contacts';
import ContactsDestroy from 'src/store/contacts/destroy';
import LeadSubmit from 'src/store/leads/submit';
import LeadCollections from 'src/store/leads/collections';
import Lead from 'src/store/leads/show';
import LeadsDestroy from 'src/store/leads/destroy';
import ActivityCollections from 'src/store/activities/collections';
import ActivitySubmit from 'src/store/activities/submit';
import Activities from 'src/store/activities';
import Events from 'src/store/activities/events';
import Activity from 'src/store/activities/show';
import ProjectSubmit from 'src/store/projects/submit';
import ProjectCollections from 'src/store/projects/collections';
import Project from 'src/store/projects/show';
import ProjectsDestroy from 'src/store/projects/destroy';
import PropertySubmit from 'src/store/properties/submit';
import PropertyCollections from 'src/store/properties/collections';
import Property from 'src/store/properties/show';
import PropertiesDestroy from 'src/store/properties/destroy';
import ListingSubmit from 'src/store/listings/submit';
import ListingCollections from 'src/store/listings/collections';
import Listing from 'src/store/listings/show';
import ListingsDestroy from 'src/store/listings/destroy';
import OfferSubmit from 'src/store/offers/submit';
import OfferCollections from 'src/store/offers/collections';
import Offer from 'src/store/offers/show';
import OffersDestroy from 'src/store/offers/destroy';
import TransactionSubmit from 'src/store/transactions/submit';
import TransactionCollections from 'src/store/transactions/collections';
import Transaction from 'src/store/transactions/show';
import TransactionsDestroy from 'src/store/transactions/destroy';
import InternalBrokerSubmit from 'src/store/internal_brokers/submit';
import InternalBrokerCollections from 'src/store/internal_brokers/collections';
import InternalBroker from 'src/store/internal_brokers/show';
import InternalBrokersDestroy from 'src/store/internal_brokers/destroy';
import PaymentSubmit from 'src/store/payments/submit';
import PaymentCollections from 'src/store/payments/collections';
import PaymentBroker from 'src/store/payments/show';
import PaymentsDestroy from 'src/store/payments/destroy';
import History from './history';
import Dashboard from './dashboard';
import Report from './report';
import PerformanceCollections from 'src/store/performances/collections';
import Performance from 'src/store/performances';
import UserEntity from 'src/store/entities/user';
import CompanyEntity from 'src/store/entities/company';
import ContactEntity from 'src/store/entities/contact';
import LeadEntity from 'src/store/entities/lead';
import ListingEntity from 'src/store/entities/listing';
import OfferEntity from 'src/store/entities/offer';
import ProjectEntity from 'src/store/entities/project';
import PropertyEntity from 'src/store/entities/property';
import TransactionEntity from 'src/store/entities/transaction';
import ListingPropertyEntity from 'src/store/entities/listing-property';
import SnagEntity from 'src/store/entities/snag';
import { contactEnumModule } from 'src/store/enums/contact';
import { snagEnumModule } from 'src/store/enums/snag';
import { lookupModule } from 'src/store/lookups';

import API from 'src/middleware/API';
import { SnagJsonApiEntity, SnagRecordCollection, SnagEnums, SnagRecord } from 'src/-types/models/snag';
import { FETCH_SNAG, FETCH_SNAG_SUCCESS, FETCH_SNAG_ERROR, FETCH_ALL_SNAGS, FETCH_ALL_SNAGS_SUCCESS, FETCH_ALL_SNAGS_ERROR, CREATE_SNAG_SUCCESS, UPDATE_SNAG, UPDATE_SNAG_SUCCESS, UPDATE_SNAG_ERROR, DELETE_SNAG_FILE_SUCCESS, RENAME_SNAG_FILE_SUCCESS, UPDATE_SNAG_RECORD, DELETE_SNAG_SUCCESS, BATCH_DELETE_SNAGS_SUCCESS, BATCH_UPDATE_SNAGS_SUCCESS } from 'src/constants/actionTypes/entities/snag';
import { BaseRecordCollection, JsonApiResponseType, JsonApiErrorResponseType, JsonApiCollectionResponseType } from 'src/-types/models/json-api';
import { ModelType } from 'src/-types/model';
import { UserRecordCollection, UserEnums } from 'src/-types/models/user';
import { PropertyRecordCollection, PropertyEnums } from 'src/-types/models/property';
import { FETCH_SNAG_ENUMS, FETCH_SNAG_ENUMS_SUCCESS, FETCH_SNAG_ENUMS_ERROR } from 'src/constants/actionTypes/enums/snag';
import { AccessToken } from 'src/-types/authentication';
import { SUBMIT_RECENTLY_VIEWED } from 'src/constants/actionTypes/RecentlyViewedActionTypes';
import { ProjectRecordCollection } from 'src/-types/models/project';
import { FieldsStateLookup, FieldsLookupResponse } from 'src/-types/lookups/fields';
import { CountriesStateLookup, CountriesLookupResponse } from 'src/-types/lookups/countries';
import { FeaturesStateLookup, FeaturesLookupResponse } from 'src/-types/lookups/features';
import { FETCH_LOOKUPS_COUNTRIES_REQUEST, FETCH_LOOKUPS_COUNTRIES_SUCCESS, FETCH_LOOKUPS_COUNTRIES_ERROR, FETCH_LOOKUPS_FEATURES_REQUEST, FETCH_LOOKUPS_FEATURES_SUCCESS, FETCH_LOOKUPS_FEATURES_ERROR, FETCH_LOOKUPS_FIELDS_REQUEST, FETCH_LOOKUPS_FIELDS_SUCCESS, FETCH_LOOKUPS_FIELDS_ERROR } from 'src/constants/actionTypes/lookups-action-types';
import { ContactEnums } from 'src/-types/models/contact';
import { FETCH_CONTACT_ENUMS, FETCH_CONTACT_ENUMS_SUCCESS, FETCH_CONTACT_ENUMS_ERROR } from 'src/constants/actionTypes/enums/contact';
import { ModelEnumState } from 'src/-types/enums';
import { CompanyEnums } from 'src/-types/models/company';
import { FETCH_COMPANY_ENUMS, FETCH_COMPANY_ENUMS_SUCCESS, FETCH_COMPANY_ENUMS_ERROR } from 'src/constants/actionTypes/enums/company';
import { companyEnumModule } from 'src/store/enums/company';

const authKeys = [
  'VERIFY_TOKEN_SUCCESS',
  'SIGN_IN_SUCCESS',
  'SIGN_OUT_SUCCESS',
  'UPDATE_ACCESS_TOKEN',
  'FORGOT_PASSWORD_SUCCESS',
  'CHANGE_PASSWORD_SUCCESS',
  'SUBMIT_RECENTLY_VIEWED',
];

export interface AuthState {
  token: AccessToken;
  user: {
    role: string
    agency: boolean;
    project: boolean;
    id: number;
    currency: string;
  };
  connected: boolean;
}

export interface RecentlyViewedState {
  items: {
    [key: string]: { id: number }[];
  };
}

export type EntityStateType = { [P in ModelType]: BaseRecordCollection };

export interface EntityState extends EntityStateType {
  project: ProjectRecordCollection;
  property: PropertyRecordCollection;
  snag: SnagRecordCollection;
  user: UserRecordCollection;
}

export type EnumStateType = { [P in ModelType]: ModelEnumState };

export interface EnumState extends EnumStateType {
  company: ModelEnumState<CompanyEnums>;
  contact: ModelEnumState<ContactEnums>;
  lead: ModelEnumState<any>;
  listing: ModelEnumState<any>;
  listingProperty: ModelEnumState<any>;
  offer: ModelEnumState<any>;
  project: ModelEnumState<any>;
  property: ModelEnumState<PropertyEnums>
  snag: ModelEnumState<SnagEnums>
  transaction: ModelEnumState<any>;
  user: ModelEnumState<UserEnums>
  userAttribute: ModelEnumState<any>;
}

export interface LookupsState {
  countries: CountriesStateLookup;
  features: FeaturesStateLookup;
  fields: FieldsStateLookup;
}

export interface State {
  Auth: AuthState;
  RecentlyViewed: RecentlyViewedState;
  entities: EntityState;
  enums: EnumState;
  lookups: LookupsState;
}

export interface Events {
  // --- lookups
  // countries
  [FETCH_LOOKUPS_COUNTRIES_REQUEST]: undefined;
  [FETCH_LOOKUPS_COUNTRIES_SUCCESS]: { response: { data: CountriesLookupResponse } };
  [FETCH_LOOKUPS_COUNTRIES_ERROR]: { response: any };
  // features
  [FETCH_LOOKUPS_FEATURES_REQUEST]: undefined;
  [FETCH_LOOKUPS_FEATURES_SUCCESS]: { response: { data: FeaturesLookupResponse } };
  [FETCH_LOOKUPS_FEATURES_ERROR]: { response: any };
  // fields
  [FETCH_LOOKUPS_FIELDS_REQUEST]: undefined;
  [FETCH_LOOKUPS_FIELDS_SUCCESS]: { response: { data: FieldsLookupResponse } };
  [FETCH_LOOKUPS_FIELDS_ERROR]: { response: any };
  // --- v1
  [SUBMIT_RECENTLY_VIEWED]: { data: any, endpoint: string };
  // --- models
  // companies
  [FETCH_COMPANY_ENUMS]: undefined;
  [FETCH_COMPANY_ENUMS_SUCCESS]: { response: CompanyEnums };
  [FETCH_COMPANY_ENUMS_ERROR]: { error: any };
  // contacts
  [FETCH_CONTACT_ENUMS]: undefined;
  [FETCH_CONTACT_ENUMS_SUCCESS]: { response: ContactEnums };
  [FETCH_CONTACT_ENUMS_ERROR]: { error: any };
  // snags
  [FETCH_SNAG]: { entityId: string };
  [FETCH_SNAG_SUCCESS]: { response: JsonApiResponseType<SnagJsonApiEntity> };
  [FETCH_SNAG_ERROR]: { entityId: string, error: any };
  [FETCH_ALL_SNAGS]: undefined;
  [FETCH_ALL_SNAGS_SUCCESS]: { response: JsonApiCollectionResponseType<SnagJsonApiEntity> };
  [FETCH_ALL_SNAGS_ERROR]: { error: any };
  [CREATE_SNAG_SUCCESS]: { response: JsonApiResponseType<SnagJsonApiEntity> };
  [UPDATE_SNAG]: { entityId: string };
  [UPDATE_SNAG_SUCCESS]: { response: JsonApiResponseType<SnagJsonApiEntity> };
  [UPDATE_SNAG_ERROR]: { entityId: string, response: JsonApiErrorResponseType<SnagJsonApiEntity> };
  [BATCH_UPDATE_SNAGS_SUCCESS]: { response: JsonApiCollectionResponseType<SnagJsonApiEntity> };
  [FETCH_SNAG_ENUMS]: undefined;
  [FETCH_SNAG_ENUMS_SUCCESS]: { response: SnagEnums };
  [FETCH_SNAG_ENUMS_ERROR]: { error: any };
  [RENAME_SNAG_FILE_SUCCESS]: { response: JsonApiResponseType<SnagJsonApiEntity> };
  [DELETE_SNAG_FILE_SUCCESS]: { response: JsonApiResponseType<SnagJsonApiEntity> };
  [UPDATE_SNAG_RECORD]: { id: string, snag: SnagRecord };
  [DELETE_SNAG_SUCCESS]: { id: string };
  [BATCH_DELETE_SNAGS_SUCCESS]: { ids: string[] };
}

export const store = createStore<State>([
  Auth, Notifications, DataTable, UploadFiles, RenameFile, DeleteFile, recentlyViewed,
  CompanyCollections, CompanySubmit, Company, CompaniesDestroy, Profile, BaseCompany,
  Users, User, UserSubmit, UserCollections, UsersDestroy, Settings, TeamCollections, TeamSubmit,
  Team, TeamsDestroy, ContactSubmit, ContactCollections, Contact, ContactsDestroy, Teams,
  UserResetPassword, LeadSubmit, LeadCollections, Lead, LeadsDestroy, ActivityCollections,
  ActivitySubmit, Activities, Events, Activity, ProjectSubmit, ProjectCollections,
  Project, ProjectsDestroy, PropertySubmit, PropertyCollections, Property, PropertiesDestroy,
  ListingSubmit, ListingCollections, Listing, ListingsDestroy, Contacts, OfferSubmit,
  OfferCollections, Offer, OffersDestroy, TransactionSubmit, TransactionCollections,
  Transaction, TransactionsDestroy, InternalBrokersDestroy, InternalBroker,
  InternalBrokerCollections, InternalBrokerSubmit, PaymentSubmit, PaymentCollections, PaymentBroker,
  PaymentsDestroy, History, Dashboard, Report, PerformanceCollections, Performance, lookupModule,
  UserEntity, CompanyEntity, ContactEntity, LeadEntity, ListingEntity, OfferEntity,
  ProjectEntity, PropertyEntity, TransactionEntity, ListingPropertyEntity, SnagEntity,
  contactEnumModule, snagEnumModule, companyEnumModule, API,
  persistState(['Auth', 'RecentlyViewed'], { key: 'recrm' }),
  callBackAuth,
  crossTab({ filter: (event, data) => authKeys.includes(String(event)), key: 'recrm-crosstab' })
  // process.env.NODE_ENV !== 'production' && require('storeon/devtools')
]);
