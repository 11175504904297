import * as types from 'src/constants/actionTypes/ActivitiesActionTypes';

const fetchActivityCollections = ({ accessToken }) => {
  return ({
    endpoint: 'activities/helper',
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_ACTIVITY_COLLECTION_REQUEST,
      types.FETCH_ACTIVITY_COLLECTION_SUCCESS,
      types.FETCH_ACTIVITY_COLLECTION_ERROR
    ]
  })
}

const createActivity = ({ accessToken, query }) => {
  return ({
    query: query,
    endpoint: 'activities',
    method: 'POST',
    credentials: true,
		accessToken,
    types: [
      types.SUBMIT_ACTIVITY_REQUEST,
      types.SUBMIT_ACTIVITY_SUCCESS,
      types.SUBMIT_ACTIVITY_ERROR
    ]
  })
};

const fetchActivity = ({ accessToken, id, query = {} }) => {
  return ({
    query,
    endpoint: `activities/${id}`,
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_ACTIVITY_REQUEST,
      types.FETCH_ACTIVITY_SUCCESS,
      types.FETCH_ACTIVITY_ERROR
    ]
  })
};

const fetchActivities = ({ accessToken, query = {} }) => {
  return ({
    query,
    endpoint: 'activities',
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_ACTIVITIES_REQUEST,
      types.FETCH_ACTIVITIES_SUCCESS,
      types.FETCH_ACTIVITIES_ERROR
    ]
  })
}

const fetchEvents = ({ accessToken, query = {} }) => {
  return ({
    query,
    endpoint: 'activities/events',
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_EVENTS_REQUEST,
      types.FETCH_EVENTS_SUCCESS,
      types.FETCH_EVENTS_ERROR
    ]
  })
}

const updateActivity = ({ accessToken, query, id }) => {
  return ({
    query,
    endpoint: `activities/${id}`,
    method: 'PUT',
    credentials: true,
		accessToken,
    types: [
      types.SUBMIT_ACTIVITY_REQUEST,
      types.SUBMIT_ACTIVITY_SUCCESS,
      types.SUBMIT_ACTIVITY_ERROR
    ]
  })
};

const fetchActivityRelated = ({ accessToken, query = {}, id }) => {
  return ({
    query,
    endpoint: `activities/${id}/relate`,
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_ACTIVITY_RELATE_REQUEST,
      types.FETCH_ACTIVITY_RELATE_SUCCESS,
      types.FETCH_ACTIVITY_RELATE_ERROR
    ]
  })
}

export {
  fetchActivityCollections, createActivity, fetchActivities, fetchEvents, updateActivity,
  fetchActivityRelated, fetchActivity
};
