import * as types from 'src/constants/actionTypes/ActivitiesActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  items: {}
}

const initialStateRelated = {
  isFetching: false,
  error: false,
  items: []
}

export default store => {
  store.on('@init', () => ({ ActivityCollections: initialState }));

  store.on(types.FETCH_ACTIVITY_COLLECTION_REQUEST, ({ ActivityCollections }) =>
    ({ ActivityCollections: { ...ActivityCollections, isFetching: true }}));
  store.on(types.FETCH_ACTIVITY_COLLECTION_SUCCESS, ({ ActivityCollections }, { response }) =>
    ({ ActivityCollections: { ...ActivityCollections, items: response.data }}));
  store.on(types.FETCH_ACTIVITY_COLLECTION_ERROR, ({ ActivityCollections }) =>
    ({ ActivityCollections: {...ActivityCollections, error: true }}));

  store.on('@init', () => ({ ActivityRelated: initialStateRelated }));

  store.on(types.FETCH_ACTIVITY_RELATE_REQUEST, ({ ActivityRelated }) =>
    ({ ActivityRelated: { ...initialStateRelated, isFetching: true }}));
  store.on(types.FETCH_ACTIVITY_RELATE_SUCCESS, ({ ActivityRelated, Activity }, { response }) => {
    return ({
      ActivityRelated: { ...ActivityRelated, items: response.data },
      Activity: { ...Activity, item: { ...Activity.item, relateCollection: response.data  }}
    });
  });
  store.on(types.FETCH_ACTIVITY_RELATE_ERROR, ({ ActivityRelated }) =>
    ({ ActivityRelated: {...ActivityRelated, error: true }}));
  store.on(types.FETCH_ACTIVITY_RELATE_RESET, () => ({ ActivityRelated: initialStateRelated }));
};
