import React, { useState, useEffect } from 'react';
import FormHooks from 'src/helpers/formHooks';
import { useTranslation } from 'react-i18next';
import useStoreon from 'src/helpers/storeon';
import { API_CALL } from 'src/middleware/API';
import { SUBMIT_OFFER_RESET } from 'src/constants/actionTypes/OffersActionTypes';
import { omit } from 'lodash';

import ControlledCollapse from 'src/components/elements/shared/ControlledCollapse';
import VerticalSelectSearch from 'src/components/elements/form/VerticalSelectSearch';
import VerticalTextArea from 'src/components/elements/form/VerticalTextArea';
import VerticalMoneyInput from 'src/components/elements/form/VerticalMoneyInput';
import VerticalDateInput from 'src/components/elements/form/VerticalDateInput';
import VerticalInputPlainText from 'src/components/elements/form/VerticalInputPlainText';
import clearValidation from 'src/components/elements/form/clearValidation';
import setServerSideErrors from 'src/components/elements/form/setServerSideErrors';
import AdditionallyJsonb from 'src/components/elements/shared/additionallyJsonb';

const saleLabels = () => ({
  propertyOwner: 'offers.seller',
  recordOwner: 'offers.sellersAgent',
  leadContact: 'offers.potentialBuyer',
  leadRefContact: 'offers.buyersAgent'
});

const rentLabels = () => ({
  propertyOwner: 'offers.landlord',
  recordOwner: 'offers.landlordsAgent',
  leadContact: 'offers.potentialTenant',
  leadRefContact: 'offers.tenantsAgent'
});

export default (props) => {
  const {
    defaultValues, children, submitHandler, id, history, successPath, serverSideValidation,
    defaultOfferType = null, leadId, lead, listingId, listing
  } = props;

  const defaultVariable = {
    propertyOwner: '#', recordOwner: '#', leadContact: '#', leadRefContact: '#'
  };

  const clearLabels = () => setVariable(defaultVariable);

  const clearValues = () => {
    if (!leadId) {
      setInputs(inputs => ({
        ...inputs, potentialBuyer: '', buyersAgent: '', leadId: ''
      }));
    }
    if (!listingId) {
      setInputs(inputs => ({
        ...inputs, seller: '', contactUser: '', listingPrice: '', listingId: ''
      }));
    }
  };

  const { OfferCollections, Auth, dispatch } = useStoreon('OfferCollections', 'Auth');
  const [validations, setValidations] = useState({});
  const [offerType, setOfferType] = useState(defaultOfferType);
  const [variable, setVariable] = useState(defaultVariable);
  const [filteredListings, setFilteredListings] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [filteredOfferedBy, setFilteredOfferedBy] = useState([]);
  const formRef = React.createRef();
  const { t } = useTranslation();

  useEffect(() => {
    if (OfferCollections.items.listing)
      setFilteredListings(OfferCollections.items.listing);
  }, [OfferCollections.items.listing]);

  serverSideValidation.then(() => {
    dispatch(SUBMIT_OFFER_RESET);
    history.push(successPath);
  }, errors => {
    setServerSideErrors({ formRef, errors, validations, setValidations })
  });

  const callBack = () => {
    clearValidation(formRef);

    if (formRef.current.checkValidity() !== false) {
      const accessToken = Auth.token;
      const { seller, contactUser, listingPrice, potentialBuyer, buyersAgent, ...offer } = inputs;
      const body = { offer: omit(offer, ['transactions']) };

      dispatch(API_CALL, submitHandler({ accessToken, body, id }));
    }

    formRef.current.classList.add('was-validated');
  };

  const {
    inputs, handleInputChange, handleSubmit, handleSelectSearch, handleDataChange, setInputs
  } = FormHooks(defaultValues, callBack);

  const { agency, currency } = Auth.user;

  useEffect(() => {
    if (offerType && OfferCollections.loaded === true) {
      setFilteredListings(
        OfferCollections.items.listing.filter(el => el.typeOfRaw === offerType)
      );
      setFilteredLeads(
        OfferCollections.items.lead.filter(el => el.typeOfRaw === offerType)
      );
      const start = offerType === 'sale' ? 0 : 2;
      const end = offerType === 'sale' ? 2 : 4;
      setFilteredOfferedBy(
        OfferCollections.items.offeredBy.slice(start, end)
      );
    } else {
      setFilteredListings(OfferCollections.items.listing);
      setFilteredLeads(OfferCollections.items.lead);
      setFilteredOfferedBy([])

    }
  }, [OfferCollections, offerType]);

  const setLeadsFields = () => {
    const lead = OfferCollections.items.lead.find(el => el.value === inputs.leadId);
    const { typeOfRaw } = lead;
    setInputs(inputs => ({
      ...inputs,
      potentialBuyer: lead.contact,
      buyersAgent: lead.contactUser.value
    }));
    callBackAfterSet({ typeOfRaw });
  };

  const setListingsFields = () => {
    const listing = OfferCollections.items.listing.find(el => el.value === inputs.listingId);
    const { propertyOwner, contactUser, listedPrice } = listing;
    const { typeOfRaw } = listing;
    setInputs(inputs => ({
      ...inputs,
      seller: propertyOwner,
      contactUser,
      listingPrice: listedPrice
    }));
    callBackAfterSet({ typeOfRaw });
  };

  const callBackAfterSet = ({ typeOfRaw }) => {
    if (leadId || listingId) return;

    const labels = typeOfRaw === 'sale' ? saleLabels() : rentLabels();
    setVariable(variable => ({ ...variable, ...labels }));
    setOfferType(typeOfRaw);
  };

  const handleOnChangeOfferType = (name, value) => {
    handleSelectSearch(name, value);
    if (value === '') {
      clearValues();
      if (leadId || listingId) return;
      clearLabels();
      setOfferType(null);
    } else if (name === 'leadId') {
      setLeadsFields();
    } else if (name === 'listingId') {
      setListingsFields();
    }
  }

  useEffect(() => {
    if (offerType !== null) {
      const labels = offerType === 'sale' ? saleLabels() : rentLabels();
      setVariable(variable => ({ ...variable, ...labels }));
    }
  }, [offerType]);

  let rows = [];
  if (OfferCollections.loaded) {
    const { fields } = OfferCollections.items;
    rows = fields.rows;
  }
  const onChanges = {
    'text': handleInputChange,
    'date': handleDataChange
  };

  return (
    <form onSubmit={handleSubmit} ref={formRef} noValidate={true}>
      <div className="row mb-5">
        <div className="col-lg-6 col-md-12">
          {inputs.id && <VerticalInputPlainText value={inputs.parent} label={t('offers.parentId')} icon={false} />}
          <VerticalSelectSearch
            collection={OfferCollections.items.status}
            value={inputs.status}
            onChange={handleSelectSearch}
            label={t('offers.status')}
            name="status"
            includeBlank={false}
          />
          <VerticalSelectSearch
            collection={filteredOfferedBy}
            value={inputs.offeredBy}
            onChange={handleSelectSearch}
            label={t('offers.offeredBy')}
            name="offeredBy"
            includeBlank={false}
          />
        </div>
        <div className="col-lg-6 col-md-12">
          <VerticalInputPlainText
            value={inputs.seller}
            label={t(`${variable.propertyOwner}`)}
            icon={false}
          />
          <VerticalInputPlainText
            value={inputs.contactUser.value}
            label={t(`${variable.recordOwner}`)}
            icon={false}
          />
          {listingId ?
            <VerticalInputPlainText
              value={listing}
              label={t('offers.listing')}
              icon={false}
            />
            :
            <VerticalSelectSearch
              collection={filteredListings}
              value={inputs.listingId}
              onChange={handleOnChangeOfferType}
              label={t('offers.listing')}
              name="listingId"
              includeBlank={false}
            />
          }

        </div>
      </div>
      <div className="row mb-5">
        <div className="col-lg-6 col-md-12">
          <VerticalInputPlainText
            value={inputs.listingPrice}
            label={t('offers.listingPrice')}
            icon={false}
          />
          <VerticalMoneyInput
            collection={OfferCollections.items.currency}
            value={inputs.amountCents}
            valueSelect={inputs.amountCurrency}
            onChange={handleInputChange}
            onChangeSelect={handleSelectSearch}
            label={t('offers.offeredAmount')}
            name="amountCents"
            nameSelect="amountCurrency"
            errors={validations.offeredAmount}
            currency={currency}
          />
          {offerType === 'sale' && <VerticalSelectSearch
            collection={OfferCollections.items.financeMethod}
            value={inputs.financeMethod}
            onChange={handleSelectSearch}
            label={t('offers.financeMethod')}
            name="financeMethod"
            includeBlank={false}
          />}
        </div>
        <div className="col-lg-6 col-md-12">
          <VerticalInputPlainText
            value={inputs.potentialBuyer}
            label={t(`${variable.leadContact}`)}
            icon={false}
          />
          <VerticalInputPlainText
            value={inputs.buyersAgent.value}
            label={t(`${variable.leadRefContact}`)}
            icon={false}
          />
          {leadId ?
            <VerticalInputPlainText
              value={lead}
              label={t('offers.lead')}
              icon={false}
            />
            :
            <VerticalSelectSearch
              collection={filteredLeads}
              value={inputs.leadId}
              onChange={handleOnChangeOfferType}
              label={t('offers.lead')}
              name="leadId"
              includeBlank={false}
            />
          }
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-lg-6 col-md-12">
          <VerticalDateInput
            value={inputs.date}
            onChange={handleDataChange}
            label={t('offers.offerDate')}
            name="date"
            errors={validations.date}
          />
        </div>
        <div className="col-lg-6 col-md-12">
          {inputs.status === 'accepted' ?
            <VerticalDateInput
              value={inputs.dateAccepted}
              onChange={handleDataChange}
              label={t('offers.dateAccepted')}
              name="dateAccepted"
              errors={validations.dateAccepted}
              disabled={inputs.status !== 'accepted'}
            />
            :
            <VerticalInputPlainText
              value={inputs.dateAccepted}
              label={t('offers.dateAccepted')}
              icon={false}
            />
          }
        </div>
      </div>
      <ControlledCollapse isOpen label={t("offers.subFolders.notes")}>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <VerticalTextArea
              value={inputs.terms}
              onChange={handleInputChange}
              label={t('offers.terms')}
              name="terms"
              errors={validations.terms}
            />
          </div>
          {agency === false &&
            <div className="col-lg-6 col-md-12">
              <VerticalTextArea
                value={inputs.additionalOptions}
                onChange={handleInputChange}
                label={t('offers.additionalOptions')}
                name="additionalOptions"
                errors={validations.additionalOptions}
              />
            </div>
          }
        </div>
      </ControlledCollapse>
      <AdditionallyJsonb
        rows={rows}
        inputs={inputs}
        onChanges={onChanges}
        validations={validations}
      />
      <ControlledCollapse isOpen label={t("offers.subFolders.systemInformation")}>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            {inputs.ownerId &&
              <VerticalSelectSearch
                collection={OfferCollections.items.owner}
                value={inputs.ownerId}
                onChange={handleSelectSearch}
                label={t('offers.owner')}
                name="ownerId"
                isClearable={false}
              />
            }
            <VerticalInputPlainText
              value={offerType && offerType.charAt(0).toUpperCase() + offerType.slice(1)}
              label={t('offers.offerType')}
              icon={false}
            />
          </div>
        </div>
      </ControlledCollapse>
      {children}
    </form>
  )
};
