import * as types from 'src/constants/actionTypes/TeamsActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  item: {}
}

export default store => {
  store.on('@init', () => ({ Team: initialState }));

  store.on(types.FETCH_TEAM_REQUEST, ({ Team }) =>
    ({ Team: { ...initialState, isFetching: true }}));
  store.on(types.FETCH_TEAM_SUCCESS, ({ Team }, { response }) =>
    ({ Team: { ...Team, item: response.data }}));
  store.on(types.FETCH_TEAM_ERROR, ({ Team }) =>
    ({ Team: {...Team, error: true }}));
}
