import { projectPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchProject, fetchProjectCollections } from 'src/actions/Projects';
import fetchingMeta from 'src/actions/files/fetchMeta';
import { fetchActivityCollections } from 'src/actions/Activities';
import { API_CALL } from 'src/middleware/API';
import Layout from 'src/components/views/showLayout';
import { fetchProjectSnags } from 'src/actions/entities/project';

const ProjectShow = lazy(() => import('src/components/views/projects/Show'));

export default {
  path: projectPath(),
  exact: true,
  strict: true,
  component: ProjectShow,
  perform: 'projects:show',
  layout: Layout,
  protected: true,
  prepareData: ({ store, params }) => {
    const state = store.get();

    if (state.Auth.connected === false) {
      return;
    }

    const { dispatch } = store;
    const { id } = params;
    const accessToken = state.Auth.token;

    if (Object.keys(state.ActivityCollections.items).length === 0) {
      dispatch(API_CALL, fetchActivityCollections({ accessToken }));
    }

    if (Object.keys(state.ProjectCollections.items).length === 0) {
      dispatch(API_CALL, fetchProjectCollections({ accessToken, query: { country: true } }));
    }

    dispatch(API_CALL, fetchProject({ accessToken, id: params.id }));
    dispatch(API_CALL, fetchingMeta({ accessToken, endpoint: 'projects', id }));
    dispatch(API_CALL, fetchProjectSnags({ id: params.id }));
  }
};
