export const FETCH_ACTIVITIES_REQUEST = 'FETCH_ACTIVITIES_REQUEST';
export const FETCH_ACTIVITIES_SUCCESS = 'FETCH_ACTIVITIES_SUCCESS';
export const FETCH_ACTIVITIES_ERROR = 'FETCH_ACTIVITIES_ERROR';

export const FETCH_EVENTS_REQUEST = 'FETCH_EVENTS_REQUEST';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_ERROR = 'FETCH_EVENTS_ERROR';

export const FETCH_ACTIVITY_REQUEST = 'FETCH_ACTIVITY_REQUEST';
export const FETCH_ACTIVITY_SUCCESS = 'FETCH_ACTIVITY_SUCCESS';
export const FETCH_ACTIVITY_ERROR = 'FETCH_ACTIVITY_ERROR';
export const FETCH_ACTIVITY_RESET = 'FETCH_ACTIVITY_RESET';

export const FETCH_ACTIVITY_COLLECTION_REQUEST = 'FETCH_ACTIVITY_COLLECTION_REQUEST';
export const FETCH_ACTIVITY_COLLECTION_SUCCESS = 'FETCH_ACTIVITY_COLLECTION_SUCCESS';
export const FETCH_ACTIVITY_COLLECTION_ERROR = 'FETCH_ACTIVITY_COLLECTION_ERROR';

// export const DELETE_LEAD_REQUEST = 'DELETE_LEAD_REQUEST';
// export const DELETE_LEAD_SUCCESS = 'CREATE_LEAD_SUCCESS';
// export const DELETE_LEAD_ERROR = 'DELETE_LEAD_ERROR';
//
// export const DELETE_LEADS_REQUEST = 'DELETE_LEADS_REQUEST';
// export const DELETE_LEADS_SUCCESS = 'DELETE_LEADS_SUCCESS';
// export const DELETE_LEADS_ERROR = 'DELETE_LEADS_ERROR';
//
export const SUBMIT_ACTIVITY_REQUEST = 'SUBMIT_ACTIVITY_REQUEST';
export const SUBMIT_ACTIVITY_SUCCESS = 'SUBMIT_ACTIVITY_SUCCESS';
export const SUBMIT_ACTIVITY_ERROR = 'SUBMIT_ACTIVITY_ERROR';
export const SUBMIT_ACTIVITY_RESET = 'SUBMIT_ACTIVITY_RESET';

export const FETCH_ACTIVITY_RELATE_REQUEST = 'FETCH_ACTIVITY_RELATE_REQUEST';
export const FETCH_ACTIVITY_RELATE_SUCCESS = 'FETCH_ACTIVITY_RELATE_SUCCESS';
export const FETCH_ACTIVITY_RELATE_ERROR = 'FETCH_ACTIVITY_RELATE_ERROR';
export const FETCH_ACTIVITY_RELATE_RESET = 'FETCH_ACTIVITY_RELATE_RESET';
