import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useStoreon from 'src/helpers/storeon';
import { API_CALL } from 'src/middleware/API';
import fetchReport from 'src/actions/Report';
import randomId from 'src/helpers/randomId';
import Icon from 'src/constants/icons/IconDashboardCircle';
import Activities from './activities';
import FilterByDateRange from 'src/datatables/filterByDateRange';
import { useMediaQuery } from 'react-responsive';
import { listOfModels, listViewByRole, listFilterByViewAndRole, graphTypeByFilterBy } from './helper';
import { isFunction } from 'lodash';
import { ReportTransactionTypeCompanyRoleStatus } from 'src/components/elements/Report/Transaction/TypeCompanyRoleStatus/TypeCompanyRoleStatus';
import { ReportContactTypeCountryCode } from 'src/components/elements/Report/Contact/TypeCountryCode/TypeCountryCode';
import { LoadingSpinner } from 'src/components/elements/LoadingSpinner/LoadingSpinner';

const defaultFilter = { start: '', end: '' };
const reportDataNormalizers = {
  'transaction': {
    'referringCompanyTypeFinalPriceStatus': (report) => {
      const { items } = report;
      const datasets = [...(items?.datasets ?? [])];
      const labels = [...(items?.labels ?? [])];

      for (let i = 0; i < labels.length; i++) {
        const [a, b, c] = datasets;

        if (a.data[i] === 0 && b.data[i] === 0 && c.data[i] === 0) {
          a.data.splice(i, 1);
          b.data.splice(i, 1);
          c.data.splice(i, 1);
          labels.splice(i, 1);

          i -= 1;
        }
      }

      return { ...report, items: { ...report.items, datasets, labels } };
    }
  }
};
const reportComponents = {
  'contact': {
    'typeCountryCode': ReportContactTypeCountryCode,
  },
  'transaction': {
    'typeCompanyRoleStatus': ReportTransactionTypeCompanyRoleStatus
  }
};
const reportApiVersion = {
  'contact': {
    'typeCountryCode': 'v2',
  },
  'transaction': {
    'typeCompanyRoleStatus': 'v2'
  }
};
const reportContainerStyle = {
  'contact': {
    'typeCountryCode': {}
  }
};

const normalizeReportData = (view, by, items) => {
  const normalizerFn = reportDataNormalizers[view]?.[by];

  if (!items || !isFunction(normalizerFn)) {
    return items;
  }

  return normalizerFn(items);
};
const getReportComponent = (view, by) => {
  return reportComponents[view]?.[by] ?? null;
};
const getReportApiVersion = (view, by) => {
  return reportApiVersion[view]?.[by] ?? 'v1';
};
const getReportContainerStyle = (view, by, isTabletOrMobile) => {
  return reportContainerStyle[view]?.[by] ?? { overflow: 'auto', width: (isTabletOrMobile ? 728 : '100%') };
};

const ReportFilterBar = ({ by, dateRange, filteredListOfModels, handleFilter, handleOnChangeBy, handleOnChangeOwnedBy, handleOnChangeView, handleRefresh, listBy, ownedBy, ownedByList, view }) => {
  const { t } = useTranslation();

  return (
    <div className="card-header mb-5">
      <div className="w-100">
        <div className="d-flex flex-column flex-md-row my-2">
          <div className="mr-1 mt-1 align-self-start">
            <Icon width={35} height={35} fill="#182852" />
          </div>
          <div className="m2-no-md align-self-md-center align-self-start d-flex form-inline flex-md-wrap flex-nowrap mx-1">
            <label className="form-label text-dark-blue mb-0 mr-2 align-self-center">{t("reports.view")}</label>
            <select className="form-control align-self-center form-control-sm" onChange={handleOnChangeView} value={view}>
              {filteredListOfModels.map(el => <option key={randomId()} value={el}>{t(`reports.viewList.${el}`)}</option>)}
            </select>
          </div>
          <div className="m2-no-md align-self-md-center align-self-start d-flex form-inline flex-md-wrap flex-nowrap mx-1">
            <label className="form-label text-dark-blue mb-0 mr-2 align-self-center">{t("reports.by")}</label>
            <select className="form-control align-self-center form-control-sm" onChange={handleOnChangeBy} value={by}>
              {(listBy || []).map(el => <option key={randomId()} value={el.label}>{t(`reports.listBy.${el.label}`)}</option>)}
            </select>
          </div>
          <div className="m2-no-md align-self-md-center align-self-start d-flex form-inline flex-md-wrap flex-nowrap mx-1">
            <label className="form-label text-dark-blue mb-0 mr-2 align-self-center">{t("reports.ownedBy")}</label>
            <select className="form-control align-self-center form-control-sm" onChange={handleOnChangeOwnedBy} value={ownedBy}>
              {(ownedByList || []).map(el => <option key={randomId()} value={el}>{t(`reports.ownedByList.${el}`)}</option>)}
            </select>
          </div>
          <div className="m2-no-md align-self-md-center align-self-start ml-auto d-flex form-inline flex-md-wrap flex-nowrap mx-1">
            <label className="form-label text-dark-blue mb-0 mr-2 align-self-center">{t("reports.filterBy")}</label>
            <FilterByDateRange key={`table-filter-${1}`} range={dateRange} onChange={handleFilter} dataField={'createdAt'} label={'label'} source={'source'} endpoint={'endpoint'} classPy={false} />
          </div>
          <div className="m2-no-md align-self-md-center align-self-end">
            <button className="btn-primary btn btn-sm mx-1" onClick={handleRefresh}>
              <i className="fa fa-refresh" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ReportV1 = ({ graphType, Report, view }) => {
  const { items, options, legendOptions, colors } = Report.items;

  return (
    <Activities items={items} options={options} legendOptions={legendOptions} colors={colors} view={view} graphType={graphType} />
  );
};

const ReportElement = () => {
  const { Auth, dispatch, Report } = useStoreon('Auth', 'Report');
  const { role, agency, project } = Auth.user;
  const isProject = useMemo(() => (agency === true && project === true) || agency !== true, [agency, project]);
  const filteredListOfModels = useMemo(() => {
    let result = listOfModels;

    if (role === 'broker') {
      result = result.filter(el => el !== 'transaction' && el !== 'internalBroker');
    }

    if (isProject !== true) {
      result = result.filter(el => el !== 'project');
    }

    if (agency) {
      result = result.filter(el => el !== 'property');
    }

    return result;
  }, [agency, isProject, role]);
  const [view, setView] = useState(filteredListOfModels[0]);
  const [listBy, setListBy] = useState(listFilterByViewAndRole({ view, role, agency }));
  const [by, setBy] = useState(listBy[0].label);
  const [ownedByList, setOwnedByList] = useState(listViewByRole({ view, role, by }));
  const [ownedBy, setOwnedBy] = useState(ownedByList[0]);
  const [filter, setFilter] = useState(defaultFilter);
  const [graphType, setGraphType] = useState(null);
  const [dateRange, setDateRange] = useState(null);

  const getFetchOptions = useCallback(({ view, by, ownedBy, filter }) => ({
    endpoint: view,
    query: { by, ownedBy, filter },
    apiVersion: getReportApiVersion(view, by)
  }), []);
  const handlerFetchReport = useCallback(({ endpoint, query, apiVersion = 'v1' }) => {
    dispatch(API_CALL, fetchReport({ accessToken: Auth.token, endpoint, query: { options: query }, apiVersion }));
  }, [Auth.token, dispatch]);
  const handleOnChangeView = useCallback(event => {
    const newListBy = listFilterByViewAndRole({ view: event.target.value, role, agency });
    const newOwnedByList = listViewByRole({ view: event.target.value, role, by: newListBy[0].label });
    const newTypeGraph = graphTypeByFilterBy({ by: newListBy[0].label });

    setGraphType(newTypeGraph);
    setOwnedByList(newOwnedByList);
    setListBy(newListBy);
    setBy(newListBy[0].label);
    setOwnedBy(newOwnedByList[0]);
    setView(event.target.value);

    const options = getFetchOptions({ view: event.target.value, ownedBy: newOwnedByList[0], by: newListBy[0].label, filter });

    handlerFetchReport(options);
  }, [agency, filter, getFetchOptions, handlerFetchReport, role]);
  const handleOnChangeOwnedBy = useCallback(event => {
    setOwnedBy(event.target.value);

    const options = getFetchOptions({ view, ownedBy: event.target.value, by, filter });

    handlerFetchReport(options);
  }, [by, filter, getFetchOptions, handlerFetchReport, view]);
  const handleOnChangeBy = useCallback(event => {
    setBy(event.target.value);

    const newOwnedByList = listViewByRole({ view, role, by: event.target.value });
    const newTypeGraph = graphTypeByFilterBy({ by: event.target.value });

    setGraphType(newTypeGraph);
    setOwnedByList(newOwnedByList);

    const options = getFetchOptions({ view, ownedBy: newOwnedByList[0], by: event.target.value, filter });

    handlerFetchReport(options);
  }, [filter, getFetchOptions, handlerFetchReport, role, view]);
  const handleRefresh = useCallback(() => {
    const options = getFetchOptions({ view, ownedBy, by, filter });

    handlerFetchReport(options);
  }, [by, filter, getFetchOptions, handlerFetchReport, ownedBy, view]);
  const handleFilter = useCallback(({ currFilters }) => {
    const { createdAt } = currFilters;
    const { name, type, ...newFilter } = createdAt;

    setFilter(prev => ({ ...prev, ...newFilter }));
    setDateRange(createdAt);

    const options = getFetchOptions({ view, ownedBy, by, filter: newFilter });

    handlerFetchReport(options);
  }, [by, getFetchOptions, handlerFetchReport, ownedBy, view]);
  const normalizedReport = useMemo(() => normalizeReportData(view, by, Report), [by, view, Report]);
  const ReportV2Component = useMemo(() => getReportComponent(view, by), [by, view]);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const reportContainerStyle = useMemo(() => getReportContainerStyle(view, by, isTabletOrMobile), [by, isTabletOrMobile, view]);
  const onFilterChangeV2 = useCallback(filter => {
    const { start, end } = filter;

    setFilter(filter);
    setDateRange({ start, end });

    const options = getFetchOptions({ view, ownedBy, by, filter });

    handlerFetchReport(options);
  }, [by, getFetchOptions, handlerFetchReport, ownedBy, view]);

  return (
    <div className="card bg-transparent">
      <ReportFilterBar
        by={by}
        dateRange={dateRange}
        filteredListOfModels={filteredListOfModels}
        handleFilter={handleFilter}
        handleOnChangeBy={handleOnChangeBy}
        handleOnChangeOwnedBy={handleOnChangeOwnedBy}
        handleOnChangeView={handleOnChangeView}
        handleRefresh={handleRefresh}
        listBy={listBy}
        ownedBy={ownedBy}
        ownedByList={ownedByList}
        view={view}
      />
      <div className="card-body mb-5">
        <div className="row">
          <div className="col">
            <div className="panel panel-primary bg-white">
              <div className="panel-body tabs-menu-body px-5">
                <div className="d-flex flex-nowrap" style={reportContainerStyle}>
                  <LoadingSpinner isLoading={normalizedReport.isFetching}>
                    {ReportV2Component
                      ? <ReportV2Component data={normalizedReport.items} filter={filter} onFilterChange={onFilterChangeV2} />
                      : <ReportV1 graphType={graphType} Report={normalizedReport} view={view} />
                    }
                  </LoadingSpinner>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportElement;
