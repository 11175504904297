import { listingNewPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchListingCollections } from 'src/actions/Listings';
import { API_CALL } from 'src/middleware/API';

const ListingNew = lazy(() => import('src/components/views/listings/New'));

export default {
  path: listingNewPath(),
  exact: true,
  strict: true,
  component: ListingNew,
  perform: 'listings:new',
  title: 'New Listing',
  protected: true,
  prepareData: ({ store }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    if (Object.keys(state.ListingCollections.items).length > 0) return;
    const accessToken = state.Auth.token;
    store.dispatch(API_CALL, fetchListingCollections({ accessToken }));
  }
};
