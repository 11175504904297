import * as types from 'src/constants/actionTypes/ReportActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  items: {},
  success: false
};

export default store => {
  store.on('@init', () => ({ Report: initialState }));
  store.on(types.FETCH_REPORT_REQUEST, ({ Report }) => ({ Report: { ...initialState, isFetching: true } }));
  store.on(types.FETCH_REPORT_SUCCESS, ({ Report }, { response }) => {
    const { data, apiVersion, meta } = response;

    return ({ Report: { ...Report, items: apiVersion >= 'v2' ? { data, meta } : data, isFetching: false, success: true } });
  });
  store.on(types.FETCH_REPORT_ERROR, ({ Report }) => ({ Report: { ...Report, isFetching: false, error: true } }));
};
