export const FETCH_CONTACTS_REQUEST = 'FETCH_CONTACTS_REQUEST';
export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS';
export const FETCH_CONTACTS_ERROR = 'FETCH_CONTACTS_ERROR';

export const FETCH_CONTACT_REQUEST = 'FETCH_CONTACT_REQUEST';
export const FETCH_CONTACT_SUCCESS = 'FETCH_CONTACT_SUCCESS';
export const FETCH_CONTACT_ERROR = 'FETCH_CONTACT_ERROR';
export const FETCH_CONTACT_RESET = 'FETCH_CONTACT_RESET';

export const FETCH_CONTACT_COLLECTION_REQUEST = 'FETCH_CONTACT_COLLECTION_REQUEST';
export const FETCH_CONTACT_COLLECTION_SUCCESS = 'FETCH_CONTACT_COLLECTION_SUCCESS';
export const FETCH_CONTACT_COLLECTION_ERROR = 'FETCH_CONTACT_COLLECTION_ERROR';

export const DELETE_CONTACT_REQUEST = 'DELETE_CONTACT_REQUEST';
export const DELETE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_ERROR = 'DELETE_CONTACT_ERROR';

export const DELETE_CONTACTS_REQUEST = 'DELETE_CONTACTS_REQUEST';
export const DELETE_CONTACTS_SUCCESS = 'DELETE_CONTACTS_SUCCESS';
export const DELETE_CONTACTS_ERROR = 'DELETE_CONTACTS_ERROR';

export const SUBMIT_CONTACT_REQUEST = 'SUBMIT_CONTACT_REQUEST';
export const SUBMIT_CONTACT_SUCCESS = 'SUBMIT_CONTACT_SUCCESS';
export const SUBMIT_CONTACT_ERROR = 'SUBMIT_CONTACT_ERROR';
export const SUBMIT_CONTACT_RESET = 'SUBMIT_CONTACT_RESET';
