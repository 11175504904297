import React from 'react';
import UncontrolledCollapse from 'src/components/elements/shared/UncontrolledCollapse';
import Link from 'src/helpers/Link';
import { useTranslation } from 'react-i18next';
import {
  listingNewPath, listingPath, userPath, propertyListingNewPath
} from 'src/helpers/routes';
import ButtonDropdown from 'src/components/elements/shared/ButtonDropdown';

export default ({ items = [], agency = false, property = [], isNewListing }) => {
  const { t } = useTranslation();

  const handleNewListing = (event) => {
    event.preventDefault();
    window.open(listingNewPath(), "_blank");
  };

  const handleListing = (item) => (event) => {
    event.preventDefault();
    window.open(propertyListingNewPath(item.value), '_blank');
  };

  const buttons = isNewListing ?
    [{
      component: ButtonDropdown,
      props: {
        items: property,
        handlerOnClick: handleListing,
        label: t('buttons.new'),
        customClassName: "btn-sm",
        methodValue: item => item.label,
        methodKey: item => item.value
      }
    }]
    :
    [];

  return (
    <UncontrolledCollapse
      label={`${t("dashboard.subFolders.pendingListings")} (${items.length})`}
      buttons={agency === true ? [] : buttons}
      handlerOnClick={handleNewListing}
      withButton={agency && !isNewListing}
      buttonHeaderLabel={t('buttons.new')}
    >
      <div className="table-responsive">
        <table className="table card-table table-vcenter text-nowrap">
          <thead>
            <tr>
              <th>{t("listings.title")}</th>
              <th>{t("listings.typeOf")}</th>
              <th>{t("listings.propertyType")}</th>
              <th>{t("listings.bedrooms")}</th>
              <th>{t("listings.listedPrice")}</th>
              <th>{t("listings.owner")}</th>
            </tr>
          </thead>
          <tbody>
            {items.length > 0 && items.map((listing, index) =>
              <tr key={`listing-${index}`}>
                <td>
                  <span className="link-block">
                    <Link to={listingPath(listing.id)} className="mr-2" target="_blank">
                      {listing.title}
                    </Link>
                  </span>
                </td>
                <td>{listing.typeOf}</td>
                <td>{listing.propertyType}</td>
                <td>{listing.bedrooms}</td>
                <td>{listing.listedPrice}</td>
                <td>
                  <span className="link-block">
                    <Link to={userPath(listing.ownerId)} className="mr-2" target="_blank">
                      {listing.owner}
                    </Link>
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </UncontrolledCollapse>
  )
}
