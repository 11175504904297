import { adminSystemSettingEdit } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchSettings, fetchSettingsCollections } from 'src/actions/Settings';
import { API_CALL } from 'src/middleware/API';

const SettingsEdit = lazy(() => import('src/components/views/admin/settings/Edit'));

export default {
  path: adminSystemSettingEdit(),
  exact: true,
  strict: true,
  component: SettingsEdit,
  protected: true,
  perform: 'admin/settings:edit',
  title: 'Settings',
  prepareData: ({ store, params }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    if (Object.keys(state.CompanyCollections.items).length ===  0) {
      store.dispatch(API_CALL, fetchSettingsCollections({ accessToken }));
    }
    const query = { noTypeCast: true }
    store.dispatch(API_CALL, fetchSettings({ accessToken, query }));
  }
};
