import * as types from 'src/constants/actionTypes/ContactsActionTypes';
import { SUBMIT_RECENTLY_VIEWED } from 'src/constants/actionTypes/RecentlyViewedActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  contact: {}
}

export default store => {
  store.on('@init', () => ({ Contact: initialState }));

  store.on(types.FETCH_CONTACT_REQUEST, ({ Contact }) =>
    ({ Contact: { ...initialState, isFetching: true }}));
  store.on(types.FETCH_CONTACT_SUCCESS, ({ Contact }, { response }) => {
    store.dispatch(SUBMIT_RECENTLY_VIEWED, {
      data: { id: response.data.id, name: response.data.name, time: Date.now()},
      endpoint: 'contacts'
    });
    return ({ Contact: { ...Contact, contact: response.data }})
  });
  store.on(types.FETCH_CONTACT_ERROR, ({ Contact }) =>
    ({ Contact: {...Contact, error: true }}));
}
