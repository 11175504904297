import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import defaultLayout from 'src/components/views/layout';
import useStoreon from 'storeon/react';
import Access from './Access';
import { loginPath, rootPath, restrictedPagePath, listingNewPath, confirmPath, resetPasswordPath } from 'src/helpers/routes';
import history from 'src/helpers/history';

const RouteWithSubRoutes = (route) => {
  const { Auth } = useStoreon('Auth');
  const authWithSet = useState(Auth);
  const { connected, user } = useMemo(() => authWithSet[0], [authWithSet]);

  useEffect(() => {
    if (Auth.connected !== connected) {
      const path = Auth.connected ? rootPath : loginPath;
      history.push(path());
    }
  }, [Auth.connected, connected]);

  const swithByRoute = ({ route, props }) => {
    const Layout = route.layout ? route.layout : defaultLayout;

    if ((route.path === resetPasswordPath() && connected)) {
      return <Layout {...props}><route.component {...props} /></Layout>
    } else if (route.path === resetPasswordPath()) {
      return <Layout {...props}><route.component {...props} /></Layout>
    } else if (
      (route.path === confirmPath()) ||
      (route.path === rootPath() && !connected)
    ) {
      return <Redirect to={{ pathname: loginPath(), state: { from: props.location } }} />
    } else if ((route.path === loginPath() && connected)) {
      return <Redirect to={{ pathname: rootPath(), state: { from: props.location } }} />
    } else if (route === undefined) {
      return <Redirect to={{ pathname: loginPath(), state: { from: props.location } }} />
    } else if (route.path === listingNewPath() && !user.agency) {
      return <Redirect to={{ pathname: rootPath(), state: { from: props.location } }} />
    } else if (route.protected && !connected) {
      return <Redirect to={{ pathname: loginPath(), state: { from: props.location } }} />
    } else if (route.path === loginPath() && !connected) {
      return <Layout {...props}><route.component {...props} /></Layout>
    }

    return (
      <Access
        role={user.role}
        perform={route.perform}
        data={{ userId: user.id, teamIds: user.teamIds }}
        yes={() => <Layout {...props}><route.component {...props} /></Layout>}
        no={() => (
          Auth.connected
            ? <Redirect to={{ pathname: restrictedPagePath() }} />
            : <Redirect to={{ pathname: loginPath(), state: { from: props.location } }} />
        )}
      />
    );
  };

  return (
    <Route path={route.path} render={props => swithByRoute({ route, props })} />
  );
};

RouteWithSubRoutes.propTypes = {
  route: PropTypes.object
};

export default RouteWithSubRoutes;
