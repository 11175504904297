import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const VerticalCheckbox = ({ label, labelMessage = '', required = false, name, onChange, errors, value }) => {
  const { t } = useTranslation();
  const errorMessage = useMemo(() => errors?.join(', ') ?? t('form.provideValid', { label }), [errors, label, t]);
  return (
    <div className="form-group">
      <div className="form-label">{label}</div>
      <label className="custom-control custom-checkbox checkbox-bottom">
        <input
          type="checkbox"
          className="custom-control-input"
          name={name}
          placeholder={label}
          required={required}
          onChange={onChange}
          checked={value ?? false}
        />
        <span className="custom-control-label before-padding-custom"></span>
        <div className='invalid-feedback'>{errorMessage}</div>
      </label>
    </div>
  );
};

VerticalCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default VerticalCheckbox;
