import * as types from 'src/constants/actionTypes/PaymentsActionTypes';

const fetchPayments = ({ accessToken, query = {} }) => {
  return ({
    query,
    endpoint: 'payments',
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_PAYMENTS_REQUEST,
      types.FETCH_PAYMENTS_SUCCESS,
      types.FETCH_PAYMENTS_ERROR
    ]
  })
}

const fetchPayment = ({ accessToken, id, query = {} }) => {
  return ({
    query,
    endpoint: `payments/${id}`,
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_PAYMENT_REQUEST,
      types.FETCH_PAYMENT_SUCCESS,
      types.FETCH_PAYMENT_ERROR
    ]
  })
};

const updatePayment = ({ accessToken, query, id }) => {
  return ({
    query,
    endpoint: `payments/${id}`,
    method: 'PUT',
    credentials: true,
		accessToken,
    types: [
      types.SUBMIT_PAYMENT_REQUEST,
      types.SUBMIT_PAYMENT_SUCCESS,
      types.SUBMIT_PAYMENT_ERROR
    ]
  })
};

const deletePayments = ({ accessToken, query }) => {
  return ({
    query,
    endpoint: `payments`,
    method: 'DELETE',
    credentials: true,
		accessToken,
    types: [
      types.DELETE_PAYMENTS_REQUEST,
      types.DELETE_PAYMENTS_SUCCESS,
      types.DELETE_PAYMENTS_ERROR
    ]
  })
};

const deletePayment = ({ accessToken, id }) => {
  return ({
    endpoint: `payments/${id}`,
    method: 'DELETE',
    credentials: true,
		accessToken,
    types: [
      types.DELETE_PAYMENT_REQUEST,
      types.DELETE_PAYMENT_SUCCESS,
      types.DELETE_PAYMENT_ERROR
    ]
  })
};

const createPayment = ({ accessToken, query }) => {
  return ({
    query: query,
    endpoint: 'payments',
    method: 'POST',
    credentials: true,
		accessToken,
    types: [
      types.SUBMIT_PAYMENT_REQUEST,
      types.SUBMIT_PAYMENT_SUCCESS,
      types.SUBMIT_PAYMENT_ERROR
    ]
  })
};

const fetchPaymentCollections = ({ accessToken }) => {
  return ({
    endpoint: 'payments/helper',
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_PAYMENT_COLLECTION_REQUEST,
      types.FETCH_PAYMENT_COLLECTION_SUCCESS,
      types.FETCH_PAYMENT_COLLECTION_ERROR
    ]
  })
}

const changeStatus = ({ accessToken, query }) => {
  return ({
    query,
    endpoint: 'payments/change_status',
    method: 'POST',
    credentials: true,
    accessToken,
    types: [
      types.SUBMIT_PAYMENT_REQUEST,
      types.SUBMIT_PAYMENT_SUCCESS,
      types.SUBMIT_PAYMENT_ERROR
    ]
  })
}

export {
  fetchPayments, fetchPayment, fetchPaymentCollections, createPayment, deletePayments,
  deletePayment, updatePayment, changeStatus
};
