import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import HorizontalMenu from './HorizontalMenu';
import Footer from './Footer';

const Layout = ({ children, match, history }) => {

  const refBackToTop = React.createRef();

  const onClickBackOnTop = (event) => {
    event.preventDefault();
    document.body.animate([
      { scrollTop: 0 }
    ], { duration: 600 });
  };

  const handleScroll = useCallback(() => {
    const { current } = refBackToTop;
    if (current) {
      current.className = document.documentElement.scrollTop > 0 ? 'fade-in' : 'fade-out';
    }
  }, [refBackToTop]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const initClass = document.documentElement.scrollTop > 0 ? 'fade-in' : 'fade-out';

  return (
    <div className="horizontalMenucontainer">
      <div className="page">
        <div className="page-main">
          <Header />
          <HorizontalMenu match={match} />
          <div className="app-content page-body">
            <div className="container">
              {children}
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <a
        href="#top"
        className={initClass}
        id="back-to-top"
        style={{display: 'inline'}}
        onClick={onClickBackOnTop}
        ref={refBackToTop}
      >
        <i className="fa fa-angle-up"></i>
      </a>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  match: PropTypes.object.isRequired
};

export default Layout
