import * as types from 'src/constants/actionTypes/UsersActionTypes';

const initialState = {
  isResetting: false,
  error: false,
  successReset: false,
}

export default store => {
  store.on('@init', () => ({ UserResetPassword: initialState }));

  store.on(types.RESET_USER_PASSWORD_REQUEST, ({ UserResetPassword }) =>
    ({ UserResetPassword: { ...UserResetPassword, isResetting: true, successReset: false }}));
  store.on(types.RESET_USER_PASSWORD_SUCCESS, ({ UserResetPassword }) =>
    ({ UserResetPassword: {...UserResetPassword, successReset: true }}));
  store.on(types.RESET_USER_PASSWORD_ERROR, ({ UserResetPassword }) =>
    ({ UserResetPassword: {...UserResetPassword, error: true }}));
}
