import { activitiesNewPath } from 'src/helpers/routes';
import activity from 'src/routes/activity';
import { API_CALL } from 'src/middleware/API';
import { fetchEvents, fetchActivityCollections } from 'src/actions/Activities';
import { startStr, endStr } from 'src/helpers/activityDefaultDates';

const prepareData = ({ store, params }) => {
  const state = store.get();
  if (state.Auth.connected === false) return;
  const accessToken = state.Auth.token;

  if (Object.keys(state.ActivityCollections.items).length ===  0) {
    store.dispatch(API_CALL, fetchActivityCollections({ accessToken }));
  }
  if (Object.keys(state.Events.items).length ===  0) {
    const query = { startStr, endStr, noTypeCast: true };
    store.dispatch(API_CALL, fetchEvents({ accessToken, query }));
  }
}

export default Object.assign({}, activity, { path: activitiesNewPath(), prepareData });
