import { Module } from 'storeon';
import { Events, State } from 'src/store';
import { FETCH_CONTACT_ENUMS, FETCH_CONTACT_ENUMS_SUCCESS, FETCH_CONTACT_ENUMS_ERROR } from 'src/constants/actionTypes/enums/contact';
import { onInit, onFetch, onFetchSuccess, onFetchError } from 'src/-utils/enums';

export const contactEnumModule: Module<State, Events> = store => {
  store.on('@init', (state) => onInit(state, 'contact'));

  store.on(FETCH_CONTACT_ENUMS, (state) => onFetch(state, 'contact'));
  store.on(FETCH_CONTACT_ENUMS_SUCCESS, (state, { response }) => onFetchSuccess(state, 'contact', response));
  store.on(FETCH_CONTACT_ENUMS_ERROR, (state, { error }) => onFetchError(state, 'contact', error));
};

export default contactEnumModule;
