import React, { useEffect } from 'react';
import { parse } from 'qs';
import FormHooks from 'src/helpers/formHooks';
import { useTranslation } from 'react-i18next';
import useStoreon from 'src/helpers/storeon';
import { changePassword as actionChangePassword } from 'src/actions/Auth';
import { API_CALL } from 'src/middleware/API';
import { loginPath } from 'src/helpers/routes';
import { hasString } from 'src/helpers/string';
import { pick } from 'lodash';

export default ({ history, location }) => {
  const { dispatch, changePassword } = useStoreon('changePassword');
  const { t, i18n } = useTranslation();
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });;
  const submit = () => {
    if (!hasString(inputs.password) || !hasString(inputs.passwordConfirmation)) {
      return;
    }

    const query = Object.assign(pick(inputs, 'password', 'passwordConfirmation'), { locale: i18n.language });
    const accessToken = pick(queryParams, 'access-token', 'client', 'uid');

    dispatch(API_CALL, actionChangePassword({ query, accessToken }));
  };
  const { inputs, handleInputChange, handleSubmit } = FormHooks({ password: '', passwordConfirmation: '' }, submit);

  useEffect(() => {
    if (changePassword && changePassword.success) {
      history.push(loginPath());
    }
  }, [changePassword, history]);

  return (
    <>
      <div className="card flex-grow-1 p-4">
        <div className="card-body">
          <h1>{t("sign.resetPassword")}</h1>
          <form onSubmit={handleSubmit}>
            <div className="input-group mb-4">
              <span className="input-group-addon"><i className="fa fa-lock"></i></span>
              <input
                type="password"
                name="password"
                placeholder="Password"
                className="form-control"
                value={inputs.password}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group mb-4">
              <span className="input-group-addon"><i className="fa fa-lock"></i></span>
              <input
                type="password"
                name="passwordConfirmation"
                placeholder="Password Confirmation"
                className="form-control"
                value={inputs.passwordConfirmation}
                onChange={handleInputChange}
              />
            </div>
            <div className="row">
              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  disabled={!hasString(inputs.password) || !hasString(inputs.passwordConfirmation)}
                >
                  {t("sign.reset")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="card text-white bg-primary d-md-down-none flex-grow-1 p-4">
        <div className="card-body text-justify">
          <p>{t('resetPassword.introParagraph1', { email: queryParams.uid })}</p>
          <p>{t('resetPassword.introParagraph2')}</p>
          <ul>
            <li>{t('general.phone')}: <a href="tel:+2302701377">+230 270 1377</a></li>
            <li>{t('general.mobile')}: <a href="tel:+23058069294">+230 5806 9294</a></li>
            <li>{t('general.whatsapp')}: <a href="https://wa.me/23058069294">+230 5806 9294</a></li>
            <li>{t('general.email')}: <a href="mailto:info@propertynexus.co">info@propertynexus.co</a></li>
          </ul>
        </div>
      </div>
    </>
  )
}
