import * as types from 'src/constants/actionTypes/AuthActionTypes';

const signInUser = ({ email, password, name, company, countryCode }) => {
  const bearer = btoa(`${email}:${password}`);
  const query = { name, company, countryCode };

  return ({
    query,
    bearer,
    endpoint: 'auth/sign_in',
    method: 'POST',
    credentials: true,
    types: [
      types.SIGN_IN_REQUEST,
      types.SIGN_IN_SUCCESS,
      types.SIGN_IN_ERROR
    ]
  });
}

const signOutUser = (accessToken) => {
  return ({
    endpoint: 'auth/sign_out',
    method: 'DELETE',
    credentials: true,
    accessToken,
    types: [
      types.SIGN_OUT_REQUEST,
      types.SIGN_OUT_SUCCESS,
      types.SIGN_OUT_ERROR
    ]
  })
}

const verifyToken = ({ accessToken, locale }) => {
  return ({
    query: { locale: locale },
    endpoint: 'auth/validate_token',
    method: 'GET',
    accessToken,
    credentials: true,
    types: [
      types.VERIFY_TOKEN_REQUEST,
      types.VERIFY_TOKEN_SUCCESS,
      types.VERIFY_TOKEN_ERROR
    ]
  });
}

const forgotPassword = ({ query = {} }) => {
  return ({
    query,
    endpoint: `auth/password`,
    method: 'POST',
    types: [
      types.FORGOT_PASSWORD_REQUEST,
      types.FORGOT_PASSWORD_SUCCESS,
      types.FORGOT_PASSWORD_ERROR
    ]
  })
}

const changePassword = ({ query = {}, accessToken }) => {
  return ({
    query,
    endpoint: `auth/password`,
    method: 'PUT',
    accessToken,
    credentials: true,
    types: [
      types.CHANGE_PASSWORD_REQUEST,
      types.CHANGE_PASSWORD_SUCCESS,
      types.CHANGE_PASSWORD_ERROR
    ]
  })
}

export {
  signInUser, signOutUser, verifyToken, forgotPassword, changePassword
}
