import * as types from 'src/constants/actionTypes/ContactsActionTypes';

const fetchContacts = ({ accessToken, query = {} }) => {
  return ({
    query,
    endpoint: 'contacts',
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_CONTACTS_REQUEST,
      types.FETCH_CONTACTS_SUCCESS,
      types.FETCH_CONTACTS_ERROR
    ]
  })
}

const fetchContact = ({ accessToken, id, query = {} }) => {
  return ({
    query,
    endpoint: `contacts/${id}`,
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_CONTACT_REQUEST,
      types.FETCH_CONTACT_SUCCESS,
      types.FETCH_CONTACT_ERROR
    ]
  })
};

const updateContact = ({ accessToken, query, id }) => {
  return ({
    query,
    endpoint: `contacts/${id}`,
    method: 'PUT',
    credentials: true,
		accessToken,
    types: [
      types.SUBMIT_CONTACT_REQUEST,
      types.SUBMIT_CONTACT_SUCCESS,
      types.SUBMIT_CONTACT_ERROR
    ]
  })
};

const deleteContacts = ({ accessToken, query }) => {
  return ({
    query,
    endpoint: `contacts`,
    method: 'DELETE',
    credentials: true,
		accessToken,
    types: [
      types.DELETE_CONTACTS_REQUEST,
      types.DELETE_CONTACTS_SUCCESS,
      types.DELETE_CONTACTS_ERROR
    ]
  })
};

const deleteContact = ({ accessToken, id }) => {
  return ({
    endpoint: `contacts/${id}`,
    method: 'DELETE',
    credentials: true,
		accessToken,
    types: [
      types.DELETE_CONTACT_REQUEST,
      types.DELETE_CONTACT_SUCCESS,
      types.DELETE_CONTACT_ERROR
    ]
  })
};

const createContact = ({ accessToken, query }) => {
  return ({
    query: query,
    endpoint: 'contacts',
    method: 'POST',
    credentials: true,
		accessToken,
    types: [
      types.SUBMIT_CONTACT_REQUEST,
      types.SUBMIT_CONTACT_SUCCESS,
      types.SUBMIT_CONTACT_ERROR
    ]
  })
};

const fetchContactCollections = ({ accessToken, query = {} }) => {
  return ({
    query,
    endpoint: 'contacts/helper',
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_CONTACT_COLLECTION_REQUEST,
      types.FETCH_CONTACT_COLLECTION_SUCCESS,
      types.FETCH_CONTACT_COLLECTION_ERROR
    ]
  })
}

export {
  fetchContacts, fetchContact, fetchContactCollections, createContact, deleteContacts,
  deleteContact, updateContact
};
