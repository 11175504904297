export const SUBMIT_UPLOAD_REQUEST = 'SUBMIT_UPLOAD_REQUEST';
export const SUBMIT_UPLOAD_SUCCESS = 'SUBMIT_UPLOAD_SUCCESS';
export const SUBMIT_UPLOAD_ERROR = 'SUBMIT_UPLOAD_ERROR';
export const SUBMIT_UPLOAD_RESET = 'SUBMIT_UPLOAD_RESET';
export const SUBMIT_UPLOAD_UPLOAD_PROGRESS = 'SUBMIT_UPLOAD_UPLOAD_PROGRESS';

export const SUBMIT_UPDATE_META_REQUEST = 'SUBMIT_UPDATE_META_REQUEST';
export const SUBMIT_UPDATE_META_SUCCESS = 'SUBMIT_UPDATE_META_SUCCESS';
export const SUBMIT_UPDATE_META_ERROR = 'SUBMIT_UPDATE_META_ERROR';
export const SUBMIT_UPDATE_META_RESET = 'SUBMIT_UPDATE_META_RESET';

export const SUBMIT_VIDEO_LINK_REQUEST = 'SUBMIT_VIDEO_LINK_REQUEST';
export const SUBMIT_VIDEO_LINK_SUCCESS = 'SUBMIT_VIDEO_LINK_SUCCESS';
export const SUBMIT_VIDEO_LINK_ERROR = 'SUBMIT_VIDEO_LINK_ERROR';
export const SUBMIT_VIDEO_LINK_RESET = 'SUBMIT_VIDEO_LINK_RESET';

export const SUBMIT_SEND_BROCHURE_REQUEST = 'SUBMIT_SEND_BROCHURE_REQUEST';
export const SUBMIT_SEND_BROCHURE_SUCCESS = 'SUBMIT_SEND_BROCHURE_SUCCESS';
export const SUBMIT_SEND_BROCHURE_ERROR = 'SUBMIT_SEND_BROCHURE_ERROR';

export const SUBMIT_SEND_BROCHURES_REQUEST = 'SUBMIT_SEND_BROCHURES_REQUEST';
export const SUBMIT_SEND_BROCHURES_SUCCESS = 'SUBMIT_SEND_BROCHURES_SUCCESS';
export const SUBMIT_SEND_BROCHURES_ERROR = 'SUBMIT_SEND_BROCHURES_ERROR';

export const DOWNLOAD_BROCHURE_REQUEST = 'DOWNLOAD_BROCHURE_REQUEST';
export const DOWNLOAD_BROCHURE_SUCCESS = 'DOWNLOAD_BROCHURE_SUCCESS';
export const DOWNLOAD_BROCHURE_ERROR = 'DOWNLOAD_BROCHURE_ERROR';

export const FETCH_META_REQUEST = 'FETCH_META_REQUEST';
export const FETCH_META_SUCCESS = 'FETCH_META_SUCCESS';
export const FETCH_META_ERROR = 'FETCH_META_ERROR';

export const EXPORT_DATA_REQUEST = 'EXPORT_DATA_REQUEST';
export const EXPORT_DATA_SUCCESS = 'EXPORT_DATA_SUCCESS';
export const EXPORT_DATA_ERROR = 'EXPORT_DATA_ERROR';
