import { transactionEditPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchTransactionCollections, fetchTransaction } from 'src/actions/Transactions';
import { API_CALL } from 'src/middleware/API';

const TransactionEdit = lazy(() => import('src/components/views/transactions/Edit'));

export default {
  path: transactionEditPath(),
  exact: true,
  strict: true,
  component: TransactionEdit,
  perform: 'transactions:update',
  title: 'Edit Transaction',
  protected: true,
  prepareData: ({ store, params }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    if (Object.keys(state.TransactionCollections.items).length ===  0) {
      store.dispatch(API_CALL, fetchTransactionCollections({ accessToken }));
    }
    const query = { noTypeCast: true }
    store.dispatch(API_CALL, fetchTransaction({ accessToken, id: params.id, query }));
  }
};
