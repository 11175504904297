import { map, Dictionary, NumericDictionary } from 'lodash';

export interface DropdownSourceElement {
  value: string;
  label: string;
}

export type DropdownSource = DropdownSourceElement[];

export function toDropdownSource<T>(collection?: Dictionary<T> | NumericDictionary<T> | T[] | null, iteratee?: (element: T) => DropdownSourceElement): DropdownSource {
  return map(collection, iteratee) as unknown as DropdownSource;
}
