import * as types from 'src/constants/actionTypes/PropertiesActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  items: {},
  loaded: false
};

export default store => {
  store.on('@init', () => ({ PropertyCollections: initialState }));

  store.on(types.FETCH_PROPERTY_COLLECTION_REQUEST, ({ PropertyCollections }) =>
    ({ PropertyCollections: { ...PropertyCollections, isFetching: true, loaded: false }}));
  store.on(types.FETCH_PROPERTY_COLLECTION_SUCCESS, ({ PropertyCollections }, { response }) =>
    ({ PropertyCollections: { ...PropertyCollections, items: response.data, loaded: true }}));
  store.on(types.FETCH_PROPERTY_COLLECTION_ERROR, ({ PropertyCollections }) =>
    ({ PropertyCollections: {...PropertyCollections, error: true }}));
};
