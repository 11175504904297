export const FETCH_SETTINGS_REQUEST = 'FETCH_SETTINGS_REQUEST';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_ERROR = 'FETCH_SETTINGS_ERROR';

export const SUBMIT_SETTINGS_REQUEST = 'SUBMIT_SETTINGS_REQUEST';
export const SUBMIT_SETTINGS_SUCCESS = 'SUBMIT_SETTINGS_SUCCESS';
export const SUBMIT_SETTINGS_ERROR = 'SUBMIT_SETTINGS_ERROR';

export const SUBMIT_SETTINGS_RESET = 'SUBMIT_SETTINGS_RESET';

export const FETCH_SETTINGS_COLLECTION_REQUEST = 'FETCH_SETTINGS_COLLECTION_REQUEST';
export const FETCH_SETTINGS_COLLECTION_SUCCESS = 'FETCH_SETTINGS_COLLECTION_SUCCESS';
export const FETCH_SETTINGS_COLLECTION_ERROR = 'FETCH_SETTINGS_COLLECTION_ERROR';
