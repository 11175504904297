import { BaseLookup, StateLookup } from 'src/-types/lookups';

export interface FeaturesLookupResponse {
  useDemoLogin: boolean;
}

export type FeaturesStateLookup = StateLookup<FeaturesLookupResponse>;

export class FeaturesLookup extends BaseLookup<FeaturesStateLookup> {
  protected defaultData: FeaturesLookupResponse = {
    useDemoLogin: false
  };

  public constructor(state: FeaturesStateLookup) {
    super(state);
  }
}
