import React, { ChangeEvent, useCallback, useState, useMemo } from 'react';
import { DatatableFilterOnChangeFunc } from 'src/-utils/datatable';
import VerticalInput from 'src/components/elements/form/VerticalInput';
import { hasString } from 'src/helpers/string';
import { debounce, noop } from 'lodash';

export interface FilterByTextProps {
  label: string;
  dataField: string;
  onChange: DatatableFilterOnChangeFunc;
}

export const FilterByText: React.FunctionComponent<FilterByTextProps> = ({ onChange = noop, label, dataField }) => {
  const [inputValue, setInputValue] = useState('');
  const debouncedOnChange = useMemo(() => debounce(onChange, 250), [onChange]);
  const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setInputValue(event.target.value);
    debouncedOnChange({ currFilters: { [dataField]: hasString(event.target.value) ? { name: dataField, value: event.target.value, type: 'ilike' } : undefined } });
  }, [dataField, debouncedOnChange]);

  return (
    <div className="ml-2">
      <VerticalInput
        value={inputValue}
        onChange={onInputChange}
        placeholder={label}
        name={dataField}
        className={'form-control-sm form-control form-control-sm-custom'}
        mustUseFormGroup={false}
      />
    </div>
  )
}

export default FilterByText;
