import * as types from 'src/constants/actionTypes/TeamsActionTypes';

const fetchTeams = ({ accessToken, query = {} }) => {
  return ({
    query,
    endpoint: 'teams',
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_TEAMS_REQUEST,
      types.FETCH_TEAMS_SUCCESS,
      types.FETCH_TEAMS_ERROR
    ]
  })
}

const fetchTeam = ({ accessToken, id, query = {} }) => {
  return ({
    query,
    endpoint: `teams/${id}`,
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_TEAM_REQUEST,
      types.FETCH_TEAM_SUCCESS,
      types.FETCH_TEAM_ERROR
    ]
  })
};

const fetchEditTeam = ({ accessToken, id, query = {} }) => {
  return ({
    query,
    endpoint: `teams/${id}/edit`,
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_TEAM_REQUEST,
      types.FETCH_TEAM_SUCCESS,
      types.FETCH_TEAM_ERROR
    ]
  })
};

const updateTeam = ({ accessToken, query, id, attach }) => {
  return ({
    query,
    attach,
    endpoint: `teams/${id}`,
    method: 'PUT',
    credentials: true,
		accessToken,
    types: [
      types.SUBMIT_TEAM_REQUEST,
      types.SUBMIT_TEAM_SUCCESS,
      types.SUBMIT_TEAM_ERROR
    ]
  })
};

const deleteTeams = ({ accessToken, query }) => {
  return ({
    query,
    endpoint: `teams`,
    method: 'DELETE',
    credentials: true,
		accessToken,
    types: [
      types.DELETE_TEAMS_REQUEST,
      types.DELETE_TEAMS_SUCCESS,
      types.DELETE_TEAMS_ERROR
    ]
  })
};

const deleteTeam = ({ accessToken, id }) => {
  return ({
    endpoint: `teams/${id}`,
    method: 'DELETE',
    credentials: true,
		accessToken,
    types: [
      types.DELETE_TEAM_REQUEST,
      types.DELETE_TEAM_SUCCESS,
      types.DELETE_TEAM_ERROR
    ]
  })
};

const createTeam = ({ accessToken, query, attach }) => {
  return ({
    query,
    attach,
    endpoint: 'teams',
    method: 'POST',
    credentials: true,
		accessToken,
    types: [
      types.SUBMIT_TEAM_REQUEST,
      types.SUBMIT_TEAM_SUCCESS,
      types.SUBMIT_TEAM_ERROR
    ]
  })
};

const fetchTeamCollections = ({ accessToken }) => {
  return ({
    endpoint: 'teams/helper',
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_TEAM_COLLECTION_REQUEST,
      types.FETCH_TEAM_COLLECTION_SUCCESS,
      types.FETCH_TEAM_COLLECTION_ERROR
    ]
  })
}

export {
  fetchTeams, deleteTeams, fetchTeam, updateTeam, fetchTeamCollections, createTeam, deleteTeam,
  fetchEditTeam
};
