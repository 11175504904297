export const FETCH_LEADS_REQUEST = 'FETCH_LEADS_REQUEST';
export const FETCH_LEADS_SUCCESS = 'FETCH_LEADS_SUCCESS';
export const FETCH_LEADS_ERROR = 'FETCH_LEADS_ERROR';

export const FETCH_LEAD_REQUEST = 'FETCH_LEAD_REQUEST';
export const FETCH_LEAD_SUCCESS = 'FETCH_LEAD_SUCCESS';
export const FETCH_LEAD_ERROR = 'FETCH_LEAD_ERROR';
export const FETCH_LEAD_RESET = 'FETCH_LEAD_RESET';

export const FETCH_LEAD_COLLECTION_REQUEST = 'FETCH_LEAD_COLLECTION_REQUEST';
export const FETCH_LEAD_COLLECTION_SUCCESS = 'FETCH_LEAD_COLLECTION_SUCCESS';
export const FETCH_LEAD_COLLECTION_ERROR = 'FETCH_LEAD_COLLECTION_ERROR';

export const DELETE_LEAD_REQUEST = 'DELETE_LEAD_REQUEST';
export const DELETE_LEAD_SUCCESS = 'CREATE_LEAD_SUCCESS';
export const DELETE_LEAD_ERROR = 'DELETE_LEAD_ERROR';

export const DELETE_LEADS_REQUEST = 'DELETE_LEADS_REQUEST';
export const DELETE_LEADS_SUCCESS = 'DELETE_LEADS_SUCCESS';
export const DELETE_LEADS_ERROR = 'DELETE_LEADS_ERROR';

export const SUBMIT_LEAD_REQUEST = 'SUBMIT_LEAD_REQUEST';
export const SUBMIT_LEAD_SUCCESS = 'SUBMIT_LEAD_SUCCESS';
export const SUBMIT_LEAD_ERROR = 'SUBMIT_LEAD_ERROR';
export const SUBMIT_LEAD_RESET = 'SUBMIT_LEAD_RESET';
