import * as types from 'src/constants/actionTypes/ProfilesActionTypes';

const fetchProfile = ({ accessToken, id, query = {} }) => {
  return ({
    query,
    endpoint: `profiles/${id}`,
    method: 'GET',
    credentials: true,
		accessToken,
    types: [
      types.FETCH_PROFILE_REQUEST,
      types.FETCH_PROFILE_SUCCESS,
      types.FETCH_PROFILE_ERROR
    ]
  })
};

const updateProfile = ({ accessToken, query, id, attach }) => {
  return ({
    query,
    attach,
    endpoint: `profiles/${id}`,
    method: 'PUT',
    credentials: true,
		accessToken,
    types: [
      types.SUBMIT_PROFILE_REQUEST,
      types.SUBMIT_PROFILE_SUCCESS,
      types.SUBMIT_PROFILE_ERROR
    ]
  })
};

export { updateProfile, fetchProfile }
