import * as types from 'src/constants/actionTypes/DataTableActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  items: {}
}

export default store => {
  store.on('@init', () => ({ DataTable: initialState }));

  store.on(types.FETCH_DATATABLE_REQUEST, ({ DataTable }) =>
    ({ DataTable: { ...initialState, isFetching: true }}));
  store.on(types.FETCH_DATATABLE_SUCCESS, ({ DataTable }, { response }) => {
    const { data, recordsFiltered, recordsTotal } = response;
    const items = Object.assign(
      {},
      DataTable.items,
      { [response.endpoint]: { data, recordsFiltered, recordsTotal }
    })
    return ({ DataTable: { ...DataTable, items }})
  });
  store.on(types.FETCH_DATATABLE_ERROR, ({ DataTable }) =>
    ({ DataTable: {...DataTable, error: true }}));
}
