import { activitiesPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { API_CALL } from 'src/middleware/API';
import { fetchEvents } from 'src/actions/Activities';
import Layout from 'src/components/views/calendarLayout'
import { startStr, endStr } from 'src/helpers/activityDefaultDates';
import history from 'src/helpers/history';

const Activity = lazy(() => import('src/components/views/activity'));

export default {
  path: activitiesPath(),
  exact: true,
  strict: true,
  component: Activity,
  layout: Layout,
  protected: true,
  perform: "activity:index",
  prepareData: ({ store }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    const query = { startStr, endStr };
    if (history.location.state && history.location.state.renderRange) {
      const { start, end } = history.location.state.renderRange;
      query.startStr = start.toISOString();
      query.endStr = end.toISOString();
    }
    store.dispatch(API_CALL, fetchEvents({ accessToken, query }));
  }
};
