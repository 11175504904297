import * as types from 'src/constants/actionTypes/PaymentsActionTypes';

const initialState = {
  isDestroing: false,
  error: false,
  successDestroy: false,
}

export default store => {
  store.on('@init', () => ({ PaymentDestroy: initialState }));

  store.on(types.DELETE_PAYMENTS_REQUEST, ({ PaymentDestroy }) =>
    ({ PaymentDestroy: { ...PaymentDestroy, isDestroing: true, successDestroy: false }}));
  store.on(types.DELETE_PAYMENTS_SUCCESS, ({ PaymentDestroy }) =>
    ({ PaymentDestroy: {...PaymentDestroy, successDestroy: true }}));
  store.on(types.DELETE_PAYMENTS_ERROR, ({ PaymentDestroy }) =>
    ({ PaymentDestroy: {...PaymentDestroy, error: true }}));

  store.on(types.DELETE_PAYMENT_REQUEST, ({ PaymentDestroy }) =>
    ({ PaymentDestroy: { ...PaymentDestroy, isDestroing: true, successDestroy: false }}));
  store.on(types.DELETE_PAYMENT_SUCCESS, ({ PaymentDestroy }) =>
    ({ PaymentDestroy: {...PaymentDestroy, successDestroy: true }}));
  store.on(types.DELETE_PAYMENT_ERROR, ({ PaymentDestroy }) =>
    ({ PaymentDestroy: {...PaymentDestroy, error: true }}));
}
