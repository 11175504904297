import { useState, useEffect, useMemo } from 'react';
import useStoreon from 'storeon/react';
import { API_CALL } from 'src/middleware/API';

export type AsyncDispatchFunc = (data: any) => Promise<any>;

const getAsyncDispatchFunc = (dispatch: any): AsyncDispatchFunc => (data: any) => new Promise((thenFn, catchFn) => {
  dispatch(API_CALL, { ...data, thenFn, catchFn });
});

export function useAsyncDispatch() {
  const { dispatch } = useStoreon();
  const dispatchAsync = useMemo(() => getAsyncDispatchFunc(dispatch), [dispatch]);
  const [result, setResult] = useState<{ dispatch: any, dispatchAsync: AsyncDispatchFunc }>({ dispatch, dispatchAsync });

  useEffect(() => {
    if (dispatch !== result.dispatch || dispatchAsync !== result.dispatchAsync) {
      setResult({ dispatch, dispatchAsync });
    }
  }, [dispatch, dispatchAsync, result.dispatch, result.dispatchAsync]);

  return result;
}
