import filterByDateRange from './filterByDateRange';
import filterByRole, { optionsByRole } from './filterByRole';
import filterByView from './filterByView';

const endpoint = "offers";

const defaultView = [
  { data: "id", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true },
  { data: "idValue", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link show-link", path: 'offerPath', pathKey: 'id' },
  { data: "offerType", searchable: true, orderable: false, search: { value: "", regex: "" }},
  { data: "potentialBuyer", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link buyer-link", path: 'contactPath', pathKey: 'potentialBuyerId' },
  { data: "listing", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link listing-link", path: 'listingPath', pathKey: 'listingId' },
  { data: "seller", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link seller-link", path: 'contactPath', pathKey: 'sellerId'},
  { data: "offeredAmount", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "status", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "offerDate", searchable: true, orderable: false, search: { value: "", regex: "" }},
  { data: "potentialBuyerId", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true },
  { data: "listingId", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true },
  { data: "sellerId", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true },
];

const activeOffersView = [
  { data: "id", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true },
  { data: "idValue", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link show-link", path: 'offerPath', pathKey: 'id' },
  { data: "offerType", searchable: true, orderable: false, search: { value: "", regex: "" }},
  { data: "potentialBuyer", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link buyer-link", path: 'contactPath', pathKey: 'potentialBuyerId'  },
  { data: "listing", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link listing-link", path: 'listingPath', pathKey: 'listingId' },
  { data: "seller", searchable: true, orderable: false, search: { value: "", regex: "" },
    classes: "table-link seller-link", path: 'contactPath', pathKey: 'sellerId' },
  { data: "offeredAmount", searchable: true, orderable: false, search: { value: "", regex: "" } },
  { data: "potentialBuyerId", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true },
  { data: "listingId", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true },
  { data: "sellerId", searchable: true, orderable: false, search: { value: "", regex: "" }, hidden: true },
];

const fieldsByView = (view) => {
  const fields = {
    "recentlyViewed": defaultView,
    "offers": defaultView,
    "activeOffers": activeOffersView,
    "acceptedOffers": activeOffersView
  };
  return fields[view];
};

export const optionsForFilterByView = {
  "offers": {},
  "activeOffers": { name: "offers.status", value: "active||countered", type: "or" },
  "acceptedOffers": { name: "offers.status", value: "accepted", type: "eq"}
};

export const defaultFilter = ({ role, ids }) => {
  const filter = {};
  const byRole = optionsByRole({ role, endpoint });
  if (!!byRole.length) filter.role = { value: byRole[0], type: "role" };
  if (!!ids.length) filter.view = { name: "id", type: "eq", value: ids };
  return filter;
};

export const columnFilters = [
  { filter: filterByDateRange, dataField: "createdAt" }
];

export const outsideFilters = [
  { component: filterByView, endpoint, options: optionsForFilterByView },
  { component: filterByRole }
];

export default ({ view = "offers", items = [] }) => {
  const columns = fieldsByView(view);
  const query = {
    columns,
    order: [{ column: 0, dir: "asc" }],
    start: 0,
    length: 10,
    endpoint
  };
  return { query, endpoint, columns };
}
