import * as types from 'src/constants/actionTypes/ListingsActionTypes';
import { SUBMIT_RECENTLY_VIEWED } from 'src/constants/actionTypes/RecentlyViewedActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  listing: {}
}

export default store => {
  store.on('@init', () => ({ Listing: initialState }));

  store.on(types.FETCH_LISTING_REQUEST, ({ Listing }) =>
    ({ Listing: { ...initialState, isFetching: true }}));
  store.on(types.FETCH_LISTING_SUCCESS, ({ Listing }, { response }) => {
    store.dispatch(SUBMIT_RECENTLY_VIEWED, {
      data: { id: response.data.id, name: response.data.name, time: Date.now()},
      endpoint: 'listings'
    });
    return ({ Listing: { ...Listing, listing: response.data }})
  });
  store.on(types.FETCH_LISTING_ERROR, ({ Listing }) =>
    ({ Listing: {...Listing, error: true }}));
}
