import { ModelType } from 'src/-types/model';
import { StateLookup, BaseLookup } from 'src/-types/lookups';

export interface Dependent {
  rule: 'dependent';
  source: string;
  config: {
    [key: string]: string[];
  };
}

export type FieldsLookupValue = Dependent;

export type FieldsLookupModel = {
  [key: string]: FieldsLookupValue[];
};

export type FieldsLookupResponse = {
  [P in ModelType]?: FieldsLookupModel;
};

export type FieldsStateLookup = StateLookup<FieldsLookupResponse>;

export class FieldsLookup extends BaseLookup<FieldsStateLookup> {
  protected defaultData: FieldsLookupResponse = {};

  public constructor(state: FieldsStateLookup) {
    super(state);
  }
}
