import { lazy } from 'react';
import { API_CALL } from 'src/middleware/API';
import { Route } from 'src/-types/routing';
import { fetchSnag } from 'src/actions/entities/snag';
import { snagPath } from 'src/helpers/routes';

const SnagShow = lazy(() => import('src/components/views/snags/SnagShow'));

export interface SnagShowRouteParams { id: string; }

export const snagShowRoute: Route<SnagShowRouteParams> = {
  path: snagPath(),
  exact: true,
  strict: true,
  component: SnagShow,
  perform: 'snags:show',
  protected: true,
  prepareData: ({ store, params }) => {
    const { Auth: { connected } } = store.get();

    if (connected !== true) {
      return;
    }

    store.dispatch(API_CALL, fetchSnag({ id: params!.id, query: { include: 'createdBy,owner,project,property,updatedBy' } }));
  }
};

export default snagShowRoute;
