import * as types from 'src/constants/actionTypes/ActivitiesActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  items: []
}

export default store => {
  store.on('@init', () => ({ Activities: initialState }));

  store.on(types.FETCH_ACTIVITIES_REQUEST, ({ Activities }) =>
    ({ Activities: { ...Activities, isFetching: true }}));
  store.on(types.FETCH_ACTIVITIES_SUCCESS, ({ Activities }, { response }) =>
    ({ Activities: { ...Activities, items: response.data }}));
  store.on(types.FETCH_ACTIVITIES_ERROR, ({ Activities }) =>
    ({ Activities: {...Activities, error: true }}));
}
