import * as types from 'src/constants/actionTypes/TeamsActionTypes';

const initialState = {
  isFetching: false,
  error: false,
  items: []
}

export default store => {
  store.on('@init', () => ({ Teams: initialState }));

  store.on(types.FETCH_TEAMS_REQUEST, ({ Teams }) =>
    ({ Teams: { ...Teams, isFetching: true }}));
  store.on(types.FETCH_TEAMS_SUCCESS, ({ Teams }, { response }) =>
    ({ Teams: { ...Teams, items: response.data }}));
  store.on(types.FETCH_TEAMS_ERROR, ({ Teams }) =>
    ({ Teams: {...Teams, error: true }}));
}
