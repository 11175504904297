import React from 'react';
import UncontrolledCollapse from 'src/components/elements/shared/UncontrolledCollapse';
import Link from 'src/helpers/Link';
import { useTranslation } from 'react-i18next';
import { paymentPath, transactionPath, userPath } from 'src/helpers/routes';

const pad = (a, b) => ([1e15]+a).slice(-b);

export default ({ items = [] }) => {
  const { t } = useTranslation();

  return (
    <UncontrolledCollapse
      label={`${t("dashboard.subFolders.duePayments")} (${items.length})`}
    >
      <div className="table-responsive">
        <table className="table card-table table-vcenter text-nowrap">
          <thead>
            <tr>
              <th>{t("payments.id")}</th>
              <th>{t("payments.typeOf")}</th>
              <th>{t("payments.transaction")}</th>
              <th>{t("payments.amountDue")}</th>
              <th>{t("payments.paymentDueDate")}</th>
              <th>{t("payments.owner")}</th>
            </tr>
          </thead>
          <tbody>
            {items.length > 0 && items.map((payment, index) =>
              <tr key={`payment-${index}`}>
                <td>
                  <span className="link-block">
                    <Link to={paymentPath(payment.id)} className="mr-2" target="_blank">
                      {`PM-${pad(payment.id, 7)}`}
                    </Link>
                  </span>
                </td>
                <td>{payment.typeOf}</td>
                <td>
                  <span className="link-block">
                    <Link to={transactionPath(payment.transactionId)} className="mr-2" target="_blank">
                      {payment.transaction}
                    </Link>
                  </span>
                </td>
                <td>{payment.amountDue}</td>
                <td>{payment.paymentDueDate}</td>
                <td>
                  <span className="link-block">
                    <Link to={userPath(payment.ownerId)} className="mr-2" target="_blank">
                      {payment.owner}
                    </Link>
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </UncontrolledCollapse>
  )
}
