import { offerPath } from 'src/helpers/routes';
import { lazy } from 'react';
import { fetchOffer, fetchOfferCollections } from 'src/actions/Offers';
import { API_CALL } from 'src/middleware/API';
import { fetchActivityCollections } from 'src/actions/Activities';
import Layout from 'src/components/views/showLayout';

const OfferShow = lazy(() => import('src/components/views/offers/Show'));

export default {
  path: offerPath(),
  exact: true,
  strict: true,
  component: OfferShow,
  perform: "offers:show",
  layout: Layout,
  protected: true,
  prepareData: ({ store, query, params }) => {
    const state = store.get();
    if (state.Auth.connected === false) return;
    const accessToken = state.Auth.token;
    if (Object.keys(state.ActivityCollections.items).length ===  0) {
      store.dispatch(API_CALL, fetchActivityCollections({ accessToken }));
    }
    store.dispatch(API_CALL, fetchOfferCollections({ accessToken }));
    store.dispatch(API_CALL, fetchOffer({ accessToken, id: params.id }));
  }
};
