import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { hasString } from 'src/helpers/string';
import { hasArray } from 'src/helpers/array';

const VerticalInput = ({
  label = undefined, required = false, name, onChange, errors = undefined, value, type = 'text', placeholder = null,
  mustShowDebugInformation = false, mustHandleValidity = false, className = undefined, mustUseFormGroup = true,
  disabled = false, hidden = false, inputName = undefined
}) => {
  const { t } = useTranslation();
  const errorMessage = useMemo(() => errors?.join(', ') ?? t('form.provideValid', { label }), [errors, label, t]);
  const classNames = useMemo(() => {
    let resultClassNames = ['form-control'];

    if (mustHandleValidity && hasArray(errors)) {
      resultClassNames.push('is-invalid');
    }

    const result = resultClassNames.join(' ');

    return result;
  }, [errors, mustHandleValidity]);
  const _className = useMemo(() => hasString(className) ? `${classNames} ${className}` : classNames, [className, classNames]);
  const input = useMemo(() => <>
    {hasString(label) && <label className="form-label" hidden={hidden}>{label}</label>}
    <input
      type={type}
      className={_className}
      name={name}
      placeholder={placeholder ?? label}
      required={required}
      onChange={onChange}
      value={value ?? ''}
      autoComplete="new-password"
      disabled={disabled}
      hidden={hidden}
      data-input-name={inputName}
    />
    {mustShowDebugInformation && <code hidden={hidden}>{JSON.stringify(errors)}</code>}
    {hasString(errorMessage) && <div className="invalid-feedback" hidden={hidden}>{errorMessage}</div>}
  </>, [_className, disabled, errorMessage, errors, hidden, inputName, label, mustShowDebugInformation, name, onChange, placeholder, required, type, value]);

  return (
    <>
      {mustUseFormGroup
        ? <div className="form-group" hidden={hidden}>{input}</div>
        : input
      }
    </>
  );
}

VerticalInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  mustUseFormGroup: PropTypes.bool
};

export default VerticalInput;
