import { notFoundPath } from 'src/helpers/routes';
import NotFoundPage from 'src/components/views/NotFound';

export default {
  path: notFoundPath(),
  exact: true,
  strict: true,
  component: NotFoundPage,
  perform: 'notFoundPage:index',
  title: 'Page Not Found'
};
