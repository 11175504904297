import React from 'react';
import PropTypes from 'prop-types';
import Link from 'src/helpers/Link';
import { propertyPath } from 'src/helpers/routes';


const LinkToProperty = ({ propertyName, propertyId }) => {
  return (
    <span className="link-block">
      <Link to={propertyPath(propertyId)} className="mr-2" target="_blank">{propertyName}</Link>
    </span>
  )
}

LinkToProperty.propTypes = {
  propertyName: PropTypes.string.isRequired,
  propertyId: PropTypes.number.isRequired
};

export default LinkToProperty;
